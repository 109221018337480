import { Flex } from '@chakra-ui/react';

import PortalCardComponent from './PortalCardComponent';

const SelectedPortalsComponent = ({ portals, onDeleteHandler }) => {
  return (
    <Flex direction="row" wrap="wrap">
      {portals?.map((portal, index) => {
        return (
          <PortalCardComponent
            key={portal.uuid}
            portal={portal}
            index={index}
            isSelected={true}
            isDefault={index === 0}
            onDeleteHandler={onDeleteHandler}
          />
        );
      })}
    </Flex>
  );
};

export default SelectedPortalsComponent;
