import { API, Auth } from 'aws-amplify';

import {
  API_VERSION,
  DEFAULT_HEADERS,
  NOTIFICATION,
  SERVER_ROLES,
  USER_ROLES,
} from '../constants/Constants';

export function getSubjectFromCognitoUser(user) {
  return user.attributes.sub || user.data.sub;
}

export function getTokenFromCognitoUser(user) {
  return user.signInUserSession.idToken.jwtToken || user.token;
}

export function getJsonFromUrl(url) {
  var question = url.indexOf('?');
  var hash = url.indexOf('#');
  if (hash === -1 && question === -1) return {};
  if (hash === -1) hash = url.length;
  var query =
    question === -1 || hash === question + 1
      ? url.substring(hash)
      : url.substring(question + 1, hash);
  var result = {};
  query.split('&').forEach(function (part) {
    if (!part) return;
    part = part.split('+').join(' '); // replace every + with space, regexp-free version
    var eq = part.indexOf('=');
    var key = eq > -1 ? part.substr(0, eq) : part;
    var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : '';
    var from = key.indexOf('[');
    if (from === -1) result[decodeURIComponent(key)] = val;
    else {
      var to = key.indexOf(']', from);
      var index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
}

export function getHashValueFromUrl() {
  return window.location.hash.replace('#', '').replace(/20/g, '');
}

export function sendMessageToSlack(type, message, user = null) {
  return new Promise((resolve, reject) => {
    let color = '#E53E3E';
    if (type === NOTIFICATION) {
      color = '#00FF00';
    }
    let title = `${type}:\nAPI: ${API_VERSION}\nPage: ${window.location.href}\nUser: `;
    title +=
      user === null ? 'Anonomous' : user.visibleName + ' (' + user.email + ')';
    const url =
      'https://hooks.slack.com/services/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';
    const payload = {
      channel: 'random-channel',
      attachments: [
        {
          title: title,
          text: message,
          author_name: 'Dashboard',
          color: color,
        },
      ],
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) {
          reject(new Error(`Server error ${res.status}`));
        }
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function isAdmin(role) {
  return role === SERVER_ROLES.ADMIN;
}

export function isSuperUser(role) {
  return role === USER_ROLES.SUPER_USER;
}

export function isHost(role) {
  return role === USER_ROLES.HOST;
}

export function isDev(role) {
  return role === USER_ROLES.DEVELOPER;
}

export function isDevSuperOrHost(role) {
  return (
    role === USER_ROLES.HOST ||
    role === USER_ROLES.DEVELOPER ||
    role === USER_ROLES.SUPER_USER
  );
}

export function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export function sortArrayByKey(array, key, reverse = false) {
  if (reverse) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x > y ? -1 : x < y ? 1 : 0;
    });
  } else {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
}

export const refreshPage = async (action) => {
  /* eslint-disable */
  try {
    const user = await Auth.currentAuthenticatedUser();
    const session = user.getSignInUserSession();

    await user.refreshSession(session.refreshToken, (err, newSession) => {
      if (err) {
        // console.error(err);
        throw err;
      }

      const { idToken } = newSession;
      action(idToken.jwtToken);
    });
  } catch (err) {
    throw err;
  }
};

/**
 * Replaces spaces in a string
 * @param {string} filename
 * @returns string
 */
export const sanitizeS3FileName = (filename) => {
  return filename.replace(/%20| |\+/g, '_');
};

export function makeId(length) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getClosest = (array, value) => {
  var closest;
  array.some(function (a) {
    if (a >= value) {
      return true;
    }
    closest = a;
  });
  return closest;
};

export const CopyToClipboard = (txt) => {
  if (navigator && navigator.clipboard) {
    navigator.clipboard.writeText(txt);
    return true;
  }
  return false;
};

export const removeSpecialChar = (txt) => {
  txt.replace(/[^a-zA-Z0-9 ]/g, '');
  return txt;
};

export const detectOS = (os) => {
  let OSName = null;
  if (navigator) {
    if (
      navigator.userAgent.indexOf('Win') !== -1 ||
      navigator.appVersion.indexOf('Win') !== -1
    )
      OSName = 'Windows';
    if (
      navigator.userAgent.indexOf('Mac') !== -1 ||
      navigator.appVersion.indexOf('Mac') !== -1
    )
      OSName = 'MacOS';
    if (
      navigator.userAgent.indexOf('Linux') !== -1 ||
      navigator.appVersion.indexOf('Linux') !== -1
    )
      OSName = 'Linux';
    if (
      navigator.userAgent.indexOf('Android') !== -1 ||
      navigator.appVersion.indexOf('Android') !== -1
    )
      OSName = 'Android';
    if (
      navigator.userAgent.indexOf('X11') !== -1 ||
      navigator.appVersion.indexOf('X11') !== -1
    )
      OSName = 'UNIX';
    if (
      navigator.userAgent.indexOf('like Mac') !== -1 ||
      navigator.appVersion.indexOf('like Mac') !== -1
    )
      OSName = 'iOS';
  }

  return OSName === os;
};

export const removeTeamApi = (accessToken, teamId) => {
  return new Promise((resolve, reject) => {
    const apiName = API_VERSION;
    const path = `/${API_VERSION}/teams/delete/${teamId}`;
    const myInit = {
      headers: {
        ...DEFAULT_HEADERS,
        token: accessToken,
      },
    };

    API.get(apiName, path, myInit)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
