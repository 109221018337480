export const TOWERDEFENCE = 'TowerDefense';
export const MANSION = 'Mansion';
export const MANSIONLOGIN = 'MansionLogin';
export const HQ = 'HQ';
export const DEFAULTHQ = 'DefaultHQ';
export const TEAMRACE = 'TeamRace';
export const PAINTBALL = 'Paintball';
export const HIDEANDSEEK = 'HideAndSeek';
export const BULLETTIME = 'BulletTime';
export const YOGA = 'Yoga';
export const HQSMALL = 'HQSmall';
export const TOWERDEFENCE_V2 = 'TowerDefense_V2';
export const THREE_SIXTY_CINEMA = 'ThreeSixtyCinema';
export const ESCAPE = 'EscapeRoom_SpaceStation';
export const HQBIG = 'HQBig';
export const CONFERENCE_ROOM = 'ConferenceRoomLarge';
