import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import React from 'react';

import { DeeplinksComponent } from './deepLinks/Deeplinks';
import YouTubeLinksComponent from './youtubeLinks/youtubeLinks';

const LinksComponent = () => {
  return (
    <>
      <h1>Manage Links</h1>
      <Tabs>
        <TabList>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            YouTube Links
          </Tab>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            Deep Links
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <YouTubeLinksComponent />
          </TabPanel>
          <TabPanel>
            <DeeplinksComponent />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default LinksComponent;
