import { DeleteIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useMemo, useEffect, useState } from 'react';

import { ACTION } from '../../actions/actions';
import FullScreenLoading from '../../components/FullScreenLoading';
import TableComponent from '../../components/TableComponent';
import { TEAM } from '../../constants/Routes';
import { useAppStateContext } from '../../context/appContext';
import { checkToken } from '../../services/authService';
import { isAdmin, removeTeamApi } from '../../services/commonService';

import { getServerList } from './DashboardService';

const PublicPrivateLabel = ({ isPublic }) => {
  const color = isPublic ? 'blue' : 'orange';
  const label = isPublic ? 'Public' : 'Private';
  return (
    <Badge
      bg={color}
      color="white"
      fontWeight="normal"
      p={1}
      width="60px"
      textAlign="center"
      borderRadius={3}
    >
      {label}
    </Badge>
  );
};

export const ServersTableComponent = ({ accessToken, onCreateFirstServer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [servers, setServers] = useState([]);
  const [serversTableData, setServersTableData] = useState([]);
  const [serverIdToDelete, setServerIdToDelete] = useState(null);
  const [serverNameToDelete, setServerNameToDelete] = useState(null);
  const [showDeleteServerModal, setShowDeleteServerModal] = useState(false);
  const [showFirstServerMessage, setShowFirstServerMessage] = useState(false);
  const { state, dispatch } = useAppStateContext();

  useEffect(() => {
    setIsLoading(true);
    getServerList(accessToken)
      .then((res) => {
        if (res === undefined) {
          checkToken(state.token)
            .then((newToken) => {
              dispatch({
                type: ACTION.UPDATE_APP_STATE,
                payload: {
                  ...state,
                  token: newToken,
                },
              });
            })
            .then(() => {
              window.location.reload();
            });
        }

        if (res.teams.length === 0) {
          setShowFirstServerMessage(true);
        }

        setServers(res.teams);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.error(err);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setServersTableData(servers);
  }, [servers]);

  const onCloseModal = () => {
    setShowDeleteServerModal(false);
  };

  function getActions(id, name) {
    return isAdmin ? (
      <Button
        m={1}
        w="10%"
        variant="ghost"
        onClick={() => {
          setServerIdToDelete(id);
          setServerNameToDelete(name);
          setShowDeleteServerModal(true);
        }}
      >
        <DeleteIcon boxSize={'20px'} />
      </Button>
    ) : null;
  }

  const data = useMemo(
    () => [...serversTableData],
    [serversTableData, servers]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'teamVisibleName',
        cell: (info) => {
          const url = `/${TEAM}/${info.row.original.teamName}`;
          return (
            <Link
              _hover={{ color: 'white', textDecoration: 'none' }}
              href={url}
            >
              <Button
                size="sm"
                m={1}
                variant="ghost"
                _hover={{ bg: 'blue', color: 'white' }}
              >
                {info.getValue()}
              </Button>
            </Link>
          );
        },
        header: 'Server Name',
        footer: (info) => info.column.id,
        filterFn: 'fuzzy',
      },
      {
        accessorKey: 'ownerEmail',
        cell: (info) => {
          return <Text isTruncated>{info.getValue()}</Text>;
        },
        header: 'Owner',
        footer: (info) => info.column.id,
        filterFn: 'fuzzy',
      },
      {
        accessorKey: 'isPublic',
        cell: (info) => {
          return <PublicPrivateLabel isPublic={info.getValue()} />;
        },
        header: 'Type',
        footer: (info) => info.column.id,
        filterText: {
          trueValue: 'Public',
          falseValue: 'Private',
        },
        enableSorting: false,
      },
      {
        id: 'actions',
        cell: (info) => {
          return isAdmin
            ? getActions(
                info.row.original.uuid,
                info.row.original.teamVisibleName
              )
            : '';
        },
        header: isAdmin ? 'Delete' : '',
        footer: (info) => info.column.id,
      },
    ],
    []
  );

  return (
    <>
      {isLoading ? (
        <FullScreenLoading />
      ) : (
        <TableComponent
          data={data}
          columns={columns}
          enableGlobalFilter={false}
        />
      )}

      {showFirstServerMessage ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          height={600}
        >
          <h2>Create your first server</h2>
          <Box mt={2} mb={5} color={'#555'} width={'50%'} textAlign={'center'}>
            Create your own custom server to use as your home base. Invite
            colleagues and friends to join your server and choose whether to set
            your server as private or public.
          </Box>
          <Button
            colorScheme="blue"
            mr={5}
            onClick={() => {
              onCreateFirstServer();
            }}
          >
            Create Server
          </Button>
        </Box>
      ) : null}

      {showDeleteServerModal ? (
        <Modal
          isCentered
          closeOnOverlayClick={true}
          isOpen={showDeleteServerModal}
          onClose={() => {
            setShowDeleteServerModal(false);
          }}
        >
          <ModalOverlay />
          <ModalContent
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <ModalHeader>Warning!</ModalHeader>
            <ModalBody
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text textAlign="center" fontSize="sm" mb={5} mt={3}>
                Are you sure you want to permanently delete{' '}
                <strong>{serverNameToDelete}</strong>? This can not be undone.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                fontSize="sm"
                onClick={() => {
                  onCloseModal();
                }}
                mx={2}
              >
                Cancel
              </Button>
              <Button
                fontSize="sm"
                variant="solid"
                colorScheme="red"
                _hover={{ bg: 'red' }}
                onClick={() => {
                  setIsLoading(true);
                  removeTeamApi(accessToken, serverIdToDelete).then(() => {
                    getServerList(accessToken)
                      .then((res) => {
                        setServersTableData(res.teams);
                        setIsLoading(false);
                        setShowDeleteServerModal(false);
                      })
                      .catch((err) => {
                        // console.error(err);
                        setIsLoading(false);
                        setShowDeleteServerModal(false);
                      });
                  });
                }}
                mx={2}
              >
                Delete Server
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  );
};
