import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  Button,
} from '@chakra-ui/react';

const TagSelect = ({ tags, setTags, options, entityName = 'Tags' }) => {
  const handleToggleTag = (tag) => {
    if (tags.includes(tag)) {
      setTags(tags.filter((t) => t !== tag));
    } else {
      setTags([...tags, tag]);
    }
  };

  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} colorScheme="blue">
        Select {entityName} ({tags.length} selected)
      </MenuButton>
      <MenuList>
        {options.map((option) => (
          <MenuItem key={option.value} minH="40px">
            <Checkbox
              isChecked={tags.includes(option.value)}
              onChange={() => handleToggleTag(option.value)}
            >
              {option.label}
            </Checkbox>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default TagSelect;
