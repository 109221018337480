import { CheckCircleIcon, DeleteIcon } from '@chakra-ui/icons';
import { Button, Flex, Text, useToast } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import FullScreenLoading from '../../../../components/FullScreenLoading';
import TableComponent from '../../../../components/TableComponent';
import { useAppStateContext } from '../../../../context/appContext';
import {
  getMorphStoreItems,
  deleteMorphStoreItems,
} from '../services/morphStore';

import AddMorphStoreItemsModal from './AddMorphStoreItemsModal';

export default function MorphStoreItemsComponent() {
  const state = useAppStateContext()?.state;
  const token = state?.token ?? '';
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [storeItems, setStoreItems] = useState([] as MorphStoreItem[]);
  const [addStoreItemsModalVisible, setAddStoreItemsModalVisible] =
    useState(false);

  useEffect(() => {
    getAllStoreItems();
  }, []);

  async function getAllStoreItems() {
    setStoreItems([]); // Clear the store items
    let lastEvaluatedKey = null;
    do {
      const response: RemioResponseV2<GetMorphStoreItemsResponse> =
        await getMorphStoreItems(token, 100, lastEvaluatedKey);
      const items = response.data.storeItems;
      setStoreItems((prevItems) => [...prevItems, ...items]);
      lastEvaluatedKey = (response.meta as { lastEvaluatedKey: string | null })
        ?.lastEvaluatedKey; // Add type assertion for response.meta
      // Wait for 1/4 second before making the next call
      await new Promise((resolve) => setTimeout(resolve, 250));
    } while (lastEvaluatedKey);
  }

  function handleAddStoreItems() {
    setAddStoreItemsModalVisible(true);
  }

  function onCloseAddStoreItemsModal() {
    setAddStoreItemsModalVisible(false);
  }

  async function onAddStoreItemsSuccess() {
    setAddStoreItemsModalVisible(false);
    await getAllStoreItems();
  }

  const deleteStoreItem = async (storeItemId: string) => {
    setIsLoading(true);
    try {
      await deleteMorphStoreItems(token, storeItemId);
      toast({
        title: 'Store Item Deleted',
        description: `Store Item Deleted`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      await getAllStoreItems();
    } catch (error) {
      toast({
        title: 'Error Deleting Store Item',
        description: `Error Deleting Store Item`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const data = useMemo(() => [...storeItems], [storeItems]);
  const columnHelper = createColumnHelper<MorphStoreItem>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.visibleName, {
        id: 'visibleName',
        header: 'Name',
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.description, {
        id: 'description',
        header: 'Description',
        cell: (info) => {
          return <Text isTruncated>{info.getValue()}</Text>;
        },
        footer: (props) => props.column.id,
        enableSorting: false,
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: 'SKU',
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.category, {
        id: 'category',
        header: 'Category (Store)',
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.subcategory, {
        id: 'subcategory',
        header: 'Subcategory',
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.isCredit, {
        id: 'isCredit',
        header: 'Credit?',
        cell: (info) => {
          return info.getValue() ? <CheckCircleIcon /> : null;
        },
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.isConsumable, {
        id: 'isConsumable',
        header: 'Consumable?',
        cell: (info) => {
          return info.getValue() ? <CheckCircleIcon /> : null;
        },
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.omitInventory, {
        id: 'omitInventory',
        header: 'Added to inventory?',
        cell: (info) => {
          return !info.getValue() ? <CheckCircleIcon /> : null;
        },
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: 'delete',
        header: 'Delete',
        cell: (info) => {
          return (
            <Button
              m={1}
              w="10%"
              variant="ghost"
              onClick={() => {
                deleteStoreItem(info.row.original.id);
              }}
            >
              <DeleteIcon boxSize={'20px'} />
            </Button>
          );
        },
        footer: (props) => props.column.id,
      }),
    ],
    []
  );

  return (
    <Flex direction="column" height="100%">
      {isLoading ? <FullScreenLoading /> : null}
      <Button m={1} w="15%" onClick={handleAddStoreItems}>
        Add Store Items
      </Button>
      {storeItems.length < 1 ? (
        'You have not added any store items as yet. Time for a change ;-)'
      ) : (
        <TableComponent data={data} columns={columns} />
      )}
      {addStoreItemsModalVisible ? (
        <AddMorphStoreItemsModal
          onClose={onCloseAddStoreItemsModal}
          onSuccess={onAddStoreItemsSuccess}
        />
      ) : null}
    </Flex>
  );
}
