import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Text, useToast } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import TableComponent from '../../../../components/TableComponent';
import { useAppStateContext } from '../../../../context/appContext';
import { getAllStores, deleteStore } from '../services/stores';


const StoresComponent = () => {
  const state = useAppStateContext()?.state;
  const token = state?.token ?? '';
  const toast = useToast();
  const [stores, setStores] = useState([] as Store[]);

  useEffect(() => {
    getAllStores(token).then((res) => {
      const stores = JSON.parse(res.body);
      setStores(stores);
    });
  }, []);

  const deleteStoreById = async (storeId: string) => {
    try {
      await deleteStore(token, storeId);
      toast({
        title: 'Store Deleted',
        description: `Store Deleted`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      const response = await getAllStores(token);
      const storesResponse = JSON.parse(response.body);
      setStores(storesResponse);
    } catch (error) {
      toast({
        title: 'Error Deleting Store',
        description: `Error Deleting Store`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const data = useMemo(() => [...stores], [stores]);
  const columnHelper = createColumnHelper<Store>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.visibleName, {
        id: 'visibleName',
        header: 'Name',
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.description, {
        id: 'description',
        header: 'Description',
        cell: (info) => {
          return <Text isTruncated>{info.getValue()}</Text>;
        },
        footer: (props) => props.column.id,
        enableSorting: false,
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: 'delete',
        header: 'Delete',
        cell: (info) => {
          return (
            <Button
              m={1}
              w="10%"
              variant="ghost"
              onClick={() => {
                deleteStoreById(info.row.original.id);
              }}
            >
              <DeleteIcon boxSize={'20px'} />
            </Button>
          );
        },
        footer: (props) => props.column.id,
      }),
    ],
    []
  );

  return (
    <>
      {stores.length < 1 ? (
        'You have not added any stores as yet. Time for a change ;-)'
      ) : (
        <TableComponent data={data} columns={columns} />
      )}
    </>
  );
};

export default StoresComponent;
