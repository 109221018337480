import { Box, Button, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { isSuperUser } from '../../../../services/commonService';

const ServerIdComponent = () => {
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const superUser = isSuperUser(state.role) || false;

  return (
    <>
      {superUser ? (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems={'center'}
          overflow="auto"
          flex={1}
        >
          <Box width={'180px'}>
            <Text>ID</Text>
          </Box>
          <Box mb={4}>
            <Text>{teamState.teamId}</Text>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default ServerIdComponent;
