/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

export function postForm(
  path: string,
  params: Record<string, string>,
  file: File,
  enctype = 'multipart/form-data'
) {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = path;
  form.enctype = enctype;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.value = params[key];
      hiddenField.name = key;
      form.appendChild(hiddenField);
    }
  }

  let formData = new FormData(form);
  formData.append('file', file);

  return fetch(path, {
    method: 'POST',
    body: formData,
  });
}
