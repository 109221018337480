import { Box, Button, useDisclosure, useToast } from '@chakra-ui/react';
import bs from 'binary-search';
import path from 'path-browserify';
import { useEffect, useState } from 'react';

import { ConfirmModal } from '../../../components/ConfirmModal';
import FullScreenLoading from '../../../components/FullScreenLoading';
import { useAppStateContext } from '../../../context/appContext';
import { PresignedUrlBody } from '../../../types/PresignedUrlBody';
import { getPostPresignedUrlPortalImage } from '../../../services/awsService';
import { postForm } from '../../../services/uploadService';
import {
  getErrorMessage,
  s3UploadThrowIfError,
} from '../../../utils/errorUtils';

import { PortalModal } from './PortalModal';
import {
  PortalDto,
  listPortals,
  updatePortal,
  deletePortal,
} from './PortalsService';
import { PortalsTable } from './PortalsTable';

export const Portals = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState<PortalDto[]>([]);
  const state = useAppStateContext()?.state;
  const portalModal = useDisclosure();
  const deletePortalModal = useDisclosure();
  const [selectedPortal, setSelectedPortal] = useState<
    Partial<PortalDto> | undefined
  >();
  const [doorPreviewImage, setDoorPreviewImage] = useState<File | undefined>();
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState<string>('');
  const toast = useToast();

  function newPortal() {
    setSelectedPortal({});
    portalModal.onOpen();
  }

  function editPortal(portal: PortalDto) {
    setSelectedPortal(portal);
    portalModal.onOpen();
  }

  function confirmDeletePortal(portal: PortalDto) {
    setSelectedPortal(portal);
    setConfirmDeleteMessage(
      `Do you really want to delete portal ${portal.uuid}?`
    );
    deletePortalModal.onOpen();
  }

  function deletePortalConfirmed(portal: Partial<PortalDto>) {
    (async () => {
      if (!portal.uuid) return;

      try {
        setIsLoading(true);

        await deletePortal(state?.token ?? '', portal.uuid);

        const newItems = [...items];
        const deletedIdx = bs(newItems, portal, (a, b) =>
          (a.uuid ?? '').localeCompare(b.uuid ?? '')
        );
        if (deletedIdx >= 0) {
          newItems.splice(deletedIdx, 1);
          setItems(newItems);
        }

        toast({
          title: 'Portal deleted.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (err) {
        showErrorMessage(getErrorMessage(err));
      } finally {
        setIsLoading(false);
        deletePortalModal.onClose();
      }
    })();
  }

  function showErrorMessage(msg: string | null) {
    toast({
      title: 'Error',
      description: msg,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  }

  async function onSavePortal(
    _isNewPortal: boolean,
    portal: Partial<PortalDto>,
    previewImage: File | null
  ) {
    try {
      setIsLoading(true);
      setSelectedPortal(portal);
      if (previewImage) {
        setDoorPreviewImage(previewImage);
      }

      // TODO: if it is new portal - check if there is no portal with this uuid to not overwrite portal

      const submitPortal: Partial<PortalDto> = { ...portal };
      if (previewImage) {
        const fileName = `${portal.uuid}-preview${path.extname(
          previewImage.name
        )}`;
        const { body } = await getPostPresignedUrlPortalImage(
          state?.token ?? '',
          fileName
        );
        const parsedBody: PresignedUrlBody | undefined =
          body && JSON.parse(body);
        if (parsedBody) {
          const response = await postForm(
            parsedBody.url,
            parsedBody.fields,
            previewImage
          );
          await s3UploadThrowIfError(response);

          // Backend will substitute ${portal_previews_prefix} with actual value
          submitPortal.doorPreview =
            '${portal_previews_prefix}/' + `${fileName}`;
          // Set to undefined so JSON.stringify drops them and the backend defaults to using doorPreview
          submitPortal.webPreviewUrl = undefined;
          submitPortal.webPreview = undefined;
        }
      }

      const newPortal = await updatePortal(
        state?.token ?? '',
        portal.uuid ?? '',
        submitPortal
      );

      const newItems = [...items];
      const insertIdx = bs(newItems, newPortal, (a, b) =>
        (a.uuid ?? '').localeCompare(b.uuid ?? '')
      );
      if (insertIdx >= 0) {
        newItems[insertIdx] = newPortal;
      } else {
        newItems.splice(~insertIdx, 0, newPortal);
      }

      setItems(newItems);

      toast({
        title: 'Portal saved.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const listResult = await listPortals(state?.token ?? '');
        const sortedItems = listResult?.items ?? [];
        sortedItems.sort((a, b) => (a.uuid ?? '').localeCompare(b.uuid ?? ''));
        setItems(sortedItems);
      } catch (err) {
        showErrorMessage(getErrorMessage(err));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Box
      display="flex"
      flexGrow={'1'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      p={5}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        overflow="auto"
        maxWidth={'1440px'}
      >
        {isLoading ? (
          <FullScreenLoading />
        ) : (
          <Box>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              flexDir={{ base: 'column', md: 'row' }}
            >
              <h1>Manage Portals</h1>
              <Button
                size={'md'}
                onClick={() => {
                  newPortal();
                }}
              >
                New Portal
              </Button>
            </Box>
            <PortalsTable
              items={items}
              onItemEditClick={editPortal}
              onItemDeleteClick={confirmDeletePortal}
            />
            <PortalModal
              {...portalModal}
              portal={selectedPortal}
              doorPreviewImage={doorPreviewImage}
              onSave={onSavePortal}
            />
            <ConfirmModal
              {...deletePortalModal}
              title="Delete portal?"
              message={confirmDeleteMessage}
              onConfirm={() =>
                selectedPortal && deletePortalConfirmed(selectedPortal)
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
