import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import FullScreenLoading from '../../../components/FullScreenLoading';
import MultiSelect from '../../../components/MultiSelect';
import { useAppStateContext } from '../../../context/appContext';

import {
  addItemsToUsers,
  getStoreItemsByCategory,
  getStoreItemsMetadata,
} from './services/gifting';

const GiftItems = () => {
  const { state } = useAppStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeItems, setStoreItems] = useState([]);
  const [details, setDetails] = useState('');
  const [users, setUsers] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const toast = useToast();

  useEffect(() => {
    // Load stores by getting the store item with the ID "categories"
    // The response of that store item is a list of all categories i.e. stores
    // We can then use that list to offer the user a way to select a store and get the items in that store
    const loadStores = async () => {
      setIsLoading(true);
      try {
        const { data } = await getStoreItemsMetadata(state.token);
        const stores = data.categories.sort();
        setStores(stores);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        toast({
          title: 'Error loading stores',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };
    loadStores();
  }, []);

  const findItemById = (id) => {
    return storeItems.find((item) => item.id === id);
  };

  const handleSelectStoreChange = async (e) => {
    if (e.target.value === '') {
      setStoreItems([]);
      return;
    }

    setIsLoading(true);
    try {
      const response = await getStoreItemsByCategory(
        state.token,
        e.target.value
      );
      const jsonBody = JSON.parse(response.body);
      const storeItems = jsonBody.storeItems;
      setStoreItems(storeItems);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toast({
        title: 'Error loading store items',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemsSelection = (selectedIds) => {
    setSelectedItemIds(selectedIds);
  };

  const handleDetailsInputChange = (e) => {
    setDetails(e.target.value);
  };

  const handleUsersInputChange = (e) => {
    const enteredUsers = e.target.value.split('\n');
    const uniqueUsers = Array.from(new Set(enteredUsers.slice(0, 50)));
    setUsers(uniqueUsers);
    setUserCount(uniqueUsers.length);
  };

  const submitGiftItemsRequest = async () => {
    setIsLoading(true);
    let successfulRequests = 0;
    const failedUsers = [];

    for (const user of users) {
      for (const itemId of selectedItemIds) {
        try {
          await addItemsToUsers(state.token, [user.trim()], [itemId], details);
          successfulRequests++;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          if (failedUsers.indexOf(user) === -1) {
            failedUsers.push(user);
          }

          toast({
            title: `Error adding item to user ${user}`,
            description: `Item: ${
              findItemById(itemId)['visibleName']
            } (ID: ${itemId})`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } finally {
          await new Promise((resolve) => setTimeout(resolve, 250));
        }
      }
    }

    if (successfulRequests > 0) {
      toast({
        title: 'Gift Items Request Success',
        description: `${successfulRequests} gifts successfully sent`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Error',
        description: 'All requests failed',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    // Clear all emails and re-add only the failed ones
    setUsers(failedUsers);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}
      <VStack spacing={4}>
        <FormControl>
          <FormLabel htmlFor="store">Store</FormLabel>
          <Select
            name="store"
            onChange={handleSelectStoreChange}
            placeholder="Select store"
          >
            {stores.map((store) => (
              <option key={store} value={store}>
                {store}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="items">Items</FormLabel>
          <MultiSelect
            items={storeItems.map((item) => ({
              id: item.id, // assuming 'id' is the unique identifier
              displayLabel: `${item.visibleName} - ${item.amount} (${item.subcategory})`, // custom label
            }))}
            valueKey="id"
            labelKey="displayLabel"
            onSelect={handleItemsSelection}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="name">Details</FormLabel>
          <Input
            name="details"
            type="text"
            value={details}
            onChange={handleDetailsInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="emails">
            Emails/Oculus Usernames/IDs (Limit 50, Current: {userCount})
          </FormLabel>
          <Textarea
            name="emails"
            type="text"
            value={users.join('\n')}
            onChange={handleUsersInputChange}
            placeholder="Enter users separated by new lines."
          />
        </FormControl>
        <Button
          colorScheme="blue"
          onClick={submitGiftItemsRequest}
          isDisabled={
            selectedItemIds.length === 0 || details === '' || users.length === 0
          }
        >
          Submit
        </Button>
      </VStack>
    </>
  );
};

export default GiftItems;
