import {
  API_VERSION,
  AWS_CONFIG,
  IDENTITY_POOL_ID,
} from './constants/Constants';

const awsmobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: AWS_CONFIG.userPoolId,
  aws_user_pools_web_client_id: AWS_CONFIG.webClientId,
  aws_cognito_identity_pool_id: IDENTITY_POOL_ID,
  federationTarget: 'COGNITO_USER_POOLS',
  API: {
    endpoints: [
      {
        name: API_VERSION,
        endpoint: `https://${AWS_CONFIG.apiAddress}.execute-api.eu-west-1.amazonaws.com`,
      },
    ],
  },
};

export default awsmobile;
