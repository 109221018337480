import {
  CognitoUserSession,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { AWS_CONFIG } from '../constants/Constants';

export async function updateAmplifySession(
  username: string,
  accessToken: string,
  idToken: string,
  refreshToken: string
) {
  // Create Cognito tokens
  const accessCognitoToken = new CognitoAccessToken({
    AccessToken: accessToken,
  });
  const idCognitoToken = new CognitoIdToken({ IdToken: idToken });
  const refreshCognitoToken = new CognitoRefreshToken({
    RefreshToken: refreshToken,
  });

  // Create a new session with the tokens
  const session = new CognitoUserSession({
    AccessToken: accessCognitoToken,
    IdToken: idCognitoToken,
    RefreshToken: refreshCognitoToken,
  });

  // Create a new user object
  const userPool = new CognitoUserPool({
    UserPoolId: AWS_CONFIG.userPoolId ?? '',
    ClientId: AWS_CONFIG.webClientId ?? '',
  });
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  // Manually set the user session in Amplify
  user.setSignInUserSession(session);

  // Update the user in local storage
  await Auth.currentSession();
}
