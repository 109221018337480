import {
  Box,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  createStandaloneToast,
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import React from 'react';

import EventsComponent from './events/events';
import FilesComponent from './files/Files';
import LinksComponent from './links/links';
import MembersComponent from './members/members';
import SettingsComponent from './settings/settings';

import { ACTION } from '../../actions/actions';
import FullScreenLoading from '../../components/FullScreenLoading';
import { PAGE_REFRESH_INTERVAL } from '../../constants/Constants';
import * as ROUTES from '../../constants/Routes';
import { TeamStateContext } from '../../context/teamContext';
import { getUserDetailsApi, getTeamInfo } from '../../services/awsService';
import * as CommonFunctions from '../../services/commonService';
import { MixPanelService } from '../../services/mixpanelService';
import ServerPortalsComponent from './serverPortals/serverPortals';

const { toast } = createStandaloneToast();
const SELECTED_TAB_COLOR = 'yellow';
const HOVER_TAB_COLOR = '#e0bb73';

class Team extends React.Component {
  static contextType = TeamStateContext;
  constructor(props) {
    super(props);
    this.teamName = this.props.match.params['name'];
    this.tabs = {
      serversettings: 0,
      members: 1,
      experiences: 2,
      files: 3,
      links: 4,
      list: ['serversettings', 'members', 'experiences', 'files', 'links'],
    };
    this.defaultTeamColors = '#fff';
    this.state = {
      accessToken: null,
      isLoading: true,
      teamId: null,
      teamName: '',
      teamVisibleName: '',
      myInfo: null,
      tabIndex:
        this.tabs[CommonFunctions.getHashValueFromUrl().toLowerCase()] || 0,
      tempPreview: null,
    };
    this.refreshPageInterval = null;
  }

  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const accessToken = CommonFunctions.getTokenFromCognitoUser(user);
      const userDetailsResponse = await getUserDetailsApi(accessToken);
      const userData = JSON.parse(userDetailsResponse.body);
      userData['superUser'] = CommonFunctions.isSuperUser(userData.role);

      this.setState({
        accessToken,
        myInfo: userData,
      });

      this.refreshTeamInfo();

      this.onPageLoad();

      // initializing data for sending event on create server using MixPanel
      MixPanelService.init(this.state.myInfo.uuid, user?.attributes?.email);

      this.refreshPageInterval = setInterval(async () => {
        try {
          await CommonFunctions.refreshPage((accessToken) => {
            if (accessToken !== this.state.accessToken) {
              this.setState({ accessToken });
            }
          });
        } catch (err) {
          this.props.history.push(`/${ROUTES.SIGNIN}`);
        }
      }, PAGE_REFRESH_INTERVAL * 60 * 1000);
    } catch (error) {
      this.setState({ isLoading: false });
      this.props.history.push(`/${ROUTES.SIGNIN}`);
    }
  }

  componentWillUnmount() {
    if (this.refreshPageInterval) {
      clearInterval(this.refreshPageInterval);
      this.refreshPageInterval = null;
    }
    toast.closeAll();
  }

  // Tabs
  prevTab = null;
  onPageLoad = () => {
    const { tabIndex } = this.state;
    if (tabIndex !== 0) {
      this.tabChangeHandler({
        target: { innerHTML: this.tabs.list[tabIndex] },
      });
    }
  };

  tabChangeHandler = ({ target }) => {
    if (this.prevTab !== target.innerHTML) {
      switch (target.innerHTML) {
        case 'Team Settings':
          break;
        default:
          break;
      }
    }
    this.prevTab = target.innerHTML;
  };

  handleTabsChange = (index) => {
    if (index !== this.state.tabIndex) {
      this.setState({ tabIndex: index });
      this.props.history.push(`#${this.tabs.list[index]}`);
    }
  };

  // Setting Functions
  refreshTeamInfo() {
    getTeamInfo(this.state.accessToken, this.teamName)
      .then((res) => {
        const teamInfo = JSON.parse(res.body);

        let teamColors;
        try {
          teamColors = teamInfo.teamColors
            ? JSON.parse(teamInfo.teamColors)
            : ['#ffffff'];
        } catch (err) {
          teamColors = ['#ffffff'];
        }

        this.setState({
          isLoading: false,
          teamName: teamInfo.teamName,
          teamId: teamInfo.teamId,
          teamVisibleName: teamInfo.teamVisibleName,
        });

        this.context.teamDispatch({
          type: ACTION.UPDATE_TEAM_STATE,
          payload: {
            ...teamInfo,
            teamColors: teamColors,
          },
        });
      })
      .catch((err) => {
        const { message } = err?.response?.data || {};
        if (message === 'No team found') {
          this.props.history.push(`/${ROUTES.NOTFOUND}`);
          return;
        }
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <Box
        display="flex"
        flexGrow={'1'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        {this.state.isLoading ? <FullScreenLoading /> : null}

        {/* Main */}
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          overflow="auto"
        >
          <Tabs
            index={this.state.tabIndex}
            orientation="vertical"
            isLazy
            flexGrow={'1'}
            variant="unstyled"
            onFocus={this.tabChangeHandler}
            onChange={this.handleTabsChange}
          >
            <TabList
              color="#989897"
              bg="dark"
              w="256px"
              display={'flex'}
              flexGrow={'1'}
            >
              <Box
                backgroundColor={SELECTED_TAB_COLOR}
                p={2}
                minHeight={'45px'}
                mb={3}
              >
                <Text
                  color={'dark'}
                  fontWeight={'semibold'}
                  textAlign={'center'}
                >
                  {this.state.teamVisibleName}
                </Text>
              </Box>
              <Tab
                padding={3}
                _selected={{
                  color: SELECTED_TAB_COLOR,
                  bg: 'blue.500',
                  borderRadius: 5,
                }}
                _focus={{
                  outline: 0,
                }}
                _hover={{
                  color: HOVER_TAB_COLOR,
                }}
              >
                Settings
              </Tab>
              <Tab
                padding={3}
                _selected={{
                  color: SELECTED_TAB_COLOR,
                  bg: 'blue.500',
                  borderRadius: 5,
                }}
                _focus={{
                  outline: 0,
                }}
                _hover={{
                  color: HOVER_TAB_COLOR,
                }}
              >
                Members
              </Tab>
              <Tab
                padding={3}
                _selected={{
                  color: SELECTED_TAB_COLOR,
                  bg: 'blue.500',
                  borderRadius: 5,
                }}
                _focus={{
                  outline: 0,
                }}
                _hover={{
                  color: HOVER_TAB_COLOR,
                }}
              >
                Experiences
              </Tab>
              {/* <Tab
                padding={3}
                _selected={{
                  color: SELECTED_TAB_COLOR,
                  bg: 'blue.500',
                  borderRadius: 5,
                }}
                _focus={{
                  outline: 0,
                }}
                _hover={{
                  color: HOVER_TAB_COLOR,
                }}
              >
                Events
              </Tab> */}
              <Tab
                padding={3}
                _selected={{
                  color: SELECTED_TAB_COLOR,
                  bg: 'blue.500',
                  borderRadius: 5,
                }}
                _focus={{
                  outline: 0,
                }}
                _hover={{
                  color: HOVER_TAB_COLOR,
                }}
              >
                Files
              </Tab>
              <Tab
                padding={3}
                _selected={{
                  color: SELECTED_TAB_COLOR,
                  bg: 'blue.500',
                  borderRadius: 5,
                }}
                _focus={{
                  outline: 0,
                }}
                _hover={{
                  color: HOVER_TAB_COLOR,
                }}
              >
                Links
              </Tab>
            </TabList>

            <TabPanels px={5} style={{ width: '90%' }}>
              <TabPanel>
                <SettingsComponent />
              </TabPanel>
              <TabPanel>
                <MembersComponent />
              </TabPanel>
              <TabPanel>
                <ServerPortalsComponent />
              </TabPanel>
              {/* <TabPanel>
                <EventsComponent />
              </TabPanel> */}
              <TabPanel>
                <FilesComponent />
              </TabPanel>
              <TabPanel>
                <LinksComponent />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    );
  }
}

export default Team;
