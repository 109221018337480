import { Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { CompatRouter, CompatRoute } from 'react-router-dom-v5-compat';

import './App.css';
import { LayoutComponent } from './components/Layout';
import { TeamLayoutComponent } from './components/teamLayout';
import { IS_ENTERPRISE } from './constants/Constants';
import * as ROUTES from './constants/Routes';
import { TeamStateProvider } from './context/teamContext';
import useInactivityLogout from './hooks/useInactivityLogout';
import NotFound from './pages/4xx/NotFound';
import AccountPage from './pages/account/Account';
import Admin from './pages/admin/Admin';
import UserReport from './pages/admin/userReports/UserReport';
import Cloud from './pages/cloud/AgoraClient';
import Dashboard from './pages/dashboard/Dashboard';
import DownloadWindowsApp from './pages/downloadWindowsApp/DownloadWindowsApp';
import Gallery from './pages/gallery/Gallery';
import People from './pages/people/People';
import Register from './pages/register/Register';
import ScreenShare from './pages/screenShare/ScreenShare';
import SendPushNotification from './pages/sendPushNotification/sendPushNotification';
import SignIn from './pages/signIn/SignIn';
import Team from './pages/team/Team';
import VerifyEmail from './pages/verifyEmail/verifyEmail';
import VerifyEmailV2 from './pages/verifyEmailV2/verifyEmailV2';

function App({ firebaseAnalytics }) {
  useInactivityLogout();

  return (
    <BrowserRouter>
      <LayoutComponent>
        <CompatRouter>
          <Switch>
            <CompatRoute
              exact
              path={`/${ROUTES.ACCOUNT}`}
              render={(props) => (
                <AccountPage {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.ADMIN}`}
              render={(props) => (
                <Admin {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.ADMIN}/*`}
              render={(props) => (
                <Admin {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            {IS_ENTERPRISE ? (
              <CompatRoute
                exact
                path={`/${ROUTES.CLOUD}/:server?`}
                render={(props) => (
                  <Cloud {...props} fbAnalytics={firebaseAnalytics} />
                )}
              />
            ) : null}
            <CompatRoute
              exact
              path={`/${ROUTES.DASHBOARD}`}
              render={(props) => (
                <Dashboard {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.DOWNLOAD}/:appType?`}
              render={(props) => (
                <DownloadWindowsApp
                  {...props}
                  fbAnalytics={firebaseAnalytics}
                />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.GALLERY}`}
              render={(props) => (
                <Gallery {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.NOTFOUND}`}
              render={() => <NotFound fbAnalytics={firebaseAnalytics} />}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.PEOPLE}`}
              render={(props) => (
                <People {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.REGISTER}`}
              render={(props) => (
                <Register {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.SCREENSHARE}/:server?`}
              render={(props) => (
                <ScreenShare {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.SEND_NOTIFICATION}/meta/:metaUserId/review`}
              render={(props) => (
                <SendPushNotification
                  {...props}
                  fbAnalytics={firebaseAnalytics}
                />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.SIGNIN}`}
              render={(props) => (
                <SignIn {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.TEAM}/:name`}
              render={(props) => (
                <TeamStateProvider>
                  <TeamLayoutComponent>
                    <Team {...props} fbAnalytics={firebaseAnalytics} />
                  </TeamLayoutComponent>
                </TeamStateProvider>
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.VERIFY_EMAIL}`}
              render={(props) => (
                <VerifyEmail {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.VERIFY_EMAIL_V2}`}
              render={(props) => (
                <VerifyEmailV2 {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            <CompatRoute
              exact
              path={`/${ROUTES.USER_REPORTS}/:reportId`}
              render={(props) => (
                <UserReport {...props} fbAnalytics={firebaseAnalytics} />
              )}
            />
            {/* Redirects */}
            <CompatRoute exact path={`/${ROUTES.PORTALS}`}>
              <Redirect to="/admin/portals" />
            </CompatRoute>
            <CompatRoute exact path={`/${ROUTES.GIFTING}`}>
              <Redirect to="/admin/gifting" />
            </CompatRoute>
            {/* Redirect to CloudFront URL for direct APK download */}
            <CompatRoute
              exact
              path={`/${ROUTES.APK_DOWNLOAD}`}
              render={() => {
                window.location.href =
                  'https://d17t9awixw55l7.cloudfront.net/APKs/Enterprise/remio_oculus_prod_3.51.885.apk';
                return null;
              }}
            />
            {/* Redirect to CloudFront URL for direct APK download */}
            <CompatRoute
              exact
              path={`/${ROUTES.PRESS_KIT}`}
              render={() => {
                window.location.href =
                  'https://d17t9awixw55l7.cloudfront.net/Press+Kit/presskit.zip';
                return null;
              }}
            />
            <CompatRoute
              exact
              path={'*'}
              render={() => <NotFound fbAnalytics={firebaseAnalytics} />}
            />
          </Switch>
        </CompatRouter>
      </LayoutComponent>
    </BrowserRouter>
  );
}

export default App;
