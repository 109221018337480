import { Box, Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

const MultiSelect = ({ items, onSelect, valueKey, labelKey }) => {
  const [selectedItemValues, setSelectedItemValues] = useState([]);

  useEffect(() => {
    onSelect(selectedItemValues);
  }, [selectedItemValues, onSelect]);

  return (
    <Box>
      <CheckboxGroup
        value={selectedItemValues}
        onChange={setSelectedItemValues}
      >
        <Stack spacing={2} direction={'column'}>
          {items.map((item) => (
            <Checkbox key={item[valueKey]} value={item[valueKey]}>
              {item[labelKey]}
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    </Box>
  );
};

export default MultiSelect;
