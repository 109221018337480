import { Button } from '@chakra-ui/react';
import React from 'react';
import { AiFillPlayCircle, AiFillPauseCircle } from 'react-icons/ai';

import { useAudio } from '../hooks/useAudio';

const AudioPlayer = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div>
      <Button variant="ghost" onClick={toggle}>
        {playing ? (
          <AiFillPauseCircle size={30} />
        ) : (
          <AiFillPlayCircle size={30} />
        )}
      </Button>
    </div>
  );
};

export default AudioPlayer;
