export function getYouTubeId(url) {
  // eslint-disable-next-line
  const regex = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export function isAddingUrlDisabled(videoUrl) {
  if (videoUrl === '') return true;

  return (
    !videoUrl.includes('https://www.youtube.com/') &&
    !videoUrl.includes('https://youtu.be/')
  );
}
