import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../../constants/Constants';

export const deleteFile = (fileName, type, teamId, accessToken) => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/cloud/${type}/delete/${teamId}/${fileName}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.get(apiName, path, myInit);
};

export const downloadFile = (fileName, type, teamId, accessToken) => {
  return new Promise((resolve, reject) => {
    const apiName = API_VERSION;
    const path = `/${API_VERSION}/teams/cloud/${type}/download/${teamId}/${fileName}`;
    const myInit = {
      headers: {
        ...DEFAULT_HEADERS,
        token: accessToken,
      },
    };

    API.get(apiName, path, myInit)
      .then((res) => {
        const url = JSON.parse(res.body).url;
        resolve(url);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadFile = (type, input, teamId, accessToken) => {
  return new Promise((resolve, reject) => {
    const apiName = API_VERSION;
    const path = `/${API_VERSION}/teams/cloud/${type}/upload/${teamId}/${input.current.files[0].name}`;
    const myInit = {
      headers: {
        ...DEFAULT_HEADERS,
        token: accessToken,
      },
    };

    API.get(apiName, path, myInit)
      .then((res) => {
        resolve(JSON.parse(res.body));
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
};
