import { DeleteIcon, AddIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, Image, Text } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const PortalCardComponent = ({
  portal,
  index,
  isSelected,
  isDefault,
  onDeleteHandler,
  onAddHandler,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: portal.uuid,
      data: {
        portal,
        index,
      },
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      position="relative"
      mr={2}
      mb={2}
      borderColor={isDefault ? 'yellow' : isSelected ? 'blue' : 'grayLight'}
      borderRadius={6}
      borderWidth={6}
      // dnd-kit
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {isDefault ? (
        <Box
          position="absolute"
          top={0}
          width="100%"
          justifyContent="center"
          backgroundColor="yellow"
          padding={1}
        >
          <Text
            color="white"
            fontWeight="semibold"
            textAlign="center"
            fontSize="md"
          >
            Default
          </Text>
        </Box>
      ) : null}
      <Box
        position="absolute"
        bottom={0}
        width="100%"
        justifyContent="center"
        backgroundColor={'rgba(0,0,0,0.4)'}
        padding={1}
      >
        <Text
          color="white"
          fontWeight="semibold"
          textAlign="center"
          fontSize="md"
        >
          {portal.doorTitle === 'None' ? '' : portal.doorTitle}
        </Text>
        {onDeleteHandler ? (
          <Center width="100%">
            <Button
              w="10%"
              variant="ghost"
              onClick={() => {
                onDeleteHandler(portal);
              }}
            >
              <DeleteIcon boxSize={'20px'} color={'white'} />
            </Button>
          </Center>
        ) : null}
        {onAddHandler ? (
          <Center width="100%">
            <Button
              w="10%"
              variant="ghost"
              onClick={() => {
                onAddHandler(portal);
              }}
            >
              <AddIcon boxSize={'20px'} color={'white'} />
            </Button>
          </Center>
        ) : null}
      </Box>
      <Image
        src={portal.webPreviewUrl}
        alt={portal.doorTitle === 'None' ? '' : portal.doorTitle}
        width={'192px'}
        height={'288px'}
        objectFit={'cover'}
      />
    </Box>
  );
};

export default PortalCardComponent;
