import { extendTheme } from '@chakra-ui/react';

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
};

export const theme = extendTheme({
  fonts: {
    body: 'Poppins, sans-serif',
    heading: 'Poppins, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 600,
    extrabold: 700,
    black: 900,
  },
  colors: {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    grayLight: '#DDDEE2',
    grayDark: '#767676',
    dark: '#15141C',
    orange: '#CC5801',
    blue: '#5AC4BD',
    blueDark: '#2F7F7F',
    yellow: '#E3AA3C',
    messageBlue: '#3182CE',
    messageBlueLight: '#BEE3F8',
    messageCyan: '#00B5D8',
    messageCyanLight: '#C4F1F9',
    messageGreen: '#38A169',
    messageGreenLight: '#C6F6D5',
    messageRed: '#E53E3E',
    messageRedLight: '#FED7D7',
    messageOrange: '#CC5801',
    messageOrangeLight: '#FEEBCB',
    messageTeal: '#319795',
    messageTealLight: '#B2F5EA',
  },
  styles: {
    global: {
      'html, body': {
        color: 'dark',
        lineHeight: 'tall',
      },
      a: {
        color: 'blueDark',
        _hover: {
          textDecoration: 'underline',
        },
      },
      h1: {
        m: '2',
        fontSize: { base: '26px', md: '36px' },
        fontWeight: 'semibold',
      },
      h2: {
        m: '2',
        fontSize: { base: '18px', md: '20px' },
        fontWeight: 'semibold',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'regular',
      },
      variants: {
        solid: {
          bg: 'grayDark',
          fontSize: 'md',
          color: 'white',
          _hover: {
            bg: 'blue',
          },
          _disabled: {
            bg: 'grayLight',
            color: 'grayDark',
            opacity: 1,
          },
        },
        clear: {
          bg: 'transparent',
          fontSize: 'md',
          _focusVisible: {
            outline: 'none',
            boxShadow: 'none',
          },
          _focus: {
            outline: 'none',
            boxShadow: 'none',
          },
        },
        cancel: {
          bg: 'transparent',
          fontSize: 'md',
          color: 'orange',
          _hover: {
            bg: 'grayLight',
          },
          _disabled: {
            bg: 'grayLight',
            color: 'grayDark',
            opacity: 1,
          },
        },
      },
    },
    Icon: {
      variants: {
        menuIcon: {
          color: 'white',
        },
      },
    },
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
              color: 'grayDark',
            },
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bg: 'green.500', // Green background
            borderColor: 'green.500',
            color: 'white', // White tick

            // Fallback to blue if green is not defined in your color palette
            _fallback: {
              bg: 'blue.500',
              borderColor: 'blue.500',
            },
          },
        },
      },
    },
  },
});
