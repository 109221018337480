import { Search2Icon } from '@chakra-ui/icons';
import {
  VStack,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

import PortalCardComponent from './PortalCardComponent';

const AvailablePortalsComponent = ({ portals, onAddHandler }) => {
  const [filterValue, setFilterValue] = React.useState('');
  const [availablePortals, setAvailablePortals] = React.useState([]);

  useEffect(() => {
    setAvailablePortals(portals);
  }, [portals]);

  return (
    <VStack>
      <InputGroup size="md" borderWidth={1} borderRadius={5} maxW={256}>
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={Search2Icon} color="gray.300" />}
        />
        <Input
          value={filterValue || ''}
          onChange={(e) => {
            setFilterValue(e.target.value);
            setAvailablePortals(
              portals.filter((portal) =>
                portal.doorTitle
                  ?.toLowerCase()
                  .includes(e.target.value.toLowerCase())
              )
            );
          }}
          placeholder={`Find a portal...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </InputGroup>
      {availablePortals?.map((portal, index) => {
        return (
          <PortalCardComponent
            key={portal.uuid}
            portal={portal}
            index={index}
            onAddHandler={onAddHandler}
          />
        );
      })}
    </VStack>
  );
};

export default AvailablePortalsComponent;
