import {
  Box,
  Button,
  createStandaloneToast,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AiFillCaretDown, AiOutlineLock } from 'react-icons/ai';
import { FaPaperclip } from 'react-icons/fa';
import { MdPublic } from 'react-icons/md';

import { ModalComponent } from '../../components/Modal';
import { updateObjectACL } from '../../services/awsService';
import { CopyToClipboard } from '../../services/commonService';

const { toast } = createStandaloneToast();

export const ShareVideoModal = ({
  modalRef = {},
  accessToken,
  userId,
  video,
  output = () => {},
}) => {
  const [loader, setLoader] = useState(false);
  const [isSharebale, setIsSharebale] = useState(false);
  const [publicModalRef] = useState({});

  useEffect(() => {
    video && setIsSharebale(video.isPublic);
  }, [video]);

  const getShareablePublicLink = (acl) => {
    setLoader(true);
    updateObjectACL(accessToken, { key: video.key, acl })
      .then((res) => {
        const { publicUrl } = JSON.parse(res.body);
        if (publicUrl && CopyToClipboard(publicUrl)) {
          setIsSharebale(publicUrl);
          toast({
            title: 'Video is now public',
            description: 'Link copied to clipboard',
            status: 'success',
            duration: 8000,
            isClosable: true,
          });
        } else {
          setIsSharebale(false);
          CopyToClipboard('');
          toast({
            title: 'Video is now private',
            description: 'Only you have access to this video',
            status: 'success',
            duration: 8000,
            isClosable: true,
          });
        }
        setLoader(false);
        video['isPublic'] = !!publicUrl;
        video['publicUrl'] = publicUrl;
        // output({ event: CUSTOM_EVENTS.shareableLink, data: { ...video, isPublic: !!publicUrl, publicUrl } });
      })
      .catch((err) => {
        // console.error('err ', err);
        setLoader(false);
      });
  };

  return (
    <>
      <ModalComponent
        header={'Confirmation'}
        publicConfirmModalRef={publicModalRef}
        hideCloseButton={true}
        confirmed={() => {
          getShareablePublicLink('public-read');
        }}
        OkBtnText={'Confirm'}
      >
        Do you want to make it public?
      </ModalComponent>

      <ModalComponent
        header={'Share Video'}
        body={''}
        publicConfirmModalRef={modalRef}
        confirmed={() => {}}
        OkBtnText="Done"
        CancelBtnText="Cancel"
      >
        <Box mt={4} mb={2} width={'100%'}>
          <Box mt={4}>
            <Text mb={2} fontWeight={500}>
              General access
            </Text>
            <Box
              border={'1px solid #e5e3e3'}
              borderRadius={20}
              display={'flex'}
              justifyItems="center"
              alignContent={'center'}
              height={'80px'}
              p={2}
            >
              {loader ? (
                <Box
                  width={'100%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Spinner speed="0.65s" size="xs" />
                </Box>
              ) : (
                <>
                  <Box mt={'10px'}>
                    {isSharebale ? (
                      <MdPublic fontSize={22} color="green" />
                    ) : (
                      <AiOutlineLock fontSize={22} />
                    )}
                  </Box>
                  <Box ml={2}>
                    {isSharebale ? (
                      <>
                        <Menu isLazy offset={[1]}>
                          <MenuButton
                            as={Button}
                            size="sm"
                            rightIcon={<AiFillCaretDown />}
                          >
                            Public
                          </MenuButton>
                          <MenuList p={0}>
                            <MenuItem
                              onClick={() => {
                                getShareablePublicLink('private');
                              }}
                            >
                              Restricted
                            </MenuItem>
                          </MenuList>
                        </Menu>
                        <Box display={'flex'} justifyContent={'center'} mt={2}>
                          <Text fontSize="sm">Anyone with a link can view</Text>
                          <Button
                            leftIcon={<FaPaperclip />}
                            size="xs"
                            ml={3}
                            variant="solid"
                            title={'Copy public url'}
                            onClick={() => {
                              if (CopyToClipboard(video.publicUrl)) {
                                toast({
                                  title:
                                    'Link has been copied to the clipboard',
                                  status: 'success',
                                  duration: 8000,
                                  isClosable: true,
                                });
                              }
                            }}
                          >
                            Copy link
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Menu isLazy offset={[1]}>
                          <MenuButton
                            as={Button}
                            size="sm"
                            rightIcon={<AiFillCaretDown />}
                          >
                            Restricted
                          </MenuButton>
                          <MenuList p={0}>
                            <MenuItem
                              onClick={() => {
                                publicModalRef.onOpen();
                              }}
                            >
                              Anyone with a link
                            </MenuItem>
                          </MenuList>
                        </Menu>
                        <Text mt={2} fontSize="sm">
                          Only you can access this video
                        </Text>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </ModalComponent>
    </>
  );
};
