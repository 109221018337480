import {
  Button,
  createStandaloneToast,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';

import { MEMBER_STATUS } from '../../../constants/Constants';
import { useAppStateContext } from '../../../context/appContext';
import { useTeamStateContext } from '../../../context/teamContext';
import { isTeamAdminOrSuperUser } from '../TeamUtils';

import { banByEmailApi } from './membersService';
import { getOptions } from './membersUtils';

const BanModal = ({ isOpen, onClose, onSuccess, editingEmail }) => {
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const { toast } = createStandaloneToast();
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('BANNED');
  const [days, setDays] = useState(0);

  const editing = !!editingEmail;
  const emailVaue = editing ? editingEmail : email;

  const isServerOrSuperAdmin = isTeamAdminOrSuperUser(
    state.role,
    teamState.teamRole
  );

  const handleModalClose = () => {
    onClose();
  };

  const handleEmailInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleBanByEmail = async () => {
    try {
      const res = await banByEmailApi(
        state.token,
        teamState.teamId,
        emailVaue,
        status,
        days
      );
      toast({
        title: 'Member status updated successfully',
        description: `${res.message}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setEmail('');
      setStatus('BANNED');
      setDays(0);
      onSuccess();
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      toast({
        title: 'Error updating member status',
        description: `${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleStatusChange = (e) => {
    let status = e.target.selectedOptions[0].text;
    status = status.split('_')[0];
    if (status === 'SUSPENDED') {
      setStatus(status);
      setDays(e.target.selectedOptions[0].value);
    } else {
      setStatus(status);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <ModalHeader>
            {editing ? 'Edit user ban/suspension' : 'Ban User'}
          </ModalHeader>
          <VStack spacing={2}>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                name="email"
                type="email"
                value={emailVaue}
                disabled={editing}
                onChange={handleEmailInputChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="status">
                {editing ? 'New status' : 'Status'}
              </FormLabel>
              <Select
                name="status"
                onChange={handleStatusChange}
                defaultValue={status}
              >
                {getOptions(MEMBER_STATUS)}
              </Select>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            disabled={!isServerOrSuperAdmin}
            mr={3}
            onClick={handleBanByEmail}
          >
            Ban User
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BanModal;
