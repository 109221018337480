import { SERVER_ROLES, USER_ROLES } from '../../constants/Constants';

export const isTeamAdminOrSuperUser = (userRole, teamRole) => {
  return (
    userRole === USER_ROLES.SUPER_USER || teamRole === SERVER_ROLES.ADMIN
  );
};

export const msToTime = (duration) => {
  const seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
    days = Math.floor(duration / (1000 * 60 * 60 * 24));

  if (days <= 0) {
    if (hours <= 0) {
      if (minutes <= 0) {
        return `${seconds}s`;
      }
      return `${minutes} min(s), ${seconds}s`;
    }
    return `${hours} hr(s), ${minutes} min(s)`;
  }

  if (isNaN(days)) return null;

  if (days > 365 && days <= 1095) {
    return 'Some time';
  } else if (days > 1095) {
    return null;
  }

  return days + ' day(s)';
};
