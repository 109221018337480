import { Text, Grid, GridItem, Input, VStack } from '@chakra-ui/react';
import { useState } from 'react';

interface UserDetailsProps {
  userDetails: UserDetails;
}

function UserDetailsRow({
  label,
  value,
  disabled,
}: {
  label: string;
  value: string;
  disabled: boolean;
}) {
  return (
    <Grid templateColumns="1fr 2fr;">
      <GridItem>
        <Text>{label}: </Text>
      </GridItem>
      <GridItem>
        <Input value={value} disabled={disabled} />
      </GridItem>
    </Grid>
  );
}

export default function UserDetailsComponent({
  userDetails,
}: UserDetailsProps) {
  const [isEditable, setIsEditable] = useState(false);

  return (
    <VStack>
      <h2>User Details</h2>
      <Grid templateColumns="1fr;">
        <UserDetailsRow
          label="UUID"
          value={userDetails.uuid}
          disabled={!isEditable}
        />
        <UserDetailsRow
          label="Email"
          value={userDetails.email}
          disabled={!isEditable}
        />
        <UserDetailsRow
          label="Name"
          value={userDetails.visibleName}
          disabled={!isEditable}
        />
        <UserDetailsRow
          label="Role"
          value={userDetails.role.toString()}
          disabled={!isEditable}
        />
        <UserDetailsRow
          label="Created"
          value={userDetails.created}
          disabled={!isEditable}
        />
        <UserDetailsRow
          label="Last Seen"
          value={userDetails.lastSeen}
          disabled={!isEditable}
        />
        <UserDetailsRow
          label="Oculus ID"
          value={userDetails.oculusId}
          disabled={!isEditable}
        />
        <UserDetailsRow
          label="Oculus App Scoped ID"
          value={userDetails.oculusAppScopedId}
          disabled={!isEditable}
        />
        <UserDetailsRow
          label="Oculus Username"
          value={userDetails.oculusUsername}
          disabled={!isEditable}
        />
        <UserDetailsRow
          label="Remz"
          value={userDetails.remzBalance.toString()}
          disabled={!isEditable}
        />
      </Grid>
    </VStack>
  );
}
