import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Select,
  createStandaloneToast,
  HStack,
  Image,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import AgoraRTM from 'agora-rtm-sdk';
import { Auth } from 'aws-amplify';
import React from 'react';

import { ACTION } from '../../actions/actions';
import { ModalComponent } from '../../components/Modal';
import {
  PUBLIC_SERVER_UID,
  PUBLIC_SERVER_NAME,
  AGORA_APP_ID,
  API_VERSION,
  AGORA_SCREEN_SHARE_OPTIONS,
} from '../../constants/Constants';
import * as ROUTES from '../../constants/Routes';
import { AppStateContext } from '../../context/appContext';
import * as awsApi from '../../services/awsService';
import { isSuperUser, detectOS } from '../../services/commonService';
import { getLoadingModal } from '../../utils/CommonComponents';

const { toast } = createStandaloneToast();
class ScreenShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      accessToken: null,
      superUser: false,
      isLoading: true,
      loadingSpaces: false,
      displayServerSelectModal: false,
      servers: [],
      selectedServer: '',
      spaces: [],
      selectedSpace: '',
      screenShareVideoEnabled: false,
      teamName: this.props.match.params['server'] || '',
    };
    this.videoTrack = null;
    this.options = {
      appid: null,
      desktopchannel: 'TestTest',
      token: null,
      msgChannelName: null,
      desktopuid: null,
    };
    this.isWindows = detectOS('Windows');
    this.options.appid = AGORA_APP_ID;
    this.toastId = React.createRef();
    this.keepAliveMessage = null;
    this.desktopClient = AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'h264',
    });
    this.shareScreenConfrimModalRef = {};
  }
  static contextType = AppStateContext;

  async componentDidMount() {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      // console.log(currentUser);
      const accessToken = currentUser.signInUserSession.idToken.jwtToken;

      this.createMessageClientAndConnectListeners();

      const { body } = await awsApi.getUserDetailsApi(accessToken);
      const userData = JSON.parse(body);
      const superUser = isSuperUser(userData.role);

      this.login(`${userData.uuid}-${Date.now()}`)
        .then(() => {
          awsApi.getServerListApi(accessToken).then(async (res) => {
            const { teams: servers } = JSON.parse(res.body);

            let selectedServer = '';
            if (this.state.teamName) {
              const selectedServerArr = servers.filter(
                (s) => s.teamName === this.state.teamName
              );
              if (selectedServerArr.length > 0) {
                selectedServer = selectedServerArr[0].uuid;
              }
            }

            this.setState(
              {
                servers,
                selectedServer,
                currentUser,
                userData,
                accessToken,
                superUser,
              },
              this.checkUserStatus
            );
          });
          // .catch((err) => console.error(err));
        })
        .catch((err) => {
          // console.error(err);
        });
    } catch (err) {
      // console.error(err);
      this.props.history.push(
        `/${ROUTES.SIGNIN}?reroute=${ROUTES.SCREENSHARE}`
      );
    }
  }

  componentWillUnmount() {
    this.cleanPage();
  }

  isUserOnline = () => {
    const {
      userData: { userStatus = {} },
    } = this.state;
    return userStatus && userStatus.is_online ? userStatus : null;
  };

  checkUserStatus() {
    const _userStatus = this.isUserOnline();
    const data = {
      selectedServer: this.state.selectedServer || '',
      selectedSpace: '',
    };

    if (_userStatus) {
      // console.log('user status', _userStatus);
      const gameName = _userStatus['current_room'].split('-')[0];
      this.getServerSpaces(_userStatus['current_space'], gameName);
      const server = this.state.servers.find(
        ({ teamName }) => teamName === _userStatus['current_space']
      );
      if (server) {
        data['selectedServer'] = server['uuid'];
      } else {
        data['selectedServer'] = _userStatus['current_space'];
      }
      data['selectedSpace'] = gameName;
    }

    if (this.state.selectedServer) {
      this.getServerSpaces(this.state.selectedServer);
    }

    this.setState({
      ...data,
      displayServerSelectModal: !_userStatus?.is_online,
      isLoading: false,
    });
  }

  cleanPage() {
    if (this.messagClient) {
      this.messagClient.removeAllListeners();
      this.messagClient = null;
    }
    if (this.msgChannel) {
      this.msgChannel.removeAllListeners();
      this.msgChannel = null;
    }

    if (this.keepAliveMessage) {
      clearInterval(this.keepAliveMessage);
      this.keepAliveMessage = null;
    }
    toast.closeAll();
  }

  createMessageClientAndConnectListeners() {
    if (this.messagClient) this.messagClient.removeAllListeners();

    if (!this.messagClient) {
      this.messagClient = AgoraRTM.createInstance(this.options.appid);

      // Client Event listeners
      // Display messages from peer
      this.messagClient.on('MessageFromPeer', (message, peerId) => {
        // console.log('message from peer ' + peerId);
        // console.log(message);
      });
      // Display connection state changes
      this.messagClient.on('ConnectionStateChanged', (state, reason) => {
        // console.log(state);
        // console.log(reason);
      });

      this.messagClient.on('ChannelMessage', (message, memberId) => {
        // console.log(message);
        // console.log(memberId);
      });
    }
    // console.log(this.messagClient);
  }

  createMessageChannelAndConnectListeners(channelName = null) {
    if (channelName) this.options.msgChannelName = channelName;

    if (!this.options.msgChannelName) {
      // console.error('No message channel Name! ');
      return;
    }

    if (!this.msgChannel) {
      this.msgChannel = this.messagClient.createChannel(
        this.options.msgChannelName
      );
      // Display msgChannel member stats
      this.msgChannel.on('MemberJoined', (memberId) => {
        // console.log(memberId + ' joined');
      });
      // Display msgChannel member stats
      this.msgChannel.on('MemberLeft', (memberId) => {
        // console.log(memberId + ' left');
      });
    }
    // console.log(this.msgChannel);
  }

  startKeepAliveMessages() {
    if (this.keepAliveMessage) clearInterval(this.keepAliveMessage);

    this.keepAliveMessage = setInterval(() => {
      const message = `${AGORA_SCREEN_SHARE_OPTIONS.SCREEN_SHARE_JOIN}&0.0.0&${this.options.desktopchannel}&${this.options.desktopuid}`;
      this.sendChannelMessage(message);
    }, 5000);
  }

  getServerSpaces(serverId, userSpace = '') {
    this.setState({
      loadingSpaces: true,
      selectedSpace: '',
      selectedServerInfo: null,
      selectedSpaceInfo: null,
    });
    let displayServerSelectModal = true;
    awsApi
      .getTeamInfo(
        this.state.accessToken,
        serverId || this.state.selectedServer,
        'availableTeamSpaces=1'
      )
      .then((res) => {
        const teamInfo = JSON.parse(res.body);
        // console.log(teamInfo);
        const spaces = [];
        if (teamInfo.availableTeamSpaces) {
          teamInfo.availableTeamSpaces.forEach((space) => {
            spaces.push(space);
          });
        }
        // console.log('spaces ', spaces);

        if (userSpace) {
          const space = spaces.filter(({ gameName }) => gameName === userSpace);
          if (space) {
            // user is loggedin and have server and space on which we can sharescreen.
            // console.log('user is online and space allowed screen sharing');
            displayServerSelectModal = false;
          } else {
            // console.log('user is online and space not allowed screen sharing');
            userSpace = '';
            displayServerSelectModal = true;
          }
        }

        this.setState(
          {
            loadingSpaces: false,
            spaces,
            selectedSpace: userSpace,
            displayServerSelectModal,
          },
          () => {
            if (!displayServerSelectModal && userSpace) {
              this.getServerAndSpaceName('getServerSpaces');
            }
          }
        );
      })
      .catch((err) => {
        // console.error(err);
        this.setState({ loadingSpaces: false });
      });
  }

  getTeamUidFromName(teamName) {
    if (this.state.userData) {
      for (let index = 0; index < this.state.userData.teams.length; index++) {
        const team = this.state.userData.teams[index];
        if (teamName !== PUBLIC_SERVER_NAME && team.teamName === teamName)
          return team.uuid;
      }
    }

    return PUBLIC_SERVER_UID;
  }

  startAgoraChannel(apiVersion, teamName) {
    /* eslint-disable */
    return new Promise(async (resolve, reject) => {
      let data = null;
      try {
        data = await this.sendMessage({
          type: 'command',
          command: 'startAgoraChannel',
          apiVersion: apiVersion,
          teamName: teamName,
        });
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  joinRTCClient() {
    /* eslint-disable */
    return new Promise(async (resolve, reject) => {
      // console.log(this.desktopClient);
      try {
        this.options.desktopuid = await this.desktopClient.join(
          this.options.appid,
          this.options.desktopchannel,
          null
        );
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async shareScreenAndAudio() {
    await this.joinRTCClient();
    // .catch((err) => console.error(err));
    // console.log('Desktop channel joined: ' + this.options.desktopchannel);

    try {
      let screenShareSettings = {
        encoderConfig: {
          width: window.screen.width * window.devicePixelRatio,
          height: window.screen.height * window.devicePixelRatio,
          frameRate: 30,
          bitrateMin: 1000,
          bitrateMax: 5000,
        },
        optimizationMode: 'motion',
      };

      this.videoTrack = await AgoraRTC.createScreenVideoTrack(
        screenShareSettings,
        'auto'
      );
      // .catch((err) => console.error(err));

      // console.log(this.videoTrack);
      if (!this.videoTrack) {
        this.setState({
          screenShareVideoEnabled: false,
          isLoading: false,
          displayServerSelectModal: true,
        });
        return;
      }

      let localVideoTrack = this.videoTrack;

      if (Array.isArray(this.videoTrack)) {
        this.videoTrack.forEach((track, i) => {
          if (track?.trackMediaType === 'video') {
            localVideoTrack = track;
          }
          track.on('track-ended', () => {
            track.close();
            if (i === this.videoTrack.length - 1) {
              this.unpublishClient();
            }
          });
        });
      } else {
        this.videoTrack.on('track-ended', () => {
          // console.log('track ended');
          this.videoTrack.close();
          this.unpublishClient();
        });
      }

      // playing tracked screen sharing video
      localVideoTrack.play('local-player');

      await this.desktopClient.publish(this.videoTrack);
      this.setState({ screenShareVideoEnabled: true, isLoading: false });
      this.startKeepAliveMessages();
    } catch (error) {
      // console.error(error);
    }
  }

  unpublishClient() {
    if (this.keepAliveMessage) {
      clearInterval(this.keepAliveMessage);
      this.keepAliveMessage = null;
    }

    const message = `${AGORA_SCREEN_SHARE_OPTIONS.SCREEN_SHARE_LEAVE}&0.0.0&${this.options.desktopchannel}&${this.options.desktopuid}`;
    this.sendChannelMessage(message);
    this.desktopClient.unpublish(this.videoTrack).then(() => {
      // console.log(this.videoTrack);
      this.desktopClient.leave((res) => {
        // console.log(res);
        this.options.desktopuid = null;
      });
      this.setState({ isLoading: true });
      setTimeout(async () => {
        const { body } = await awsApi.getUserDetailsApi(this.state.accessToken);
        const userData = JSON.parse(body);
        const { userStatus = {} } = userData;
        if (userStatus?.is_online) {
          // checking is user online then show again share to that server option
          this.setState(
            {
              userData,
              spaces: [],
              selectedServerInfo: null,
              selectedSpaceInfo: null,
              isLoading: false,
            },
            this.checkUserStatus
          );
        } else {
          // if not online then need to select the server and space.
          this.videoTrack = null;
          this.setState({
            screenShareVideoEnabled: false,
            displayServerSelectModal: true,
            selectedServerInfo: null,
            selectedSpaceInfo: null,
            selectedServer: '',
            selectedSpace: '',
            spaces: [],
            isLoading: false,
          });
        }
      }, 1000);
    });
  }

  async backToDashboard() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.props.history.push(`/${ROUTES.DASHBOARD}`);
    }, 2000);
  }

  // send channel message. It should be in the format: messageType&version&message. Version can be ignored for screen sharing. It is used more in VR
  async sendChannelMessage(channelMessage) {
    if (this.msgChannel !== null) {
      await this.msgChannel.sendMessage({ text: channelMessage }).then(() => {
        // console.log('message sent');
        // console.log(channelMessage);
        // console.log(this.msgChannel.channelId);
      });
    }
  }

  login(uid) {
    /* eslint-disable */
    return new Promise(async (resolve, reject) => {
      try {
        let messageOptions = {
          uid: uid,
          token: null,
        };
        // console.log(messageOptions);
        await this.messagClient.login(messageOptions);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  async joinMessageChannel() {
    // Channel event listeners
    // Display channel messages
    try {
      if (this.msgChannel)
        if (this.msgChannel.joinState !== 'JOINED')
          await this.msgChannel.join().then(() => {
            // console.log('successfully joined message channel');
            // console.log(this.msgChannel);
          });
      // else console.error('Could not join message channel');
    } catch (error) {
      // console.error('Could not join message channel', error);
    }
  }

  async signOut() {
    try {
      await Auth.signOut();
      this.context.dispatch({
        type: ACTION.CLEAR_APP_STATE,
        payload: {
          isLoggedIn: false,
        },
      });
      this.props.history.push(`/${ROUTES.SIGNIN}`);
    } catch (error) {
      // console.error('error signing out: ', error);
    }
  }

  shareScreenHandler = () => {
    this.getServerAndSpaceName();

    let env = API_VERSION.split('-')[0].toUpperCase();
    let messageChannelName = `${this.state.selectedServer}_${env}`;
    this.options.desktopchannel = `${this.state.selectedServer}_${this.state.selectedSpace}`;

    // console.log(messageChannelName);
    // console.log(this.state.currentUser);
    this.setState({ displayServerSelectModal: false, isLoading: true });
    try {
      if (this.msgChannel) {
        this.msgChannel.removeAllListeners();
        this.msgChannel.leave().then((res) => {
          // console.log(res);
          this.msgChannel = null;
          this.createMessageChannelAndConnectListeners(messageChannelName);
          this.joinMessageChannel();
          this.shareScreenAndAudio();
        });
      } else {
        this.createMessageChannelAndConnectListeners(messageChannelName);
        this.joinMessageChannel();
        this.shareScreenAndAudio();
      }
    } catch (error) {
      // console.error(error);
    }
  };

  availablePortalsImage = (space, indx) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        position="relative"
        key={indx}
        my={1}
        onClick={() => this.setState({ selectedSpace: space.gameName })}
        border={this.state.selectedSpace === space.gameName && '5px solid blue'}
        cursor={'pointer'}
      >
        <Box
          position="absolute"
          bottom={3}
          width="100%"
          justifyContent="center"
        >
          <Text
            color="white"
            fontWeight="semibold"
            textAlign="center"
            fontSize="md"
          >
            {space.visibleName === 'None' ? '' : space.visibleName}
          </Text>
        </Box>
        <Image
          src={space.webPreviewUrl}
          alt={space.visibleName === 'None' ? '' : space.visibleName}
          className="portal-image"
          borderRadius={5}
        />
      </Box>
    );
  };

  modalSelectServerAndSpace = () => {
    return (
      <Modal
        isOpen={this.state.displayServerSelectModal}
        size={'5xl'}
        closeOnOverlayClick={false}
        onClose={() => {
          this.setState({ displayServerSelectModal: false });
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose where you want to share to</ModalHeader>
          <ModalBody>
            <Box
              display="flex"
              // alignItems="center"
              flexDirection="column"
            >
              {/*{!this.state.superUser ? (*/}
              {/*  <Alert status="warning" mb={3}>*/}
              {/*    <AlertIcon />*/}
              {/*    Access share screen in private servers only*/}
              {/*  </Alert>*/}
              {/*) : null}*/}
              <Select
                placeholder="Select Server"
                value={this.state.selectedServer}
                onChange={(e) => {
                  this.setState(
                    { selectedServer: e.target.value },
                    this.getServerSpaces
                  );
                }}
                width={300}
              >
                {this.state.servers.map((team, i) => (
                  <option value={team.uuid} key={i}>
                    {team.teamVisibleName}
                  </option>
                ))}
              </Select>
              {
                <HStack
                  spacing={2}
                  shouldWrapChildren={true}
                  width="100%"
                  overflowX="auto"
                  minH="180px"
                  justifyContent={this.state.loadingSpaces && 'center'}
                >
                  {this.state.loadingSpaces && (
                    <Spinner
                      thickness="5px"
                      speed="1.5s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  )}
                  {!this.state.loadingSpaces &&
                    this.state.spaces.map((space, indx) =>
                      this.availablePortalsImage(space, indx)
                    )}
                </HStack>
              }
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => this.props.history.push(`/${ROUTES.DASHBOARD}`)}
            >
              Exit
            </Button>
            <Button
              disabled={
                this.state.selectedServer === '' ||
                this.state.selectedSpace === ''
              }
              colorScheme="blue"
              mx={3}
              onClick={this.shareScreenHandler}
            >
              Share Screen
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  getServerAndSpaceName = (runFrom) => {
    const { selectedServer, selectedSpace, servers, spaces } = this.state;
    const server = servers.find(({ uuid }) => uuid === selectedServer);
    const space = spaces.find(({ gameName }) => gameName === selectedSpace);

    this.setState(
      {
        selectedServerInfo: server,
        selectedSpaceInfo: space,
      },
      () => {
        const { selectedSpaceInfo } = this.state;
        if (runFrom === 'getServerSpaces') {
          if (selectedSpaceInfo) {
            this.shareScreenConfrimModalRef.onOpen();
          } else {
            this.setState({
              displayServerSelectModal: true,
            });
          }
        }
      }
    );
  };

  render() {
    const { isLoading, selectedServerInfo, selectedSpaceInfo } = this.state;

    return (
      <Box
        display="flex"
        flexGrow={'1'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        {getLoadingModal(isLoading)}

        <ModalComponent
          header={'Screen Sharing'}
          body={`We see you are currently in ${selectedServerInfo?.teamVisibleName}, ${selectedSpaceInfo?.visibleName}. Would you like to share your screen into this space?`}
          publicConfirmModalRef={this.shareScreenConfrimModalRef}
          OkBtnText={'Share'}
          CancelBtnText={'Choose another Space'}
          confirmed={this.shareScreenHandler}
          canceled={() =>
            this.setState({
              displayServerSelectModal: true,
              selectedServerInfo: null,
              selectedSpaceInfo: null,
            })
          }
          isCentered={true}
          hideCloseButton={true}
        />

        {this.modalSelectServerAndSpace()}

        {selectedServerInfo && selectedSpaceInfo && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="slef-start"
            alignItems="center"
            width="100%"
            height="100%"
            mt={5}
          >
            <Box display={'flex'}>
              {this.availablePortalsImage(selectedSpaceInfo)}
              <Box m={5}>
                <Text>Currently sharing to;</Text>
                <Text>
                  Server: <b>{selectedServerInfo.teamVisibleName}</b>
                </Text>
                <Text>
                  Space: <b>{selectedSpaceInfo.visibleName}</b>
                </Text>
              </Box>
            </Box>

            <Box id="local-player" height={'50%'} width={'75%'} m={5} />

            <Button
              onClick={() => {
                if (Array.isArray(this.videoTrack)) {
                  this.videoTrack.forEach((track, i) => {
                    track.close();
                    if (i === this.videoTrack.length - 1) {
                      this.unpublishClient();
                    }
                  });
                } else {
                  // console.log('track ended');
                  this.videoTrack.close();
                  this.unpublishClient();
                }
              }}
            >
              Stop Sharing
            </Button>
          </Box>
        )}
      </Box>
    );
  }
}

export default ScreenShare;
