import {
  Box,
  Button,
  createStandaloneToast,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { createServer, getLowercaseServerName } from './DashboardService';

import FullScreenLoading from '../../components/FullScreenLoading';
import { TEAM } from '../../constants/Routes';
import { removeSpecialChar } from '../../services/commonService';
import { removeEmojis } from '../../utils/removeEmojis';

export const CreateNewServerModal = ({ token, userId, email, handleClose }) => {
  const history = useHistory();
  const { toast } = createStandaloneToast();
  const [showLoading, setShowLoading] = useState(false);
  const [teamModal, setTeamModal] = useState({
    key: '',
    teamVisibleName: '',
    teamname: '',
  });

  const createNewServerButtonDisabled = () => {
    return teamModal.teamname !== '';
  };

  return (
    <div>
      {showLoading ? <FullScreenLoading /> : null}
      <Modal
        isOpen={true}
        onClose={() => {
          handleClose();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Server Details</ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <ModalBody>
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center">
                <Text pl={2}>Name: </Text>
                <Input
                  value={teamModal.teamVisibleName}
                  placeholder="Remio"
                  onChange={(e) => {
                    let newServerVisibleName = e.target.value;
                    newServerVisibleName = removeEmojis(newServerVisibleName);
                    const lowercaseServerName =
                      getLowercaseServerName(newServerVisibleName);
                    setTeamModal({
                      ...teamModal,
                      teamVisibleName: newServerVisibleName,
                      teamname: removeSpecialChar(lowercaseServerName),
                    });
                  }}
                  m={1}
                  p={1}
                />
              </Box>

              <Text pl={2}>TeamId: {teamModal.teamname}</Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              variant={'ghost'}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!createNewServerButtonDisabled()}
              colorScheme="blue"
              mr={3}
              onClick={() => {
                setShowLoading(true);
                createServer(
                  token,
                  teamModal.teamVisibleName,
                  teamModal.teamname,
                  userId,
                  email
                )
                  .then((teamInfo) => {
                    setShowLoading(false);
                    const url = `/${TEAM}/${teamInfo.teamName}`;
                    history.push(url);
                  })
                  .catch((error) => {
                    toast({
                      title: 'Oh no, could not create a server!',
                      description:
                        error.response?.data?.message ||
                        'Please try again at a later time. If this continues, contact us at support@remiovr.com',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                    });
                    setShowLoading(false);
                  });
              }}
            >
              Create Server
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
