import { Box, Button, IconButton, Text, Tooltip } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { RiDeleteBin5Line } from 'react-icons/ri';

import { ACTION } from '../../../actions/actions';
import FullScreenLoading from '../../../components/FullScreenLoading';
import TableComponent from '../../../components/TableComponent';
import { useAppStateContext } from '../../../context/appContext';
import { useTeamStateContext } from '../../../context/teamContext';
import { isAdmin, isSuperUser } from '../../../services/commonService';
import { isTeamAdminOrSuperUser } from '../TeamUtils';

import BanModal from './banModal';
import { getMembersBannedApi, removeMemberBanApi } from './membersService';

const MembersBanned = () => {
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [membersBanned, setMembersBanned] = useState([]);
  const [banModalVisible, setBanModalVisible] = useState(false);
  const [editingEmail, setEditingEmail] = useState(null);

  const isServerOrSuperAdmin = isTeamAdminOrSuperUser(
    state.role,
    teamState.teamRole
  );

  useEffect(() => {
    if (isTeamAdminOrSuperUser(state.role, teamState.teamRole)) {
      getMembersBannedApi(state.token, teamState.teamId).then((res) => {
        const bannedList = JSON.parse(res.body);
        setMembersBanned(bannedList);
        teamDispatch({
          type: ACTION.UPDATE_TEAM_STATE,
          payload: {
            ...teamState,
            hasBannedUserUpdated: false,
          },
        });
      });
    } else {
      setMembersBanned([]);
    }
  }, [teamState.hasBannedUserUpdated]);

  function extractDate(a) {
    if (a === null) return;
    //we can chain the methods for "input" variable:
    const input = a.split('T')[0].split('-');
    const day = input[2];
    const month = input[1];
    const year = input[0];
    let monthNames =
      'January,February,March,April,May,June,July,August,September,October,November,December';
    monthNames = monthNames.split(',');
    return monthNames[Number(month) - 1] + ' ' + day + ', ' + year;
  }

  const handleOpenBanModal = (email) => {
    setEditingEmail(email);
    setBanModalVisible(true);
  };

  const handleCloseBanModal = () => {
    setBanModalVisible(false);
  };

  const onBanSuccess = async () => {
    setIsLoading(true);
    try {
      const res = await getMembersBannedApi(state.token, teamState.teamId);
      const bannedList = JSON.parse(res.body);
      setMembersBanned(bannedList);
      teamDispatch({
        type: ACTION.UPDATE_TEAM_STATE,
        payload: {
          ...teamState,
          hasBannedUserUpdated: false,
        },
      });
    } catch (error) {
      // TODO: Something useful
    } finally {
      setIsLoading(false);
      setBanModalVisible(false);
    }
  };

  const data = React.useMemo(() => [...membersBanned], [membersBanned]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'visibleName',
        header: 'Name',
        footer: (info) => info.column.id,
        cell: (info) => {
          return <Text isTruncated>{info.row.original.visibleName}</Text>;
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        footer: (info) => info.column.id,
        enableColumnFilter: isSuperUser(state.role),
        cell: (info) => {
          return <Text isTruncated>{info.row.original.email}</Text>;
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        footer: (info) => info.column.id,
        cell: (info) => {
          return <Text isTruncated>{info.row.original.status}</Text>;
        },
      },
      {
        accessorKey: 'expires',
        header: 'Until',
        footer: (info) => info.column.id,
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => {
          return (
            <Text isTruncated>
              {/* Either the row has a UTC timestamp, or nothing at all */}
              {info.row.original.expires
                ? extractDate(
                    new Date(info.row.original.expires * 1000).toISOString()
                  )
                : 'N/A'}
            </Text>
          );
        },
      },
      {
        id: 'delete',
        header: '',
        footer: (info) => info.column.id,
        cell: (info) => {
          return isAdmin
            ? getActions(info.row.original.userId, teamState.teamId, info.row.original.email, info.row.original.status)
            : '';
        },
      },
    ],
    []
  );

  function getActions(userId, serverId, email, status) {
    return isAdmin ? (<Box display="flex">
    <IconButton
        icon={<BiEditAlt />}
        title={'Delete user'}
        size="xs"
        m={1}
        onClick={() => handleOpenBanModal(email, status)}
        aria-label={'Delete member'}
      />
      <IconButton
        icon={<RiDeleteBin5Line />}
        title={'Remove ban/suspension'}
        size="xs"
        m={1}
        onClick={() => {
          setIsLoading(true);
          removeMemberBanApi(state.token, userId, serverId).then((res) => {
            getMembersBannedApi(state.token, teamState.teamId).then((res) => {
              const bannedList = JSON.parse(res.body);
              setMembersBanned(bannedList);
              setIsLoading(false);
            });
          });
        }}
        aria-label={'Remove ban/suspension'}
      />
      </Box>
    ) : null;
  }

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}
      <Box mb={2}>
        <Tooltip
          label="This action is only available for server admins. Contact your admin to add new members!"
          hasArrow
          isDisabled={isServerOrSuperAdmin}
        >
          <Button
            m={1}
            w="15%"
            onClick={() => handleOpenBanModal()}
            isDisabled={!isServerOrSuperAdmin}
          >
            Ban User
          </Button>
        </Tooltip>
      </Box>
      <TableComponent
        data={data}
        columns={columns}
        rawSortBy={[{ id: 'visibleName', desc: false }]}
        disableGlobalFilter={true}
      />
      {membersBanned.length < 1
        ? 'There are no banned/suspended members on this server.'
        : null}

      {/* Modals */}
      <BanModal
        isOpen={banModalVisible}
        onClose={handleCloseBanModal}
        onSuccess={onBanSuccess}
        editingEmail={editingEmail}
      />
    </>
  );
};

export default MembersBanned;
