import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Text, useToast } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';

import AudioPlayer from '../../../../components/AudioPlayer';
import FullScreenLoading from '../../../../components/FullScreenLoading';
import TableComponent from '../../../../components/TableComponent';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { isTeamAdminOrSuperUser } from '../../TeamUtils';

import { deleteHologramsApi, getHologramsApi } from './hologramsService';

const HologramsComponent = () => {
  const toast = useToast();
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [holograms, setHolograms] = useState([]);
  const isAdmin = isTeamAdminOrSuperUser(state.role, teamState.teamRole);

  useEffect(() => {
    setIsLoading(true);
    getHologramsApi(state.token, teamState.teamName)
      .then((res) => {
        setHolograms(JSON.parse(res.body));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.error(err);
      });
  }, []);

  const deleteHologram = (hologramUuid) => {
    deleteHologramsApi(state.token, hologramUuid)
      .then(() => {
        getHologramsApi(state.token, teamState.teamName)
          .then((res) => {
            setHolograms(JSON.parse(res.body));
            setIsLoading(false);
            toast({
              title: 'Hologram deleted.',
              description: "We've successfully deleted your hologram.",
              status: 'success',
              duration: 5000,
              isClosable: false,
            });
          })
          .catch((err) => {
            // console.error(err);
            toast({
              title: 'Problem deleting your hologram.',
              description: "We've encountered a problem your hologram.",
              status: 'error',
              duration: 5000,
              isClosable: false,
            });
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        // console.error(err);
      });
  };

  function getActions(id) {
    return isAdmin ? (
      <Button
        m={1}
        w="10%"
        variant="ghost"
        onClick={() => {
          setIsLoading(true);
          deleteHologram(id);
        }}
      >
        <DeleteIcon boxSize={'20px'} />
      </Button>
    ) : null;
  }

  function getAudio(audioPath) {
    return <AudioPlayer url={audioPath} />;
  }

  function buildHologramsData() {
    return holograms.reduce((accumulator, hologram) => {
      const hologramRow = {
        id: hologram.uuid,
        col1: hologram.filename,
        col2: getAudio(hologram.audioUrl),
        col3: hologram.scene_name,
        col4: hologram.owner_email,
      };
      accumulator.push(hologramRow);

      return accumulator;
    }, []);
  }

  const data = useMemo(() => buildHologramsData(), [holograms]);

  const columns = [
    {
      accessorKey: 'col1',
      header: 'FileName',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
      cell: (info) => {
        return <Text isTruncated>{info.row.original.col1}</Text>;
      },
    },
    {
      accessorKey: 'col2',
      header: 'Audio',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => {
        return info.row.original.col2;
      },
    },
    {
      accessorKey: 'col3',
      header: 'Location',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
      cell: (info) => {
        return <Text isTruncated>{info.row.original.col3}</Text>;
      },
    },
    {
      accessorKey: 'col4',
      header: 'Owner Email',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
      cell: (info) => {
        return <Text isTruncated>{info.row.original.col4}</Text>;
      },
    },
    {
      id: 'delete',
      header: 'Delete',
      footer: (info) => info.column.id,
      cell: (info) => {
        return getActions(info.row.original.id);
      },
    },
  ]

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Text fontSize="md" mb={6} mt={6}>
        Manage and listen to your saved holograms.
      </Text>

      <TableComponent
        data={data}
        columns={columns}
        rawSortBy={[{ id: 'Filename', desc: false }]}
        disableGlobalFilter={true}
      />
    </>
  );
};

export default HologramsComponent;
