import {
  Box,
  Button,
  createStandaloneToast,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { addEventApi } from './eventsService';

import FullScreenLoading from '../../../components/FullScreenLoading';
import { useAppStateContext } from '../../../context/appContext';
import { useTeamStateContext } from '../../../context/teamContext';
import { MixPanelService } from '../../../services/mixpanelService';

const AddEventModal = ({ onCloseAddEventModal, onAddEventSuccess }) => {
  const history = useHistory();
  const { toast } = createStandaloneToast();
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const [showLoading, setShowLoading] = useState(false);
  const [eventModal, setEventModal] = useState({
    name: '',
    description: '',
    startDate: '',
    endDate: '',
    thumbnail: '',
    logo: '',
    serverId: teamState.teamId,
    tags: [],
  });

  const isAddButtonDisabled = () => {
    return (
      eventModal.description !== '' ||
      eventModal.name !== '' ||
      eventModal.startDate !== '' ||
      eventModal.endDate !== '' ||
      eventModal.thumbnail !== '' ||
      eventModal.logo !== ''
    );
  };

  return (
    <div>
      {showLoading ? <FullScreenLoading /> : null}
      <Modal
        isOpen={true}
        onClose={() => {
          onCloseAddEventModal();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Event Details</ModalHeader>
          <ModalCloseButton onClick={onCloseAddEventModal} />
          <ModalBody>
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center">
                <Text pl={2}>Name: </Text>
                <Input
                  value={eventModal.name}
                  placeholder="Your awesome event"
                  onChange={(e) => {
                    setEventModal({
                      ...eventModal,
                      name: e.target.value,
                    });
                  }}
                  m={1}
                  p={1}
                />
              </Box>

              <Text pl={2}>Description: {eventModal.description}</Text>
              <Textarea
                value={eventModal.description}
                placeholder="Describe your event here"
                onChange={(e) => {
                  setEventModal({
                    ...eventModal,
                    description: e.target.value,
                  });
                }}
                m={1}
                p={1}
              />

              <Text pl={2}>Start Date: </Text>
              <Input
                value={eventModal.startDate}
                type="datetime-local"
                onChange={(e) => {
                  setEventModal({
                    ...eventModal,
                    startDate: e.target.value,
                  });
                }}
                m={1}
                p={1}
              />

              <Text pl={2}>End Date: </Text>
              <Input
                value={eventModal.endDate}
                type="datetime-local"
                onChange={(e) => {
                  setEventModal({
                    ...eventModal,
                    endDate: e.target.value,
                  });
                }}
                m={1}
                p={1}
              />

              <Text pl={2}>Thumbnail: </Text>
              <Input
                value={eventModal.thumbnail}
                onChange={(e) => {
                  setEventModal({
                    ...eventModal,
                    thumbnail: e.target.value,
                  });
                }}
                m={1}
                p={1}
              />

              <Text pl={2}>Logo: </Text>
              <Input
                value={eventModal.logo}
                onChange={(e) => {
                  setEventModal({
                    ...eventModal,
                    logo: e.target.value,
                  });
                }}
                m={1}
                p={1}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              variant={'ghost'}
              onClick={() => {
                onCloseAddEventModal();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isAddButtonDisabled()}
              colorScheme="blue"
              mr={3}
              onClick={async () => {
                setShowLoading(true);
                try {
                  await addEventApi(state.token, eventModal);
                  setShowLoading(false);
                } catch (error) {
                  toast({
                    title: 'Error creating event',
                    description:
                      error.response?.data?.message ||
                      'We could not create your event. Please try again later.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                  setShowLoading(false);
                }
              }}
            >
              Create Event
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddEventModal;
