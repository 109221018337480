import {
  Box,
  createStandaloneToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  IconButton,
  Image,
  Button,
  ModalFooter,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
} from '@chakra-ui/react';
import React, { useState, useEffect, useCallback } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import {
  BiLeftArrow,
  BiRightArrow,
  BiDownload,
  BiCloudDownload,
  BiTrash,
  BiChevronDown,
} from 'react-icons/bi';

import { ScrollComponent } from './ScrollComponent';
import { SharePhotoModal } from './SharePhotoModal';
import { ThumbnailComponent } from '../../components/ThumbnailComponent';

import { ModalComponent } from '../../components/Modal';
import { CUSTOM_EVENTS } from '../../constants/Constants';
import {
  getGalleryImages,
  downloadGalleryFile,
  deleteGalleryImage,
  getSharedTeamPhotos,
} from '../../services/awsService';
import { sortArrayByKey, getClosest } from '../../services/commonService';
import { downloadFileDelayed } from '../../services/downloadService';
import { getLoadingModal } from '../../utils/CommonComponents';

const { toast } = createStandaloneToast();

export const GalleryComponent = ({
  accessToken,
  userId,
  teamId = null,
  reload = false,
  photosObj = null,
  tempPreview = null,
  output = () => {},
  ...props
}) => {
  const [thumbnails, setThumbnails] = useState([]);
  const [selectedThumbnails, setSelectedThumbnails] = useState({});
  const [mergedPhotos, setMergedPhotos] = useState({});
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userPagination, setUserPagination] = useState({ page: 0 });
  const [teamPagination, setTeamPagination] = useState({ page: 0 });
  const [selectTeamModalRef] = useState({});
  const [deleteConfirmModalRef] = useState({});
  const [isImageModalOpen, setisImageModalOpen] = useState(false);
  const [imageModal, setImageModal] = useState({
    index: 0,
    src: null,
    name: null,
  });
  const [timeoutRef, setTimeoutRef] = useState(null);
  const [serverId, setServerId] = useState(teamId);

  useEffect(() => {
    // avoid shift click selection
    document.onselectstart = () => false;

    return () => {
      if (timeoutRef) clearTimeout(timeoutRef);
    };
  }, []);

  useEffect(() => {
    if (serverId !== teamId && accessToken) {
      onServerHasChanged();
      return;
    }

    if (serverId === teamId && accessToken) {
      onSharedPhoto(teamId);
    }

    if (accessToken && userId) {
      getGalleryPhotos(1, 1);
    }
  }, [accessToken, userId, teamId, photosObj]);

  useEffect(() => {
    const _thumbnails = sortArrayByKey(
      Object.values(mergedPhotos),
      'lastModified',
      true
    );
    setThumbnails([..._thumbnails]);
    output({
      event: 'updatedThumbnails',
      data: {
        photos: mergedPhotos,
        pagination: teamId ? teamPagination : userPagination,
        teamId,
      },
    });
  }, [mergedPhotos]);

  useEffect(() => {
    if (reload) {
      getGalleryPhotos(1, 1);
      output({ event: 'reloaded', data: false });
    }
  }, [reload]);

  useEffect(() => {
    if (tempPreview) {
      // when picture uploaded we can show thumbnail temporary.
      const _thumbnails = sortArrayByKey(
        Object.values(mergedPhotos),
        'lastModified',
        true
      );
      showUploadedImageThumbnail((obj) => {
        setThumbnails([obj, ..._thumbnails]);
      });
    }
  }, [tempPreview]);

  useEffect(() => {
    if (teamPagination?.page === 0 && teamId) {
      getGalleryPhotos(1, 1);
    }
  }, [teamPagination]);

  useEffect(() => {
    if (photosObj && photosObj?.photos?.length !== thumbnails?.length) {
      const _thumbnails = sortArrayByKey(
        Object.values(photosObj.photos),
        'lastModified',
        true
      );
      setThumbnails([..._thumbnails]);
    }
  }, [photosObj]);

  const onServerHasChanged = () => {
    if (photosObj) {
      setMergedPhotos(photosObj.photos);
      setTeamPagination(photosObj.pagination);
    } else {
      // set pagination page at 0 and on useEffect will be get GalleryImages
      setTeamPagination({ page: 0 });
      setMergedPhotos({});
      teamPagination?.page === 0 && teamId !== null && getGalleryPhotos(1, 1);
    }
    setServerId(teamId);
  };

  const showUploadedImageThumbnail = (cb) => {
    if (tempPreview) {
      // file
      var oFReader = new FileReader();
      oFReader.readAsDataURL(tempPreview);
      oFReader.onload = function (oFREvent) {
        cb({
          url: oFREvent.target.result,
          canDelete: false,
          canShare: false,
          name: tempPreview.name,
          type: teamId ? 'Team' : 'User',
          uuid: teamId || userId,
          isTemp: true,
        });
        setTimeoutRef(
          setTimeout(() => {
            output({ event: 'tempPreview', data: null });
            output({ event: 'reloaded', data: true });
          }, 6000)
        );
      };
    }
  };

  const createObject = useCallback((arr) => {
    const _merged = arr.reduce(
      (acc, o) => {
        acc[o.name] = o;
        return acc;
      },
      { ...mergedPhotos }
    );
    setMergedPhotos(_merged);
  });

  const getGalleryPhotos = async (u_page = 1, t_page = 1) => {
    const pagination = teamId ? teamPagination : userPagination;
    const page = teamId ? t_page : u_page;
    if (!isLoading && (pagination.page !== page || reload)) {
      !reload && setIsLoading(true);
      try {
        const { body } = await getGalleryImages(
          accessToken,
          userId,
          teamId,
          page,
          t_page,
          50
        );
        let {
          files = [],
          defaultFiles = [],
          teamFiles = [],
          team_pagination,
          user_pagination,
        } = JSON.parse(body);
        if (u_page > 1 || !userId) {
          defaultFiles = [];
        }
        teamFiles = teamFiles || [];
        let _thumbnails = [...defaultFiles, ...files, ...teamFiles];
        _thumbnails = _thumbnails.map((t) => {
          if (!t['canShare']) t['canShare'] = teamId ? false : true;
          if (!t['canDelete'])
            t['canDelete'] =
              (teamId && t.type === 'Team') || !teamId ? true : false;
          return t;
        });

        setTeamPagination(team_pagination);
        setUserPagination(user_pagination);

        // if teamId found then get shared photos
        if (teamId) {
          const { body } = await getSharedTeamPhotos(accessToken, teamId);
          const _sharedPhotos = JSON.parse(body);
          if (_sharedPhotos) {
            _sharedPhotos.map((t) => {
              t['canDelete'] = false;
              t['canShare'] = false;
              return t;
            });
            _thumbnails = [..._thumbnails, ..._sharedPhotos];
          }
        }

        createObject(_thumbnails);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // console.error(error);
      }
    }
  };

  const onThumbnailClick = async ({ image, index }) => {
    setIsLoading(true);
    try {
      const data = await downloadGalleryFile(
        accessToken,
        image.name,
        image.uuid,
        image.type
      );
      setisImageModalOpen(true);
      setImageModal({
        src: data.url,
        name: data.name,
        uuid: image.uuid,
        type: image.type,
        index,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Download Photo failed',
        description:
          error?.response?.data?.message ||
          'Error on downloading photo, please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const downloadPhoto = async ({ image }) => {
    setIsLoading(true);
    try {
      const file = await downloadGalleryFile(
        accessToken,
        image.name,
        image.uuid,
        image.type
      );
      setIsLoading(false);
      downloadFileDelayed(file.url, image.name);
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Download Photo failed',
        description:
          error?.response?.data?.message ||
          'Error on downloading photo, please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const sharePhotoHandler = ({ image, index }) => {
    setSelectedThumbnail({ ...image });
    selectTeamModalRef.onOpen();
  };

  const deletePhotoHandler = async ({ image }) => {
    setIsLoading(true);
    try {
      const res = await deleteGalleryImage(
        accessToken,
        image.uuid,
        image.name,
        teamId ? 'Team' : 'User'
      );
      const _thumbnails = thumbnails.slice();
      const indexToRemove = _thumbnails.findIndex(
        (thumbnail) => thumbnail.name === image.name
      );
      let deletedThumbnail = null;
      if (indexToRemove !== null) {
        deletedThumbnail = _thumbnails.splice(indexToRemove, 1);
      }
      setThumbnails(_thumbnails);
      const copyMergedPhotos = { ...mergedPhotos };
      delete copyMergedPhotos[deletedThumbnail[0].name];
      setMergedPhotos(copyMergedPhotos);
      setIsLoading(false);
      setSelectedThumbnail(null);
      toast({
        title: 'Delete Photo',
        description: 'Photo has been deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Delete Photo failed',
        description:
          error?.response?.data?.message ||
          'Error on deleting photo, please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const selectThumbnail = (event, { image, index }) => {
    let _selectedThumbnails = { ...selectedThumbnails };
    if (event === CUSTOM_EVENTS.onClick) {
      if (_selectedThumbnails[index]) {
        delete _selectedThumbnails[index];
      } else {
        // _selectedThumbnails = {};
        _selectedThumbnails[index] = image;
      }
    } else if (event === CUSTOM_EVENTS.onShiftKey) {
      const keys = Object.keys(_selectedThumbnails).sort((a, b) => a - b);
      const start = keys[0];
      const end = Number(keys[keys.length - 1]);
      let flag = false;
      if (index > end) {
        const _thumbnails = thumbnails.slice(end, index + 1);
        flag = !!_thumbnails.length;
        _thumbnails.forEach((image, i) => {
          _selectedThumbnails[end + i] = image;
        });
      }
      if (index < start) {
        const _thumbnails = thumbnails.slice(index, start);
        flag = !!_thumbnails.length;
        _thumbnails.forEach((image, i) => {
          _selectedThumbnails[index + i] = image;
        });
      }
      if (!flag && keys.length) {
        const closet = Number(getClosest(keys, index));
        const _thumbnails = thumbnails.slice(closet, Number(index) + 1);
        _thumbnails.forEach((image, i) => {
          _selectedThumbnails[closet + i] = image;
        });
      }
      if (!keys.length) {
        // if nothing has been selected
        _selectedThumbnails = {};
        _selectedThumbnails[index] = image;
      }
    }
    setSelectedThumbnails(_selectedThumbnails);
  };

  const thumbnailOutputHandler = async ({ event, data }) => {
    switch (event) {
      case 'onClick':
      case 'onCtrlClick':
      case 'onShiftKey':
        selectThumbnail(event, data);
        break;
      case 'onImageClick':
        await onThumbnailClick(data);
        break;
      case 'downloadImage':
        downloadPhoto(data);
        break;
      case 'deleteImage':
        setSelectedThumbnail(data);
        deleteConfirmModalRef.onOpen();
        break;
      case 'sharePhoto':
        sharePhotoHandler(data);
        break;
      default:
        break;
    }
  };

  const downloadSelectedPhotos = () => {
    setIsLoading(true);
    const promises = Object.values(selectedThumbnails).map((image) =>
      downloadGalleryFile(accessToken, image.name, image.uuid, image.type)
    );
    Promise.allSettled(promises).then((results) => {
      results.forEach(({ status, value }, i) => {
        if (status === 'fulfilled') {
          setTimeout(
            () => downloadFileDelayed(value.url, value.name, i),
            i * 1000
          );
        }
      });
      setIsLoading(false);
      clearSelectedThumbnails();
    });
  };

  const deleteSelectedPhotos = () => {
    setIsLoading(true);
    const selectedPhotos = Object.values(selectedThumbnails);
    const promises = Object.values(selectedPhotos).map((image) =>
      deleteGalleryImage(
        accessToken,
        image.uuid,
        image.name,
        teamId ? 'Team' : 'User'
      )
    );
    Promise.allSettled(promises).then((results) => {
      const _thumbnails = thumbnails.slice();
      results.forEach(({ status }, i) => {
        if (status === 'fulfilled') {
          const image = selectedPhotos[i];
          const indexToRemove = _thumbnails.findIndex(
            (thumbnail) => thumbnail.name === image.name
          );
          if (indexToRemove !== null) {
            _thumbnails.splice(indexToRemove, 1);
          }
        }
      });
      setIsLoading(false);
      setThumbnails(_thumbnails);
      clearSelectedThumbnails();
      toast({
        title: 'Delete Photo',
        description: 'Photo has been deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    });
  };

  const getImageModal = () => {
    return (
      <Modal
        isOpen={isImageModalOpen}
        onClose={() => {
          setisImageModalOpen(false);
        }}
        isCentered
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <IconButton
                icon={<BiLeftArrow />}
                m={1}
                onClick={async () => {
                  setIsLoading(true);
                  let newIndex;
                  if (imageModal.index === 0) {
                    newIndex = thumbnails.length - 1;
                  } else {
                    newIndex = imageModal.index - 1;
                  }
                  try {
                    const thumbnail = thumbnails[newIndex];
                    const data = await downloadGalleryFile(
                      accessToken,
                      thumbnail.name,
                      thumbnail.uuid,
                      thumbnail.type
                    );
                    setisImageModalOpen(true);
                    setImageModal({
                      src: data.url,
                      name: data.name,
                      uuid: thumbnail.uuid,
                      type: thumbnail.type,
                      index: newIndex,
                    });
                    setIsLoading(false);
                  } catch (error) {
                    setIsLoading(false);
                  }
                }}
              />
              {imageModal.src && (
                <Image
                  objectFit="cover"
                  src={imageModal.src}
                  alt={imageModal.name}
                  p={10}
                  maxHeight="80vh"
                />
              )}
              <IconButton
                icon={<BiRightArrow />}
                m={1}
                onClick={async () => {
                  setIsLoading(true);
                  let newIndex;
                  if (imageModal.index === thumbnails.length - 1) {
                    newIndex = 0;
                  } else {
                    newIndex = imageModal.index + 1;
                  }
                  try {
                    const thumbnail = thumbnails[newIndex];
                    const data = await downloadGalleryFile(
                      accessToken,
                      thumbnail.name,
                      thumbnail.uuid,
                      thumbnail.type
                    );
                    setisImageModalOpen(true);
                    setImageModal({
                      src: data.url,
                      name: data.name,
                      uuid: thumbnail.uuid,
                      type: thumbnail.type,
                      index: newIndex,
                    });
                    setIsLoading(false);
                  } catch (error) {
                    setIsLoading(false);
                  }
                }}
              />
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                leftIcon={<BiDownload />}
                colorScheme="teal"
                variant="solid"
                onClick={() => downloadPhoto({ image: imageModal })}
              >
                Download
              </Button>
            </Box>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const onScrollHandler = ({ event, data }) => {
    if (event === CUSTOM_EVENTS.next) {
      const u_page = userId ? data.nextPage : 1;
      const t_page = teamId ? data.nextPage : 1;
      getGalleryPhotos(u_page, t_page);
    }
  };

  const showingMenuForSelectedThumbails = () => {
    if (!Object.keys(selectedThumbnails).length) {
      return null;
    }
    let top = 125,
      left = '40px';
    if (window.location.pathname !== '/gallery') {
      top = '12%';
      left = '11.5%';
    }
    return (
      <Box position={'fixed'} top={top} left={left} zIndex={1}>
        <Menu>
          <MenuButton as={Button} rightIcon={<BiChevronDown />}>
            Selected Photos
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<BiCloudDownload />}
              onClick={downloadSelectedPhotos}
            >
              Download
            </MenuItem>
            <MenuItem icon={<BiTrash />} onClick={deleteSelectedPhotos}>
              Delete
            </MenuItem>
            <MenuItem
              icon={<AiOutlineClear />}
              onClick={clearSelectedThumbnails}
            >
              Clear Selection
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    );
  };

  const clearSelectedThumbnails = () => setSelectedThumbnails({});

  const onSharedPhoto = async (teamId, photo) => {
    if (teamId) {
      const { body } = await getSharedTeamPhotos(accessToken, teamId);
      const _sharedPhotos = JSON.parse(body);
      const data = { teamId };
      data['photos'] = _sharedPhotos.reduce((acc, o) => {
        acc[o.name] = o;
        o['canDelete'] = false;
        o['canShare'] = false;
        return acc;
      }, {});
      output({ event: CUSTOM_EVENTS.sharedPhoto, data });
    }
  };

  return (
    <Box>
      {showingMenuForSelectedThumbails()}
      {getLoadingModal(isLoading)}
      {getImageModal()}
      {accessToken && userId && (
        <SharePhotoModal
          modalRef={selectTeamModalRef}
          accessToken={accessToken}
          userId={userId}
          photo={selectedThumbnail}
          output={({ event, data }) => {
            if (event === CUSTOM_EVENTS.shareableLink) {
              const { isPublic, publicUrl, photo } = data;
              const _thumbnails = [...thumbnails];
              const index = _thumbnails.findIndex((t) => t.name === photo.name);
              const _photo = _thumbnails[index];
              _photo['isPublic'] = isPublic;
              _photo['publicUrl'] = publicUrl;
              _thumbnails[index] = _photo;
              setThumbnails(_thumbnails);
            }
            if (event === CUSTOM_EVENTS.sharedPhoto) {
              onSharedPhoto(data.teamId, data.photo);
            }
            if (event === CUSTOM_EVENTS.accessRevoked) {
              output({
                event: CUSTOM_EVENTS.accessRevoked,
                data: {
                  teamId: data.teamId,
                  photoName: data.photo.name,
                },
              });
            }
          }}
        />
      )}
      <ModalComponent
        header={'Delete Photo'}
        body={'Do you want to delete your photo?'}
        publicConfirmModalRef={deleteConfirmModalRef}
        confirmed={() => {
          deletePhotoHandler(selectedThumbnail);
        }}
        OkBtnText={'Delete'}
        okBtnColor={'red'}
      />
      {!thumbnails.length && !isLoading && (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'40vh'}
        >
          {!userId && !teamId ? (
            <Text fontSize="md" color="#989897">
              Please select a server from the dropdown to view your shared
              photos.
            </Text>
          ) : (
            <Text fontSize="md" color="#989897">
              You currently don't have any images in your gallery.
            </Text>
          )}
        </Box>
      )}

      {!!thumbnails.length && !isLoading && (
        <ScrollComponent
          pagination={teamId ? teamPagination : userPagination}
          output={onScrollHandler}
          height={'calc(100vh - 524px)'}
        >
          {thumbnails.map((thumbnail, index) => (
            <ThumbnailComponent
              key={`${thumbnail.name}-${index}`}
              data={thumbnail}
              output={thumbnailOutputHandler}
              index={index}
              canShare={thumbnail.canShare}
              canDelete={thumbnail.canDelete}
              selected={selectedThumbnails[index] || false}
            />
          ))}
        </ScrollComponent>
      )}
    </Box>
  );
};
