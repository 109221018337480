import { RepeatIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  createStandaloneToast,
  IconButton,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useState, useRef } from 'react';
import { BiEditAlt, BiTransfer } from 'react-icons/bi';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { CSVDownloader } from 'react-papaparse';
import { useHistory } from 'react-router-dom';

import UndoImage from '../../../assets/images/undo-icon.png';
import FullScreenLoading from '../../../components/FullScreenLoading';
import TableComponent from '../../../components/TableComponent';
import { SERVER_ROLES } from '../../../constants/Constants';
import * as ROUTES from '../../../constants/Routes';
import { useAppStateContext } from '../../../context/appContext';
import { useTeamStateContext } from '../../../context/teamContext';
import {
  getTeamInfo,
  refreshUserCode,
  removeTeamMember as removeTeamMemberAPI,
} from '../../../services/awsService';
import { isAdmin, isSuperUser } from '../../../services/commonService';
import { deleteTeamApi } from '../teamService';
import { isTeamAdminOrSuperUser } from '../TeamUtils';

import AddMembersModal from './addMembersModal';
import EditMemberModal from './editMemberModal';
import {
  addTeamMemberToDatabase,
  getTeamMembersDetails,
  getUserStatus,
} from './membersService';
import {
  getIndexFromMember,
  getLastSeen,
  getMemberEmail,
  getMemberRoom,
  getNameWithBadge,
  getRoleName,
  getUploadStatus,
  getVerificationCode,
} from './membersUtils';
import TransferServerOwnershipModal from './transferServerOwnershipModal';

const MembersAll = () => {
  const history = useHistory();
  const { toast } = createStandaloneToast();
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const membersRef = useRef();
  membersRef.current = members;
  const [newMembers, setNewMembers] = useState([]);
  const [membersStatus, setMembersStatus] = useState([]);
  const [membersForCSV, setMembersForCSV] = useState({});
  const [memberEdit, setMemberEdit] = useState({});
  const [addMembersModalVisible, setAddMembersModalVisible] = useState(false);
  const [editMemberModalVisible, setEditMemberModalVisible] = useState(false);
  const [isTransferOwnershipModalOpen, setIsTransferOwnershipModalOpen] =
    useState(false);
  const [memberToTransferOwnershipTo, setMemberToTransferOwnershipTo] =
    useState({});
  const [getMemberAPICalled, setGetMemberAPICalled] = useState(false);
  const [toastIdRef, setToastIdRef] = useState(React.createRef());
  let statusTimeout = null;
  let setTimeoutRef = null;

  const isServerOrSuperAdmin = isTeamAdminOrSuperUser(
    state.role,
    teamState.teamRole
  );

  useEffect(() => {
    getTeamMembers();
  }, []);

  useEffect(() => {
    if (members.length >= 1) {
      getUserStatusLoop();
    }
  }, [members]);

  useEffect(() => {
    if (members.length >= 1 && membersStatus.length >= 1) {
      compileData();
    }
  }, [members, membersStatus]);

  const teamColumns = React.useMemo(
    () => [
      {
        accessorKey: 'visibleName',
        header: 'Name',
        footer: (info) => info.column.id,
        enableColumnFilter: false,
        cell: (info) => {
          return getNameWithBadge(info.row.original, state.email);
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        footer: (info) => info.column.id,
        enableColumnFilter: false,
        cell: (info) => {
          return info.row.original.email;
        },
      },
      {
        accessorKey: 'verificationCode',
        header: 'Code',
        footer: (info) => info.column.id,
        enableColumnFilter: false,
        cell: (info) => {
          return getVerificationCode(
            info.row.original,
            state,
            teamState.teamRole
          );
        },
      },
      {
        accessorKey: 'role',
        header: 'Role',
        footer: (info) => info.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'status',
        header: 'Room',
        footer: (info) => info.column.id,
        enableColumnFilter: false,
        cell: (info) => {
          return getMemberRoom(info.row.original);
        },
      },
      {
        accessorKey: 'lastSeen',
        header: 'Last Seen',
        footer: (info) => info.column.id,
        enableColumnFilter: false,
      },
      {
        id: 'unassign',
        footer: (info) => info.column.id,
        cell: (info) => {
          return getMemberEditControls(info.row.original, info.row.index);
        },
      },
    ],
    []
  );

  const getTeamMembers = async (reload = false, silentReload = false) => {
    if (!getMemberAPICalled || reload) {
      if (!silentReload) setIsLoading(true);
      try {
        const teamInfoResponse = await getTeamInfo(
          state.token,
          teamState.teamName,
          'members=1'
        );
        const teamInfo = JSON.parse(teamInfoResponse.body);
        if (Array.isArray(teamInfo.members)) {
          const membersDetails = await getTeamMembersDetails(
            state.token,
            teamInfo.members,
            teamInfo.teamId
          );
          for (let i = 0; i < membersDetails.length; i++) {
            const memberAllInfo = membersDetails[i];
            for (let j = 0; j < teamInfo.members.length; j++) {
              const member = teamInfo.members[j];
              if (member.uuid === memberAllInfo.uuid) {
                member['email'] = memberAllInfo.email;
                member['verificationCode'] = memberAllInfo.verificationCode;
                member['visibleName'] = memberAllInfo.visibleName;
                member['tags'] = memberAllInfo.tags || [];
                break;
              }
            }
          }

          setMembers(teamInfo.members || []);
          setMembersForCSV(
            teamInfo.members.map((member) => {
              return {
                name: member.visibleName,
                email: member.email,
                role: member.role === 0 ? 'Admin' : 'Participant',
                code: member.verificationCode,
              };
            })
          );
          setIsLoading(false);
          setGetMemberAPICalled(true);
        }
      } catch (error) {
        // console.error(error);
        setIsLoading(false);
        toast({
          title: 'Could not get team members',
          description: `We encountered an error while trying to get team members. Please try again later.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const removeTeamMember = (member) => {
    removeTeamMemberAPI(state.token, teamState.teamId, member.uuid)
      .then((res) => {
        const memberIndex = getIndexFromMember(membersRef.current, member);
        if (memberIndex >= 0) {
          const duration = 8000;
          const newMembersToSet = [...membersRef.current];
          newMembersToSet.splice(memberIndex, 1);
          setMembers(newMembersToSet);
          setIsLoading(false);
          getTeamMembers(true, true);

          const toastObj = {
            duration,
            render: () => (
              <Box
                color="white"
                p={3}
                bg="green.500"
                width={'100%'}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <Box mr={2}>
                  <Box>
                    <b>Member removed successfully</b>
                  </Box>
                  <Box>
                    {member.email} {res.message}
                  </Box>
                </Box>
                <Box
                  width={'15%'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Box p={2} border="solid 1px white" borderRadius={12}>
                    <Image
                      src={UndoImage}
                      title="Undo"
                      width={50}
                      cursor={'pointer'}
                      onClick={() => {
                        member['role'] = SERVER_ROLES[member['role']];
                        addTeamMemberToDatabase(
                          state.token,
                          member,
                          teamState.teamId
                        ).then(() => {
                          getTeamMembers(true);
                        });
                        toast.close(toastIdRef.current);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            ),
          };

          if (toastIdRef.current) {
            toast.update(toastIdRef.current, toastObj);
          } else {
            toastIdRef.current = toast(toastObj);
          }

          // handling after toast duration need to empty current reference, to show next toast
          setTimeoutRef = setTimeout(() => {
            toastIdRef.current = null;
          }, duration);
        }
      })
      .catch((err) => {
        toast({
          title: 'Member remove failed',
          description: `Error to remove member from the team, please try again later.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  const compileData = () => {
    const mems = [...membersRef.current];
    if (mems.length >= 1) {
      for (let i = 0; i < membersStatus.length; i++) {
        const status = membersStatus[i];
        for (let j = 0; j < mems.length; j++) {
          if (mems[j].uuid === status.uuid) {
            mems[j] = {
              ...membersRef.current[j],
              current_room: status.current_room,
              lastSeen: status.lastSeen,
              is_online: status.is_online,
            };
            break;
          }
        }
      }
    }

    return mems.map((member) => {
      return {
        ...member,
        email: getMemberEmail(member.email, state, teamState.teamRole),
        role: getRoleName(member.role),
        lastSeen: member.lastSeen ? getLastSeen(member) : 'Never',
      };
    });
  };

  const getUserStatusLoop = () => {
    getUserStatus(state.token, membersRef.current, teamState.teamId)
      .then((res) => {
        setMembersStatus(res);
        statusTimeout = setTimeout(() => getUserStatusLoop(), 20000);
      })
      .catch((err) => {
        if (statusTimeout) {
          clearTimeout(statusTimeout);
          statusTimeout = null;
        }
      });
  };

  const getTransferOwnershipButton = (member) => {
    if (state.email === teamState.ownerEmail || isSuperUser(state.role)) {
      return (
        <>
          <IconButton
            icon={<BiTransfer />}
            size="xs"
            m={1}
            title={'Make Server Owner'}
            aria-label={'Make Server Owner'}
            onClick={() => {
              setIsTransferOwnershipModalOpen(true);
              setMemberToTransferOwnershipTo(member);
            }}
          />
        </>
      );
    }
  };

  const onCloseTransferOwnershipModal = () => {
    setIsTransferOwnershipModalOpen(false);
    setMemberToTransferOwnershipTo({});
  };

  const onUpdateOwnerSuccess = () => {
    setIsTransferOwnershipModalOpen(false);
    setMemberToTransferOwnershipTo({});
    getTeamMembers(true);
    toast({
      title: 'Server Ownership Transferred Successfully',
      description: 'Server Ownership Transferred Successfully!',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const setMemberProcessingStatus = (memberIndex, state) => {
    const newMembersToSet = [...membersRef.current];
    newMembersToSet[memberIndex].processing = state;
    setMembers(newMembersToSet);
  };

  const getMemberEditControls = (member, i) => {
    if (member.processing) {
      if (member.processing === 'Busy') {
        return getUploadStatus('Busy');
      }
    }

    if (isServerOrSuperAdmin && state.email === member.email) {
      return (
        <Box display="flex">
          <IconButton
            icon={<RiDeleteBin5Line />}
            title={'Delete user'}
            size="xs"
            m={1}
            onClick={() => {
              if (membersRef.current.length === 1) {
                setIsLoading(true);
                deleteTeamApi(state.token, teamState.teamId)
                  .then((res) => {
                    history.push(`/${ROUTES.DASHBOARD}`);
                  })
                  .catch((err) => {
                    setIsLoading(false);
                  });
              } else {
                if (getIndexFromMember(membersRef.current, member) >= 0)
                  setMemberProcessingStatus(
                    getIndexFromMember(membersRef.current, member),
                    'Busy'
                  );
                removeTeamMember(member);
              }
            }}
            aria-label={'Delete member'}
          />
          {getRefreshButton(member.uuid)}
          {getTransferOwnershipButton(member)}
        </Box>
      );
    } else if (isServerOrSuperAdmin) {
      return (
        <Box display="flex">
          <IconButton
            icon={<RiDeleteBin5Line />}
            size="xs"
            title={'Delete user'}
            m={1}
            onClick={() => {
              if (getIndexFromMember(membersRef.current, member) >= 0)
                setMemberProcessingStatus(
                  getIndexFromMember(membersRef.current, member),
                  'Busy'
                );
              removeTeamMember(member);
            }}
            aria-label={'Delete user'}
          />
          <IconButton
            icon={<BiEditAlt />}
            size="xs"
            m={1}
            title={'Edit user'}
            onClick={() => {
              setMemberEdit(member);
              setEditMemberModalVisible(true);
            }}
            aria-label={'Edit user'}
          />
          {getRefreshButton(member.uuid)}
          {getTransferOwnershipButton(member)}
        </Box>
      );
    } else if (
      state.role === SERVER_ROLES.PARTICIPANT &&
      state.email === member.email
    ) {
      return (
        <Box display="flex">
          <IconButton
            icon={<RiDeleteBin5Line />}
            title={'Delete user'}
            size="xs"
            m={1}
            onClick={() => {
              if (getIndexFromMember(membersRef.current, member) >= 0)
                setMemberProcessingStatus(
                  getIndexFromMember(membersRef.current, member),
                  'Busy'
                );
              removeTeamMember(member);
            }}
            aria-label={'Delete user'}
          />
          {getRefreshButton(member.uuid)}
        </Box>
      );
    }

    return null;
  };

  const getRefreshButton = (userId) => {
    return (
      <Button
        size="xs"
        m={1}
        title={'Refresh user code'}
        onClick={() => {
          setMemberProcessingStatus(
            getIndexFromMember(membersRef.current, { uuid: userId }),
            'Busy'
          );
          refreshUserCode(userId, state.token)
            .then((res) => {
              const newCode = JSON.parse(res.body).verificationCode;
              const newMembersToSet = [...membersRef.current];
              const memberIndex = getIndexFromMember(membersRef.current, {
                uuid: userId,
              });
              newMembersToSet[memberIndex] = {
                ...newMembersToSet[memberIndex],
                verificationCode: newCode,
              };
              setIsLoading(false);
              setMembers(newMembersToSet);
              setMemberProcessingStatus(memberIndex, null);
              getTeamMembers(true, true);
            })
            .catch((err) => {
              setIsLoading(false);
              setMemberProcessingStatus(
                getIndexFromMember(membersRef.current, { uuid: userId }),
                null
              );
            });
        }}
      >
        <RepeatIcon />
      </Button>
    );
  };

  const onCloseAddMembersModal = () => {
    setAddMembersModalVisible(false);
  };

  const onAddMembersSuccess = () => {
    setNewMembers([]);
    setAddMembersModalVisible(false);
    getTeamMembers(true);
  };

  const onEditMemberModalSuccess = () => {
    setEditMemberModalVisible(false);
    getTeamMembers(true);
  };

  const onCloseEditMemberModal = () => {
    setEditMemberModalVisible(false);
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Box mt={4}>
        <Tooltip
          label="This action is only available for server admins. Contact your admin to add new members!"
          hasArrow
          isDisabled={isServerOrSuperAdmin}
        >
          <Button
            m={1}
            w="15%"
            onClick={() => setAddMembersModalVisible(true)}
            isDisabled={!isServerOrSuperAdmin}
          >
            Add Members
          </Button>
        </Tooltip>
        <CSVDownloader
          data={membersForCSV}
          filename={`${teamState.teamName}-members`}
          bom={true}
          config={{}}
        >
          <Button m={1} w="15%" variant="ghost">
            Download (CSV)
          </Button>
        </CSVDownloader>
        <TableComponent
          data={compileData()}
          columns={teamColumns}
          rawSortBy={[{ id: 'visibleName', desc: false }]}
        />
      </Box>

      {/* Modals */}
      {editMemberModalVisible ? (
        <EditMemberModal
          memberEdit={memberEdit}
          onCloseEditMemberModal={onCloseEditMemberModal}
          onEditMemberModalSuccess={onEditMemberModalSuccess}
        />
      ) : null}

      {addMembersModalVisible ? (
        <AddMembersModal
          onCloseAddMembersModal={onCloseAddMembersModal}
          onAddMembersSuccess={onAddMembersSuccess}
        />
      ) : null}

      {isTransferOwnershipModalOpen ? (
        <TransferServerOwnershipModal
          member={memberToTransferOwnershipTo}
          onCloseTransferOwnershipModal={onCloseTransferOwnershipModal}
          server={teamState.teamId}
          onUpdateOwnerSuccess={onUpdateOwnerSuccess}
        />
      ) : null}
    </>
  );
};

export default MembersAll;
