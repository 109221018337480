import {
  Box,
  Select,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  createStandaloneToast,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BiCopy } from 'react-icons/bi';
import { MdDelete, MdEdit } from 'react-icons/md';

import FullScreenLoading from '../../../../components/FullScreenLoading';
import { ModalComponent } from '../../../../components/Modal';
import { META_STORE_ID } from '../../../../constants/Constants';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import {
  getDeepLinks,
  addDeepLinks,
  deleteDeepLinks,
  editDeepLinks,
} from '../../../../services/awsService';
import { sortArrayByKey } from '../../../../services/commonService';

export function DeeplinksComponent() {
  const { toast } = createStandaloneToast();
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const [selectedGame, setSelectedGame] = useState({ gameName: '' });
  const [selectedDeeplink, setSelectedDeeplink] = useState(null);
  const [linkExpiration, setLinkExpiration] = useState('Never');
  const [deeplinks, setDeeplinks] = useState([]);
  const [toUpdate, setToUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deepLinkModalRef, setDeepLinkModalRef] = useState({});
  const [deleteLinkModalRef, setDeleteLinkModalRef] = useState({});
  const teamId = teamState.teamId;
  const spaces = [
    ...teamState.availableTeamSpaces,
    ...teamState.availablePortals.filter((t) => t.isRemioGame),
  ];

  useEffect(() => {
    if (teamId) {
      refreshDeeplinks(teamId);
    }
  }, [teamId]);

  useEffect(() => {
    if (spaces?.length) {
      setSelectedGame({ ...spaces[0] });
    }
  }, []);

  const refreshDeeplinks = (teamId) => {
    setIsLoading(true);
    getDeepLinks(state.token, teamId)
      .then(({ body }) => {
        const { deeplinks } = JSON.parse(body);
        let sortedDeeplinks = sortArrayByKey(deeplinks, 'lastUpdated', true);
        sortedDeeplinks = sortedDeeplinks.map((d) => {
          const space = spaces.find((s) => s.gameName === d.gameName);
          d.linkExpiration = d.linkExpiration || '-';
          return {
            ...d,
            visibleName: space?.visibleName || space?.doorTitle,
          };
        });
        setDeeplinks(sortedDeeplinks);
        initState();
        setIsLoading(false);
      })
      .catch((err) => {
        // console.error(err);
        setIsLoading(false);
      });
  };

  const initState = () => {
    setToUpdate(false);
    setSelectedGame({ gameName: spaces[0]?.gameName });
    setLinkExpiration('Never');
    // setLinkExpiration(null);
    setSelectedDeeplink(null);
  };

  const createDeeplinkHandler = async () => {
    const { gameName } = selectedGame;
    if (gameName) {
      try {
        setIsLoading(true);
        const body = { teamId, gameName, linkExpiration };
        if (toUpdate) {
          const res = await editDeepLinks(state.token, {
            ...body,
            uid: toUpdate,
          });
          const { uid: deeplinkId } = JSON.parse(res.body);
          const updateIndex = deeplinks.findIndex((i) => i.uid === deeplinkId);
          const updateObj = deeplinks[updateIndex];
          const _space = spaces.find((i) => i.gameName === body.gameName);
          updateObj.visibleName = _space.visibleName || _space.doorTitle;
          updateObj.linkExpiration = body.linkExpiration;
          deeplinks[updateIndex] = updateObj;
          setDeeplinks([...deeplinks]);
          copyToClipboard(deeplinkId, false);
          toast({
            title: 'Deep link',
            description: 'Deep link updated and copied to clipboard',
            status: 'success',
            duration: 8000,
            isClosable: true,
          });
        } else {
          const res = await addDeepLinks(state.token, body);
          const { uid: deeplinkId } = JSON.parse(res.body);
          body.uid = deeplinkId;
          body.serverId = body.teamId;
          const _space = spaces.find((i) => i.gameName === body.gameName);
          body.visibleName = _space.visibleName || _space.doorTitle;
          setDeeplinks([body, ...deeplinks]);
          copyToClipboard(deeplinkId, false);
          toast({
            title: 'Deep link',
            description: 'Deep link created & copied to clipboard',
            status: 'success',
            duration: 8000,
            isClosable: true,
          });
        }
        initState();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        // console.error('err ', err);
        toast({
          title: 'Deep link',
          description:
            err?.response?.data?.message || 'Error: request has been failed.',
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
      }
    }
  };

  const deleteDeeplinkHandler = async (uid) => {
    setIsLoading(true);
    await deleteDeepLinks(state.token, { uid, teamId });
    const afterDelete = deeplinks.filter((item) => item.uid !== uid);
    setDeeplinks(afterDelete);
    setIsLoading(false);
    toast({
      title: 'Deep link',
      description: 'Deep link has deleted',
      status: 'success',
      duration: 8000,
      isClosable: true,
    });
  };

  const editDeeplinkHandler = (deeplink) => {
    setToUpdate(deeplink.uid);
    setSelectedGame(deeplink);
    setLinkExpiration(deeplink.linkExpiration || 'Never');
    setSelectedDeeplink(deeplink);
    deepLinkModalRef.onOpen();
  };

  const copyToClipboard = (uid, showToast = false) => {
    navigator.clipboard.writeText(
      `https://www.oculus.com/vr/${META_STORE_ID}/${uid}`
    );
    showToast &&
      toast({
        title: 'Deep link copied to clipboard',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Box mt={4}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Text mb={{ base: 4, md: 0 }}>
            Create, copy, update or delete deep links to your server.
          </Text>
          <ModalComponent
            header={'Deep Links'}
            confirmed={createDeeplinkHandler}
            OkBtnText={toUpdate ? 'Update' : 'Create'}
            withTigger={'Create Deep Link'}
            publicConfirmModalRef={deepLinkModalRef}
            canceled={initState}
          >
            <form>
              <Box mt={5} width={350}>
                <FormControl>
                  <FormLabel>Select Space</FormLabel>
                  <Select
                    defaultValue={selectedGame?.gameName}
                    onChange={({ target: { value } }) => {
                      setSelectedGame(spaces.find((s) => s.gameName === value));
                    }}
                  >
                    {spaces.map((s, i) => {
                      return s.visibleName === 'None' ||
                        s.doorTitle === 'None' ? null : (
                        <option
                          key={`dd-${i}-sapce-${s.uuid}`}
                          value={s.gameName}
                        >
                          {s.visibleName || s.doorTitle}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </form>
          </ModalComponent>
        </Box>

        <Box mt={5}>
          <Table size="sm" mt={4}>
            <Thead>
              <Tr>
                <Th>Link Id</Th>
                <Th>Space Name</Th>
                <Th textAlign={'right'}>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {deeplinks.map((link) => (
                <Tr
                  key={link.uid}
                  bg={link.uid === selectedDeeplink?.uid && '#EDF2FC'}
                >
                  <Td>{link.uid}</Td>
                  <Td>{link.visibleName}</Td>
                  {/* <Td style={{ color: isValidDate(link.linkExpiration) ? 'green' : 'red' }}>
                                            {link.linkExpiration || '-'}
                                        </Td> */}
                  <Td textAlign={'right'}>
                    <IconButton
                      icon={<BiCopy />}
                      color="white"
                      variant="solid"
                      size="s"
                      p={1}
                      mr={2}
                      title="Copy link"
                      onClick={() => {
                        copyToClipboard(link.uid, true);
                      }}
                    />

                    <IconButton
                      icon={<MdEdit />}
                      color="white"
                      variant="solid"
                      size="s"
                      p={1}
                      mr={2}
                      title="Update link"
                      onClick={() => {
                        editDeeplinkHandler(link);
                      }}
                    />

                    <IconButton
                      icon={<MdDelete />}
                      color="white"
                      variant="solid"
                      size="s"
                      p={1}
                      mr={2}
                      title="Delete link"
                      onClick={() => {
                        setSelectedDeeplink(link);
                        deleteLinkModalRef.onOpen();
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>

      <ModalComponent
        header={'Delete'}
        publicConfirmModalRef={deleteLinkModalRef}
        confirmed={() => {
          deleteDeeplinkHandler(selectedDeeplink.uid);
        }}
        canceled={() => {
          setSelectedDeeplink(null);
        }}
        OkBtnText={'Confirm'}
      >
        Do you want to delete '<em>{selectedDeeplink?.uid}</em>' link?
      </ModalComponent>
    </>
  );
}
