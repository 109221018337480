import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';

import * as CONSTANTS from '../../constants/Constants';
import * as aws from '../../services/awsService';
import { hasRepeatingOrSequentialChars } from '../../utils/passwords';

export const ResetPasswordModal = ({
  onCloseSubmitForgotPasswordVisible,
  forgotPasswordEmail,
  onForgotPasswordSuccess,
}) => {
  const [isStrongPassword, setIsStrongPassword] = useState(null);
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordWarning, setPasswordWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const PASSWORD_STRENGTH_THRESHOLD = CONSTANTS.IS_ENTERPRISE ? 4 : 0;
  const PASSWORD_MIN_LENGTH = CONSTANTS.IS_ENTERPRISE ? 12 : 8;
  const consumer_score_words = [
    'Your password is very weak, we strongly encourage you to use another phrase',
    'Your password is guessable, consider adding another word to make it stronger',
    'Your password is OK, consider adding another word for stronger security',
    'Your password is strong, good job',
    "Your password is very strong, you're protected from many attacks",
  ];
  const enterprise_score_words = [
    'Your password is very weak, use phrases, numbers and special characters to make it stronger',
    'Your password is guessable, add another word/number(s)/special character(s) to make it stronger',
    'Your password is OK, add another word/number(s)/special character(s) for stronger security',
    'Your password is strong, add some complexity for maximum security',
    'Your password is very strong, you are protected from many attacks',
  ];
  const SCORE_WORDS = CONSTANTS.IS_ENTERPRISE
    ? enterprise_score_words
    : consumer_score_words;

  const isInvalid =
    code === '' ||
    newPassword === '' ||
    newPassword.length < PASSWORD_MIN_LENGTH ||
    newPassword !== confirmNewPassword ||
    !isStrongPassword ||
    (passwordWarning !== '' && CONSTANTS.IS_ENTERPRISE);

  function checkPasswordScore(score) {
    if (score >= 0) {
      setIsStrongPassword(true);
    } else {
      setIsStrongPassword(false);
    }
  }

  function handleOnPasswordChange(event) {
    const updatedPassword = event.target.value;
    setNewPassword(updatedPassword);
    const repeatedLetters = hasRepeatingOrSequentialChars(updatedPassword);
    if (repeatedLetters) {
      setPasswordWarning('Your password has repeating characters');
    } else {
      setPasswordWarning('');
    }
  }

  async function handleForgotPasswordOnClick() {
    setIsLoading(true);
    try {
      // Try to change the password
      await aws.forgotPasswordSubmit(forgotPasswordEmail, code, newPassword);
      onForgotPasswordSuccess(forgotPasswordEmail, newPassword);
    } catch (error) {
      setError(error.response?.data?.message || error.message || error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal isOpen={true} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent display="flex" flexDirection="column" alignItems="center">
        <ModalHeader>Reset password for {forgotPasswordEmail}</ModalHeader>
        <ModalBody display="flex" flexDirection="column" alignItems="center">
          <Input
            width={300}
            fontSize="sm"
            name="confirmationCode"
            placeholder="Enter Confirmation Code"
            mb={1}
            value={code}
            autoComplete="off"
            onChange={(event) => {
              setCode(event.target.value);
            }}
          />
          <Input
            width={300}
            fontSize="sm"
            name="password"
            placeholder="Enter New Password"
            mb={1}
            value={newPassword}
            type="password"
            autoComplete="new-password"
            onChange={handleOnPasswordChange}
          />
          <Box maxW="300px">
            <PasswordStrengthBar
              scoreWordStyle={{ textAlign: 'left' }}
              password={newPassword}
              minLength={PASSWORD_STRENGTH_THRESHOLD}
              onChangeScore={checkPasswordScore}
              shortScoreWord="Your password is too short, you need at least 8 characters"
              scoreWords={SCORE_WORDS}
            />
          </Box>
          <Input
            width={300}
            fontSize="sm"
            name="confirmPassword"
            placeholder="Confirm Password"
            mb={1}
            type="password"
            autoComplete="new-password"
            value={confirmNewPassword}
            onChange={(event) => {
              setConfirmNewPassword(event.target.value);
            }}
          />
          {passwordWarning !== '' && (
            <Text
              textAlign="center"
              fontSize="sm"
              mb={3}
              mt={3}
              color="#E53E3E"
              maxW={300}
            >
              Your password has repeating characters or sequences, choose one
              without either.
            </Text>
          )}
          {confirmNewPassword !== '' && newPassword !== confirmNewPassword && (
            <Text
              textAlign="center"
              fontSize="sm"
              mb={3}
              mt={3}
              color="#E53E3E"
              maxW={300}
            >
              Passwords do not match
            </Text>
          )}
          {error && (
            <Text
              textAlign="center"
              fontSize="sm"
              mb={5}
              mt={3}
              color="#E53E3E"
              maxW={300}
            >
              {error}
            </Text>
          )}

          <ModalFooter>
            <Button
              mr={3}
              variant={'ghost'}
              onClick={() => {
                onCloseSubmitForgotPasswordVisible();
              }}
            >
              Cancel
            </Button>
            <Button
              fontSize="sm"
              id="start"
              onClick={handleForgotPasswordOnClick}
              disabled={isInvalid}
            >
              Change Password
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
