import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../constants/Constants';

export const deleteTeamApi = (token, teamId) => {
  return new Promise((resolve, reject) => {
    const apiName = API_VERSION;
    const path = `/${API_VERSION}/teams/delete/${teamId}`;
    const myInit = {
      headers: {
        ...DEFAULT_HEADERS,
        token: token,
      },
    };

    API.get(apiName, path, myInit)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveTeamColorsToDatabase = (token, teamColors, teamId) => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/team_colors/${teamId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: JSON.stringify(teamColors),
  };

  return API.put(apiName, path, myInit);
};
