import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { updateServerOwner } from './membersService';

import FullScreenLoading from '../../../components/FullScreenLoading';
import { useAppStateContext } from '../../../context/appContext';

const TransferServerOwnershipModal = ({
  member,
  onCloseTransferOwnershipModal,
  server,
  onUpdateOwnerSuccess,
}) => {
  const { state } = useAppStateContext();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const transferOwnership = () => {
    updateServerOwner(state.token, server, member.uuid).then(() => {
      setIsLoading(false);
      onUpdateOwnerSuccess();
    });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          onCloseTransferOwnershipModal(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transfer Ownership of this Server</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to transfer ownership of this server to{' '}
              {member.visibleName}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="solid"
              onClick={() => {
                setIsLoading(true);
                transferOwnership();
              }}
            >
              Transfer Ownership
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TransferServerOwnershipModal;
