import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';

import FullScreenLoading from '../../../../components/FullScreenLoading';
import { updateMetaAvatarLimit } from '../settingsService';

function MetaAvatarsEnabledModal({
  onCloseMetaAvatarsModal,
  onEnableMetaAvatarsSuccess,
  serverId,
  token,
  currentLimit,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setIsOpen] = useState(true);
  const [limit, setLimit] = useState(currentLimit || 0);

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Modal
        isOpen={open}
        onClose={() => {
          setIsOpen(false);
          onCloseMetaAvatarsModal();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Meta Avatar Limit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Set the Meta Avatar Limit, maximum 1000</Text>
            <NumberInput
              onChange={(valueString) => setLimit(valueString)}
              value={limit}
              defaultValue={limit}
              min={0}
              max={1000}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                setIsLoading(true);
                updateMetaAvatarLimit(token, serverId, limit)
                  .then((res) => {
                    setIsLoading(false);
                    onEnableMetaAvatarsSuccess(limit);
                  })
                  .catch(() => setIsLoading(false));
              }}
              variant="solid"
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MetaAvatarsEnabledModal;
