import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Text } from '@chakra-ui/react';
import { useState, useMemo, useEffect } from 'react';

import FullScreenLoading from '../../../components/FullScreenLoading';
import TableComponent from '../../../components/TableComponent';
import { useAppStateContext } from '../../../context/appContext';
import { useTeamStateContext } from '../../../context/teamContext';
import { isAdmin } from '../../../services/commonService';
import { isTeamAdminOrSuperUser } from '../TeamUtils';

import { getMembersInvitedApi, removeMemberInvitedApi } from './membersService';

const MembersInvited = () => {
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [membersInvited, setMembersInvited] = useState([]);

  useEffect(() => {
    if (isTeamAdminOrSuperUser(state.role, teamState.teamRole)) {
      getMembersInvitedApi(state.token, teamState.teamId).then((res) => {
        const invitedList = JSON.parse(res.body);
        setMembersInvited(invitedList);
      });
    } else {
      setMembersInvited([]);
    }
  }, []);

  const data = useMemo(() => [...membersInvited], [membersInvited]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'visibleName',
        header: 'Name',
        footer: (info) => info.column.id,
        enableSorting: false,
        cell: (info) => {
          return <Text isTruncated>{info.row.original.visibleName}</Text>;
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        footer: (info) => info.column.id,
        enableSorting: false,
        cell: (info) => {
          return <Text isTruncated>{info.row.original.email}</Text>;
        },
      },
      {
        accessorKey: 'requesterEmail',
        header: 'Invited By',
        footer: (info) => info.column.id,
        enableSorting: false,
        cell: (info) => {
          return <Text isTruncated>{info.row.original.requesterEmail}</Text>;
        },
      },
      {
        accessorKey: 'created',
        header: 'Date Invited',
        footer: (info) => info.column.id,
        enableColumnFilter: false,
        cell: (info) => {
          return <Text isTruncated>{info.row.original.created}</Text>;
        },
      },
      {
        id: 'delete',
        header: isAdmin(state.role) ? 'Delete Member' : '',
        footer: (info) => info.column.id,
        cell: (info) => {
          return isAdmin(state.role)
            ? getActions(
                info.row.original.id,
                info.row.original.userId,
                info.row.original.serverId
              )
            : '';
        },
      },
    ],
    []
  );

  function getActions(inviteId, userId, serverId) {
    return isAdmin(state.role) ? (
      <Button
        m={1}
        w="10%"
        variant="ghost"
        onClick={() => {
          setIsLoading(true);
          removeMemberInvitedApi(state.token, inviteId).then((res) => {
            getMembersInvitedApi(state.token, teamState.teamId).then((res) => {
              const invitedList = JSON.parse(res.body);
              setMembersInvited(invitedList);
              setIsLoading(false);
            });
          });
        }}
      >
        <DeleteIcon title={'remove invitation'} boxSize={'20px'} />
      </Button>
    ) : null;
  }

  return (
    <>
      {isLoading ? (
        <FullScreenLoading />
      ) : (
        <TableComponent
          data={data}
          columns={columns}
          rawSortBy={[{ id: 'visibleName', desc: false }]}
          disableGlobalFilter={true}
        />
      )}
      {membersInvited.length < 1
        ? 'There are no invited members on this server.'
        : null}
    </>
  );
};

export default MembersInvited;
