import {
  Box,
  Button,
  WrapItem,
  Text,
  createStandaloneToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  IconButton,
  Select,
  Tooltip,
  calc,
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import React from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import {
  BiCameraMovie,
  BiCamera,
  BiDownload,
  BiCloudDownload,
  BiTrash,
  BiChevronDown,
  BiCheckCircle,
  BiShareAlt,
} from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import ReactPlayer from 'react-player/lazy';

import { GalleryComponent } from './GalleryComponent';
import { ScrollComponent } from './ScrollComponent';
import { ShareVideoModal } from './ShareVideoModal';
import { UploadGalleryPhoto } from './UploadGalleryPhoto';

import {
  PAGE_REFRESH_INTERVAL,
  CUSTOM_EVENTS,
} from '../../constants/Constants';
import * as ROUTES from '../../constants/Routes';
import { AppStateContext } from '../../context/appContext';
import {
  getUserDetailsApi,
  getUserDetailsWithToken,
  deleteGalleryVideo,
  getUserVideos as getUserGalleryVideos,
  getServerListApi,
} from '../../services/awsService';
import * as CommonFunctions from '../../services/commonService';
import { detectOS } from '../../services/commonService';
import * as CommonComponents from '../../utils/CommonComponents';
import { downloadFileDelayed } from '../../services/downloadService';

const { toast } = createStandaloneToast();

const VIDEO_MODE = 'videoMode';
const PHOTO_MODE = 'photoMode';

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.teamResetState = {
      teamname: '',
      key: '',
    };
    this.state = {
      accessToken: null,
      isLoading: true,
      superUser: false,
      videos: [],
      mode: VIDEO_MODE,
      video_pagination: { page: 1 },
      selectedVideos: {},
      tempPreview: null,
      selectedServerId: null,
      teams: [],
      downloadedServerImages: {},
      selectedVideo: null,
    };

    this.isWindows = detectOS('Windows');
    this.selectTeamModalRef = {};
    this.token = CommonFunctions.getJsonFromUrl(window.location.href).token;
    this.refreshPageInterval = null;
  }
  static contextType = AppStateContext;

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const accessToken = user.signInUserSession.idToken.jwtToken;
        const userId = user.attributes.sub;
        this.setState({ accessToken, userId });
        this.getUserVideos(1, 25);
        this.fetchUserServers(accessToken);
        getUserDetailsApi(accessToken).then((res) => {
          const userData = JSON.parse(res.body);
          this.setState({
            superUser: CommonFunctions.isSuperUser(userData.role),
          });
        });
        this.refreshPageInterval = setInterval(async () => {
          try {
            await CommonFunctions.refreshPage((accessToken) => {
              this.setState({ accessToken });
            });
          } catch (err) {
            // console.error(err);
            this.props.history.push(
              `/${ROUTES.SIGNIN}?reroute=${ROUTES.GALLERY}`
            );
          }
        }, PAGE_REFRESH_INTERVAL * 60 * 1000);
      })
      .catch((err) => {
        // console.error(err);
        if (this.token) {
          getUserDetailsWithToken(this.token)
            .then((user) => {
              const accessToken = user.token;
              this.setState({
                accessToken: user.token,
                userId: user?.attributes?.sub,
              });
              this.getUserVideos(1, 25);
              this.fetchUserServers(accessToken);
              this.refreshPageInterval = setInterval(async () => {
                try {
                  await CommonFunctions.refreshPage((accessToken) => {
                    this.setState({ accessToken });
                  });
                } catch (err) {
                  // console.error(err);
                  this.props.history.push(
                    `/${ROUTES.SIGNIN}?reroute=${ROUTES.GALLERY}`
                  );
                }
              }, PAGE_REFRESH_INTERVAL * 60 * 1000);
            })
            .catch((err) => {
              // console.error(err);
              this.setState({ isLoading: false });
              this.props.history.push(
                `/${ROUTES.SIGNIN}?reroute=${ROUTES.GALLERY}`
              );
            });
        } else {
          this.props.history.push(
            `/${ROUTES.SIGNIN}?reroute=${ROUTES.GALLERY}`
          );
        }
      });
  }

  onSelectThumbnail = (event, video, index) => {
    let _selectedThumbnails = { ...this.state.selectedVideos };
    if (event === CUSTOM_EVENTS.onClick) {
      if (_selectedThumbnails[index]) {
        delete _selectedThumbnails[index];
      } else {
        // _selectedThumbnails = {};
        _selectedThumbnails[index] = video;
      }
    } else if (event === CUSTOM_EVENTS.onShiftKey) {
      const { videos: thumbnails } = this.state;
      const keys = Object.keys(_selectedThumbnails).sort((a, b) => a - b);
      const start = keys[0];
      const end = Number(keys[keys.length - 1]);
      let flag = false;
      if (index > end) {
        const _thumbnails = thumbnails.slice(end, index + 1);
        flag = !!_thumbnails.length;
        _thumbnails.forEach((video, i) => {
          _selectedThumbnails[end + i] = video;
        });
      }
      if (index < start) {
        const _thumbnails = thumbnails.slice(index, start);
        flag = !!_thumbnails.length;
        _thumbnails.forEach((video, i) => {
          _selectedThumbnails[index + i] = video;
        });
      }
      if (!flag && keys.length) {
        const closet = Number(CommonFunctions.getClosest(keys, index));
        const _thumbnails = thumbnails.slice(closet, Number(index) + 1);
        _thumbnails.forEach((video, i) => {
          _selectedThumbnails[closet + i] = video;
        });
      }
      if (!keys.length) {
        // if nothing has been selected
        _selectedThumbnails = {};
        _selectedThumbnails[index] = video;
      }
    }
    this.setState({ selectedVideos: _selectedThumbnails });
  };

  getVideos() {
    const { selectedVideos, videos } = this.state;
    const videoComponents = [];
    videos.forEach((video, i) => {
      const selected = selectedVideos[i];
      video?.name &&
        videoComponents.push(
          <WrapItem
            key={i}
            p={2}
            pt={0}
            display="flex"
            flexDirection="column"
            alignItems="center"
            border={selected && '2px solid #309795'}
            borderWidth={!selected && 1}
            className="thumbnail-container"
            minHeight={300}
            width={{ base: '100%', md: '31%' }}
          >
            <ReactPlayer
              className="thumbnail-video"
              url={video.url}
              playing={true}
              controls={true}
              light={
                video.thumbnailKey ||
                'https://remio-public-production.s3.eu-west-1.amazonaws.com/Images/video_thumbnail.jpeg'
              }
              width={'100%'}
              height={'100%'}
            />
            <Box
              className="thumbnail-middle thumbnail-video-middle"
              style={{
                opacity: selected && 1,
                top: selected && '9%',
                left: selected && '6%',
              }}
            >
              <Box
                color={'white'}
                onClick={(e) => {
                  e.stopPropagation();
                  let event =
                    e.ctrlKey || e.metaKey ? 'onCtrlClick' : 'onClick';
                  event = e.shiftKey ? 'onShiftKey' : event;
                  this.onSelectThumbnail(event, video, i);
                }}
              >
                <IconButton
                  icon={<BiCheckCircle />}
                  _hover={{
                    background: selected ? 'blue' : 'grayLight',
                  }}
                  background={selected ? 'blue' : 'grayLight'}
                  variant="solid"
                  size="lg"
                  p={1}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={'center'}
              width="100%"
              pl={1}
              pr={1}
              pt={2}
            >
              <Text
                isTruncated
                mr={4}
                title={video.name}
                width={'calc(100% - 130px)'}
                cursor={'pointer'}
                onClick={(e) => {
                  const event =
                    e.ctrlKey || e.metaKey ? 'onCtrlClick' : 'onClick';
                  this.onSelectThumbnail(event, video, i);
                }}
              >
                {video.name}
              </Text>
              <Box width={'130px'}>
                <Tooltip label="Share" fontSize="md">
                  <IconButton
                    icon={<BiShareAlt />}
                    color="white"
                    variant="solid"
                    size="s"
                    p={1}
                    onClick={() => {
                      this.setState({ selectedVideo: video }, () => {
                        this.selectTeamModalRef.onOpen();
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip label="Delete" fontSize="md">
                  <IconButton
                    icon={<MdDelete />}
                    color="white"
                    variant="solid"
                    size="s"
                    p={1}
                    mx={2}
                    onClick={async () => {
                      this.setState({ isLoading: true });
                      try {
                        const resp = await deleteGalleryVideo(
                          this.state.accessToken,
                          this.state.userId,
                          video.name
                        );
                        let { videos } = this.state;
                        videos = videos.filter((v) => v.name !== video.name);
                        this.setState({ videos });
                      } catch (err) {
                        // console.error('Error: ', err);
                      }
                      this.setState({ isLoading: false });
                    }}
                  />
                </Tooltip>
                <Tooltip label="Download" fontSize="md">
                  <IconButton
                    icon={<BiDownload />}
                    color="white"
                    variant="solid"
                    size="s"
                    p={1}
                    onClick={() => {
                      downloadFileDelayed(video.url, video.name);
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </WrapItem>
        );
    });
    return videoComponents.length ? videoComponents : this.noVideosFound();
  }

  noVideosFound() {
    const { videos, isLoading } = this.state;
    return (
      (!videos || !videos.length) &&
      !isLoading && (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'40vh'}
          width={'100%'}
        >
          <Text fontSize="md" color="#989897">
            You currently don't have any vidoes in your gallery.
          </Text>
        </Box>
      )
    );
  }

  getUserVideos(page = 1, limit = 25) {
    this.setState({ isLoading: true });
    getUserGalleryVideos(this.state.accessToken, page, limit)
      .then(({ body }) => {
        let { videos, pagination } = JSON.parse(body);
        if (Array.isArray(videos)) {
          videos = CommonFunctions.sortArrayByKey(
            [...this.state.videos, ...videos],
            'lastModified',
            true
          );
        } else {
          videos = [];
        }
        this.setState({
          videos,
          video_pagination: pagination,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        // console.error(err);
      });
  }

  componentWillUnmount() {
    if (this.refreshPageInterval) {
      clearInterval(this.refreshPageInterval);
      this.refreshPageInterval = null;
    }
  }

  onScrollHandler = ({ event, data }) => {
    if (event === CUSTOM_EVENTS.next) {
      this.getUserVideos(data.nextPage, 25);
    }
  };

  downloadSelectedVideos = () => {
    const { selectedVideos } = this.state;
    Object.values(selectedVideos).forEach((video, i) => {
      setTimeout(downloadFileDelayed(video.url, video.name, i), i * 1500);
    });
    this.setState({ selectedVideos: {} });
  };

  deleteSelectedVideos = () => {
    this.setState({ isLoading: true });
    const { selectedVideos, videos } = this.state;
    const _selectedVideos = Object.values(selectedVideos);
    const promises = Object.values(_selectedVideos).map((video) =>
      deleteGalleryVideo(this.state.accessToken, this.state.userId, video.name)
    );
    Promise.allSettled(promises).then((results) => {
      const _thumbnails = videos.slice();
      results.forEach(({ status }, i) => {
        if (status === 'fulfilled') {
          const video = _selectedVideos[i];
          const indexToRemove = _thumbnails.findIndex(
            (thumbnail) => thumbnail.name === video.name
          );
          if (indexToRemove !== null) {
            _thumbnails.splice(indexToRemove, 1);
          }
        }
      });
      this.setState({
        videos: _thumbnails,
        selectedVideos: {},
        isLoading: false,
      });
      toast({
        title: 'Delete Vidoes',
        description: 'Videos has been deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    });
  };

  showingMenuForSelectedThumbails = () => {
    const { selectedVideos } = this.state;
    if (
      !Object.keys(selectedVideos).length ||
      window.location.pathname !== '/gallery'
    ) {
      return null;
    }
    return (
      <Box position={'fixed'} top={125} left={'40px'} zIndex={1}>
        <Menu>
          <MenuButton as={Button} rightIcon={<BiChevronDown />}>
            Selected Videos
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<BiCloudDownload />}
              onClick={this.downloadSelectedVideos}
            >
              Download Selected Videos
            </MenuItem>
            <MenuItem icon={<BiTrash />} onClick={this.deleteSelectedVideos}>
              Delete
            </MenuItem>
            <MenuItem
              icon={<AiOutlineClear />}
              onClick={() => {
                this.setState({ selectedVideos: {} });
              }}
            >
              Clear Selection
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    );
  };

  uploadPhotoHandler = ({ event, data }) => {
    switch (event) {
      case 'loader':
        this.setState({ isLoading: data });
        break;
      case 'onSuccess':
        this.setState({ isLoading: false, tempPreview: data.file });
        toast({
          title: 'Photo Upload',
          description: data.message,
          status: 'success',
          duration: 8000,
          isClosable: true,
        });
        break;
      case 'onError':
        this.setState({ isLoading: false });
        toast({
          title: 'Photo Upload',
          description: data,
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
        break;
      default:
        break;
    }
  };

  fetchUserServers = (accessToken) => {
    getServerListApi(accessToken)
      .then(({ body }) => {
        const { teams } = JSON.parse(body);
        if (teams && teams !== null) {
          this.setState({ teams });
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };

  showingServerDropdown = () => {
    const { teams } = this.state;
    return (
      <Box width={'30%'} pl={1}>
        <Select
          placeholder="Select Server"
          onChange={({ target: { value } }) =>
            this.setState({ selectedServerId: value })
          }
        >
          {teams.map(({ uuid, teamVisibleName }) => (
            <option value={uuid} key={uuid}>
              {teamVisibleName}
            </option>
          ))}
        </Select>
      </Box>
    );
  };

  render() {
    return (
      <Box
        display="flex"
        flexGrow={'1'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom={8}
      >
        {CommonComponents.getLoadingModal(this.state.isLoading)}
        {/*{CommonComponents.getMenu(this, this.state.superUser)}*/}

        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          p={5}
        >
          <Box display="flex" flexDirection="column">
            <h1 align={'center'}>Gallery</h1>
            <ShareVideoModal
              modalRef={this.selectTeamModalRef}
              accessToken={this.state.accessToken}
              userId={this.state.userId}
              video={this.state.selectedVideo}
              output={({ event, data }) => {
                if (event === CUSTOM_EVENTS.shareableLink) {
                  const videos = [...this.state.videos];
                  const index = videos.findIndex((t) => t.name === data.name);
                  videos[index] = data;
                  this.setState({
                    videos,
                    selectedVideo: data,
                  });
                }
              }}
            />
            <Tabs variant="enclosed">
              <TabList pl={5} mt={3}>
                <Tab
                  _selected={{
                    color: 'white',
                    bg: 'blue',
                  }}
                >
                  <b>Personal</b>
                </Tab>
                <Tab
                  _selected={{
                    color: 'white',
                    bg: 'blue',
                  }}
                >
                  <b>Shared</b>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box display="flex" flexDirection="column">
                    <Box>
                      <Button
                        mx={1}
                        size="lg"
                        leftIcon={<BiCameraMovie />}
                        // colorScheme="blue"
                        variant={
                          this.state.mode === VIDEO_MODE ? 'solid' : 'outline'
                        }
                        onClick={() =>
                          this.setState({
                            mode:
                              this.state.mode === VIDEO_MODE
                                ? PHOTO_MODE
                                : VIDEO_MODE,
                          })
                        }
                      >
                        Videos
                      </Button>
                      <Button
                        mx={1}
                        size="lg"
                        leftIcon={<BiCamera />}
                        // colorScheme="blue"
                        variant={
                          this.state.mode === PHOTO_MODE ? 'solid' : 'outline'
                        }
                        onClick={() =>
                          this.setState({
                            mode:
                              this.state.mode === VIDEO_MODE
                                ? PHOTO_MODE
                                : VIDEO_MODE,
                          })
                        }
                      >
                        Photos
                      </Button>
                    </Box>
                    <Box>
                      <Box display={this.state.mode !== PHOTO_MODE && 'none'}>
                        <UploadGalleryPhoto
                          inputs={{
                            accessToken: this.state.accessToken,
                            uuid: this.state.userId,
                            type: 'User',
                          }}
                          outputs={this.uploadPhotoHandler}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box display={this.state.mode !== PHOTO_MODE && 'none'}>
                    <GalleryComponent
                      accessToken={this.state.accessToken}
                      userId={this.state.userId}
                      teamId={null}
                      reload={this.state.galleryReload}
                      tempPreview={this.state.tempPreview}
                      output={({ event, data }) => {
                        if (event === CUSTOM_EVENTS.tempPreview) {
                          this.setState({
                            tempPreview: data,
                          });
                        } else if (event === CUSTOM_EVENTS.reloaded) {
                          this.setState({
                            galleryReload: data,
                          });
                        } else if (event === CUSTOM_EVENTS.sharedPhoto) {
                          const { downloadedServerImages } = this.state;
                          if (downloadedServerImages[data.teamId]) {
                            const { photos } =
                              downloadedServerImages[data.teamId];
                            downloadedServerImages[data.teamId]['photos'] = {
                              ...data.photos,
                              ...photos,
                            };
                            this.setState({
                              downloadedServerImages: JSON.parse(
                                JSON.stringify(downloadedServerImages)
                              ),
                            });
                          }
                        } else if (event === CUSTOM_EVENTS.accessRevoked) {
                          const { downloadedServerImages } = this.state;
                          if (downloadedServerImages[data.teamId]) {
                            const { photos } =
                              downloadedServerImages[data.teamId];
                            delete photos[data.photoName];
                            downloadedServerImages[data.teamId]['photos'] = {
                              ...photos,
                            };
                            this.setState({
                              downloadedServerImages: JSON.parse(
                                JSON.stringify(downloadedServerImages)
                              ),
                            });
                          }
                        }
                      }}
                    />
                  </Box>
                  <Box
                    display={this.state.mode === PHOTO_MODE && 'none'}
                    width={'100%'}
                    mt={3}
                  >
                    {this.showingMenuForSelectedThumbails()}
                    <ScrollComponent
                      pagination={this.state.video_pagination}
                      output={this.onScrollHandler}
                      flexGrow={'1'}
                    >
                      {this.getVideos()}
                    </ScrollComponent>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box display={'flex'} flexDirection="column">
                    {this.showingServerDropdown()}
                    <Box w={'50'}>
                      {this.state.selectedServerId && (
                        <UploadGalleryPhoto
                          outputs={this.uploadPhotoHandler}
                          inputs={{
                            accessToken: this.state.accessToken,
                            uuid: this.state.selectedServerId,
                            type: 'Team',
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <GalleryComponent
                    accessToken={this.state.accessToken}
                    userId={null}
                    teamId={this.state.selectedServerId}
                    reload={this.state.galleryReload}
                    tempPreview={this.state.tempPreview}
                    photosObj={
                      this.state.downloadedServerImages[
                        this.state.selectedServerId
                      ]
                    }
                    output={({ event, data }) => {
                      if (event === CUSTOM_EVENTS.tempPreview) {
                        this.setState({
                          tempPreview: data,
                        });
                      } else if (event === CUSTOM_EVENTS.reloaded) {
                        this.setState({
                          galleryReload: data,
                        });
                      } else if (event === CUSTOM_EVENTS.updatedThumbnails) {
                        const { downloadedServerImages } = this.state;
                        downloadedServerImages[data.teamId] = data;
                        this.setState({
                          downloadedServerImages,
                        });
                      }
                    }}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Gallery;
