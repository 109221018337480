import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../constants/Constants';

export const sendMetaReviewAppNotification = (token, metaUserId) => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/notifications/send_review_notification/${metaUserId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
  };

  return API.post(apiName, path, myInit);
};
