import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, Input, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useState, createRef } from 'react';

import FullScreenLoading from '../../../../components/FullScreenLoading';
import TableComponent from '../../../../components/TableComponent';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { getTeamInfo } from '../../../../services/awsService';
import * as uploadService from '../../../../services/uploadService';
import { isTeamAdminOrSuperUser } from '../../TeamUtils';
import { deleteFile, downloadFile, uploadFile } from '../FilesService';

const ModelsComponent = () => {
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [defaultModels, setDefaultModels] = useState([]);
  const [modelsTableData, setModelsTableData] = useState([]);
  const [canUpload, setCanUpload] = useState(false);
  const [fileInput, setFileInput] = useState(createRef());

  useEffect(() => {
    getModels();
  }, []);

  useEffect(() => {
    setModelsTableData(buildModelsData());
  }, [models, defaultModels]);

  const notDefaultModel = (file) => {
    return !defaultModels.includes(file);
  };

  const getModels = () => {
    setLoading(true);
    getTeamInfo(state.token, teamState.teamName, 'models=1')
      .then((res) => {
        const teamInfo = JSON.parse(res.body);
        setModels([...teamInfo.default_models, ...teamInfo.models]);
        setDefaultModels([...teamInfo.default_models]);
        setLoading(false);
      })
      .catch((err) => {
        // console.error(err);
        setLoading(false);
      });
  };

  const deleteModel = (file) => {
    setLoading(true);
    deleteFile(file, 'models', teamState.teamId, state.token).then(() => {
      getModels();
    });
  };

  function getActions(file) {
    return isTeamAdminOrSuperUser(state.role, teamState.teamRole) &&
      notDefaultModel(file) ? (
      <Button
        m={1}
        w="10%"
        variant="ghost"
        onClick={() => {
          setLoading(true);
          deleteModel(file);
        }}
      >
        <DeleteIcon boxSize={'20px'} />
      </Button>
    ) : null;
  }

  function buildModelsData() {
    return models.reduce((accumulator, model) => {
      const modelRow = {
        col1: model,
        col5: getActions(model),
      };
      accumulator.push(modelRow);

      return accumulator;
    }, []);
  }

  const data = useMemo(() => [...modelsTableData], [modelsTableData]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'col1',
        header: 'FileName',
        footer: (info) => info.column.id,
        enableColumnFilter: false,
        cell: (info) => {
          return (
            <Button
              variant="ghost"
              _hover={{
                backgroundColor: 'transaprent',
                color: 'blueDark',
              }}
              onClick={() => {
                downloadFile(
                  info.row.original.col1,
                  'models',
                  teamState.teamId,
                  state.token
                ).then((res) => {
                  window.open(res);
                });
              }}
            >
              <Text isTruncated>{info.row.original.col1}</Text>
            </Button>
          );
        },
      },
      {
        accessorKey: 'col5',
        header: '',
        footer: (info) => info.column.id,
        enableFiltering: false,
        cell: (info) => {
          return info.row.original.col5;
        },
      },
    ],
    []
  );

  return (
    <>
      {loading ? <FullScreenLoading /> : null}

      <Text fontSize="md" mb={6} mt={6}>
        Upload any 3D model in <Text as="strong">.glb</Text> format. Uploaded
        models will be available in the conference room of your Remio server.
      </Text>

      {isTeamAdminOrSuperUser(state.role, teamState.teamRole) ? (
        <Box
          display="flex"
          alignItems="center"
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Input
            onChange={(e) => {
              e.target.value !== '' ? setCanUpload(true) : setCanUpload(false);
            }}
            type="file"
            accept=".glb"
            ref={fileInput}
            m={1}
            p={1}
            width={{ base: '100%' }}
          />

          <Button
            m={1}
            width={{ base: '100%', md: '120px' }}
            disabled={!canUpload}
            onClick={() => {
              setLoading(true);
              uploadFile('models', fileInput, teamState.teamId, state.token)
                .then((res) => {
                  uploadService
                    .postForm(res.url, res.fields, fileInput.current.files[0])
                    .then((res) => {
                      getModels();
                    })
                    .catch((err) => {
                      setLoading(false);
                    });
                })
                .catch((err) => {
                  setLoading(false);
                });
            }}
          >
            Upload File
          </Button>
        </Box>
      ) : null}

      <TableComponent
        data={data}
        columns={columns}
        rawSortBy={[{ id: 'Filename', desc: false }]}
      />
    </>
  );
};

export default ModelsComponent;
