import { Tab } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const SELECTED_TAB_COLOR = 'yellow';
const HOVER_TAB_COLOR = '#e0bb73';

const SidebarTabComponent = ({ children, path }) => {
  return (
    <Tab
      as={Link}
      to={`/admin/${path}`}
      w={'100%'}
      color="inherit" // inherit color from parent, not the Link
      textDecoration="none" // remove underline from the Link
      _selected={{
        color: SELECTED_TAB_COLOR,
        bg: 'blue.500',
        borderRadius: 5,
      }}
      _focus={{
        outline: 0,
      }}
      _hover={{
        color: HOVER_TAB_COLOR,
        textDecoration: 'none', // ensure hover doesn't underline the link
      }}
    >
      {children}
    </Tab>
  );
};

export default SidebarTabComponent;
