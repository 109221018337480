import { Box, Button, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

import SetServerCodeModal from './setServerCodeModal';

import { ACTION } from '../../../../actions/actions';
import FullScreenLoading from '../../../../components/FullScreenLoading';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { setTeamCodeApi } from '../../../../services/awsService';
import { isTeamAdminOrSuperUser } from '../../TeamUtils';

const SetServerCodeComponent = () => {
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const teamCodeModalResetState = {
    expiryMins: '60',
  };
  const [teamCodeExpiration, setTeamCodeExpiration] = useState('');
  const [teamCode, setTeamCode] = useState('');
  const [teamCodeModal, setTeamCodeModal] = useState(teamCodeModalResetState);
  const [isTeamCodeModalOpen, setIsTeamCodeModalOpen] = useState(false);
  const isAdmin = isTeamAdminOrSuperUser(state.role, teamState.teamRole);

  const showServerCode = () => {
    let displayMsg = 'Forever';
    if (teamCodeModal.expiryMins !== 0 && teamCodeExpiration) {
      displayMsg = new Date(teamCodeExpiration).toLocaleString();
    }
    return (
      <>
        <Box display="flex" width="182px">
          <Text style={{ fontWeight: 500 }}>Server Code: </Text>
          <Text ml={2} fontWeight={'semibold'}>
            {teamState.teamCode}
          </Text>
        </Box>

        <Box display="flex">
          <Text>Expiration: </Text>
          <Text ml={2}> {displayMsg} </Text>
        </Box>

        <Button
          size={'sm'}
          variant={'cancel'}
          ml={6}
          onClick={removeServerCode}
        >
          Remove
        </Button>
      </>
    );
  };

  const removeServerCode = () => {
    setIsLoading(true);
    setTeamCodeApi(state.token, teamState.teamId, {
      expiryMins: '-1',
    })
      .then((res) => {
        setTeamCode('');
        setTeamCodeExpiration('');
        teamDispatch({
          type: ACTION.UPDATE_TEAM_STATE,
          payload: {
            ...teamState,
            teamCode: '',
            teamCodeExpiration: '',
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onSetServerCodeComplete = (teamCode, teamCodeExpiration) => {
    setTeamCode(teamCode);
    setTeamCodeExpiration(teamCodeExpiration);
    teamDispatch({
      type: ACTION.UPDATE_TEAM_STATE,
      payload: {
        ...teamState,
        teamCode: teamCode,
        teamCodeExpiration: teamCodeExpiration,
      },
    });
    setIsLoading(false);
    setIsTeamCodeModalOpen(false);
  };

  const onCancelSetServerCodeComplete = () => {
    setIsLoading(false);
    setIsTeamCodeModalOpen(false);
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems={'center'}
        overflow="auto"
        flex={1}
      >
        {teamState.teamCode && showServerCode()}

        {!teamState.teamCode && (
          <>
            <Box width={'180px'}>
              <Text>Set Server Code</Text>
            </Box>

            <Button
              variant={'outline'}
              _hover={{ color: 'white', backgroundColor: 'blue' }}
              size={'sm'}
              onClick={() => {
                setIsTeamCodeModalOpen(true);
                setTeamCodeModal(teamCodeModalResetState);
              }}
              isDisabled={!isAdmin}
            >
              Set Server Code
            </Button>
          </>
        )}
      </Box>

      {isTeamCodeModalOpen ? (
        <SetServerCodeModal
          expiryMinutes={teamCodeModal.expiryMins}
          onSetServerCodeComplete={onSetServerCodeComplete}
          onCancelSetServerCodeComplete={onCancelSetServerCodeComplete}
        />
      ) : null}
    </>
  );
};

export default SetServerCodeComponent;
