import { ChakraProvider } from '@chakra-ui/react';
import { Amplify } from 'aws-amplify';
import { AppStateProvider } from './context/appContext';
import 'firebase/analytics';
import firebase from 'firebase/app';
import mixpanel from 'mixpanel-browser';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import awsconfig from './aws-exports';
import {
  FIREBASE_CONFIG,
  MIXPANEL_TOKEN,
  CHANNEL_NAME,
} from './constants/Constants';
import * as serviceWorker from './serviceWorker';
import { theme } from './theme';

import '@fontsource/poppins';

const app = firebase.initializeApp(FIREBASE_CONFIG);
const analytics = firebase.analytics(app);
analytics.setAnalyticsCollectionEnabled(false); // Do not collect analytics by default
Amplify.configure(awsconfig);

mixpanel.init(MIXPANEL_TOKEN, {
  debug: CHANNEL_NAME === 'prod',
  ignore_dnt: true,
  persistence: 'localStorage',
});

// TODO: create a container, then a root object, and then call render
// React is pretty much v17 instead of 18 as we use old methods
// We need to start using hooks as soon as possible to upgrade our code
// and the libraries we leverage
ReactDOM.render(
  <ChakraProvider theme={theme}>
    <AppStateProvider>
      <Router>
        <App firebaseAnalytics={analytics} />
      </Router>
    </AppStateProvider>
  </ChakraProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register('./service-worker.js');
// serviceWorker.unregister('./service-worker.js');
