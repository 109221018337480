import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Image,
  Link,
  Button,
  Text,
  Input,
  Select,
  Tooltip,
  IconButton,
  Switch,
  createStandaloneToast,
  Badge,
  Table,
  Thead,
  Th,
  Td,
  Tr,
  Tbody,
  FormControl,
  FormLabel,
  Spinner,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import AgoraRTM from 'agora-rtm-sdk';
import { Auth } from 'aws-amplify';
import React from 'react';
import { AiOutlineAudioMuted } from 'react-icons/ai';
import { BiLink } from 'react-icons/bi';
import { BsArrowsFullscreen, BsMic } from 'react-icons/bs';
import { FaRandom } from 'react-icons/fa';
import { RiCloseFill } from 'react-icons/ri';

import ControlsImage from '../../assets/images/controls.png';
import MovementImage from '../../assets/images/movement_wasd_qe.png';
import RotateImage from '../../assets/images/rotate_arrow.png';
import { DownloadStandAloneIcon } from '../../components/DownloadStandAloneIcon';
import {
  AGORA_APP_ID,
  WEBSOCKET_SERVER_URL_MUMBAI,
  WEBSOCKET_SERVER_URL_LOCAL,
} from '../../constants/Constants';
import {
  ERROR,
  NOTIFICATION,
  PUBLIC_SERVER_UID,
  DEFAULT_SETTINGS_SERVER_NAME,
  PUBLIC_SERVER_NAME,
  PUBLIC_SERVER_VISIBLE_NAME,
  CHANNEL_NAME,
  WEBSOCKET_SERVER_URL,
  SEND_PLAYER_INFO_DATA,
  API_VERSION,
  AGORA_SCREEN_SHARE_OPTIONS,
} from '../../constants/Constants';
import * as GAMES from '../../constants/Games';
import * as ROUTES from '../../constants/Routes';
import * as awsApi from '../../services/awsService';
import {
  isSuperUser,
  sendMessageToSlack,
  getJsonFromUrl,
  detectOS,
} from '../../services/commonService';

class SocketPromise extends Promise {
  constructor(resolve, reject) {
    super(resolve, reject);
    this.resolve = resolve;
    this.reject = reject;
  }
}

const STATES = {
  READY: 'Ready',
  STOPPED: 'Stopped',
  STARTING: 'Starting',
  INUSE: 'In use',
};

// Used to cap Agora's screen share resolution
const MAX_SCREEN_SHARE_WIDTH = 1280;
const MAX_SCREEN_SHARE_HEIGHT = 800;
const MIN_SCREEN_SHARE_WIDTH = 720;
const MIN_SCREEN_SHARE_HEIGHT = 600;
const MAX_SCREEN_SHARE_FPS = 24;
const MIN_SCREEN_SHARE_FPS = 15;
const MAX_SCREEN_SHARE_BITRATE = 5000;
const MIN_SCREEN_SHARE_BITRATE = 2000;

const progressMessages = {
  5: 'Wrapping up a few things...',
  15: 'Almost there! Our browser player will start in a few seconds. Hang tight!',
  30: 'We have found an existing browser player instance that we are connecting you to. Give us a few seconds to set it up.',
  60: 'Please wait a minute or two for us to wake up our server. It was sleeping zzzZ :)',
  180: 'All of our browser player slots are full. Please wait a few minutes for us to spin up another instance for you!',
  1000: 'Loading browser players',
  2000: 'Exiting spectator mode',
};

const brokenConnectionMessage = (
  <>
    <Text mb={2}>
      Looks like there's a connection problem between us. To resolve, try the
      following:
    </Text>
    <UnorderedList>
      <ListItem>
        Verify that your device has a stable internet connection
      </ListItem>
      <ListItem>Disable your VPN, cloud access works best without it</ListItem>
      <ListItem>Exit this page and try again</ListItem>
      <ListItem>
        Contact{' '}
        <Link
          href="mailto:support@remiovr.com"
          style={{ textDecoration: 'underline' }}
        >
          support@remiovr.com
        </Link>{' '}
        providing your browser, the browser's version, and where you are located
      </ListItem>
    </UnorderedList>
  </>
);

const { toast } = createStandaloneToast();
class AgoraClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      progress: 0,
      infoModal: {
        isOpen: false,
        playerName: '',
        roomSuffix: '',
      },
      roomName: '',
      teams: [],
      userData: null,
      superUser: false,
      cameraVideoEnabled: false,
      screenShareVideoEnabled: false,
      spectatorReady: false,
      isMasterSpectator: false,
      isErrorModalOpen: false,
      errorTitle: '',
      errorMessage: '',
      isLoading: true,
      showProgress: true,
      isAudioMuted: true,
      isMenuOpen: false,
      isSignInModalOpen: false,
      accessToken: null,
      teamPortals: [],
      teamSpaces: [],
      defaultTeamSpace: null,
      playersToFollow: [],
      followSettings: {
        isOpen: false,
        playerToFollow: '',
        followGaze: true,
        IgnoreVerticalDistance: true,
      },
      selectedGameName: '',
      eta: 1000,
      gameInstanceData: [],
      teamName: this.props.match.params['server'],
    };

    this.spectatorClient = AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'h264',
    });
    this.desktopClient = AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'h264',
    });
    this.socketConnectionKeepAliveTimer = null;
    this.socketConnectionKeepAliveResponseTimer = null;
    this.sendTimestampTimer = null;

    this.localSpectatorTracks = {
      audioTrack: null,
      videoTrack: null,
    };

    this.localDesktopTracks = {
      audioTrack: null,
      videoTrack: null,
    };

    this.streamList = {};

    this.options = {
      appid: AGORA_APP_ID,
      spectatorchannel: null,
      desktopchannel: null,
      spectatoruid: null,
      desktopuid: null,
      token: null,
      userid: Math.random().toString(36).substr(2, 9),
      sendcontrolevents: false,
      sendtimestamps: false,
      spectatorobjectname: null,
      msgChannelName: null,
    };

    this.webSocket = null;
    this.lastSendTime = 0;
    this.socketPromiseMap = new Map();
    this.rightMouseKeyPressed = false;

    // add event listener to play remote tracks when remote user publishs.
    this.spectatorClient.on(
      'user-published',
      this.handleUserPublished.bind(this)
    );
    this.spectatorClient.on(
      'user-unpublished',
      this.handleUserUnpublished.bind(this)
    );
    this.expectedTime = 60;
    this.progressCountdownInterval = null;
    this.startProgressTimestamp = null;
    this.endProgressTimestamp = null;
    this.forceDevSuperHost = false;
    this.serverUrl = WEBSOCKET_SERVER_URL;
    this.queryParams = getJsonFromUrl(window.location.href);
    this.region = this.queryParams.region;
    this.masterId = this.queryParams.masterId;
    this.teamId = this.queryParams.teamId;

    if (this.region === 'mumbai') this.serverUrl = WEBSOCKET_SERVER_URL_MUMBAI;

    if (this.region === 'local') this.serverUrl = WEBSOCKET_SERVER_URL_LOCAL;

    this.initConnectionsTimeout = null;
    this.toastId = React.createRef();

    this.validKeys = [
      'KeyW',
      'KeyA',
      'KeyS',
      'KeyD',
      'KeyQ',
      'KeyE',
      'KeyR',
      'KeyF',
      'KeyG',
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ShiftLeft',
      'Space',
      'Enter',
    ];

    this.keyMessage = { type: 'keyPress' };

    this.validKeys.forEach((validKey) => {
      this.keyMessage[validKey] = false;
    });

    this.mouseMoveCooldown = null;

    this.processContectMenuThis = null;
    this.processMouseDownThis = null;
    this.processMouseMoveThis = null;
    this.processKeyDownThis = null;
    this.processKeyUpThis = null;
    this.isWindows = detectOS('Windows');

    this.connectToInstanceTimeout = null;
    this.playerHTMLElement = null;

    this.checkValidAgoraPlayer = null;
  }

  componentDidMount() {
    if (this.masterId && this.teamId) {
      const channelName = `${CHANNEL_NAME}-` + this.teamId + '-' + this.masterId;
      // console.log(channelName);
      this.joinSpectatorChannel(channelName);
      this.setState({ isLoading: false });

      this.checkValidAgoraPlayer = setInterval(() => {
        const remoteVideoPlayer =
          document.getElementsByClassName('agora_video_player');

        if (remoteVideoPlayer.length === 0) {
          this.setState({
            isLoading: false,
            errorTitle: 'Controlling player disconnected',
            errorMessage:
              'It seems like the controlling player disconnected. Please refresh the page to connect to a new one.',
            isErrorModalOpen: true,
          });
        }
      }, 5000);
    } else {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          this.setState({
            currentUser: user,
            accessToken: user.signInUserSession.idToken.jwtToken,
          });
          awsApi.getUserDetailsApi(this.state.accessToken).then((res) => {
            const userData = JSON.parse(res.body);

            if (userData.visibleName !== null) {
              this.setState({
                infoModal: {
                  ...this.state.infoModal,
                  playerName: userData.visibleName,
                },
                userData: userData,
                superUser: isSuperUser(userData.role),
              });
            } else {
              this.setState({
                infoModal: {
                  ...this.state.infoModal,
                  playerName: 'Spectator',
                },
              });
            }
            awsApi
              .getServerListApi(this.state.accessToken)
              .then(async (res) => {
                const teams = JSON.parse(res.body);
                if (teams.teams !== null) {
                  if (this.state.teamName) {
                    const { infoModal } = this.state;
                    infoModal['roomSuffix'] = this.state.teamName;
                    this.setState({ infoModal });
                  }
                  this.setState({
                    teams: teams.teams,
                    userData: {
                      ...this.state.userData,
                      teams: teams.teams,
                    },
                  });
                }
                const success = await this.connectWebsocket().catch(() => {
                  return false;
                });
                if (success) {
                  this.canConnectToInstanceRecursive();
                }
                this.createMessageClientAndConnectListeners();
                this.login(`${userData.uuid}-${Date.now()}`)
                  .then(() => {
                    // console.log('Logged into agoraRTM');
                  })
                  .catch((err) => {
                    // console.error(err);
                  });
              });
          });
        })
        .catch((err) => {
          // console.error(err);
          this.setState({
            isLoading: false,
            isSignInModalOpen: true,
          });
        });
    }
  }

  async canConnectToInstanceRecursive() {
    const canConnect = await this.canConnectToInstance();
    if (canConnect) {
      clearTimeout(this.connectToInstanceTimeout);
      this.connectToInstanceTimeout = null;
      this.setState({
        infoModal: { ...this.state.infoModal, isOpen: true },
        isLoading: false,
      });
    } else {
      // console.log('display instance loading modal');
      this.connectToInstanceTimeout = setTimeout(() => {
        this.canConnectToInstanceRecursive();
      }, 5000);
    }
  }

  canConnectToInstance() {
    /* eslint-disable */
    return new Promise(async (resolve, reject) => {
      let gameInstances = await this.getGameInstances().catch((error) => {
        return { gameInstances: [], error: error };
      });
      this.setState({ gameInstanceData: gameInstances });
      let canConnect = false;
      if (gameInstances.length > 0) {
        for (let index = 0; index < gameInstances.length; index++) {
          const instance = gameInstances[index];
          if (
            instance.instanceStatus === 'Running' &&
            instance.isAdminUserConnected &&
            instance.masterUserId === '' &&
            instance.isUnityInstanceStarted
          ) {
            canConnect = true;
            resolve(canConnect);
          }
        }
        for (let index = 0; index < gameInstances.length; index++) {
          const instance = gameInstances[index];
          if (
            instance.instanceStatus === 'Running' &&
            instance.isAdminUserConnected &&
            instance.masterUserId === '' &&
            !instance.isUnityInstanceStarted
          ) {
            this.setState({ eta: 30 });
            resolve(canConnect);
          }
        }
        for (let index = 0; index < gameInstances.length; index++) {
          const instance = gameInstances[index];
          if (
            instance.instanceStatus === 'Running' &&
            !instance.isAdminUserConnected &&
            instance.masterUserId === '' &&
            !instance.isUnityInstanceStarted
          ) {
            this.setState({ eta: 60 });
            resolve(canConnect);
          }
        }
      }
      resolve(canConnect);
    });
  }

  getInstanceSummaryState(instance) {
    let instanceSummaryState = STATES.STOPPED;

    if (
      instance.instanceStatus === 'Running' &&
      instance.isAdminUserConnected &&
      instance.masterUserId === '' &&
      instance.isUnityInstanceStarted
    ) {
      instanceSummaryState = STATES.READY;
    } else if (
      instance.instanceStatus === 'Running' &&
      instance.isAdminUserConnected &&
      instance.masterUserId !== '' &&
      instance.isUnityInstanceStarted
    ) {
      instanceSummaryState = STATES.INUSE;
    } else if (
      instance.instanceStatus === 'Running' &&
      instance.isAdminUserConnected &&
      instance.masterUserId === '' &&
      !instance.isUnityInstanceStarted
    ) {
      instanceSummaryState = STATES.STARTING;
    } else if (
      instance.instanceStatus === 'Running' &&
      instance.masterUserId === ''
    ) {
      instanceSummaryState = STATES.STARTING;
    }

    return instanceSummaryState;
  }

  componentWillUnmount() {
    this.options.sendcontrolevents = false;
    this.disconnectFromConsoleApp();

    if (this.checkValidAgoraPlayer) clearInterval(this.checkValidAgoraPlayer);

    if (this.progressCountdownInterval)
      clearInterval(this.progressCountdownInterval);

    if (this.initConnectionsTimeout) clearTimeout(this.initConnectionsTimeout);

    if (this.messagClient) {
      this.messagClient.removeAllListeners();
      this.messagClient = null;
    }
    if (this.msgChannel) {
      this.msgChannel.removeAllListeners();
      this.msgChannel = null;
    }

    if (this.keepAliveMessage) {
      clearInterval(this.keepAliveMessage);
      this.keepAliveMessage = null;
    }

    if (this.connectToInstanceTimeout) {
      clearTimeout(this.connectToInstanceTimeout);
    }

    toast.closeAll();
  }

  async initConnections() {
    let gameInstanceForMaster = null;
    if (this.state.gameInstanceData.length > 0) {
      for (let index = 0; index < this.state.gameInstanceData.length; index++) {
        const instance = this.state.gameInstanceData[index];
        if (
          instance.instanceStatus === 'Running' &&
          instance.isAdminUserConnected &&
          instance.masterUserId === '' &&
          instance.isUnityInstanceStarted
        ) {
          gameInstanceForMaster = instance;
          break;
        }
      }

      // console.log('Found instance for master:');
      // console.log(gameInstanceForMaster);
    } else if (this.state.gameInstanceData.error !== undefined) {
      let message =
        'Get game instances command error: ' +
        this.state.gameInstanceData.error;
      // console.error(message);
      this.setState({
        isLoading: false,
        errorTitle:
          "Sorry, something's up on our end and we couldn't connect you with our instance",
        errorMessage: brokenConnectionMessage,
        isErrorModalOpen: true,
      });
      if (this.region !== 'local')
        sendMessageToSlack(ERROR, message, this.state.userData);
      return;
    }

    if (gameInstanceForMaster) {
      let connectData = await this.connectMasterSpectator(
        this.options.userid,
        this.state.infoModal.roomSuffix
      ).catch((error) => {
        return { connectStatus: 'failed', error: error };
      });

      if (connectData.connectStatus === 'succeeded') {
        this.setState({ isLoading: false, isMasterSpectator: true });
        let channelName =
          `${CHANNEL_NAME}-` +
          this.state.infoModal.roomSuffix +
          '-' +
          this.options.userid;
        this.sendPlayerInfo();
        this.sendPlayerInfo('ConsumerLoginMenu');
        // console.log(channelName);
        this.joinSpectatorChannel(channelName);
        return;
      }

      // If master spectator failed here, someone might have connected right before you.
      if (connectData.connectStatus === 'failed') {
        /* eslint-disable */
        let message = 'Connect command failed ';
        let connectionError = connectData.error;
        // console.error(message + connectionError);

        this.setState({
          isLoading: false,
          errorTitle: 'Your spot was taken!',
          errorMessage: (
            <Text>
              The instance we tried to connect you with might have been taken by
              someone else. Please refresh page to try again.
            </Text>
          ),
          isErrorModalOpen: true,
        });
        if (this.region !== 'local')
          sendMessageToSlack(
            ERROR,
            'The instance we tried to connect you with might have been taken by someone else. Please refresh page to try again.',
            this.state.userData
          );
      }
      return;
    }

    this.setState({
      isLoading: false,
      errorTitle: "We're at max capacity",
      errorMessage: (
        <Text>
          We're currently at our limit for offering browser sessions. We'd love
          to have you in Remio, be sure to come back and try again!
        </Text>
      ),
      isErrorModalOpen: true,
    });
    if (this.region !== 'local')
      sendMessageToSlack(
        ERROR,
        'There are no available spectators running, or you do not have access to the already running spectators. Please try again later.',
        this.state.userData
      );
  }

  utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }

  getTeamUidFromName(teamName) {
    const teams = this.state.userData?.teams || this.state.teams || [];
    for (let index = 0; index < teams.length; index++) {
      const team = teams[index];
      if (teamName !== PUBLIC_SERVER_NAME && team.teamName === teamName)
        return team.uuid;
    }

    return PUBLIC_SERVER_UID;
  }

  sendTeamSelectionToStandalone(data = '') {
    try {
      this.webSocket.send(
        JSON.stringify({
          type: 'command',
          command: 'custom',
          objectName: 'ClientLoginMenu',
          functionName: 'OnTeamButtonPressed',
          data: this.getTeamUidFromName(this.state.infoModal.roomSuffix),
        })
      );
    } catch (error) {
      // console.error('Connect command error: ' + error);
    }

    try {
      this.webSocket.send(
        JSON.stringify({
          type: 'command',
          command: 'custom',
          objectName: 'ConsumerLoginMenu',
          functionName: 'OnTeamButtonPressed',
          data: this.getTeamUidFromName(this.state.infoModal.roomSuffix),
        })
      );
    } catch (error) {
      // console.error('Connect command error: ' + error);
    }
  }

  async sendPlayerInfo(objectName = 'ClientLoginMenu') {
    try {
      this.webSocket.send(
        JSON.stringify({
          type: 'command',
          command: 'custom',
          objectName: objectName,
          functionName: 'SetAPIEndpoint',
          data: SEND_PLAYER_INFO_DATA,
        })
      );
    } catch (error) {
      // console.error('Connect command error: ' + error);
    }

    if (this.state.isMasterSpectator) {
      const apiVersion = CHANNEL_NAME;
      const teamName = this.state.infoModal.roomSuffix;
      var data = await this.startAgoraChannel(apiVersion, teamName).catch(
        (error) => {
          return { agoraChannelStatus: 'failed', error: error };
        }
      );
      if (data.agoraChannelStatus === 'started') {
        // console.log(
        //   'Agora channel started - API: ' +
        //     apiVersion +
        //     ', Server name: ' +
        //     teamName
        // );
      } else {
        var message = 'Start Agora channel command failed';
        if (data.error !== undefined) message += ' - ' + data.error;
        // console.error(message);
        if (this.region !== 'local')
          sendMessageToSlack(ERROR, message, this.state.userData);
      }
    }

    setTimeout(() => {
      if (this.state.currentUser) {
        try {
          this.webSocket.send(
            JSON.stringify({
              type: 'command',
              command: 'custom',
              objectName: objectName,
              functionName: 'GetUserDetailsUsingToken',
              data: this.utf8_to_b64(
                this.state.currentUser.signInUserSession.accessToken.jwtToken
              ),
            })
          );
        } catch (error) {
          // console.error('Connect command error: ' + error);
        }
      } else {
        try {
          this.webSocket.send(
            JSON.stringify({
              type: 'command',
              command: 'custom',
              objectName: objectName,
              functionName: 'SaveName',
              data: this.state.infoModal.playerName,
            })
          );
        } catch (error) {
          // console.error('Connect command error: ' + error);
        }
        setTimeout(() => {
          try {
            this.webSocket.send(
              JSON.stringify({
                type: 'command',
                command: 'custom',
                objectName: objectName,
                functionName: 'DemoButtonClicked',
                data: '',
              })
            );
          } catch (error) {
            // console.error('Connect command error: ' + error);
          }
        }, 1000);
      }
    }, 1000);
  }

  async sendMessage(message) {
    var messageId = Math.random().toString(36).substr(2, 9);
    message.id = messageId;
    var messageString = JSON.stringify(message);
    var _resolve;
    var _reject;
    var socketPromise = new SocketPromise((resolve, reject) => {
      _resolve = resolve;
      _reject = reject;
    });
    socketPromise.resolve = _resolve;
    socketPromise.reject = _reject;
    this.socketPromiseMap.set(messageId, socketPromise);
    this.webSocket.send(messageString);
    // console.log('Message sent: ' + messageString);
    return socketPromise;
  }

  getGameInstances() {
    return new Promise(async (resolve, reject) => {
      var data = null;
      try {
        data = await this.sendMessage({
          type: 'command',
          command: 'getGameInstances',
        });
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  connectMasterSpectator(userId, teamName) {
    return new Promise(async (resolve, reject) => {
      var data = null;
      try {
        data = await this.sendMessage({
          userId: userId,
          teamName: teamName,
          type: 'command',
          command: 'connect',
          masterUser: true,
        });
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  connectNonMasterSpectator(userId, teamName, masterUserId) {
    return new Promise(async (resolve, reject) => {
      var data = null;
      try {
        data = await this.sendMessage({
          userId: userId,
          teamName: teamName,
          type: 'command',
          command: 'connect',
          masterUser: false,
          masterUserId: masterUserId,
        });
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  startAgoraChannel(apiVersion, teamName) {
    return new Promise(async (resolve, reject) => {
      var data = null;
      try {
        data = await this.sendMessage({
          type: 'command',
          command: 'startAgoraChannel',
          apiVersion: apiVersion,
          teamName: teamName,
        });
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  connectWebsocket() {
    return new Promise((resolve, reject) => {
      var promiseResolved = false;
      // console.log('Calling connect websocket: ' + this.serverUrl);
      this.webSocket = new WebSocket(this.serverUrl);
      this.webSocket.addEventListener('open', async (event) => {
        // console.log('WebSocket connected');
        this.socketConnectionKeepAliveTimer = setInterval(() => {
          var currentMilliseconds = new Date().getTime();
          this.webSocket.send(
            JSON.stringify({
              keepAlive: 'ping',
              timestamp: currentMilliseconds,
            })
          );
          this.socketConnectionKeepAliveResponseTimer = setTimeout(() => {
            clearInterval(this.socketConnectionKeepAliveTimer);
            this.socketConnectionKeepAliveTimer = null;
            this.webSocket.close();
            this.webSocket = null;
            var message =
              'Broken connection to the server has been detected - no keep alive message received';
            // console.error(message);
            this.setState({
              isLoading: false,
              errorTitle: 'We lost your connection',
              errorMessage: brokenConnectionMessage,
              isErrorModalOpen: true,
            });
            if (this.region !== 'local')
              sendMessageToSlack(ERROR, message, this.state.userData);
          }, 5000);
        }, 10000);
        promiseResolved = true;
        resolve(true);
      });

      this.webSocket.addEventListener('message', (event) => {
        var message = JSON.parse(event.data);
        var messageId = message.id;
        if (messageId !== undefined) {
          if (this.socketPromiseMap.has(messageId)) {
            if (message.value.error === undefined)
              this.socketPromiseMap.get(messageId).resolve(message.value);
            else
              this.socketPromiseMap.get(messageId).reject(message.value.error);
            this.socketPromiseMap.delete(messageId);
          } else {
            console.warn('Got response for unknown message, ID: ' + messageId);
          }
        } else {
          if (message.keepAlive !== undefined) {
            clearTimeout(this.socketConnectionKeepAliveResponseTimer);
            this.socketConnectionKeepAliveResponseTimer = null;
            var currentMilliseconds = new Date().getTime();
            var ping = currentMilliseconds - message.timestamp;
            let pingLabel = document.getElementById('ping-label');
            if (pingLabel) pingLabel.innerHTML = 'Ping: ' + ping + 'ms';
          } else if (
            message.type !== undefined &&
            message.type === 'command' &&
            message.command === 'custom'
          ) {
            // console.log('Custom command message received: ');
            // console.log(message);
            if (this[message.functionName] !== undefined)
              this[message.functionName](message.data);
          } else {
            // console.log('Unhandled message received: ' + event.data);
          }
        }
      });

      this.webSocket.addEventListener('close', (event) => {
        var error;
        // Normal Closure and No Status Received are considered as success
        if (event.code === 1000 || event.code === 1005) error = null;
        else if (event.code === 1001) error = 'Going Away';
        else if (event.code === 1002) error = 'Protocol Error';
        else if (event.code === 1003) error = 'Unsupported Data';
        else if (event.code === 1006) error = 'Abnormal Closure';
        else if (event.code === 1007) error = 'Invalid frame payload data';
        else if (event.code === 1008) error = 'Policy Violation';
        else if (event.code === 1009) error = 'Message too big';
        else if (event.code === 1010) error = 'Missing Extension';
        else if (event.code === 1011) error = 'Internal Error';
        else if (event.code === 1012) error = 'Service Restart';
        else if (event.code === 1013) error = 'Try Again Later';
        else if (event.code === 1014) error = 'Bad Gateway';
        else if (event.code === 1015) error = 'TLS Handshake';
        if (error !== undefined && error !== null) {
          if (!promiseResolved) reject();
          var message =
            'WebSocket connection error: ' + event.code + ' - ' + error;
          // console.error(message);
          clearInterval(this.socketConnectionKeepAliveTimer);
          this.socketConnectionKeepAliveTimer = null;
          if (this.state.isMasterSpectator && this.options.sendtimestamps)
            clearInterval(this.sendTimestampTimer);
          this.setState({
            isLoading: false,
            errorTitle: 'We lost your connection',
            errorMessage: brokenConnectionMessage,
            isErrorModalOpen: true,
          });
          if (this.region !== 'local')
            sendMessageToSlack(ERROR, message, this.state.userData);
        } else {
          // console.log('WebSocket connection closed');
        }
      });

      this.webSocket.addEventListener('error', (event) => {
        // console.error('WebSocket error');
      });
    });
  }

  disconnectFromConsoleApp() {
    clearInterval(this.socketConnectionKeepAliveTimer);
    this.socketConnectionKeepAliveTimer = null;
    if (this.state.isMasterSpectator && this.options.sendtimestamps)
      clearInterval(this.sendTimestampTimer);
    this.sendTimestampTimer = null;
    if (this.webSocket) this.webSocket.close();
    this.webSocket = null;
  }

  async joinSpectatorChannel(channelName) {
    // Send mouse position event if user is the master user.
    if (this.state.isMasterSpectator) {
      // Send timestamps for delay measurement - testing purposes only
      if (this.options.sendtimestamps) {
        var startTimestamp = new Date().getTime();
        this.sendTimestampTimer = setInterval(() => {
          var currentMilliseconds = new Date().getTime() - startTimestamp;

          var minutes, seconds, milliseconds;
          milliseconds = currentMilliseconds;
          seconds = Math.floor(milliseconds / 1000);
          milliseconds = milliseconds % 1000;
          minutes = Math.floor(seconds / 60);
          seconds = seconds % 60;
          // var timestampText = (minutes < 10 ? "0" : "") + minutes + ":" +
          //     (seconds < 10 ? "0" : "") + seconds + ":" +
          //     (milliseconds < 10 ? "00" : (milliseconds < 100 ? "0" : "")) + milliseconds
          //$("#timestamp").text(timestampText);

          this.webSocket.send(
            JSON.stringify({
              type: 'timestamp',
              timestamp: currentMilliseconds,
            })
          );
        }, 30);
      }

      this.webSocket.send(
        JSON.stringify({ type: 'mouseDelta', xDelta: 0.0, yDelta: 0.0 })
      );

      // Send mouse position event if user is the master user.
      // console.log('Adding listeners');
      this.processContectMenuThis = this.procesContextMenu.bind(this);
      this.processMouseDownThis = this.processMouseDown.bind(this);
      this.processMouseUpThis = this.processMouseUp.bind(this);
      this.processMouseMoveThis = this.processMouseMove.bind(this);
      this.processKeyDownThis = this.processKeyDown.bind(this);
      this.processKeyUpThis = this.processKeyUp.bind(this);

      document.addEventListener('contextmenu', this.processContectMenuThis);
      document.addEventListener('mousedown', this.processMouseDownThis);
      document.addEventListener('mouseup', this.processMouseUpThis);
      document.addEventListener('mousemove', this.processMouseMoveThis);
      document.addEventListener('keydown', this.processKeyDownThis);
      document.addEventListener('keyup', this.processKeyUpThis);
      this.options.sendcontrolevents = true;
    }

    this.options.spectatorchannel = channelName;
    this.options.spectatoruid = await this.spectatorClient.join(
      this.options.appid,
      channelName,
      null
    );
    this.localSpectatorTracks.audioTrack =
      await AgoraRTC.createMicrophoneAudioTrack();
    await this.spectatorClient.publish(this.localSpectatorTracks.audioTrack);

    if (this.state.isMasterSpectator) {
      this.setState({ isAudioMuted: false });
      if (this.toastId.current) {
        toast.update(this.toastId.current, {
          title: 'You are unmuted',
          description: `People on this channel can hear you.\nHaving issues? Make sure you allowed access to you microphone`,
          status: 'success',
          duration: null,
          isClosable: true,
          onCloseComplete: () => {
            this.toastId.current = null;
          },
        });
      } else {
        this.toastId.current = toast({
          title: 'You are unmuted',
          description: `People on this channel can hear you.\nHaving issues? Make sure you allowed access to you microphone`,
          status: 'success',
          duration: null,
          isClosable: true,
          onCloseComplete: () => {
            this.toastId.current = null;
          },
        });
      }
    } else {
      this.localSpectatorTracks.audioTrack.setMuted(true).then((res) => {
        // console.log(res);
        this.setState({ isAudioMuted: true });
        if (this.toastId.current) {
          toast.update(this.toastId.current, {
            title: 'You are muted',
            description: `People on this channel can't hear you. To speak, unmute yourself.`,
            status: 'warning',
            duration: null,
            isClosable: true,
            onCloseComplete: () => {
              this.toastId.current = null;
            },
          });
        } else {
          this.toastId.current = toast({
            title: 'You are muted',
            description: `People on this channel can't hear you. To speak, unmute yourself.`,
            status: 'warning',
            duration: null,
            isClosable: true,
            onCloseComplete: () => {
              this.toastId.current = null;
            },
          });
        }
      });
      // .catch((err) => console.error(err));
    }

    // console.log(this.localSpectatorTracks);
    // console.log('publish success');
    let message = 'Successfully started as ';
    message += this.state.isMasterSpectator
      ? 'Master Spectator'
      : 'Non-master Spectator';
    if (this.region !== 'local')
      sendMessageToSlack(NOTIFICATION, message, this.state.userData);
  }

  leaveSpectatorChannel() {
    return new Promise((resolve, reject) => {
      if (this.state.isMasterSpectator) {
        document.removeEventListener(
          'contextmenu',
          this.processContectMenuThis
        );
        document.removeEventListener('mousedown', this.processMouseDownThis);
        document.removeEventListener('mouseup', this.processMouseUpThis);
        document.removeEventListener('mousemove', this.processMouseMoveThis);
        document.removeEventListener('keydown', this.processKeyDownThis);
        document.removeEventListener('keyup', this.processKeyUpThis);
      }
      this.rightMouseKeyPressed = false;

      // remove remote users and player views
      this.streamList = {};
      let remotePlayerList = document.getElementById('remote-playerlist');
      if (remotePlayerList) remotePlayerList.innerHTML = '';

      // leave the channel
      this.unpublishSpectatorClient()
        .then(() => {
          resolve();
        })
        .catch((err) => reject(err));

      // console.log('client leaves channel success');
    });
  }

  procesContextMenu(event) {
    event.preventDefault();
  }

  isMouseEventWithinPlayer(event) {
    return this.playerHTMLElement.contains(event.target);
  }

  processMouseDown(event) {
    if (!this.isMouseEventWithinPlayer(event)) return;

    const playerSurface = this.getAgoraComponent();
    if (event.button === 2) {
      if (!this.rightMouseKeyPressed) {
        this.rightMouseKeyPressed = true;
        playerSurface.requestPointerLock();
      } else {
        this.rightMouseKeyPressed = false;
        document.exitPointerLock();
        if (
          this.webSocket !== null &&
          this.webSocket.readyState === WebSocket.OPEN &&
          this.state.isMasterSpectator &&
          this.options.sendcontrolevents
        ) {
          this.webSocket.send(
            JSON.stringify({
              type: 'mouseDelta',
              xDelta: 0.0,
              yDelta: 0.0,
            })
          );
        }
      }
    }
    if (event.button === 0) {
      let coords = document.fullscreenElement
        ? this.getMouseCoordinatesRelativeToWindow(event)
        : this.getMouseCoordinatesRelativeToElement(event, playerSurface);
      coords['type'] = 'mouseCoordinates';
      if (
        this.webSocket !== null &&
        this.webSocket.readyState === WebSocket.OPEN &&
        this.state.isMasterSpectator &&
        this.options.sendcontrolevents
      ) {
        this.webSocket.send(JSON.stringify(coords));
      }
    }
  }

  processMouseUp(event) {
    if (event.button === 0) {
      let coords = {
        type: 'mouseCoordinates',
        x: 0,
        y: 0,
      };
      if (
        this.webSocket !== null &&
        this.webSocket.readyState === WebSocket.OPEN &&
        this.state.isMasterSpectator &&
        this.options.sendcontrolevents
      ) {
        this.webSocket.send(JSON.stringify(coords));
      }
    }
  }

  getMouseCoordinatesRelativeToElement(event, element) {
    let videoStream = this.streamList[Object.keys(this.streamList)[0]];
    let rTarget =
      videoStream.videoTrack._videoHeight / videoStream.videoTrack._videoWidth;

    if (element) {
      let rect = element.getBoundingClientRect();
      let elWidth = element.offsetWidth;
      let elHeight = element.offsetHeight;

      if (elHeight / elWidth > rTarget) {
        elWidth = elHeight / rTarget;
      } else if (elHeight / elWidth < rTarget) {
        elHeight = elWidth * rTarget;
      }

      var xCoordinate =
        (event.clientX - rect.left + (elWidth - element.offsetWidth) / 2) /
        elWidth;
      var yCoordinate =
        1 -
        (event.clientY - rect.top + (elHeight - element.offsetHeight) / 2) /
          elHeight;

      if (xCoordinate > 1 || xCoordinate < 0) xCoordinate = 0;
      if (yCoordinate > 1 || yCoordinate < 0) yCoordinate = 0;

      return { x: xCoordinate, y: yCoordinate };
    }
    return { x: 0, y: 0 };
  }

  getMouseCoordinatesRelativeToWindow(event) {
    let videoStream = this.streamList[Object.keys(this.streamList)[0]];
    let rTarget =
      videoStream.videoTrack._videoHeight / videoStream.videoTrack._videoWidth;

    let elWidth = window.innerWidth;
    let elHeight = window.innerHeight;

    if (elHeight / elWidth > rTarget) {
      elWidth = elHeight / rTarget;
    } else if (elHeight / elWidth < rTarget) {
      elHeight = elWidth * rTarget;
    }

    var xCoordinate =
      (event.clientX + (elWidth - window.innerWidth) / 2) / elWidth;
    var yCoordinate =
      1 - (event.clientY + (elHeight - window.innerHeight) / 2) / elHeight;

    if (xCoordinate > 1 || xCoordinate < 0) xCoordinate = 0;
    if (yCoordinate > 1 || yCoordinate < 0) yCoordinate = 0;

    return { x: xCoordinate, y: yCoordinate };
  }

  getAgoraComponent() {
    let agoraComponent = null;

    for (let index = 0; index < Object.keys(this.streamList).length; index++) {
      const element = Object.keys(this.streamList)[index];
      agoraComponent = document.getElementById(`player-${element}`);
      if (agoraComponent !== null) {
        break;
      }
    }

    return agoraComponent;
  }

  processMouseMove(event) {
    if (
      this.webSocket !== null &&
      this.webSocket.readyState === WebSocket.OPEN &&
      this.state.isMasterSpectator &&
      this.options.sendcontrolevents &&
      this.rightMouseKeyPressed
    ) {
      if (
        new Date().getTime() - this.lastSendTime > 30 &&
        Object.keys(this.streamList).length > 0
      ) {
        if (this.mouseMoveCooldown) clearTimeout(this.mouseMoveCooldown);
        let delta = {};
        delta['type'] = 'mouseDelta';
        delta['xDelta'] = event.movementX;
        delta['yDelta'] = -event.movementY;
        this.webSocket.send(JSON.stringify(delta));
        this.lastSendTime = new Date().getTime();
        this.mouseMoveCooldown = setTimeout(() => {
          delta['xDelta'] = 0;
          delta['yDelta'] = 0;
          this.webSocket.send(JSON.stringify(delta));
          this.lastSendTime = new Date().getTime();
        }, 100);
      }
    }
  }

  setCurrentScene(scene) {
    // console.log('Setting current roomname to ' + scene);
    this.setState({ roomName: scene });
  }

  getPlayerList(playerListString) {
    // console.log(playerListString);
    let playersToFollow = [];
    let playerIdsAndNames = playerListString.split(';');

    if (Array.isArray(playerIdsAndNames)) {
      playerIdsAndNames.forEach((element) => {
        if (element !== '') {
          playersToFollow.push({
            name: element.split(',')[0],
            userId: element.split(',')[1],
          });
        }
      });
    }

    this.setState({ playersToFollow: playersToFollow });
  }

  setLocalSpectator(name) {
    this.options.spectatorobjectname = name;
    this.setState({ spectatorReady: true });
  }

  canConnectToTeam(teamName) {
    if (teamName === PUBLIC_SERVER_NAME) {
      // console.log('connection allowed');
      return true;
    }

    if (!this.state.currentUser) {
      // console.error(
      //   'User is not signed in, and trying to connect to a private space'
      // );
      if (this.region !== 'local')
        sendMessageToSlack(
          ERROR,
          'User is not signed in, and trying to connect to a private space',
          this.state.userData
        );
      return false;
    }

    for (let index = 0; index < this.state.teams.length; index++) {
      const team = this.state.teams[index];
      if (team.teamName === teamName) {
        // console.log('connection allowed');
        return true;
      }
    }

    // console.error('User does not belong to Server ' + teamName);
    if (this.region !== 'local')
      sendMessageToSlack(
        ERROR,
        'User does not belong to server ' + teamName,
        this.state.userData
      );

    return false;
  }

  processKeyDown(event) {
    var sendMessage = false;
    if (this.validKeys.includes(event.code)) {
      if (this.keyMessage[event.code] === false) {
        this.keyMessage[event.code] = true;
        sendMessage = true;
      }
    }

    if (
      sendMessage &&
      this.webSocket !== null &&
      this.webSocket.readyState === WebSocket.OPEN &&
      this.state.isMasterSpectator &&
      this.options.sendcontrolevents
    ) {
      this.webSocket.send(JSON.stringify(this.keyMessage));
    }
  }

  processKeyUp(event) {
    var sendMessage = false;
    if (this.validKeys.includes(event.code)) {
      if (this.keyMessage[event.code] === true) {
        this.keyMessage[event.code] = false;
        sendMessage = true;
      }
    }

    if (
      sendMessage &&
      this.webSocket !== null &&
      this.webSocket.readyState === WebSocket.OPEN &&
      this.state.isMasterSpectator &&
      this.options.sendcontrolevents
    ) {
      this.webSocket.send(JSON.stringify(this.keyMessage));
    }
  }

  async subscribe(user, mediaType) {
    const uid = user.uid;
    // subscribe to a remote user
    await this.spectatorClient.subscribe(user, mediaType);
    // console.log('subscribe success');

    let remotePlayerList = document.getElementById('remote-playerlist');
    // console.log(remotePlayerList);

    if (mediaType === 'video') {
      let agPlayer = document.createElement('div');
      agPlayer.id = `player-${uid}`;
      agPlayer.className = 'player';
      agPlayer.style = 'width: 100%; height: 100%;';

      remotePlayerList.appendChild(agPlayer);
      this.playerHTMLElement = agPlayer;
      // console.log('Playing video item');
      user.videoTrack.play(`player-${uid}`);
    }

    if (mediaType === 'audio') {
      // console.log('Playing audio item');
      user.audioTrack.play();
    }
  }

  handleUserPublished(user, mediaType) {
    const id = user.uid;
    // console.log(user);
    // console.log(mediaType);
    // Supress subscribing to more than one stream.
    // if (Object.keys(this.streamList).length > 0 && this.streamList[id] === undefined)
    //     return;
    // if (Object.keys(this.streamList).length === 0)
    //     this.streamList[id] = user;
    this.streamList[id] = user;
    // console.log(this.streamList);
    this.subscribe(user, mediaType);
  }

  handleUserUnpublished(user) {
    const id = user.uid;
    delete this.streamList[id];
    // console.log(this.streamList);
    let agPlayer = document.getElementById('player-' + id);
    // console.log(agPlayer);
    if (agPlayer) {
      agPlayer.remove();
      this.playerHTMLElement = null;
    }
  }

  goToScene(gameName) {
    if (
      this.webSocket !== null &&
      this.webSocket.readyState === WebSocket.OPEN &&
      this.state.isMasterSpectator
    ) {
      this.webSocket.send(
        JSON.stringify({
          type: 'command',
          command: 'custom',
          objectName:
            'Remio.Common.NetworkScene.SceneNetworked, Remio.Common.NetworkScene',
          functionName: 'GoToScene',
          data: gameName,
        })
      );
    }
  }

  getGameSection() {
    if (!this.state.isMasterSpectator) return;

    let gameOptions = [];

    if (this.state.teamSpaces.length > 0) {
      gameOptions = this.state.teamSpaces.map((portal, i) => (
        <option
          key={`${i}-space`}
          onClick={() => this.goToScene(portal.gameName)}
          disabled={
            this.state.roomName === '' ||
            this.state.roomName === GAMES.MANSIONLOGIN ||
            this.state.roomName === portal.gameName
          }
          value={portal.gameName}
        >
          {portal.visibleName}
        </option>
      ));
      gameOptions.unshift(
        <option
          key={'default_space'}
          onClick={() => this.goToScene(this.state.defaultTeamSpace.gameName)}
          disabled={
            this.state.roomName === '' ||
            this.state.roomName === GAMES.MANSIONLOGIN ||
            this.state.roomName === this.state.defaultTeamSpace.gameName
          }
          value={this.state.defaultTeamSpace.gameName}
        >
          HQ
        </option>
      );
    }
    gameOptions = [
      ...gameOptions,
      ...this.state.teamPortals
        .filter((t) => t.isRemioGame)
        .map((portal, i) => (
          <option
            key={`${i}-portal`}
            onClick={() => this.goToScene(portal.gameName)}
            disabled={
              this.state.roomName === '' ||
              this.state.roomName === GAMES.MANSIONLOGIN ||
              this.state.roomName === portal.gameName
            }
            value={portal.gameName}
          >
            {portal.doorTitle}
          </option>
        )),
    ];

    return (
      <>
        <Box width={'95%'} mb={3}>
          {this.state.isMasterSpectator ? this.getMagicLinkButton() : null}
        </Box>
        <Box
          width={'95%'}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          borderWidth={1}
          borderRadius={5}
          p={2}
          m={1}
        >
          <Text
            p={1}
            fontWeight="semibold"
            textDecoration="underline"
            fontSize="medium"
          >
            Go To Room
          </Text>
          <Select
            mt={1}
            mb={3}
            size="xs"
            placeholder="Select Room"
            value={this.state.selectedGameName}
            onChange={({ target: { value } }) => {
              this.setState({ selectedGameName: value });
            }}
          >
            {gameOptions}
          </Select>
          <Button
            size="sm"
            variant="solid"
            colorScheme="blue"
            disabled={
              this.state.roomName === '' ||
              this.state.roomName === GAMES.MANSIONLOGIN ||
              this.state.selectedGameName === ''
            }
            onClick={() => {
              this.goToScene(this.state.selectedGameName);
              this.setState({ selectedGameName: '' });
            }}
          >
            Go!
          </Button>
        </Box>
      </>
    );
  }

  getFollowingSection() {
    if (!this.state.isMasterSpectator) return;

    return (
      <Box
        width={'95%'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        borderWidth={1}
        borderRadius={5}
        p={2}
        m={1}
        mt={3}
      >
        <Text
          p={1}
          fontWeight="semibold"
          textDecoration="underline"
          fontSize="medium"
        >
          Follow Player
        </Text>
        <Box mb={2} width={'100%'}>
          <Select
            mb={2}
            size="xs"
            placeholder="Select Player"
            value={this.state.followSettings.playerToFollow}
            onChange={({ target: { value } }) => {
              this.setState(
                {
                  followSettings: {
                    ...this.state.followSettings,
                    playerToFollow: value,
                  },
                },
                () => {
                  this.setState({
                    followSettings: {
                      ...this.state.followSettings,
                    },
                  });
                }
              );
            }}
            onClick={() => {
              if (
                this.state.roomName === '' ||
                this.state.roomName === GAMES.MANSIONLOGIN
              )
                return;

              try {
                this.webSocket.send(
                  JSON.stringify({
                    type: 'command',
                    command: 'custom',
                    objectName:
                      'Remio.Common.HUB.PlayerFollower, Remio.Common.HUB',
                    functionName: 'SendPlayerListToBrowser',
                    data: null,
                  })
                );
              } catch (error) {
                // console.error('Connect command error: ' + error);
              }
              this.setState({
                followSettings: {
                  ...this.state.followSettings,
                },
              });
            }}
          >
            {this.getPlayersToFollowOptions()}
          </Select>
        </Box>

        {this.state.roomName === '' ||
        this.state.roomName === GAMES.MANSIONLOGIN ? null : (
          <Box
            mb={2}
            width={'100%'}
            display={'flex'}
            justifyContent="space-around"
          >
            <Tooltip
              hasArrow
              label="Follow Random Player"
              bg="gray.100"
              color="black"
              placement="auto"
              mr={3}
            >
              <IconButton
                size="sm"
                icon={<FaRandom />}
                onClick={() => {
                  try {
                    this.webSocket.send(
                      JSON.stringify({
                        type: 'command',
                        command: 'custom',
                        objectName:
                          'Remio.Common.HUB.PlayerFollower, Remio.Common.HUB',
                        functionName: 'FollowRandomPlayer',
                        data: null,
                      })
                    );
                  } catch (error) {
                    // console.error('Connect command error: ' + error);
                  }
                }}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              label="Stop Following Player"
              bg="gray.100"
              color="black"
              placement="auto"
            >
              <IconButton
                size="sm"
                icon={<RiCloseFill />}
                variant="solid"
                colorScheme="red"
                onClick={() => {
                  try {
                    this.webSocket.send(
                      JSON.stringify({
                        type: 'command',
                        command: 'custom',
                        objectName:
                          'Remio.Common.HUB.PlayerFollower, Remio.Common.HUB',
                        functionName: 'StopFollowing',
                        data: null,
                      })
                    );
                  } catch (error) {
                    // console.error('Connect command error: ' + error);
                  }
                }}
              />
            </Tooltip>
            <Button
              size="sm"
              variant="solid"
              colorScheme="blue"
              disabled={this.state.followSettings.playerToFollow === ''}
              onClick={() => {
                this.applyFollowSettings();
              }}
              alignSelf="center"
            >
              Follow
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  joinRTCClient() {
    return new Promise(async (resolve, reject) => {
      // console.log(this.desktopClient);
      try {
        this.options.desktopuid = await this.desktopClient.join(
          this.options.appid,
          this.options.desktopchannel,
          null
        );
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  createMessageClientAndConnectListeners() {
    if (this.messagClient) this.messagClient.removeAllListeners();

    if (!this.messagClient) {
      this.messagClient = AgoraRTM.createInstance(this.options.appid);

      // Client Event listeners
      // Display messages from peer
      this.messagClient.on('MessageFromPeer', (message, peerId) => {
        // console.log('message from peer ' + peerId);
        // console.log(message);
      });
      // Display connection state changes
      this.messagClient.on('ConnectionStateChanged', (state, reason) => {
        // console.log(state);
        // console.log(reason);
      });

      this.messagClient.on('ChannelMessage', (message, memberId) => {
        // console.log(message);
        // console.log(memberId);
      });
    }
    // console.log(this.messagClient);
  }

  createMessageChannelAndConnectListeners(channelName = null) {
    if (channelName) this.options.msgChannelName = channelName;

    if (!this.options.msgChannelName) {
      // console.error('No message channel Name! ');
      return;
    }

    if (!this.msgChannel) {
      this.msgChannel = this.messagClient.createChannel(
        this.options.msgChannelName
      );
      // Display msgChannel member stats
      this.msgChannel.on('MemberJoined', (memberId) => {
        // console.log(memberId + ' joined');
      });
      // Display msgChannel member stats
      this.msgChannel.on('MemberLeft', (memberId) => {
        // console.log(memberId + ' left');
      });
    }
    // console.log(this.msgChannel);
  }

  startKeepAliveMessages() {
    if (this.keepAliveMessage) clearInterval(this.keepAliveMessage);

    this.keepAliveMessage = setInterval(() => {
      const message = `${AGORA_SCREEN_SHARE_OPTIONS.SCREEN_SHARE_JOIN}&0.0.0&${this.options.desktopchannel}&${this.options.desktopuid}`;
      this.sendChannelMessage(message);
    }, 5000);
  }

  // send channel message. It should be in the format: messageType&version&message. Version can be ignored for screen sharing. It is used more in VR
  async sendChannelMessage(channelMessage) {
    if (this.msgChannel !== null) {
      await this.msgChannel.sendMessage({ text: channelMessage }).then(() => {
        // console.log('message sent');
        // console.log(channelMessage);
        // console.log(this.msgChannel.channelId);
      });
    }
  }

  login(uid) {
    return new Promise(async (resolve, reject) => {
      try {
        let messageOptions = {
          uid: uid,
          token: null,
        };
        // console.log(messageOptions);
        await this.messagClient.login(messageOptions);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  async joinMessageChannel() {
    // Channel event listeners
    // Display channel messages
    try {
      if (this.msgChannel)
        if (this.msgChannel.joinState !== 'JOINED')
          await this.msgChannel.join().then(() => {
            // console.log('successfully joined message channel');
            // console.log(this.msgChannel);
          });
      // else console.error('Could not join message channel');
    } catch (error) {
      // console.error('Could not join message channel', error);
    }
  }

  async shareScreenAndAudio() {
    await this.joinRTCClient();

    try {
      let screenShareSettings = {
        encoderConfig: {
          width: {
            max: MAX_SCREEN_SHARE_WIDTH,
            min: MIN_SCREEN_SHARE_WIDTH,
          },
          height: {
            max: MAX_SCREEN_SHARE_HEIGHT,
            min: MIN_SCREEN_SHARE_HEIGHT,
          },
          frameRate: {
            max: MAX_SCREEN_SHARE_FPS,
            min: MIN_SCREEN_SHARE_FPS,
          },
          bitrateMax: MAX_SCREEN_SHARE_BITRATE,
          bitrateMin: MIN_SCREEN_SHARE_BITRATE,
        },
        optimizationMode: 'motion',
      };

      this.localDesktopTracks.videoTrack =
        await AgoraRTC.createScreenVideoTrack(screenShareSettings, 'auto');
      // .catch((err) => console.error(err));

      if (!this.localDesktopTracks.videoTrack) return;

      if (Array.isArray(this.localDesktopTracks.videoTrack)) {
        this.localDesktopTracks.videoTrack.forEach((track, i) => {
          track.on('track-ended', () => {
            track.close();
            if (i === this.localDesktopTracks.videoTrack.length - 1) {
              this.unpublishDesktopClient();
            }
          });
        });
      } else {
        this.localDesktopTracks.videoTrack.on('track-ended', () => {
          // console.log('track ended');
          this.localDesktopTracks.videoTrack.close();
          this.unpublishDesktopClient();
        });
      }

      await this.desktopClient.publish(this.localDesktopTracks.videoTrack);
      this.startKeepAliveMessages();
      this.setState({ screenShareVideoEnabled: true });
    } catch (error) {
      // console.error(error);
    }
  }

  unpublishDesktopClient() {
    if (this.keepAliveMessage) {
      clearInterval(this.keepAliveMessage);
      this.keepAliveMessage = null;
    }

    const message = `${AGORA_SCREEN_SHARE_OPTIONS.SCREEN_SHARE_LEAVE}&0.0.0&${this.options.desktopchannel}&${this.options.desktopuid}`;
    this.sendChannelMessage(message);
    this.desktopClient
      .unpublish(this.localDesktopTracks.videoTrack)
      .then(() => {
        // console.log(this.localDesktopTracks.videoTrack);

        this.desktopClient.leave((res) => {
          // console.log(res);
          this.options.desktopuid = null;
        });
        setTimeout(() => {
          this.localDesktopTracks.videoTrack = null;
          this.setState({ screenShareVideoEnabled: false });
        }, 1000);
      });
  }

  unpublishSpectatorClient() {
    return new Promise(async (resolve, reject) => {
      if (this.localSpectatorTracks.audioTrack)
        await this.spectatorClient
          .unpublish(this.localSpectatorTracks.audioTrack)
          .catch((error) => reject(error));

      if (this.localSpectatorTracks.audioTrack)
        this.localSpectatorTracks.audioTrack.close();

      if (this.spectatorClient)
        await this.spectatorClient.leave().catch((error) => reject(error));
      this.options.spectatoruid = null;
      setTimeout(() => {
        this.localSpectatorTracks.audioTrack = null;
        resolve();
      }, 1000);
    });
  }

  getControlButtons() {
    let margin = 1;
    let minHeight = '25px';

    return (
      <Box display="flex" flexDirection="column" justifyContent="center">
        {this.state.isMasterSpectator ? (
          <Box
            display="flex"
            justifyContent="center"
            minH={minHeight}
            m={margin}
          >
            <Button
              size="xs"
              minH={minHeight}
              variant="solid"
              colorScheme="blue"
              w="100%"
              disabled={
                !this.state.spectatorReady || this.state.cameraVideoEnabled
              }
              onClick={() => {
                if (this.state.screenShareVideoEnabled) {
                  if (Array.isArray(this.localDesktopTracks.videoTrack)) {
                    this.localDesktopTracks.videoTrack.forEach((track, i) => {
                      track.close();
                      if (i === this.localDesktopTracks.videoTrack.length - 1) {
                        this.unpublishDesktopClient();
                      }
                    });
                  } else {
                    this.localDesktopTracks.videoTrack.close();
                    this.unpublishDesktopClient();
                  }
                } else {
                  let env = API_VERSION.split('-')[0].toUpperCase();
                  let messageChannelName = `${this.getTeamUidFromName(
                    this.state.infoModal.roomSuffix
                  )}_${env}`;
                  this.options.desktopchannel = `${this.getTeamUidFromName(
                    this.state.infoModal.roomSuffix
                  )}_${this.state.roomName}`;
                  try {
                    if (this.msgChannel) {
                      this.msgChannel.removeAllListeners();
                      this.msgChannel.leave().then((res) => {
                        // console.log(res);
                        this.msgChannel = null;
                        this.createMessageChannelAndConnectListeners(
                          messageChannelName
                        );
                        this.joinMessageChannel();
                        this.shareScreenAndAudio();
                      });
                    } else {
                      this.createMessageChannelAndConnectListeners(
                        messageChannelName
                      );
                      this.joinMessageChannel();
                      this.shareScreenAndAudio();
                    }
                  } catch (error) {
                    // console.error(error);
                  }
                }
              }}
            >
              {this.state.screenShareVideoEnabled
                ? 'Stop Screenshare'
                : 'Share Screen'}
            </Button>
          </Box>
        ) : null}

        <Button
          leftIcon={
            this.state.isAudioMuted ? <BsMic /> : <AiOutlineAudioMuted />
          }
          size="xs"
          minH={minHeight}
          m={margin}
          variant="solid"
          colorScheme={this.state.isAudioMuted ? 'orange' : 'green'}
          onClick={() => {
            if (this.localSpectatorTracks.audioTrack) {
              if (this.state.isAudioMuted) {
                this.localSpectatorTracks.audioTrack
                  .setMuted(false)
                  .then((res) => {
                    // console.log(res);
                    this.setState({ isAudioMuted: false });
                    if (this.toastId.current) {
                      toast.update(this.toastId.current, {
                        title: 'You are unmuted',
                        description: `People on this channel can hear you.\nHaving issues? Make sure you allowed access to you microphone`,
                        status: 'success',
                        duration: null,
                        isClosable: true,
                        onCloseComplete: () => {
                          this.toastId.current = null;
                        },
                      });
                    } else {
                      this.toastId.current = toast({
                        title: 'You are unmuted',
                        description: `People on this channel can hear you.\nHaving issues? Make sure you allowed access to you microphone`,
                        status: 'success',
                        duration: null,
                        isClosable: true,
                        onCloseComplete: () => {
                          this.toastId.current = null;
                        },
                      });
                    }
                  });
                // .catch((err) => console.error(err));
              } else {
                this.localSpectatorTracks.audioTrack
                  .setMuted(true)
                  .then((res) => {
                    // console.log(res);
                    this.setState({ isAudioMuted: true });
                    if (this.toastId.current) {
                      toast.update(this.toastId.current, {
                        title: 'You are muted',
                        description: `People on this channel can't hear you. To speak, unmute yourself.`,
                        status: 'warning',
                        duration: null,
                        isClosable: true,
                        onCloseComplete: () => {
                          this.toastId.current = null;
                        },
                      });
                    } else {
                      this.toastId.current = toast({
                        title: 'You are muted',
                        description: `People on this channel can't hear you. To speak, unmute yourself.`,
                        status: 'warning',
                        duration: null,
                        isClosable: true,
                        onCloseComplete: () => {
                          this.toastId.current = null;
                        },
                      });
                    }
                  });
                // .catch((err) => console.error(err));
              }
            }
          }}
        >
          {this.state.isAudioMuted ? 'Unmute' : 'Mute'}
        </Button>

        <Button
          minH={minHeight}
          m={margin}
          size="xs"
          variant="solid"
          colorScheme="red"
          onClick={() => {
            this.backToDashboard();
          }}
        >
          Quit
        </Button>
      </Box>
    );
  }

  getTeamOptions() {
    let teamOptions = [];

    this.state.teams.forEach((team, i) => {
      if (team.teamName !== DEFAULT_SETTINGS_SERVER_NAME) {
        teamOptions.push(
          <option value={team.teamName} key={i}>
            {team.teamVisibleName}
          </option>
        );
      }
    });

    if (teamOptions.length === 0) {
      teamOptions.push(
        <option value={PUBLIC_SERVER_NAME} key={0}>
          {PUBLIC_SERVER_VISIBLE_NAME}
        </option>
      );
    }

    return teamOptions;
  }

  canSave() {
    if (this.state.infoModal.roomSuffix === '') return false;
    if (this.state.infoModal.playerName === '') return false;

    return true;
  }

  async backToDashboard() {
    this.setState({
      isLoading: true,
      isErrorModalOpen: false,
      showProgress: false,
      eta: 2000,
    });

    if (this.state.screenShareVideoEnabled) {
      if (Array.isArray(this.localDesktopTracks.videoTrack)) {
        this.localDesktopTracks.videoTrack.forEach((track, i) => {
          track.close();
          if (i === this.localDesktopTracks.videoTrack.length - 1) {
            this.unpublishDesktopClient();
          }
        });
      } else {
        this.localDesktopTracks.videoTrack.close();
        this.unpublishDesktopClient();
      }
    }

    await this.leaveSpectatorChannel();
    this.props.history.push(`/${ROUTES.DASHBOARD}`);
  }

  getMovementInstructions(width) {
    return (
      <Box
        width={width}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        borderWidth={1}
        borderRadius={5}
        p={2}
        m={2}
      >
        <Text
          p={2}
          fontWeight="semibold"
          textDecoration="underline"
          fontSize="large"
        >
          Movement
        </Text>
        <Image src={MovementImage} width="80%" />
        <Text pt={5} textAlign="center">
          Use W-A-S-D to move horizontally.
        </Text>
        <Text pb={5} textAlign="center">
          Use Q-E to move vertically
        </Text>
      </Box>
    );
  }

  getTurningInstructions(width) {
    return (
      <Box
        width={width}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        borderWidth={1}
        borderRadius={5}
        p={2}
        m={2}
      >
        <Text
          p={2}
          fontWeight="semibold"
          textDecoration="underline"
          fontSize="large"
        >
          Turning
        </Text>
        <Image src={RotateImage} width="90%" />
        <Text pt={3} textAlign="center">
          Right Click
        </Text>
        <Text textAlign="center">OR</Text>
        <Text pb={2} textAlign="center">
          Arrow Keys
        </Text>
      </Box>
    );
  }

  getControls(width) {
    return (
      <Box
        width={width}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        borderWidth={1}
        borderRadius={5}
        p={2}
        m={2}
      >
        <Text
          p={2}
          fontWeight="semibold"
          textDecoration="underline"
          fontSize="large"
        >
          Other
        </Text>
        <Image src={ControlsImage} width="100%" />
      </Box>
    );
  }

  getMagicLinkButton() {
    return (
      <Button
        size={'sm'}
        width={'100%'}
        borderRadius={6}
        onClick={() => {
          const link = this.getMagicLink();
          navigator.clipboard.writeText(link);
        }}
      >
        {/*<IconButton*/}
        {/*  size={'sm'}*/}
        {/*  icon={<BiLink />}*/}
        {/*  bg={'transparent'}*/}
        {/*  _hover={{ bg: 'transparent' }}*/}
        {/*  aria-label={'Copy magic link'}*/}
        {/*/>{' '}*/}
        Copy Magic Link{' '}
      </Button>
    );
  }

  getMagicLink(userId = null, roomSuffix = null) {
    let link = window.location.href;

    // console.log(link);
    if (link.includes('?')) {
      link += '&';
    } else {
      link += '?';
    }

    link += `masterId=${userId ? userId : this.options.userid}&teamId=${
      roomSuffix ? roomSuffix : this.state.infoModal.roomSuffix
    }`;
    // console.log(link);
    return link;
  }

  getPlayersToFollowOptions() {
    let playersOptions = [];

    this.state.playersToFollow.forEach((player, i) => {
      playersOptions.push(
        <option value={player.userId} key={i}>
          {player.name}
        </option>
      );
    });

    return playersOptions;
  }

  applyFollowSettings() {
    try {
      this.webSocket.send(
        JSON.stringify({
          type: 'command',
          command: 'custom',
          objectName: 'Remio.Common.HUB.PlayerFollower, Remio.Common.HUB',
          functionName: 'ToggleFollowGaze',
          data: this.state.followSettings.followGaze === true ? '1' : '0',
        })
      );
    } catch (error) {
      // console.error('Connect command error: ' + error);
    }

    setTimeout(() => {
      try {
        this.webSocket.send(
          JSON.stringify({
            type: 'command',
            command: 'custom',
            objectName: 'Remio.Common.HUB.PlayerFollower, Remio.Common.HUB',
            functionName: 'ToggleIgnoreVert',
            data:
              this.state.followSettings.IgnoreVerticalDistance === true
                ? '1'
                : '0',
          })
        );
      } catch (error) {
        // console.error('Connect command error: ' + error);
      }
    }, 1000);

    setTimeout(() => {
      if (this.state.followSettings.playerToFollow !== '') {
        try {
          this.webSocket.send(
            JSON.stringify({
              type: 'command',
              command: 'custom',
              objectName: 'Remio.Common.HUB.PlayerFollower, Remio.Common.HUB',
              functionName: 'FollowPlayer',
              data: this.state.followSettings.playerToFollow,
            })
          );
        } catch (error) {
          // console.error('Connect command error: ' + error);
        }
      }
    }, 2000);
  }

  retryConnectionButton() {
    if (this.webSocket === null) return null;

    if (this.webSocket.readyState === WebSocket.OPEN) {
      return (
        <Button
          fontSize="sm"
          variant="solid"
          colorScheme="blue"
          onClick={async () => {
            let canConnect = await this.canConnectToInstance();
            // console.log(canConnect);
          }}
          mx={2}
        >
          Retry Connection
        </Button>
      );
    }

    return null;
  }

  // downloadStandaloneHandler = () => {
  //   window.open(window.location.origin + '/download', '_blank').focus();
  // };

  getInstanceStatusComponent() {
    if (this.state.gameInstanceData.length === 0) {
      return <Box>Initializing the environment...</Box>;
    } else {
      let gameInstanceComponents = [];
      this.state.gameInstanceData.forEach((gameInstance, i) => {
        let instanceState = this.getInstanceSummaryState(gameInstance);
        let instanceReadyColor = 'red';

        if (instanceState === STATES.READY) instanceReadyColor = 'green';
        if (instanceState === STATES.INUSE) instanceReadyColor = 'orange';

        let canJoin =
          gameInstance.isAdminUserConnected &&
          instanceState === STATES.INUSE &&
          gameInstance.masterUserId != '' &&
          gameInstance.teamName != '' &&
          this.canConnectToTeam(gameInstance.teamName);

        gameInstanceComponents.push(
          <Tr key={i}>
            <Td>
              <Badge variant="subtle">
                {gameInstance.instanceId.substring(0, 10) + '...'}
              </Badge>
            </Td>
            <Td>
              <Badge
                variant="subtle"
                colorScheme={
                  gameInstance.instanceStatus === 'Running' ? 'green' : 'red'
                }
              >
                {gameInstance.instanceStatus.toString()}
              </Badge>
            </Td>
            <Td>
              <Badge
                variant="subtle"
                colorScheme={
                  gameInstance.isAdminUserConnected ? 'green' : 'red'
                }
              >
                {gameInstance.isAdminUserConnected.toString()}
              </Badge>
            </Td>
            <Td>
              <Badge
                variant="subtle"
                colorScheme={
                  gameInstance.isUnityInstanceStarted ? 'green' : 'red'
                }
              >
                {gameInstance.isUnityInstanceStarted.toString()}
              </Badge>
            </Td>
            <Td>
              {instanceState === STATES.STARTING ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xs"
                />
              ) : (
                <Tooltip
                  hasArrow
                  label="Join someone else as a spectator"
                  bg="gray.100"
                  color="black"
                  placement="top"
                  mr={3}
                >
                  <Button
                    variant="solid"
                    colorScheme={instanceReadyColor}
                    disabled={!canJoin}
                    size="xs"
                    onClick={() => {
                      if (canJoin) {
                        window
                          .open(
                            this.getMagicLink(
                              gameInstance.masterUserId,
                              gameInstance.teamName
                            )
                          )
                          .focus();
                      }
                    }}
                  >
                    {canJoin ? 'Join ' + gameInstance.teamName : instanceState}
                  </Button>
                </Tooltip>
              )}
            </Td>
          </Tr>
        );
      });

      return (
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Instance</Th>
              <Th>Status</Th>
              <Th>Admin Connected</Th>
              <Th>Remio Started</Th>
              <Th>Ready</Th>
            </Tr>
          </Thead>
          <Tbody>{gameInstanceComponents}</Tbody>
        </Table>
      );
    }
  }

  render() {
    return (
      <Box
        display="flex"
        flexGrow={'1'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Modal
          isOpen={this.state.followSettings.isOpen}
          onClose={() => {
            this.setState({
              followSettings: {
                ...this.state.followSettings,
                isOpen: false,
              },
            });
          }}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Follow Settings</ModalHeader>
            <ModalBody>
              <Box display="flex" alignItems="center">
                <Select
                  placeholder="Select Player To Follow"
                  value={this.state.followSettings.playerToFollow}
                  onChange={(e) => {
                    this.setState({
                      followSettings: {
                        ...this.state.followSettings,
                        playerToFollow: e.target.value,
                      },
                    });
                  }}
                >
                  {this.getPlayersToFollowOptions()}
                </Select>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                m={1}
              >
                <Text>Look at Player</Text>
                <Switch
                  isChecked={this.state.followSettings.followGaze}
                  onChange={(e) => {
                    this.setState({
                      followSettings: {
                        ...this.state.followSettings,
                        followGaze: e.target.checked,
                      },
                    });
                  }}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                m={1}
              >
                <Text>Ignore Vertical Component</Text>
                <Switch
                  isChecked={this.state.followSettings.IgnoreVerticalDistance}
                  onChange={(e) => {
                    this.setState({
                      followSettings: {
                        ...this.state.followSettings,
                        IgnoreVerticalDistance: e.target.checked,
                      },
                    });
                  }}
                />
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => {
                  this.setState({
                    followSettings: {
                      ...this.state.followSettings,
                      isOpen: false,
                    },
                  });
                }}
              >
                Close
              </Button>
              <Button
                colorScheme="blue"
                mx={3}
                onClick={() => {
                  this.applyFollowSettings();
                  this.setState({
                    followSettings: {
                      ...this.state.followSettings,
                      isOpen: false,
                    },
                  });
                }}
              >
                Apply
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={this.state.infoModal.isOpen}
          closeOnOverlayClick={false}
          onClose={() => {
            this.setState({
              infoModal: {
                ...this.state.infoModal,
                isOpen: false,
              },
            });
          }}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Enter your details</ModalHeader>
            <ModalBody>
              <Box display="flex" alignItems="center">
                <FormControl mb={2}>
                  <FormLabel mb={0}>Name:</FormLabel>
                  <Input
                    value={this.state.infoModal.playerName}
                    placeholder="Please enter your VR name"
                    onChange={(e) => {
                      this.setState({
                        infoModal: {
                          ...this.state.infoModal,
                          playerName: e.target.value,
                        },
                      });
                    }}
                    p={1}
                    style={{ paddingLeft: 15 }}
                  />
                </FormControl>
              </Box>
              <Box display="flex" alignItems="center">
                <FormControl>
                  <FormLabel mb={0}>Select Server:</FormLabel>
                  <Select
                    placeholder="Select Server"
                    value={this.state.infoModal.roomSuffix}
                    onChange={(e) => {
                      const { infoModal } = this.state;
                      infoModal['roomSuffix'] = e.target.value;
                      this.setState({ infoModal });
                    }}
                  >
                    {this.getTeamOptions()}
                  </Select>
                </FormControl>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => this.props.history.push(`/${ROUTES.DASHBOARD}`)}
              >
                Exit
              </Button>
              <Button
                disabled={!this.canSave()}
                colorScheme="blue"
                mx={3}
                onClick={async () => {
                  this.setState({
                    infoModal: {
                      ...this.state.infoModal,
                      isOpen: false,
                    },
                    isLoading: true,
                    showProgress: false,
                    eta: 1000,
                  });
                  const teamInfoRes = this.state.userData
                    ? await awsApi.getTeamInfoForCloud(
                        this.state.accessToken,
                        this.getTeamUidFromName(this.state.infoModal.roomSuffix)
                      )
                    : await awsApi.getPublicServerInfo(
                        this.state.infoModal.roomSuffix
                      );
                  const teamInfo = JSON.parse(teamInfoRes.body);
                  this.initConnections();
                  this.setState({
                    teamPortals: teamInfo.teamPortals,
                    teamSpaces: teamInfo.availableTeamSpaces,
                    defaultTeamSpace: teamInfo.defaultTeamSpace,
                  });
                }}
              >
                Join
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={this.state.isErrorModalOpen}
          isCentered
          closeOnOverlayClick={false}
          size={'xl'}
        >
          <ModalOverlay />
          <ModalContent
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <ModalHeader>{this.state.errorTitle}</ModalHeader>
            <ModalBody
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text textAlign="left" fontSize="sm" mb={5} mt={3}>
                {this.state.errorMessage}
              </Text>
            </ModalBody>
            <ModalFooter>
              {this.retryConnectionButton()}
              <Button
                fontSize="sm"
                onClick={() => {
                  if (this.masterId && this.teamId) {
                    this.props.history.push(`/${ROUTES.CLOUD}`);
                    window.location.reload();
                  } else {
                    window.location.reload();
                  }
                }}
                mx={2}
              >
                Refresh Page
              </Button>
              <Button
                fontSize="sm"
                variant="solid"
                colorScheme="red"
                onClick={() => this.backToDashboard()}
                mx={2}
              >
                Back to Servers
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={this.state.isSignInModalOpen}
          isCentered
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <ModalHeader>Are you part of a Remio event?</ModalHeader>
            <ModalBody
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text textAlign="center" fontSize="md" mb={5} mt={3}>
                {
                  'Please log in to join your server. Check your previous emails from Remio for your password.'
                }
              </Text>
              <Text textAlign="center" fontSize="md" mb={5} mt={3}>
                {
                  'Otherwise, continue as a guest to join the one of our public servers.'
                }
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                fontSize="sm"
                variant="solid"
                colorScheme="red"
                onClick={() => this.props.history.push(`/${ROUTES.DASHBOARD}`)}
                mx={2}
              >
                Exit
              </Button>
              <Button
                fontSize="sm"
                variant="solid"
                colorScheme="blue"
                onClick={() => {
                  let reroutePath = this.props.location.pathname.substring(1);
                  let url = `/${ROUTES.SIGNIN}?reroute=${reroutePath}`;
                  this.props.history.push(url);
                }}
                mx={2}
              >
                Log In / Register
              </Button>
              <Button
                fontSize="sm"
                onClick={async () => {
                  const guestServersRes = await awsApi.getGuestServerListApi();
                  const guestServers = JSON.parse(guestServersRes.body);
                  this.setState({
                    isLoading: true,
                    isSignInModalOpen: false,
                    teams: guestServers.teams,
                  });

                  let success = await this.connectWebsocket().catch(() => {
                    return false;
                  });
                  if (success) {
                    this.canConnectToInstanceRecursive();
                    this.createMessageClientAndConnectListeners();
                    this.login(Date.now().toString())
                      .then(() => {
                        // console.log('Logged into agoraRTM');
                      })
                      .catch((err) => {
                        // console.error(err);
                      });
                  }
                }}
                mx={2}
              >
                Continue As Guest
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={this.state.isLoading}
          isCentered
          closeOnOverlayClick={false}
          size="3xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader display="flex" justifyContent="center">
              {progressMessages[this.state.eta]}
            </ModalHeader>
            {this.state.showProgress ? (
              <ModalBody maxH="300px" overflowY="auto">
                {this.getInstanceStatusComponent()}
              </ModalBody>
            ) : (
              <Spinner
                thickness="5px"
                speed="1s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
                alignSelf="center"
              />
            )}

            <ModalFooter>
              <Button onClick={() => this.backToDashboard()}>Exit</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/*<div className="parent-header-icons force-header-icons-right">*/}
        {/*  {this.state.isMasterSpectator ? this.getMagicLinkButton() : null}*/}
        {/*</div>*/}

        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            flexDirection="column"
            width="12%"
            h="80vh"
            alignItems="center"
            overflowY={'auto'}
          >
            {this.state.isMasterSpectator
              ? this.getMovementInstructions('90%')
              : null}
            {this.state.isMasterSpectator
              ? this.getTurningInstructions('90%')
              : null}
            {this.state.isMasterSpectator ? this.getControls('90%') : null}
          </Box>
          {this.state.videoEnabled ? (
            <Box
              id="local-player"
              position="absolute"
              borderWidth={1}
              borderRadius={5}
              w="12.8em"
              h="7.2em"
              left="12%"
              top="12%"
              zIndex={1000}
            />
          ) : null}

          <Box
            display="flex"
            position="relative"
            flexDirection="column"
            width="76%"
            id="remote-playerlist"
          >
            <Box
              position="absolute"
              borderWidth={1}
              borderRadius={5}
              bg="gray.500"
              w="7em"
              h="1.7em"
              right="1%"
              top="1%"
              zIndex={1000}
            >
              <Text
                id="ping-label"
                color="gray.200"
                fontWeight="bold"
                textAlign="center"
              >
                Ping: 0ms
              </Text>
            </Box>

            <IconButton
              position="absolute"
              right="1%"
              bottom="1%"
              m={1}
              onClick={() => {
                let videoElements = document.getElementsByTagName('video');

                if (videoElements.length > 0) {
                  // console.log(videoElements[0]);
                  videoElements[0].requestFullscreen();
                  videoElements[0].style.pointerEvents = 'none';
                }
              }}
              icon={<BsArrowsFullscreen />}
              zIndex={1000}
            />
          </Box>
          <Box display="flex" flexDirection="column" width="12%" m={2}>
            <Box
              display="flex"
              flexDirection="column"
              overflowY="auto"
              h="80vh"
              justifyContent="space-between"
            >
              <Box>
                {this.getGameSection()}
                {this.getFollowingSection()}
              </Box>
              {this.getControlButtons()}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default AgoraClient;
