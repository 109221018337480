import {
  Button,
  Flex,
  FormControl,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';

import { useAppStateContext } from '../../../context/appContext';

import { deleteUser } from './deleteUsersService';

const DeleteUsers = () => {
  const { state } = useAppStateContext();
  const [emails, setEmails] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const toast = useToast();

  const handleEmailsInputChange = (e) => {
    const enteredEmails = e.target.value.split('\n');
    const uniqueEmails = Array.from(new Set(enteredEmails));
    setEmails(uniqueEmails);
  };

  const submitDeleteUserRequests = async () => {
    setIsProcessing(true);
    let successCount = 0;
    let failureCount = 0;

    for (const email of emails
      .map((email) => email.trim())
      .filter((email) => email !== '')) {
      try {
        await deleteUser(state.token, email);
        successCount++;
      } catch (error) {
        failureCount++;
        toast({
          title: `Failed to delete ${email}`,
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      // wait 1 second between requests to avoid rate limiting
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    setEmails([]);
    setIsProcessing(false);

    toast({
      title: 'User deletion completed',
      description: `${successCount} users deleted successfully, ${failureCount} failed.`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Flex
      direction="column"
      width="100%"
      height="100%"
      p={5}
      maxWidth={'1440px'}
    >
      <h1>Delete Users</h1>
      <VStack spacing={4} w={{ base: '100%', md: '90%', lg: '80%' }}>
        <FormControl>
          <Textarea
            name="emails"
            type="text"
            value={emails.join('\n')}
            onChange={handleEmailsInputChange}
            placeholder="Enter emails separated by new lines."
            isDisabled={isProcessing}
            size="lg"
            resize="vertical"
          />
        </FormControl>
        <Button
          colorScheme="blue"
          onClick={submitDeleteUserRequests}
          isDisabled={emails.length === 0 || isProcessing}
        >
          Submit
        </Button>
      </VStack>
    </Flex>
  );
};

export default DeleteUsers;
