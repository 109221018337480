import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FooterComponent } from './Footer';
import { HeaderComponent } from './Header';

import { ACTION } from '../actions/actions';
import { PAGE_REFRESH_INTERVAL } from '../constants/Constants';
import * as ROUTES from '../constants/Routes';
import { useAppStateContext } from '../context/appContext';
import { signOut } from '../services/authService';
import { refreshPage } from '../services/commonService';

export const LayoutComponent = ({ children }) => {
  const { state, dispatch } = useAppStateContext();
  const history = useHistory();
  const location = useLocation();

  const excludedRoutes = [
    '/404',
    '/cloud',
    '/download',
    '/register',
    '/signin',
    '/verifyEmail',
    '/verify-email/v2',
  ];

  const signOutAndRedirect = async () => {
    await signOut();
    dispatch({
      type: ACTION.CLEAR_APP_STATE,
      payload: {
        isLoggedIn: false,
      },
    });
    history.push(`/${ROUTES.SIGNIN}`);
  };

  const checkTokenAndRefresh = async () => {
    try {
      await refreshPage((accessToken) => {
        dispatch({
          type: ACTION.UPDATE_APP_STATE,
          payload: {
            ...state,
            token: accessToken,
          },
        });
      });
    } catch (err) {
      signOutAndRedirect();
    }
  };

  // Clean up team state if not on teams pages
  useEffect(() => {
    if (!location.pathname.includes('team')) {
      localStorage.removeItem('RemioTeamState');
    }
  });

  useEffect(() => {
    if (
      state.isLoggedIn === false &&
      !excludedRoutes.some((route) => location.pathname.startsWith(route))
    ) {
      signOutAndRedirect();
    }
  }, []);

  useEffect(() => {
    // Return if the pathname is excluded
    if (excludedRoutes.some((route) => location.pathname.startsWith(route)))
      return;

    checkTokenAndRefresh();
    setInterval(async () => {
      checkTokenAndRefresh();
    }, PAGE_REFRESH_INTERVAL * 60 * 1000);
  }, []);

  return (
    <Box display={'flex'} flexDir={'column'} height={'100vh'}>
      <HeaderComponent />
      {children}
      <FooterComponent />
    </Box>
  );
};
