import {
  Box,
  Button,
  Input,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';

import { YouTubeEmbed } from './youtubeEmbed';
import { getVideoLinks, updateVideoLinks } from './youtubeService';
import { isAddingUrlDisabled } from './youtubeUtils';

import FullScreenLoading from '../../../../components/FullScreenLoading';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { isTeamAdminOrSuperUser } from '../../TeamUtils';

const YouTubeLinksComponent = () => {
  const toast = useToast();
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const [videoUrlToAdd, setVideoUrlToAdd] = useState('');
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = state.token;
  const teamId = teamState.teamId;
  const isAdmin = isTeamAdminOrSuperUser(state.role, teamState.teamRole);

  useEffect(() => {
    setIsLoading(true);
    if (accessToken && teamId) {
      getVideoLinks(accessToken, teamId)
        .then((res) => {
          const teamInfo = JSON.parse(res.body);
          setVideos(parseVideoResponse(teamInfo));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          // console.error(err);
        });
    }
  }, [accessToken, teamId]);

  function parseVideoResponse(serverInfoResponse) {
    let teamVideos = [];
    if (serverInfoResponse && serverInfoResponse !== 'None') {
      teamVideos = JSON.parse(serverInfoResponse);
    }
    if (!Array.isArray(teamVideos)) {
      teamVideos = [];
    }

    return teamVideos;
  }

  const addVideo = (accessToken, newVideos) => {
    updateVideoLinks(accessToken, teamId, newVideos)
      .then(() => {
        getVideoLinks(accessToken, teamId)
          .then((res) => {
            const teamInfo = JSON.parse(res.body);
            setVideos(parseVideoResponse(teamInfo));
            setVideoUrlToAdd('');
            setIsLoading(false);
            toast({
              title: 'Video added',
              description: "We've successfully added your YouTube video.",
              status: 'success',
              duration: 5000,
              isClosable: false,
            });
          })
          .catch((err) => {
            // console.error(err);
            toast({
              title: 'Problem adding your video.',
              description: "We've encountered a problem your YouTube video.",
              status: 'error',
              duration: 5000,
              isClosable: false,
            });
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        // console.error(err);
      });
  };
  const deleteVideo = (accessToken, newVideos) => {
    updateVideoLinks(accessToken, teamId, newVideos)
      .then(() => {
        getVideoLinks(accessToken, teamId)
          .then((res) => {
            const teamInfo = JSON.parse(res.body);
            setVideos(parseVideoResponse(teamInfo));
            setIsLoading(false);
            toast({
              title: 'Video deleted',
              description: "We've successfully deleted your YouTube video.",
              status: 'success',
              duration: 5000,
              isClosable: false,
            });
          })
          .catch((err) => {
            // console.error(err);
            toast({
              title: 'Problem deleting your video.',
              description: "We've encountered a problem your YouTube video.",
              status: 'error',
              duration: 5000,
              isClosable: false,
            });
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        // console.error(err);
      });
  };

  // Determine if the add button should be disabled
  const addingVideoDisabled = isAddingUrlDisabled(videoUrlToAdd);

  return (
    <Box mt={4}>
      <Text>Upload videos from YouTube using links</Text>
      {isAdmin ? (
        <Box
          display="flex"
          flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
          width={'100%'}
          alignItems="center"
          mb={6}
          mt={6}
        >
          <Input
            value={videoUrlToAdd}
            onChange={(e) => {
              setVideoUrlToAdd(e.target.value.trim());
            }}
            m={1}
            p={1}
            w={['100%', '100%', '75%']}
            placeholder="Enter YouTube Link, eg. https://www.youtube.com/..."
          />

          <Button
            m={1}
            w={['100%', '100%', '25%']}
            disabled={addingVideoDisabled}
            onClick={async () => {
              setIsLoading(true);
              const newVideos = [...videos];
              newVideos.push(videoUrlToAdd);
              addVideo(accessToken, newVideos);
            }}
          >
            Add Video
          </Button>
        </Box>
      ) : null}
      <Box display="flex" flexDirection="column" overflow="auto" flex={1}>
        {isLoading ? (
          <FullScreenLoading />
        ) : (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={'6'}>
            {videos.map((videoLink, idx) => (
              <VStack
                display="flex"
                alignItems="center"
                key={idx}
                p={4}
                mb={6}
                width={'100%'}
                boxShadow={'base'}
                borderRadius={6}
              >
                <YouTubeEmbed key={idx} youtubeUrl={videoLink} />
                {isAdmin ? (
                  <Button
                    display={'flex'}
                    variant="cancel"
                    onClick={() => {
                      setIsLoading(true);
                      const newVideos = [...videos];
                      newVideos.splice(idx, 1);
                      deleteVideo(accessToken, newVideos);
                    }}
                  >
                    Delete Video
                  </Button>
                ) : null}
              </VStack>
            ))}
          </SimpleGrid>
        )}
      </Box>
    </Box>
  );
};

export default YouTubeLinksComponent;
