import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  Spinner,
  ModalFooter,
} from '@chakra-ui/react';
import React from 'react';

export function DeleteServerModal(
  text,
  confirmation,
  callbackSuccess,
  callbackCancel
) {
  return (
    <Modal isOpen={confirmation} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent display="flex" flexDirection="column" alignItems="center">
        <ModalHeader>Warning!</ModalHeader>
        <ModalBody display="flex" flexDirection="column" alignItems="center">
          <Text textAlign="center" fontSize="sm" mb={5} mt={3}>
            {text}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            fontSize="sm"
            variant={'cancel'}
            onClick={callbackCancel}
            mx={2}
          >
            Cancel
          </Button>
          <Button
            fontSize="sm"
            variant="solid"
            colorScheme="red"
            _hover={{ bg: 'red' }}
            onClick={callbackSuccess}
            mx={2}
          >
            Delete Server
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function getLoadingModal(
  loading,
  error = null,
  message = 'Loading ...'
) {
  if (error) {
    return (
      <Modal isOpen={loading} isCentered closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
          w="20%"
          h="20%"
        >
          <Text>{message === null ? 'An error occurred' : message}</Text>
          <Text>{error.message}</Text>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={loading} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
        w="20%"
        h="20%"
      >
        <Spinner
          thickness="5px"
          speed="1s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text>{message}</Text>
      </ModalContent>
    </Modal>
  );
}
