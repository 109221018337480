import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import * as aws from '../../services/awsService';

export const ForgotPasswordModal = ({
  onCloseForgotPasswordModal,
  onCloseForgotPasswordModalWithSuccess,
}) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const isInvalid =
    email === '' || !email.includes('@') || !email.includes('.');

  return (
    <Modal
      isOpen={true}
      isCentered
      onClose={() => {
        onCloseForgotPasswordModal();
      }}
    >
      <ModalOverlay />
      <ModalContent display="flex" flexDirection="column" alignItems="center">
        <ModalHeader>Reset Password</ModalHeader>
        <ModalBody display="flex" flexDirection="column" alignItems="center">
          <Text mb={6}>We'll send a reset password code to your email</Text>
          <Input
            width={300}
            fontSize="sm"
            placeholder="Enter Email"
            mb={1}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          {error && (
            <Text
              textAlign="center"
              fontSize="sm"
              mb={5}
              mt={3}
              color="#E53E3E"
            >
              {error.message}
            </Text>
          )}
        </ModalBody>
        <ModalFooter display="flex" justifyContent="space-around">
          <Button
            mr={3}
            variant={'ghost'}
            onClick={() => {
              onCloseForgotPasswordModal();
            }}
          >
            Cancel
          </Button>
          <Button
            fontSize="sm"
            id="start"
            onClick={() => {
              aws
                .sendResetEmailCode(email)
                .then((res) => {
                  // console.log(res);
                  onCloseForgotPasswordModalWithSuccess(email);
                })
                .catch((error) => {
                  onCloseForgotPasswordModal();
                  setError(error);
                });
            }}
            disabled={isInvalid}
          >
            Send Email
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
