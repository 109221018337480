import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
  useToast,
  ButtonGroup,
  Divider,
  Box,
} from '@chakra-ui/react';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import FullScreenLoading from '../../../components/FullScreenLoading';
import TagSelect from '../../../components/TagSelect';
import { useAppStateContext } from '../../../context/appContext';
import {
  getAvailableTags,
  updateUserTags,
  getAvailableLabels,
  updateUserLabels,
} from '../../../services/awsService';
import { getErrorMessage } from '../../../utils/errorUtils';
import { getLabelOptions } from '../../../utils/geLabelOptions';
import { getTagOptions } from '../../../utils/getTagOptions';
import { deleteUser } from '../deleteUsers/deleteUsersService';

import PurchasedItemsComponent from './components/PurchasedItems';
import UserDetailsComponent from './components/UserDetails';
import { adminGetUserByEmail } from './manageUsersService';

type DeleteUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
};

function DeleteUserModal({ isOpen, onClose, onClick }: DeleteUserModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to delete this user?</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={onClick}>
            Delete
          </Button>
          <Button onClick={onClose} ml={4}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function getLabelNameByValue(value: number, availableLabels: UserLabels) {
  return availableLabels[value - 1];
}

export default function ManageUsers() {
  const state = useAppStateContext()?.state;
  const token = state?.token ?? '';
  const [userEmail, setUserEmail] = useState('');
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [memberTags, setMemberTags] = useState<Array<string>>([]);
  const [availableTags, setAvailableTags] = useState<Record<string, string>>(
    {}
  );
  const [availableLabels, setAvailableLabels] = useState<UserLabels>([]);
  const [memberLabels, setMemberLabels] = useState<Array<number>>([]);
  const toast = useToast();

  useEffect(() => {
    getTagList();
    getLabelList();
  }, []);

  async function getTagList() {
    try {
      const availableTagsResponse = await getAvailableTags(token);
      const availableTagList = JSON.parse(availableTagsResponse?.body ?? '{}');
      setAvailableTags(availableTagList as unknown as Record<string, string>);
    } catch (error) {
      toast({
        title: 'Error',
        description: getErrorMessage(error),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  async function getLabelList() {
    try {
      const { data } = await getAvailableLabels(token);
      setAvailableLabels(data);
    } catch (error) {
      toast({
        title: 'Error',
        description: getErrorMessage(error),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  async function handleSaveTagsButtonClick() {
    try {
      await updateUserTags(token, userDetails?.uuid || '', memberTags);
      toast({
        title: 'Tags updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Failed to update tags',
        description: getErrorMessage(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  async function handleSaveLabelsButtonClick() {
    try {
      await updateUserLabels(token, userDetails?.uuid || '', memberLabels);
      toast({
        title: 'Labels updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Failed to update labels',
        description: getErrorMessage(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  function handleEmailInputChange(e: ChangeEvent<HTMLInputElement>) {
    setUserEmail(e.target.value.trim());
  }

  function handlePressEnter(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      submitGetUserRequest();
    }
  }

  function handleDeleteButtonClick() {
    setDeleteModalOpen(true);
  }

  function handleOnCloseDeleteModal() {
    setDeleteModalOpen(false);
  }

  async function handleOnClickDeleteModal() {
    try {
      setIsLoading(true);
      await deleteUser(state?.token, userDetails?.email);
      toast({
        title: `User ${userDetails?.email} deleted`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setUserDetails(null);
    } catch (error) {
      toast({
        title: `Failed to delete user ${userDetails?.email}`,
        description: getErrorMessage(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setDeleteModalOpen(false);
    } finally {
      setIsLoading(false);
    }
  }

  async function submitGetUserRequest() {
    setIsProcessing(true);
    try {
      setIsLoading(true);
      const { data } = await adminGetUserByEmail(token, userEmail);
      setUserDetails(data);
      setMemberTags((data?.tags as unknown as string[]) || []);
      setMemberLabels((data?.labels as unknown as number[]) || []);
    } catch (error) {
      toast({
        title: `Failed to get user ${userEmail}`,
        description: getErrorMessage(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    setUserEmail('');
    setIsProcessing(false);
    setIsLoading(false);
  }

  return (
    <Flex
      flexDirection="column"
      width="100%"
      height="100%"
      p={10}
      maxWidth={'1440px'}
    >
      {isLoading ? <FullScreenLoading /> : null}
      <h1>Manager Users</h1>
      <VStack spacing={4} w={{ base: '100%', md: '90%', lg: '80%' }}>
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            name="email"
            type="text"
            value={userEmail}
            onChange={handleEmailInputChange}
            onKeyUp={handlePressEnter}
          />
        </FormControl>
        <Button
          colorScheme="blue"
          onClick={submitGetUserRequest}
          isDisabled={userEmail.length === 0 || isProcessing}
        >
          Submit
        </Button>
      </VStack>
      {userDetails && (
        <VStack spacing={2}>
          <UserDetailsComponent userDetails={userDetails} />
          <Divider />
          <ButtonGroup spacing={2}>
            <TagSelect
              tags={memberTags}
              setTags={setMemberTags}
              options={getTagOptions(availableTags)}
            />
            <Button colorScheme="blue" onClick={handleSaveTagsButtonClick}>
              Save Tags
            </Button>
          </ButtonGroup>
          <Box p={4}>
            <Text fontSize="md">Current Tags: {memberTags.join(', ')}</Text>
          </Box>

          <ButtonGroup spacing={2}>
            <TagSelect
              tags={memberLabels}
              setTags={setMemberLabels}
              options={getLabelOptions(availableLabels)}
              entityName="Labels"
            />
            <Button colorScheme="blue" onClick={handleSaveLabelsButtonClick}>
              Save Labels
            </Button>
          </ButtonGroup>
          <Box p={4}>
            <Text fontSize="md">
              Current Labels:{' '}
              {memberLabels
                .map((x) => getLabelNameByValue(x, availableLabels).name)
                .join(', ')}
            </Text>
          </Box>

          <Button colorScheme="red" onClick={handleDeleteButtonClick}>
            Delete User
          </Button>
          <DeleteUserModal
            isOpen={deleteModalOpen}
            onClose={handleOnCloseDeleteModal}
            onClick={handleOnClickDeleteModal}
          />
          <PurchasedItemsComponent userId={userDetails.uuid} />
        </VStack>
      )}
    </Flex>
  );
}
