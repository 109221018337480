import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ACTION } from '../actions/actions';
import { useAppStateContext } from '../context/appContext';
import { useTeamStateContext } from '../context/teamContext';
import { getTeamInfo } from '../services/awsService';

export const TeamLayoutComponent = ({ children }) => {
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const location = useLocation();
  const teamNameFromUrl = location.pathname.substring(6);
  const RemioTeamState = localStorage.getItem('RemioTeamState');
  let teamInfo;

  // TODO: this initial api call and dispatch is being done from the call in teams.js until settings in it's own functional component

  // useEffect(() => {
  //   if (
  //     RemioTeamState === null ||
  //     RemioTeamState === 'undefined' ||
  //     JSON.parse(RemioTeamState).teamName !== teamNameFromUrl
  //   ) {
  //     getTeamInfo(state.token, teamNameFromUrl).then((res) => {
  //       teamInfo = JSON.parse(res.body);
  //       teamDispatch({
  //         type: ACTION.UPDATE_TEAM_STATE,
  //         payload: {
  //           ...teamState,
  //           ...teamInfo,
  //         },
  //       });
  //     });
  //   }
  // }, []);

  return <>{children}</>;
};
