import { Box } from '@chakra-ui/react';
import React from 'react';

import TeamColorsComponent from './colors';
import DeleteServerComponent from './deleteServer';
import EnableMetaAvatarsComponent from './enableMetaAvatars';
import HostedModesComponent from './hostedModes';
import PublicServerComponent from './publicServer';
import ServerIdComponent from './serverId';
import ServerLogoComponent from './serverLogo';
import SetServerCodeComponent from './setServerCode';
import VisibleNameComponent from './visibleName';

import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';

const GeneralSettingsComponent = () => {
  const { teamState } = useTeamStateContext();

  return (
    <>
      {teamState.teamName !== '' ? (
        <Box display="flex" flexDirection="column" mb={4}>
          <Box
            display={'flex'}
            flexDir={{ base: 'column', md: 'row' }}
            boxShadow={'base'}
            p={6}
            mt={6}
          >
            <Box width={{ base: '100%', md: '80%' }}>
              <ServerIdComponent />
              <VisibleNameComponent />
              <PublicServerComponent />
              <TeamColorsComponent />
              <HostedModesComponent />
              <SetServerCodeComponent />
              <EnableMetaAvatarsComponent />
            </Box>

            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'top'}
              width={{ base: '100%', md: '20%' }}
              mt={{ base: 4, md: 0 }}
            >
              <ServerLogoComponent />
            </Box>
          </Box>
        </Box>
      ) : null}

      <DeleteServerComponent />
    </>
  );
};

export default GeneralSettingsComponent;
