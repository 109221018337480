import { Switch, FormControl, Text } from '@chakra-ui/react';
import React from 'react';

export const ToggleButton = ({ label, onChanged, ...props }) => {
  const changeHandler = (ev) => {
    onChanged({ checked: ev.target.checked });
  };

  return (
    <FormControl display="flex" alignItems="center">
      <Text fontWeight="medium" width={'180px'}>
        {label}
      </Text>
      <Switch size={'lg'} id="toggle-btn" onChange={changeHandler} {...props} />
    </FormControl>
  );
};
