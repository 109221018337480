import { Box, Button, createStandaloneToast, Text } from '@chakra-ui/react';
import { useMemo, useEffect, useState } from 'react';

import FullScreenLoading from '../../components/FullScreenLoading';
import TableComponent from '../../components/TableComponent';
import { useAppStateContext } from '../../context/appContext';

import {
  getAllFriendsAndRequests,
  removeFriendApi,
  respondToFriendRequestApi,
  deleteFriendRequestApi,
} from './peopleService';

const People = () => {
  const { toast } = createStandaloneToast();
  const { state } = useAppStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [friendsAndRequests, setFriendsAndRequests] = useState([]);

  useEffect(() => {
    getAllFriendsAndRequests(state.token).then((res) => {
      const friendsAndRequestsList = JSON.parse(res.body);
      setFriendsAndRequests(friendsAndRequestsList);
    });
  }, []);

  const data = useMemo(() => [...friendsAndRequests], [friendsAndRequests]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        footer: (info) => info.column.id,
        cell: (info) => {
          return <Text isTruncated>{info.getValue()}</Text>;
        },
      },
      {
        accessorKey: 'relationship',
        header: 'Relationship',
        footer: (info) => info.column.id,
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => {
          return <Text isTruncated>{info.getValue()}</Text>;
        },
      },
      {
        accessorKey: 'created',
        header: 'Since',
        footer: (info) => info.column.id,
        cell: (info) => {
          const createdDate = info.getValue();
          if (!createdDate) return '';
          const date = new Date(createdDate);
          const day = date.getUTCDate().toString().padStart(2, '0');
          const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
          const year = date.getUTCFullYear().toString();
          const dateString = `${day}-${month}-${year}`;
          return <Text isTruncated>{dateString}</Text>;
        },
        enableColumnFilter: false,
      },
      {
        id: 'actions',
        header: 'Actions',
        footer: (info) => info.column.id,
        cell: (info) => {
          if (info.row.original.status === 'accepted') {
            return (
              <Button
                display={'flex'}
                variant="cancel"
                onClick={() => {
                  setIsLoading(true);
                  removeFriendApi(state.token, info.row.original.userId)
                    .then((res) => {
                      getAllFriendsAndRequests(state.token).then((res) => {
                        const friendsAndRequestsList = JSON.parse(res.body);
                        setFriendsAndRequests(friendsAndRequestsList);
                        setIsLoading(false);
                        toast({
                          title: 'Friend removed',
                          description: `${info.row.original.name} has been removed from your friends list.`,
                          status: 'success',
                          duration: 5000,
                          isClosable: true,
                        });
                      });
                    })
                    .catch((err) => {
                      setIsLoading(false);
                      toast({
                        title: 'Error',
                        description: `There was an error removing ${info.row.original.name} from your friends list.`,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                      });
                    });
                }}
              >
                <Text>Unfriend</Text>
              </Button>
            );
          } else if (info.row.original.status === 'received') {
            return (
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Button
                  display={'flex'}
                  variant="cancel"
                  onClick={() => {
                    setIsLoading(true);
                    respondToFriendRequestApi(
                      state.token,
                      info.row.original.requestId,
                      '0'
                    )
                      .then((res) => {
                        getAllFriendsAndRequests(state.token).then((res) => {
                          const friendsAndRequestsList = JSON.parse(res.body);
                          setFriendsAndRequests(friendsAndRequestsList);
                          setIsLoading(false);
                          toast({
                            title: 'Friend request rejected',
                            description: `You have rejected ${info.row.original.name}'s friend request.`,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                          });
                        });
                      })
                      .catch((err) => {
                        setIsLoading(false);
                        toast({
                          title: 'Error',
                          description: `There was an error rejecting ${info.row.original.name}.`,
                          status: 'error',
                          duration: 5000,
                          isClosable: true,
                        });
                      });
                  }}
                >
                  <Text>Reject</Text>
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    setIsLoading(true);
                    respondToFriendRequestApi(
                      state.token,
                      info.row.original.requestId,
                      '1'
                    )
                      .then((res) => {
                        getAllFriendsAndRequests(state.token).then((res) => {
                          const friendsAndRequestsList = JSON.parse(res.body);
                          setFriendsAndRequests(friendsAndRequestsList);
                          setIsLoading(false);
                          toast({
                            title: 'Friend request accepted',
                            description: `You're now friends with ${info.row.original.name}!`,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                          });
                        });
                      })
                      .catch((err) => {
                        setIsLoading(false);
                        toast({
                          title: 'Error',
                          description: `There was an error accepting ${info.row.original.name}'s friend request.`,
                          status: 'error',
                          duration: 5000,
                          isClosable: true,
                        });
                      });
                  }}
                >
                  <Text>Accept</Text>
                </Button>
              </Box>
            );
          } else if (info.row.original.status === 'sent') {
            return (
              <Button
                display={'flex'}
                variant="cancel"
                onClick={() => {
                  setIsLoading(true);
                  deleteFriendRequestApi(
                    state.token,
                    info.row.original.requestId
                  ).then((res) => {
                    getAllFriendsAndRequests(state.token)
                      .then((res) => {
                        const friendsAndRequestsList = JSON.parse(res.body);
                        setFriendsAndRequests(friendsAndRequestsList);
                        setIsLoading(false);
                        toast({
                          title: 'Friend request cancelled',
                          description: `Request to ${info.row.original.name} has been cancelled.`,
                          status: 'success',
                          duration: 5000,
                          isClosable: true,
                        });
                      })
                      .catch((err) => {
                        setIsLoading(false);
                        toast({
                          title: 'Error',
                          description: `There was an error cancelling your friend request to ${info.row.original.name}.`,
                          status: 'error',
                          duration: 5000,
                          isClosable: true,
                        });
                      });
                  });
                }}
              >
                <Text>Cancel</Text>
              </Button>
            );
          } else {
            return null;
          }
        },
      },
    ],
    []
  );

  return (
    <Box
      display="flex"
      flexGrow={'1'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      paddingBottom={8}
    >
      {isLoading ? <FullScreenLoading /> : null}

      {/* Main */}
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        p={5}
        maxWidth={'1440px'}
      >
        <h1 align={'center'}>People</h1>
        {friendsAndRequests.length < 1 ? (
          'You have not added any friends as yet. What are you waiting for? Head into Remio and meet some new people!'
        ) : (
          <TableComponent
            data={data}
            columns={columns}
            enableGlobalFilter={false}
          />
        )}
      </Box>
    </Box>
  );
};

export default People;
