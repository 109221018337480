import {
  Text,
  Input,
  Button,
  Box,
  Link,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { ForgotPasswordModal } from './ForgotPasswordModal';
import { ResetPasswordModal } from './ResetPasswordModal';

import { ACTION } from '../../actions/actions';
import FullScreenLoading from '../../components/FullScreenLoading';
import * as CONSTANTS from '../../constants/Constants';
import * as ROUTES from '../../constants/Routes';
import { useAppStateContext } from '../../context/appContext';
import * as aws from '../../services/awsService';
import { getUserDetailsApi } from '../../services/awsService';
import { updateAmplifySession } from '../../services/cognitoService';
import * as CommonFunctions from '../../services/commonService';

const SignIn = () => {
  const INITIAL_STATE = {
    email: '',
    code: '',
    confirmation: false,
    error: null,
  };

  const { dispatch } = useAppStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [submitForgotPasswordVisible, setSubmitForgotPasswordVisible] =
    useState(false);
  const [state, setState] = useState(INITIAL_STATE);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const reroute =
    CommonFunctions.getJsonFromUrl(window.location.href).reroute || null;
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const { email, error } = state;
  const isInvalid = password === '' || email === '';

  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [expiresIn, setExpiresIn] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (accessToken !== null) {
      fetchUser().then((r) => {
        // console.log('r', r);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    if (window.location.hash === '') return;

    setIsLoading(true);

    if (window.location.hash.startsWith('#access_token')) {
      const [atText, idText, _, eiText] = window.location.hash.split('&');
      setAccessToken(atText.split('=')[1]);
      setIdToken(idText.split('=')[1]);
      setExpiresIn(eiText.split('=')[1]);
    } else if (window.location.hash.startsWith('#id_token')) {
      const [idText, _, eiText] = window.location.hash.split('&');
      setIdToken(idText.split('=')[1]);
      setExpiresIn(eiText.split('=')[1]);
    } else if (window.location.hash.startsWith('#error_description')) {
      const [errorDescription, _] = window.location.hash.split('&');
      const errorDescriptionText = errorDescription.split('=')[1];

      if (errorDescriptionText.includes('RETRY_')) {
        const words = errorDescriptionText.split('+');
        const retryText = words.filter((w) => w.includes('RETRY_'))[0];
        const provider = retryText.split('_')[1].slice(0, -1); // splice to remove the '.' character added by Cognito in the URL

        if (provider === 'Auth0') {
          openFederatedLogin('Auth0');
        }
      } else {
        // TODO: Handle real errors..
      }
    } else if (window.location.hash.startsWith('#forgot')) {
      setIsLoading(false);
      setForgotPasswordVisible(true);
      return;
    }

    if (reroute) {
      history.push(`/${reroute}`);
    } else {
      history.push(`/${ROUTES.DASHBOARD}`);
    }
  }, [reroute]);

  async function fetchUser() {
    try {
      const user = await axios.get(
        `${CONSTANTS.USER_POOL_DOMAIN}/oauth2/userInfo`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      await Auth.federatedSignIn(
        CONSTANTS.AUTH0_PROVIDER,
        {
          token: idToken,
          expires_at: expiresIn * 1000 + new Date().getTime(),
        },
        user
      );
      // console.log(`federated sign in successful ${user}`);
    } catch (error) {
      // console.error(error);
    }
  }

  const onCloseForgotPasswordModal = () => {
    setForgotPasswordVisible(false);
  };

  const onCloseSubmitForgotPasswordVisible = () => {
    setSubmitForgotPasswordVisible(false);
  };

  const onCloseForgotPasswordModalWithSuccess = (email) => {
    setForgotPasswordEmail(email);
    setForgotPasswordVisible(false);
    setSubmitForgotPasswordVisible(true);
  };

  const onForgotPasswordSuccess = (email, password) => {
    signIn(email, password);
  };

  const signIn = async (email, password) => {
    setIsLoading(true);
    try {
      const response = await aws.signIn(email, password);
      const authResponse = response.data;
      await updateAmplifySession(
        email,
        authResponse.accessToken,
        authResponse.idToken,
        authResponse.refreshToken
      );
      dispatch({
        type: ACTION.UPDATE_APP_STATE,
        payload: {
          isLoggedIn: true,
          email: email,
          username: email,
          token: authResponse.idToken,
          refreshToken: authResponse.refreshToken,
          uuid: authResponse.userId,
        },
      });

      const res = await getUserDetails(authResponse.idToken);
      dispatch({
        type: ACTION.UPDATE_APP_STATE,
        payload: {
          ...state,
          ...res,
        },
      });

      if (reroute) {
        history.push(`/${reroute}`);
      } else {
        history.push(`/${ROUTES.DASHBOARD}`);
      }
    } catch (error) {
      if (error.code === CONSTANTS.USERNOTCONFIRMED_CODE) {
        setState({ ...state, confirmation: true });
        setIsLoading(false);
      } else {
        setState({ ...state, error: error.response?.data?.message || error });
        setIsLoading(false);
      }
    }
  };

  const getUserDetails = (token) => {
    return getUserDetailsApi(token)
      .then((res) => {
        return JSON.parse(res.body);
      })
      .catch((err) => {
        // console.error(err);
      });
  };

  const openFederatedLogin = (provider) => {
    window.location.assign(
      `${CONSTANTS.USER_POOL_DOMAIN}/oauth2/authorize?identity_provider=${provider}&redirect_uri=${window.location.origin}/signin&response_type=TOKEN&client_id=${CONSTANTS.USER_POOL_CLIENT_ID}&scope=aws.cognito.signin.user.admin email openid profile`
    );
  };

  // const openAuth0SAML = (event) => {
  //   event.preventDefault();
  //   openFederatedLogin('Auth0');
  // };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Box
        display="flex"
        flexGrow={'1'}
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
        overflow="auto"
        p={5}
      >
        <h1>Sign In</h1>

        <Box borderRadius={6} boxShadow="base" padding={6}>
          {/*<FormControl variant="floating" id="email">*/}
          <Input
            width={300}
            fontSize="sm"
            placeholder="Enter Email Address"
            value={email}
            onChange={(event) => {
              setState({ ...state, email: event.target.value });
            }}
          />
          {/* It is important that the Label comes after the Control due to css selectors */}
          {/*  <FormLabel color={'gray'}>Email Address</FormLabel>*/}
          {/*</FormControl>*/}
          <Box mt={3}>
            <InputGroup>
              <Input
                width={300}
                fontSize="sm"
                placeholder="Enter Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
              <InputRightElement
                style={{ marginRight: 11 }}
                onClick={() => {
                  if (showPassword === true) {
                    setShowPassword(false);
                  } else {
                    setShowPassword(true);
                  }
                }}
                children={
                  showPassword ? (
                    <FaEyeSlash color="gray" />
                  ) : (
                    <FaEye color="gray" />
                  )
                }
              />
            </InputGroup>
          </Box>
          {error && (
            <Text
              textAlign="center"
              fontSize="sm"
              mt={2}
              color="#E53E3E"
              maxW={300}
            >
              {error.message || error}
            </Text>
          )}
          <Box width={'100%'}>
            <Button
              fontSize="sm"
              id="start"
              onClick={() => signIn(state.email, password)}
              disabled={isInvalid}
              mt={6}
              type="submit"
            >
              Sign In
            </Button>
          </Box>

          {/* <Button
            fontSize="sm"
            id="start"
            onClick={this.openAuth0SAML}
            my={2}
          >Sign In With Auth0
          </Button> */}
        </Box>

        <Box mt={6}>
          <Link
            color="blueDark"
            mt={5}
            onClick={() => {
              if (reroute) {
                history.push(`/${ROUTES.REGISTER}?reroute=${reroute}`);
              } else {
                history.push(`/${ROUTES.REGISTER}`);
              }
            }}
          >
            New user? Register
          </Link>
          <span style={{ color: 'gray', margin: '0 2px' }}> | </span>
          <Link
            color="blueDark"
            mt={5}
            onClick={() => {
              setForgotPasswordVisible(true);
            }}
          >
            Forgot password?
          </Link>
        </Box>
      </Box>

      {/* Forgot Password Modal */}
      {forgotPasswordVisible ? (
        <ForgotPasswordModal
          onCloseForgotPasswordModal={onCloseForgotPasswordModal}
          onCloseForgotPasswordModalWithSuccess={
            onCloseForgotPasswordModalWithSuccess
          }
        />
      ) : null}

      {/* Reset Password Modal */}
      {submitForgotPasswordVisible ? (
        <ResetPasswordModal
          onCloseSubmitForgotPasswordVisible={
            onCloseSubmitForgotPasswordVisible
          }
          forgotPasswordEmail={forgotPasswordEmail}
          onForgotPasswordSuccess={onForgotPasswordSuccess}
        />
      ) : null}
    </>
  );
};

export default SignIn;
