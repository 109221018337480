import { API } from 'aws-amplify';
import { API_VERSION, DEFAULT_HEADERS_V2 } from '../../../constants/Constants';
import { PresignedUrlBody } from '../../../types/PresignedUrlBody';

interface BannerPlacement {
  banners?: string[];
}

export async function getLobbyBanners(
  accessToken: string
): Promise<BannerPlacement> {
  const apiName = API_VERSION!;
  const path = `/${API_VERSION}/announcements/lobby_banners/latest`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS_V2,
      token: accessToken,
    },
  };

  const response: RemioResponseV2<BannerPlacement> = await API.get(
    apiName,
    path,
    myInit
  );
  return response.data;
}

export async function updateLobbyBanners(
  accessToken: string,
  banners: string[]
): Promise<void> {
  const apiName = API_VERSION!;
  const path = `/${API_VERSION}/announcements/lobby_banners/latest`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS_V2,
      token: accessToken,
    },
    body: {
      banners,
    },
  };

  await API.put(apiName, path, myInit);
}

export async function getBannerUploadUrl(
  accessToken: string,
  filename: string
): Promise<PresignedUrlBody> {
  const apiName = API_VERSION!;
  const path = `/${API_VERSION}/announcements/banner/image`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS_V2,
      token: accessToken,
    },
    body: {
      filename,
    },
  };

  const response: RemioResponseV2<PresignedUrlBody> = await API.post(
    apiName,
    path,
    myInit
  );
  return response.data;
}
