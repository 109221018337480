import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../constants/Constants';

export function getAllFriendsAndRequests(accessToken) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/users/cloud/social/get_friends_and_requests`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.get(apiName, path, myInit);
}

export function removeFriendApi(accessToken, friendId) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/users/cloud/social/remove_friend?remove=${friendId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.del(apiName, path, myInit);
}

export function respondToFriendRequestApi(accessToken, requestId, answer) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/users/cloud/social/respond_friend_request?requestId=${requestId}&answer=${answer}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.put(apiName, path, myInit);
}

export function deleteFriendRequestApi(accessToken, requestId) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/users/cloud/social/delete_sent_friend_request?requestId=${requestId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.del(apiName, path, myInit);
}
