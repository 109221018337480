import { Button, Divider, VStack, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import FullScreenLoading from '../../../../components/FullScreenLoading';
import MultiSelect from '../../../../components/MultiSelect';
import { useAppStateContext } from '../../../../context/appContext';
import { getErrorMessage } from '../../../../utils/errorUtils';
import {
  adminDeletePurchasedItem,
  adminGetPurchasedItems,
} from '../manageUsersService';

interface PurchasedItemsProps {
  userId: string;
}

export default function PurchasedItemsComponent({
  userId,
}: PurchasedItemsProps) {
  const state = useAppStateContext()?.state;
  const token = state?.token ?? '';
  const [purchasedItems, setPurchasedItems] = useState([] as PurchasedItem[]);
  const [selectedItemIds, setSelectedItemIds] = useState([] as string[]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    getUserItems(userId);
  }, [userId]);

  async function getUserItems(userId: string) {
    try {
      const { data } = await adminGetPurchasedItems(token, userId);
      setPurchasedItems(data.purchasedItems);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toast({
        title: 'Failed to get user items',
        description: getErrorMessage(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  function handleItemsSelection(selectedIds: string[]) {
    setSelectedItemIds(selectedIds);
  }

  async function handleDeleteSelected() {
    try {
      setIsLoading(true);
      await adminDeletePurchasedItem(token, userId, selectedItemIds);
      setSelectedItemIds([]);
      await getUserItems(userId);
      toast({
        title: 'Items deleted',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Failed to delete items',
        description: getErrorMessage(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <VStack>
      {isLoading ? <FullScreenLoading /> : null}
      <h2>Inventory</h2>
      <MultiSelect
        items={purchasedItems.map((item) => ({
          id: item.id,
          displayLabel: `${item.storeItemName} - ${item.category}`,
        }))}
        valueKey="id"
        labelKey="displayLabel"
        onSelect={handleItemsSelection}
      />
      <Divider />
      <Button colorScheme="red" onClick={handleDeleteSelected}>
        Delete Selected
      </Button>
    </VStack>
  );
}
