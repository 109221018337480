import {
  Box,
  Button,
  createStandaloneToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';

import { ACTION } from '../../../../actions/actions';
import FullScreenLoading from '../../../../components/FullScreenLoading';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { setTeamServerPublicPrivate } from '../../../../services/awsService';

const PublicServerModal = ({ onToggleModalPublic }) => {
  const { toast } = createStandaloneToast();
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const [isOpen, setIsOpen] = useState(true);
  const [isPublic, setIsPublic] = useState(teamState.isPublic);
  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    onToggleModalPublic(false);
  };

  const toggleModalPublic = () => {
    setIsLoading(true);
    setTeamServerPublicPrivate(state.token, teamState.teamId, {
      isPublic: !isPublic,
    })
      .then((res) => {
        teamDispatch({
          type: ACTION.UPDATE_TEAM_STATE,
          payload: {
            ...teamState,
            isPublic: !teamState.isPublic,
          },
        });
        setIsLoading(false);
        toast({
          title: 'Server accessibility has been set successfully',
          description: `${res.message}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onToggleModalPublic(true);
      })
      .catch((err) => {
        setIsLoading(false);
        toast({
          title: 'Error setting server accessibility',
          description: `${err.message || err}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        onToggleModalPublic(false);
      });
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {!isPublic ? `Public Server` : `Private Server`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!isPublic ? (
              <Box>
                <Text fontWeight={'semibold'}>
                  WARNING: You are about to make your server public.
                </Text>
                <Text>
                  Are you sure you want to make your server public? Everyone who
                  enters your server through Remio will be able to see your data
                  (presentations, 3D models, etc.). Your web dashboard will
                  still only be accessible for your server members.
                </Text>
              </Box>
            ) : (
              <Box>
                <Text fontWeight={'semibold'}>
                  WARNING: You are about to make your server private.
                </Text>
                <Text>
                  Are you sure you want to make your server private? Only server
                  members will be able to see this server (presentations, 3D
                  models, etc.). Your web dashboard will only be accessible for
                  your server members.
                </Text>
              </Box>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="solid" onClick={toggleModalPublic}>
              {!isPublic ? `Make Server Public` : `Make Server Private`}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PublicServerModal;
