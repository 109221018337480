import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';

import MorphStoreItemsComponent from './components/MorphStoreItems';
import StoresComponent from './components/Stores';

const Shopping = () => {
  return (
    <Flex direction="column" p={5} w="100%" h="100%">
      <h1>Stores and Items</h1>
      <Tabs orientation="horizontal" isLazy>
        <TabList>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            Morph Store
          </Tab>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            Stores
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <MorphStoreItemsComponent />
          </TabPanel>
          <TabPanel>
            <StoresComponent />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default Shopping;
