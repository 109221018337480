import {
  Text,
  Box,
  Heading,
  Spinner,
  Button,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { sendMetaReviewAppNotification } from './sendPushNotificationService';

import * as ROUTES from '../../constants/Routes';
import { useAppStateContext } from '../../context/appContext';

const SendPushNotification = () => {
  const { state } = useAppStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const { metaUserId } = useParams();
  const toast = useToast();

  useEffect(() => {
    if (!metaUserId) {
      window.location.replace('404');
    }

    sendNotification(metaUserId);
  }, []);

  const sendNotification = async (metaUserId) => {
    try {
      const response = await sendMetaReviewAppNotification(
        state.token,
        metaUserId
      );
      // eslint-disable-next-line no-console
      console.log(response);
      setIsSuccess(true);
      setIsLoading(false);
      toast({
        title: 'Push notification sent',
        description: 'Successfully sent push notification to user.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      toast({
        title: 'Notification not sent',
        description: 'Failed to send push notification to user.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" flexGrow={'1'} width="100%">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
        overflow="auto"
        flex={1}
        p={5}
      >
        {isLoading ? (
          <>
            <Heading color="gray" isTruncated>
              Please wait while we attempt to send your push notification.
            </Heading>

            <Box height="100%" alignItems="center" pt="7%">
              <Spinner
                thickness="5px"
                speed="1.5s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Box>
          </>
        ) : null}

        {!isLoading && isSuccess ? (
          <>
            <Heading color="gray" isTruncated>
              Push notification sent
            </Heading>
            <Text m={5} fontSize="18px" color="gray" isTruncated>
              The user should have received a push notification to review the
              app.
            </Text>
            <Button
              variant="solid"
              colorScheme="blue"
              alignSelf="center"
              onClick={() => history.push(`/`)}
            >
              Home
            </Button>
          </>
        ) : null}

        {!isLoading && isError ? (
          <>
            <Heading color="gray" isTruncated>
              Oops! There was a problem.
            </Heading>
            <Text m={5} fontSize="18px" color="gray" isTruncated>
              Something went wrong sending your push notification.
            </Text>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default SendPushNotification;
