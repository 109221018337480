import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import PublicServerModal from './publicServerModal';

import { ToggleButton } from '../../../../components/ToggleButton';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { isTeamAdminOrSuperUser } from '../../TeamUtils';

const PublicServerComponent = () => {
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const [isEnablePublicServerModal, setIsEnablePublicServerModal] =
    useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const isAdmin = isTeamAdminOrSuperUser(state.role, teamState.teamRole);

  useEffect(() => {
    if (teamState.teamName !== '') {
      setIsPublic(teamState.isPublic);
    }
  }, [teamState.isPublic]);

  const openPublicServerConfirmationModal = () => {
    setIsEnablePublicServerModal(true);
  };

  const onToggleModalPublic = (bool) => {
    setIsEnablePublicServerModal(false);
  };

  return (
    <>
      <Box mb={4}>
        <ToggleButton
          label={'Public Server'}
          onChanged={openPublicServerConfirmationModal}
          isChecked={isPublic}
          isDisabled={!isAdmin}
        />
      </Box>

      {isEnablePublicServerModal ? (
        <PublicServerModal onToggleModalPublic={onToggleModalPublic} />
      ) : null}
    </>
  );
};

export default PublicServerComponent;
