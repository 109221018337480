import {
  Box,
  IconButton,
  Text,
  createStandaloneToast,
  FormControl,
  FormLabel,
  Input,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { RiPencilLine, RiLinkUnlink, RiEdit2Line } from 'react-icons/ri';

import { deleteDeviceAPI, updateDeviceNameAPI } from './AccountService';

import { ACTION } from '../../actions/actions';
import FullScreenLoading from '../../components/FullScreenLoading';
import { useAppStateContext } from '../../context/appContext';
import { saveUserName } from '../../services/awsService';

const AccountPage = () => {
  const { state, dispatch } = useAppStateContext();
  const { toast } = createStandaloneToast();
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdateVrNameModal, setShowUpdateVrNameModal] = useState(false);
  const [showUpdateDeviceModal, setShowUpdateDeviceModal] = useState(false);
  const [showDeleteDeviceModal, setShowDeleteDeviceModal] = useState(false);
  const [vrName, setVrName] = useState(state.visibleName);
  const [selectedDevice, setSelectedDevice] = useState({
    deviceId: '',
    deviceName: '',
    index: null,
  });

  const deviceNameUpdateModal = ({ deviceId, deviceName }, index) => {
    setSelectedDevice({
      deviceId: deviceId,
      deviceName: deviceName,
      index: index,
    });
    setShowUpdateDeviceModal(true);
  };

  function closeDeviceNameUpdateModal() {
    setSelectedDevice({
      deviceId: '',
      deviceName: '',
      index: null,
    });
    setShowUpdateDeviceModal(false);
  }

  const deleteDeviceModal = ({ deviceId, deviceName }, index) => {
    setSelectedDevice({
      deviceId: deviceId,
      deviceName: deviceName,
      index: index,
    });
    setShowDeleteDeviceModal(true);
  };

  function closeDeleteDeviceModal() {
    setSelectedDevice({
      deviceId: '',
      deviceName: '',
      index: null,
    });
    setShowDeleteDeviceModal(false);
  }

  function closeUpdateVrNameModal() {
    setVrName(state.visibleName);
    setShowUpdateVrNameModal(false);
  }

  const deleteDevice = () => {
    const { deviceId, index } = selectedDevice;
    setIsLoading(true);
    deleteDeviceAPI(deviceId, state.uuid, state.token)
      .then((res) => {
        const { devices } = state;
        devices.splice(index, 1);
        dispatch({
          type: ACTION.UPDATE_APP_STATE,
          payload: {
            ...state,
            ...devices,
          },
        });
        setShowDeleteDeviceModal(false);
        setIsLoading(false);
        toast({
          title: 'Device Unlinked',
          description: res.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setShowDeleteDeviceModal(false);
        setIsLoading(false);
        toast({
          title: 'Device Unlinked',
          description: err,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        // console.error(err);
      });
  };

  const updateDeviceName = () => {
    setIsLoading(true);
    const { deviceId, deviceName, index } = selectedDevice;
    updateDeviceNameAPI(deviceName, deviceId, state.uuid, state.token)
      .then((res) => {
        const { devices } = state;
        devices[index].deviceName = deviceName;
        dispatch({
          type: ACTION.UPDATE_APP_STATE,
          payload: {
            ...state,
            devices: [...devices],
          },
        });
        toast({
          title: 'Device Name Updated',
          description: res.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setShowUpdateDeviceModal(false);
        setIsLoading(false);
      })
      .catch((err) => {
        toast({
          title: 'Failed to Update',
          description: err,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        // console.error(err);
        setIsLoading(false);
      });
  };

  const updateVrName = () => {
    setIsLoading(true);
    const userId = state.uuid;
    const visibleName = vrName;
    saveUserName(state.token, { userId, visibleName })
      .then((res) => {
        dispatch({
          type: ACTION.UPDATE_APP_STATE,
          payload: {
            ...state,
            visibleName: vrName,
          },
        });
        setShowUpdateVrNameModal(false);
        setIsLoading(false);
        toast({
          title: 'Updated',
          description: res.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        const { message } = error?.response?.data || {};
        toast({
          title: 'Failed to Update',
          description: message || 'Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        // console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Box
        display="flex"
        flexGrow={'1'}
        flexDir={'column'}
        p={10}
        pl={'5%'}
        pr={'5%'}
      >
        <h1>Account Settings</h1>
        <Box>
          <Text m={2}>
            <strong>Email: </strong>
            {state.email}
          </Text>
          <Text m={2}>
            <strong>VR Name: </strong>
            {state.visibleName}
            <IconButton
              aria-label="Update your name"
              icon={<RiEdit2Line />}
              title={'Update your VR name'}
              size="sm"
              ml={2}
              onClick={() => {
                setShowUpdateVrNameModal(true);
              }}
            />
          </Text>
        </Box>

        {state.devices && state.devices.length !== 0 ? (
          <Box maxWidth={'480px'} width={'100%'}>
            <h2>Your Devices</h2>
            {state.devices.map((device, i) => {
              return (
                <Box
                  key={i}
                  m={2}
                  p={6}
                  display="flex"
                  flexDir={'column'}
                  justifyContent="space-between"
                  borderRadius={6}
                  boxShadow="base"
                >
                  <Box mb={2} display="flex" justifyContent="space-between">
                    <Text>{device.deviceName}</Text>
                  </Box>
                  <Box>
                    <strong>ID: </strong> {device.deviceId}
                  </Box>
                  <Box mt={4}>
                    <Button
                      color={'blue'}
                      variant={'ghost'}
                      _hover={{ bg: 'blue', color: 'white' }}
                      mr={2}
                      leftIcon={<RiPencilLine />}
                      title={'Update device name'}
                      onClick={() => deviceNameUpdateModal(device, i)}
                      aria-label="Confirm device update"
                    >
                      Update
                    </Button>
                    <Button
                      color={'orange'}
                      variant={'ghost'}
                      _hover={{ bg: 'orange', color: 'white' }}
                      leftIcon={<RiLinkUnlink />}
                      title={'Unlink this device'}
                      onClick={() => deleteDeviceModal(device, i)}
                      aria-label="Unlink device"
                    >
                      Unlink
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : null}
      </Box>

      {/* Update VR Name Modal */}
      {showUpdateVrNameModal ? (
        <Modal
          size={'lg'}
          isOpen={true}
          onClose={() => closeUpdateVrNameModal()}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update VR Name</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  id="name"
                  type="text"
                  value={vrName}
                  onChange={(e) => {
                    setVrName(e.target.value);
                    // setSelectedDevice({
                    //   ...selectedDevice,
                    //   deviceName: e.target.value,
                    // });
                  }}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={() => closeUpdateVrNameModal()}
              >
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={() => updateVrName()}>
                Update
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}

      {/* Update Device Name Modal */}
      {showUpdateDeviceModal ? (
        <Modal
          size={'lg'}
          isOpen={true}
          onClose={() => closeDeviceNameUpdateModal()}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Device Name</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  id="name"
                  type="text"
                  value={selectedDevice.deviceName}
                  onChange={(e) => {
                    setSelectedDevice({
                      ...selectedDevice,
                      deviceName: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={() => closeDeviceNameUpdateModal()}
              >
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={() => updateDeviceName()}>
                Update
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}

      {/* Unlink Device Modal */}
      {showDeleteDeviceModal ? (
        <Modal
          size={'lg'}
          isOpen={true}
          onClose={() => closeDeleteDeviceModal()}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Unlink Device</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                You are about to unlink device{' '}
                <em>{selectedDevice.deviceName}</em> from your account. Do you
                want to continue?
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={() => closeDeleteDeviceModal()}
              >
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={() => deleteDevice()}>
                Unlink Device
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  );
};

export default AccountPage;
