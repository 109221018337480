import { Auth } from 'aws-amplify';

export async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    // console.error('error signing out: ', error);
  }
}

export async function checkToken(currentToken) {
  const currentUser = await Auth.currentAuthenticatedUser();
  const accessToken = currentUser.signInUserSession.idToken.jwtToken;

  if (currentToken !== accessToken) {
    return accessToken;
  }
}
