/**
 * Returns an array of options for a select tag given an object of options
 * @param {object} options
 * @returns Array
 */
export function getTagOptions(options) {
  return Object.keys(options).map((key) => ({
    value: key,
    label: options[key],
  }));
}
