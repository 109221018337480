import { Box, Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';

import * as ROUTES from '../../constants/Routes';
import { useAppStateContext } from '../../context/appContext';
import { isSuperUser } from '../../services/commonService';

import DeleteUsers from './deleteUsers/deleteUsers';
import Gifting from './gifting/gifting';
import ManageUsers from './manageUsers/manageUsers';
import { Portals } from './portals/Portals';
import Shopping from './shopping/shopping';
import SidebarComponent from './SidebarComponent';
import { NewsBoard } from './newsBoard/NewsBoard';

const Admin = () => {
  const { state } = useAppStateContext();
  const history = useHistory();

  useEffect(() => {
    if (!isSuperUser(state.role)) {
      history.push(`/${ROUTES.DASHBOARD}`);
    }
  }, []);

  return (
    <Router>
      <Flex direction="row" grow={1}>
        <SidebarComponent />
        <Box flex="1" pt={4}>
          <Switch>
            <Route exact path="/admin">
              <Redirect to="/admin/gifting" />
            </Route>
            <Route path="/admin/gifting">
              <Gifting />
            </Route>
            <Route path="/admin/portals">
              <Portals />
            </Route>
            <Route path="/admin/shopping">
              <Shopping />
            </Route>
            <Route path="/admin/manageusers">
              <ManageUsers />
            </Route>
            <Route path="/admin/deleteusers">
              <DeleteUsers />
            </Route>
            <Route path="/admin/newsboard">
              <NewsBoard />
            </Route>
          </Switch>
        </Box>
      </Flex>
    </Router>
  );
};

export default Admin;
