import {
  Box,
  Button,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';

export const ConfirmCodeModal = ({
  onConfirmSignUp,
  onResendConfirmationCode,
}) => {
  const [code, setCode] = useState('');
  const isInvalid = code === '';

  return (
    <Modal isOpen={true} isCentered closeOnOverlayClick={false} onClose={null}>
      <ModalOverlay />
      <ModalContent display="flex" flexDirection="column" alignItems="center">
        <ModalHeader>Confirm Sign Up</ModalHeader>
        <ModalBody display="flex" flexDirection="column" alignItems="center">
          <Text mb={6}>Please check your email for the sign up code</Text>
          <Box>
            <Input
              width={280}
              fontSize="sm"
              placeholder="Enter Confirmation Code"
              mr={2}
              value={code}
              onChange={(event) => {
                setCode(event.target.value);
              }}
            />
            <Button
              fontSize="sm"
              id="start"
              onClick={() => onConfirmSignUp(code)}
              disabled={isInvalid}
            >
              Confirm
            </Button>
          </Box>

          <Link
            color="bluedark"
            mt={5}
            mb={6}
            onClick={() => onResendConfirmationCode()}
          >
            Did not receive an email? Click to resend
          </Link>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
