import { useEffect, useState } from 'react';
import path from 'path-browserify';
import {
  getBannerUploadUrl,
  getLobbyBanners,
  updateLobbyBanners,
} from './NewsBoardService';
import { useAppStateContext } from '../../../context/appContext';
import {
  getErrorMessage,
  s3UploadThrowIfError,
} from '../../../utils/errorUtils';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { ImageUploader } from '../../../components/ImageUploader';
import {
  ThumbnailComponent,
  ThumbnailEventArgs,
} from '../../../components/ThumbnailComponent';
import { ScrollComponent } from '../../gallery/ScrollComponent';
import { downloadFileDelayed } from '../../../services/downloadService';
import { postForm } from '../../../services/uploadService';
import FullScreenLoading from '../../../components/FullScreenLoading';

export const NewsBoard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [banners, setBanners] = useState<(string | File)[]>([]);
  const [initialBanners, setInitialBanners] = useState<(string | File)[]>([]);
  const state = useAppStateContext()?.state;
  useEffect(() => {
    (async () => {
      const accessToken = state?.token;
      if (typeof accessToken === 'undefined') {
        setErrorMessage('User is not logged in');
        return;
      }

      try {
        setIsLoading(true);
        await refreshBannersFromServer(accessToken);
      } catch (err) {
        setErrorMessage(getErrorMessage(err));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  async function refreshBannersFromServer(accessToken: string) {
    const response = await getLobbyBanners(accessToken);
    setBanners(response.banners ?? []);
    setInitialBanners(response.banners ?? []);
  }

  function handleThumbnailEvent(e: ThumbnailEventArgs) {
    switch (e.event) {
      case 'downloadImage': {
        const imageUrl = e.data.image.url;
        if (typeof imageUrl === 'string') {
          const filename = imageUrl.split('/').pop();
          downloadFileDelayed(imageUrl, filename ?? 'image', 0);
        }
        break;
      }
      case 'deleteImage':
        setBanners(banners.filter((_, idx) => idx !== e.data.index));
        break;
      default:
        break;
    }
  }

  function handleUploadFiles(files: File[] | null) {
    if (files === null) {
      return;
    }

    setBanners([...banners, ...files]);
  }

  function renderThumbnail(banner: string | File, index: number) {
    const bannerUrl =
      typeof banner === 'string' ? banner : URL.createObjectURL(banner);
    return (
      <ThumbnailComponent
        key={bannerUrl}
        data={{
          url: bannerUrl,
        }}
        selected={false}
        canShare={false}
        canDelete={true}
        index={index}
        output={handleThumbnailEvent}
      />
    );
  }

  function hasChanges() {
    if (banners.length !== initialBanners.length) {
      return true;
    }

    for (let i = 0, len = banners.length; i < len; i++) {
      if (banners[i] !== initialBanners[i]) {
        return true;
      }
    }

    return false;
  }

  function onSaveClicked() {
    (async () => {
      try {
        setIsLoading(true);
        const accessToken = state?.token;
        if (typeof accessToken === 'undefined') {
          setErrorMessage('Not logged in');
          return;
        }

        const uploadTasks = await Promise.allSettled(
          banners.map(async (banner) => {
            if (typeof banner === 'string') {
              return banner;
            }

            const presignedUrl = await getBannerUploadUrl(
              accessToken,
              banner.name
            );
            const response = await postForm(
              presignedUrl.url,
              presignedUrl.fields,
              banner
            );
            await s3UploadThrowIfError(response);

            // Return banner s3 key
            return presignedUrl.fields.key;
          })
        );

        const errors = uploadTasks
          .map((x) => (x.status === 'rejected' ? x.reason : null))
          .filter((x) => !!x);
        if (errors.length) {
          throw new Error('Image upload error: ' + errors.join('\n'));
        }

        const newBanners = uploadTasks
          .map((x) => (x.status === 'fulfilled' ? x.value : null))
          .filter((x) => !!x) as string[];

        await updateLobbyBanners(accessToken, newBanners);
        await refreshBannersFromServer(accessToken);
      } catch (err) {
        setErrorMessage(getErrorMessage(err));
      } finally {
        setIsLoading(false);
      }
    })();
  }

  function onCloseError(): void {
    setErrorMessage(null);
  }

  return (
    <Box margin="10px">
      <h1>News board</h1>
      {isLoading ? (
        <FullScreenLoading />
      ) : (
        <>
          {!!errorMessage ? (
            <Alert status="error" width={1000}>
              <AlertIcon />
              <Box>
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{errorMessage}</AlertDescription>
              </Box>
              <CloseButton
                alignSelf="flex-start"
                position="relative"
                right={-1}
                top={-1}
                onClick={onCloseError}
              />
            </Alert>
          ) : (
            <></>
          )}
          <Flex width={1200}>
            <Box width={500}>
              <ImageUploader
                allowMultiSelect={true}
                onFilesSelected={handleUploadFiles}
              />
            </Box>
            <Spacer />
            <Button isDisabled={!hasChanges()} onClick={onSaveClicked}>
              Save
            </Button>
          </Flex>
          <ScrollComponent height="100%" output={() => {}} pagination={null}>
            {banners.map(renderThumbnail)}
          </ScrollComponent>
        </>
      )}
    </Box>
  );
};
