import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../../../constants/Constants';

export function getHologramsApi(accessToken, teamName) {
  const apiName = API_VERSION;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };
  const path = `/${API_VERSION}/hologram/list/${teamName}`;
  return API.get(apiName, path, myInit);
}

export function deleteHologramsApi(accessToken, hologramUuid) {
  const apiName = API_VERSION;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };
  const path = `/${API_VERSION}/hologram/delete/${hologramUuid}`;
  return API.del(apiName, path, myInit);
}
