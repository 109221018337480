import { Box, Button, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import MetaAvatarsEnabledModal from './enableMetaAvatarsModal';

import { ACTION } from '../../../../actions/actions';
import FullScreenLoading from '../../../../components/FullScreenLoading';
import { ToggleButton } from '../../../../components/ToggleButton';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import * as CommonFunctions from '../../../../services/commonService';
import { MixPanelService } from '../../../../services/mixpanelService';
import { isTeamAdminOrSuperUser } from '../../TeamUtils';
import { removeMetaAvatarLimit } from '../settingsService';

const EnableMetaAvatarsComponent = () => {
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [metaAvatarsEnabled, setMetaAvatarsEnabled] = useState(false);
  const [metaAvatarLimit, setMetaAvatarLimit] = useState(0);
  const [isMetaAvatarsEnabledModalOpen, setIsMetaAvatarsEnabledModalOpen] =
    useState(false);

  useEffect(() => {
    if (teamState.metaAvatarLimit !== undefined) {
      setMetaAvatarLimit(teamState.metaAvatarLimit);
    } else {
      setMetaAvatarLimit(0);
    }
  }, [teamState]);

  const onCloseMetaAvatarsModal = () => {
    setMetaAvatarsEnabled(false);
    setIsMetaAvatarsEnabledModalOpen(false);
  };

  const onEnableMetaAvatarsSuccess = (limit) => {
    setMetaAvatarsEnabled(true);
    setIsMetaAvatarsEnabledModalOpen(false);
    setMetaAvatarLimit(limit);

    if (!CommonFunctions.isSuperUser(state.role)) {
      MixPanelService.sendEvent('Meta Avatar Limit Updated', {
        userId: state.uuid,
        $email: state.email,
        serverId: teamState.serverId,
        teamname: teamState.teamName,
        limit: limit,
      });
    }
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      {teamState.teamName !== '' &&
      isTeamAdminOrSuperUser(state.role, teamState.teamRole) ? (
        <Box>
          <Box width={'400px'} mt={4}>
            {metaAvatarLimit === 0 ? (
              <ToggleButton
                label={'Enable Meta Avatars'}
                onChanged={() => {
                  setMetaAvatarsEnabled(true);
                  setIsMetaAvatarsEnabledModalOpen(true);
                }}
                isChecked={metaAvatarsEnabled}
              />
            ) : (
              <Text>
                Meta Avatar Limit: {metaAvatarLimit}
                <Button
                  variant={'cancel'}
                  size={'sm'}
                  ml={7}
                  onClick={() => {
                    setIsLoading(true);
                    removeMetaAvatarLimit(state.token, teamState.teamId).then(
                      () => {
                        setIsLoading(false);
                        setMetaAvatarLimit(0);
                        setMetaAvatarsEnabled(false);

                        teamDispatch({
                          type: ACTION.UPDATE_TEAM_STATE,
                          payload: {
                            ...teamState,
                            metaAvatarLimit: 0,
                          },
                        });

                        MixPanelService.sendEvent('Meta Avatar Limit Removed', {
                          userId: state.uuid,
                          $email: state.email,
                          serverId: teamState.serverId,
                        });
                      }
                    );
                  }}
                >
                  Remove
                </Button>
              </Text>
            )}
          </Box>
        </Box>
      ) : null}

      {isMetaAvatarsEnabledModalOpen ? (
        <MetaAvatarsEnabledModal
          onCloseMetaAvatarsModal={onCloseMetaAvatarsModal}
          onEnableMetaAvatarsSuccess={onEnableMetaAvatarsSuccess}
          serverId={teamState.teamId}
          token={state.token}
          currentLimit={metaAvatarLimit}
        />
      ) : null}
    </>
  );
};

export default EnableMetaAvatarsComponent;
