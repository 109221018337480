import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../../constants/Constants';

export function updateServerOwner(accessToken, serverId, newOwnerId) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/update_owner/${serverId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
    body: { newOwnerId },
  };

  return API.put(apiName, path, myInit);
}

export const setMemberRoleApi = (token, userId, serverId, role) => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/front/user/set_role`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: {
      userId,
      serverId,
      role,
    },
  };

  return API.put(apiName, path, myInit);
};

export const addTeamMemberToDatabase = (token, member, teamId) => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/add_member`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: {
      team: teamId,
      email: member.email,
      visibleName: member.visibleName,
      role: parseInt(member.role),
    },
  };

  return API.put(apiName, path, myInit);
};

export const getTeamMembersDetails = (token, members, teamId) => {
  return new Promise((resolve, reject) => {
    const apiName = API_VERSION;
    const myInit = {
      headers: {
        ...DEFAULT_HEADERS,
        token: token,
      },
    };

    const allMembersDetails = [];
    let apiPath;

    if (!members) resolve(allMembersDetails);

    if (members.length === 0) resolve(allMembersDetails);

    const loops = Math.ceil(members.length / 100);
    if (loops > 0) {
      for (let i = 0; i < loops; i++) {
        setTimeout(() => {
          if (i === 0) {
            apiPath = `/${API_VERSION}/teams/team_members_details/${teamId}`;
          } else {
            let index = i * 100 > members.length ? members.length - 1 : i * 100;
            index -= 1;
            apiPath = `/${API_VERSION}/teams/team_members_details/${teamId}?lastKey=${members[index].uuid}`;
          }

          API.get(apiName, apiPath, myInit)
            .then((res) => {
              allMembersDetails.push.apply(
                allMembersDetails,
                JSON.parse(res.body).members_details
              );
              if (allMembersDetails.length === members.length) {
                resolve(allMembersDetails);
              }
            })
            .catch((err) => {
              reject(err);
            });
        }, Math.random() * 5000);
      }
    }
  });
};

export const getUserStatus = (token, members, teamId) => {
  return new Promise((resolve, reject) => {
    const apiName = API_VERSION;
    const myInit = {
      headers: {
        ...DEFAULT_HEADERS,
        token: token,
      },
    };

    const allUserStatusses = [];
    let apiPath;

    if (!members) resolve(allUserStatusses);

    if (members.length === 0) resolve(allUserStatusses);

    const loops = Math.floor(members.length / 100);
    const remainder = members.length % 100;
    if (loops > 0) {
      for (let i = 0; i < loops; i++) {
        setTimeout(() => {
          if (i === 0) {
            apiPath = `/${API_VERSION}/teams/team_members_status/${teamId}`;
          } else {
            apiPath = `/${API_VERSION}/teams/team_members_status/${teamId}?lastKey=${
              members[i * 100].uuid
            }`;
          }

          API.get(apiName, apiPath, myInit)
            .then((res) => {
              allUserStatusses.push.apply(
                allUserStatusses,
                JSON.parse(res.body).members_status
              );
              if (allUserStatusses.length === members.length)
                resolve(allUserStatusses);
            })
            .catch((err) => {
              reject(err);
            });
        }, Math.random() * 5000);
      }
    }

    if (remainder > 0) {
      if (loops > 0)
        apiPath = `/${API_VERSION}/teams/team_members_status/${teamId}?lastKey=${
          members[loops * 100 - 1].uuid
        }`;
      else apiPath = `/${API_VERSION}/teams/team_members_status/${teamId}`;

      API.get(apiName, apiPath, myInit)
        .then((res) => {
          allUserStatusses.push.apply(
            allUserStatusses,
            JSON.parse(res.body).members_status
          );
          if (allUserStatusses.length === members.length)
            resolve(allUserStatusses);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

export function updateMemberStatusApi(accessToken, serverId, userId, sentence) {
  const path = `/${API_VERSION}/teams/update_status/${serverId}/${userId}`;
  let body;

  if (sentence === 'BANNED') {
    body = {
      status: 'BANNED',
    };
  } else {
    body = {
      status: 'SUSPENDED',
      days: parseInt(sentence),
    };
  }

  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
    body,
  };
  return API.put(API_VERSION, path, myInit);
}

export function banByEmailApi(accessToken, serverId, email, status, days) {
  const path = `/${API_VERSION}/teams/update_status_by_email/${serverId}`;
  const body = { email };

  if (status === 'BANNED') {
    body['status'] = 'BANNED';
  } else {
    body['status'] = 'SUSPENDED';
    body['days'] = days;
  }

  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
    body,
  };
  return API.post(API_VERSION, path, myInit);
}

export function getMembersBannedApi(accessToken, serverId) {
  const path = `/${API_VERSION}/teams/list/banned_users/${serverId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };
  return API.get(API_VERSION, path, myInit);
}

export function removeMemberBanApi(accessToken, userId, serverId) {
  const path = `/${API_VERSION}/teams/update_status/${serverId}/${userId}`;

  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };
  return API.del(API_VERSION, path, myInit);
}

export function getMembersInvitedApi(accessToken, serverId) {
  const path = `/${API_VERSION}/invites/server/${serverId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };
  return API.get(API_VERSION, path, myInit);
}

export function removeMemberInvitedApi(accessToken, userId) {
  const path = `/${API_VERSION}/invites/${userId}`;

  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };
  return API.del(API_VERSION, path, myInit);
}
