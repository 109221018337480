import { Box, Button, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

import { invertColor } from '../../../../services/commonService';

const ColorPicker = ({ onChangeComplete, color, onClose }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorInternal, setColorInternal] = useState(color);

  const handleClick = () => {
    setDisplayColorPicker(true);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
    onClose(colorInternal);
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  return (
    <Box display={'flex'} alignItems={'center'} mb={4}>
      <Box width={'180px'}>
        <Text>Team Color</Text>
      </Box>
      <Box
        width={'60px'}
        height={'30px'}
        borderRadius={6}
        border={'1px solid lightGray'}
        onClick={handleClick}
        _hover={{ cursor: 'pointer' }}
        backgroundColor={color}
        // color={invertColor(color, true)}
      />
      {displayColorPicker ? (
        <Box style={popover}>
          <Box style={cover} onClick={handleClose} />
          <ChromePicker
            disableAlpha={true}
            onChange={(color) => {
              setColorInternal(color.hex);
            }}
            onChangeComplete={onChangeComplete}
            color={colorInternal}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default ColorPicker;
