import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../../constants/Constants';

export const deleteUser = (token, email) => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/users/admin/delete_user`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: { email },
  };

  return API.post(apiName, path, myInit);
};
