import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import React from 'react';

interface ConfirmModalProps extends UseDisclosureReturn {
  title?: string;
  message?: string;
  confirmTitle?: string;
  cancelTitle?: string;
  onConfirm?: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onClose,
  title,
  message,
  confirmTitle,
  cancelTitle,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{message}</ModalBody>

        <ModalFooter>
          <Button mr={3} variant={'ghost'} onClick={onClose}>
            {cancelTitle ?? 'Cancel'}
          </Button>
          <Button colorScheme="blue" mr={3} onClick={onConfirm}>
            {confirmTitle ?? 'Yes'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
