import React from 'react';

import GeneralSettingsComponent from './generalSettings/generalSettings';

const SettingsComponent = () => {
  return (
    <>
      <h1>Server Settings</h1>
      <GeneralSettingsComponent />
    </>
  );
};

export default SettingsComponent;
