import {
  Box,
  Button,
  HStack,
  Heading,
  Container
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';

import { getStandaloneSignedURL } from '../../services/awsService';

const DownloadWindowsApp = () => {
  const [url, setUrl] = useState<string | null>(null);
  const [enterpriseUrl, setEnterpriseUrl] = useState<string | null>(null);
  const { appType } = useParams();

  useEffect(() => {
    (async function () {
      const [url, enterpriseUrl] = await Promise.all([
        getStandaloneSignedURL(),
        getStandaloneSignedURL('windowsenterprise'),
      ]);

      setUrl(url);
      setEnterpriseUrl(enterpriseUrl);

      if (appType === 'enterprise') {
        window.open(enterpriseUrl, '_blank')?.focus();
      } else if (appType === 'consumer') {
        window.open(url, '_blank')?.focus();
      }
    })().catch((err) => console.error(err));
  }, []);

  // Function to render enterprise button
  const renderEnterpriseButton = () => (
    <Button
      margin="5em"
      isDisabled={enterpriseUrl == null}
      onClick={() =>
        enterpriseUrl && window.open(enterpriseUrl, '_blank')?.focus()
      }
    >
      Windows for Enterprise
    </Button>
  );

  // Function to render consumer button
  const renderConsumerButton = () => (
    <Button
      margin="5em"
      isDisabled={url == null}
      onClick={() => url && window.open(url, '_blank')?.focus()}
    >
      Windows for Consumer
    </Button>
  );

  return (
    <Box display="flex" flexDirection="column" flexGrow={'1'} width="100%" alignItems="center">
          <Heading marginTop="50px">Download Remio for Windows</Heading>
          <Container mt={5} display="flex" flexDirection="column" maxW={"75%"} width={"50%"}>
            <strong>Installation Steps:</strong>
            <ul>
              {!appType ? (
                 <li>Click to download the appropriate windows version, either for <strong>Enterprise</strong> or <strong>Consumer</strong>.
                <ul style={{ paddingLeft: "50px" }}>
                  <li>
                    <strong>Windows for Enterprise</strong> is compatible with Remio Enterpise VR app and normally used for events.
                  </li>
                  <li>
                    <strong>Windows for Consumer</strong> is compatible with Remio VR app on the official Meta Quest store.
                  </li>
                </ul>
              </li>
              ): null}
              <li>
                Right-click on the downloaded folder and select <em>Extract All</em>, then follow the
                instructions.
              </li>
              <li>
                Our Windows application is not on the Microsoft Store yet. You
                might have to provide specific permissions for it to run on your
                device.
              </li>
            </ul>
          </Container>
          <HStack marginTop="10px">
          {appType === 'enterprise' && renderEnterpriseButton()}
          {appType === 'consumer' && renderConsumerButton()}
          {appType === undefined && (
            <>
              {renderEnterpriseButton()}
              {renderConsumerButton()}
            </>
          )}
          </HStack>
    </Box>
  );
};

export default DownloadWindowsApp;
