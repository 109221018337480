export const ACCOUNT = 'account';
export const ADMIN = 'admin';
export const APK_DOWNLOAD = 'download-apk';
export const CLOUD = 'cloud';
export const DASHBOARD = '';
export const DOWNLOAD = 'download';
export const GALLERY = 'gallery';
export const NOTFOUND = '404';
export const PORTALS = 'portals';
export const PEOPLE = 'people';
export const PRESS_KIT = 'press-kit';
export const REGISTER = 'register';
export const SCREENSHARE = 'screenshare';
export const SEND_NOTIFICATION = 'sendPushNotification';
export const SIGNIN = 'signin';
export const TEAM = 'team';
export const GIFTING = 'gifting';
export const VERIFY_EMAIL = 'verifyEmail';
export const VERIFY_EMAIL_V2 = 'verify-email/v2';
export const USER_REPORTS = 'userReports';
