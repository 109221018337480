import { React } from 'react';

import { getYouTubeId } from './youtubeUtils';

export const YouTubeEmbed = ({ youtubeUrl }) => {
  const videoId = getYouTubeId(youtubeUrl);
  return (
    <div className="responsive-video">
      <iframe
        width="100%"
        src={`https://www.youtube-nocookie.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={`YouTube video for ${youtubeUrl}`}
      ></iframe>
    </div>
  );
};
