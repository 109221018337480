export const downloadFileDelayed = (
  url: string,
  name: string,
  delaySeconds: number = 1
) => {
  setTimeout(() => {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.setAttribute('id', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, delaySeconds * 1000);
};
