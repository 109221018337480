import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ACTION } from '../actions/actions';
import { IS_ENTERPRISE } from '../constants/Constants';
import * as ROUTES from '../constants/Routes';
import { useAppStateContext } from '../context/appContext';

/**
 * Custom React hook to log out users after a specified period of inactivity.
 */
const useInactivityLogout = (minutes = 30): void => {
  const dispatch = useAppStateContext()?.dispatch;
  const history = useHistory();

  useEffect(() => {
    if (!IS_ENTERPRISE) {
      return;
    }
    let logoutTimer: NodeJS.Timeout;

    async function signOut() {
      let isUserSignedIn = false;
      try {
        await Auth.currentAuthenticatedUser();
        isUserSignedIn = true;
      } catch (error) {
        /* empty */
      }

      if (!isUserSignedIn) {
        return;
      }

      try {
        await Auth.signOut();
        if (dispatch) {
          dispatch({
            type: ACTION.CLEAR_APP_STATE,
            payload: {
              isLoggedIn: false,
            },
          });
        }
        history.replace(ROUTES.DASHBOARD);
        window.location.reload();
        // eslint-disable-next-line no-console
        console.log('User signed out');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error signing out:', error);
      }
    }

    const events: string[] = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
    ];

    const resetTimer = (): void => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(signOut, minutes * 60 * 1000); // Convert minutes to milliseconds
    };

    // Initialize and reset the timer on events
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    // Set the initial timer
    resetTimer();

    // Cleanup function
    return () => {
      clearTimeout(logoutTimer);
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, []);
};

export default useInactivityLogout;
