import {
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';

const FullScreenLoading = () => {
  const [message, setMessage] = useState('Loading ...');

  return (
    <Modal isOpen={true} isCentered closeOnOverlayClick={false} onClose={null}>
      <ModalOverlay />
      <ModalContent
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
        w="20%"
        h="20%"
      >
        <Spinner
          thickness="5px"
          speed="1s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text>{message}</Text>
      </ModalContent>
    </Modal>
  );
};

export default FullScreenLoading;
