import {
  Box,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  createStandaloneToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CreateNewServerModal } from './CreateNewServerModal';
import { ServersTableComponent } from './ServersTable';

import FullScreenLoading from '../../components/FullScreenLoading';
import * as ROUTES from '../../constants/Routes';
import { useAppStateContext } from '../../context/appContext';
import { joinServerViaCodeApi } from '../../services/awsService';
import { MixPanelService } from '../../services/mixpanelService';

const Dashboard = () => {
  const { state } = useAppStateContext();
  const { toast } = createStandaloneToast();
  const history = useHistory();
  const joinResetState = {
    code: '',
  };
  const [joinModal, setJoinModal] = useState(joinResetState);
  const [isLoading, setIsLoading] = useState(false);
  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const canJoinServer = () => joinModal.code === '';

  useEffect(() => {
    if (state.isLoggedIn === false) {
      history.push(`/${ROUTES.SIGNIN}`);
    }
  }, []);

  // initializing data for sending event on create server using MixPanel
  MixPanelService.init(state?.userId, state?.email);

  function getJoinServerViaCodeModal() {
    return (
      <Modal
        isOpen={isJoinModalOpen}
        onClose={() => {
          setIsJoinModalOpen(false);
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Join Server Via Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center">
                <Text pl={2} pr={1}>
                  Code:{' '}
                </Text>
                <Input
                  value={joinModal.code}
                  placeholder="123456"
                  maxLength={6}
                  onChange={(e) => {
                    const code = e.target.value;
                    setJoinModal({
                      ...joinModal,
                      code,
                    });
                  }}
                  m={1}
                  p={1}
                />
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              variant={'ghost'}
              onClick={() => {
                setIsJoinModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={canJoinServer()}
              colorScheme="blue"
              mr={3}
              onClick={() => {
                joinServerViaCode();
              }}
            >
              Join
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  function joinServerViaCode() {
    setIsJoinModalOpen(false);
    setIsLoading(true);
    joinServerViaCodeApi(state.token, {
      code: joinModal.code,
    })
      .then((res) => {
        const teamInfo = JSON.parse(res.body);
        MixPanelService.sendEvent('Member Joined', {
          userId: state.userId,
          $email: state.email,
          teamname: teamInfo.teamName,
        });
        history.push(`/${ROUTES.TEAM}/${teamInfo.teamName}`);
      })
      .catch((err) => {
        // console.error(err.message);
        setIsLoading(false);
        toast({
          title: 'Join server via code',
          description: err?.response?.data?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  }

  const handleCloseCreateServerModal = () => {
    setIsOpen(false);
  };

  const onCreateFirstServer = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Box
        display="flex"
        flexGrow={'1'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        p={10}
        pl={'5%'}
        pr={'5%'}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          overflow="auto"
          maxWidth={'1440px'}
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            flexDir={{ base: 'column', md: 'row' }}
          >
            <h1>Manage Servers</h1>

            <Box display="flex" py={2}>
              <Box display={'flex'} alignItems={'center'}>
                <Button
                  size={'md'}
                  mr={5}
                  variant={'ghost'}
                  color={'blueDark'}
                  onClick={() => {
                    setIsJoinModalOpen(true);
                    setJoinModal(joinResetState);
                  }}
                >
                  Join Server Via Code
                </Button>
                <Button
                  size={'md'}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Create Server
                </Button>
              </Box>
            </Box>
          </Box>

          {state.token !== null ? (
            <ServersTableComponent
              accessToken={state.token}
              onCreateFirstServer={onCreateFirstServer}
            />
          ) : null}
        </Box>
      </Box>

      {/* Create new server modal */}
      {isOpen ? (
        <CreateNewServerModal
          token={state.token}
          userId={state.uuid}
          email={state.email}
          handleClose={handleCloseCreateServerModal}
        />
      ) : null}

      {/* Join Server via code modal */}
      {getJoinServerViaCodeModal()}
    </>
  );
};

export default Dashboard;
