import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../constants/Constants';
import { getServerListApi } from '../../services/awsService';
import { MixPanelService } from '../../services/mixpanelService';

export const getServerList = (accessToken) => {
  return getServerListApi(accessToken)
    .then((res) => {
      return JSON.parse(res.body);
    })
    .catch((err) => {
      // console.error(err);
    });
};

export const createServer = (
  accessToken,
  teamVisibleName,
  teamname,
  userId,
  email
) => {
  if (teamVisibleName === '') {
    // console.log('No server name');
    return;
  }

  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/create`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
    body: {
      name: teamname,
      visibleName: teamVisibleName,
    },
  };

  return API.put(apiName, path, myInit)
    .then((res) => {
      MixPanelService.init(userId, email);
      MixPanelService.sendEvent('Server Created', {
        userId,
        $email: email,
        teamname,
      });
      return JSON.parse(res.body);
    })
    .catch((error) => {
      // console.error(error);
    });
};

export const getLowercaseServerName = (teamVisibleName) => {
  return teamVisibleName.replace(/\s/g, '').toLowerCase();
};
