import { Box, Button, createStandaloneToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import DeleteServerModal from './deleteServerModal';

import FullScreenLoading from '../../../../components/FullScreenLoading';
import * as ROUTES from '../../../../constants/Routes';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { deleteTeamApi } from '../../teamService';
import { isTeamAdminOrSuperUser } from '../../TeamUtils';

const DeleteServerComponent = () => {
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteServerModalOpen, setIsDeleteServerModalOpen] = useState(false);

  const onDeleteServerCancel = () => {
    setIsDeleteServerModalOpen(false);
  };

  const onDeleteServerSuccess = () => {
    setIsLoading(true);
    setIsDeleteServerModalOpen(false);
    deleteTeamApi(state.token, teamState.teamId)
      .then(() => {
        history.push(`/${ROUTES.DASHBOARD}`);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Box display="flex" mt={5}>
        {teamState.teamName !== '' &&
        isTeamAdminOrSuperUser(state.role, teamState.teamRole) ? (
          <Button
            variant="cancel"
            m={3}
            onClick={() => {
              setIsDeleteServerModalOpen(true);
            }}
          >
            Delete Server
          </Button>
        ) : null}
      </Box>

      {isDeleteServerModalOpen ? (
        <DeleteServerModal
          teamVisibleName={teamState.teamVisibleName}
          onDeleteServerSuccess={onDeleteServerSuccess}
          onDeleteServerCancel={onDeleteServerCancel}
        />
      ) : null}
    </>
  );
};

export default DeleteServerComponent;
