import {
  Text,
  Input,
  Button,
  Box,
  Link,
  InputGroup,
  InputRightElement,
  createStandaloneToast,
} from '@chakra-ui/react';
import { Auth, API } from 'aws-amplify';
import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useHistory } from 'react-router-dom';

import { ACTION } from '../../actions/actions';
import FullScreenLoading from '../../components/FullScreenLoading';
import * as CONSTANTS from '../../constants/Constants';
import * as ROUTES from '../../constants/Routes';
import { useAppStateContext } from '../../context/appContext';
import * as aws from '../../services/awsService';
import { updateAmplifySession } from '../../services/cognitoService';
import { getJsonFromUrl } from '../../services/commonService';
import { MixPanelService } from '../../services/mixpanelService';
import { hasRepeatingOrSequentialChars } from '../../utils/passwords';
import { removeEmojis } from '../../utils/removeEmojis';

import { ConfirmCodeModal } from './confirmCodeModal';

const Register = () => {
  const INITIAL_STATE = {
    email: '',
    password: '',
    visibleName: '',
    confirmPassword: '',
  };
  const { toast } = createStandaloneToast();
  const { dispatch } = useAppStateContext();
  const history = useHistory();
  const [state, setState] = useState(INITIAL_STATE);
  const [isLoading, setIsLoading] = useState(false);
  const [reroute, setReroute] = useState(
    getJsonFromUrl(window.location.href).reroute || null
  );
  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [passwordWarning, setPasswordWarning] = useState('');

  const PASSWORD_STRENGTH_THRESHOLD = CONSTANTS.IS_ENTERPRISE ? 4 : 0;
  const PASSWORD_MIN_LENGTH = CONSTANTS.IS_ENTERPRISE ? 12 : 8;
  const consumer_score_words = [
    'Your password is very weak, we strongly encourage you to use another phrase',
    'Your password is guessable, consider adding another word to make it stronger',
    'Your password is OK, consider adding another word for stronger security',
    'Your password is strong, good job',
    "Your password is very strong, you're protected from many attacks",
  ];
  const enterprise_score_words = [
    'Your password is very weak, use phrases, numbers and special characters to make it stronger',
    'Your password is guessable, add another word/number(s)/special character(s) to make it stronger',
    'Your password is OK, add another word/number(s)/special character(s) for stronger security',
    'Your password is strong, add some complexity for maximum security',
    'Your password is very strong, you are protected from many attacks',
  ];
  const SCORE_WORDS = CONSTANTS.IS_ENTERPRISE
    ? enterprise_score_words
    : consumer_score_words;

  const signUp = async () => {
    setIsLoading(true);
    const apiName = CONSTANTS.API_VERSION;
    const path = `/${CONSTANTS.API_VERSION}/users/register`;
    const myInit = {
      headers: {
        ...CONSTANTS.DEFAULT_HEADERS,
      },
      body: {
        username: state.email,
        email: state.email,
        password: state.password,
        visibleName: state.visibleName,
      },
    };

    try {
      const res = await API.post(apiName, path, myInit);
      const userId = res.uuid;
      const email = res.email;
      MixPanelService.init(userId, email);
      MixPanelService.sendEvent('New User Registered', {
        userId: state.userId,
        $email: state.email,
      });
      setConfirmation(true);
    } catch (error) {
      setError(error.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmSignUp = (code) => {
    confirmSignUp(code).then(() => {
      // console.log(`signup confirmed`, res);
    });
  };

  const onResendConfirmationCode = () => {
    resendConfirmationCode().then(() => {
      // console.log(`confirmation code resent`, res);
    });
  };

  const confirmSignUp = async (code) => {
    setError(null);
    try {
      const success = await Auth.confirmSignUp(state.email, code);
      signIn(state.email, state.password);
    } catch (error) {
      if (error.code === 'InvalidLambdaResponseException') {
        signIn(state.email, state.password);
        setIsLoading(false);
      } else {
        setState({ ...INITIAL_STATE });
        setError(error);
        setIsLoading(false);
        // console.error('error confirming sign up', error);
      }
    }
  };

  const signIn = async (email, password) => {
    try {
      setIsLoading(true);
      const response = await aws.signIn(email, password);
      const authResponse = response.data;
      await updateAmplifySession(
        email,
        authResponse.accessToken,
        authResponse.idToken,
        authResponse.refreshToken
      );
      dispatch({
        type: ACTION.UPDATE_APP_STATE,
        payload: {
          isLoggedIn: true,
          email: email,
          username: email,
          token: authResponse.token,
          refreshToken: authResponse.refreshToken,
          uuid: authResponse.userId,
        },
      });
      if (reroute) {
        history.push(`/${reroute}`);
      } else {
        history.push(`/${ROUTES.DASHBOARD}`);
      }
    } catch (error) {
      if (error.code === CONSTANTS.USERNOTCONFIRMED_CODE) {
        setConfirmation(true);
      } else {
        setState({ ...INITIAL_STATE });
        setError(error.response?.data?.message || error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resendConfirmationCode = async () => {
    try {
      await Auth.resendSignUp(state.email);
      toast({
        title: 'Confirmation Code',
        description: `Code has been resent successfully`,
        status: 'success',
        duration: 8000,
        isClosable: true,
      });
      // console.log('code resent successfully');
    } catch (err) {
      toast({
        title: 'Confirmation Code',
        description: `error resending code: ${err}`,
        status: 'error',
        duration: 8000,
        isClosable: true,
      });
      // console.error('error resending code: ', err);
    }
  };

  const checkPasswordScore = (score) => {
    if (score >= PASSWORD_STRENGTH_THRESHOLD) {
      setIsStrongPassword(true);
    } else {
      setIsStrongPassword(false);
    }
  };

  function handleOnPasswordChange(event) {
    const updatedPassword = event.target.value;
    setState({
      ...state,
      password: updatedPassword,
    });
    const repeatedLetters = hasRepeatingOrSequentialChars(updatedPassword);
    if (repeatedLetters) {
      setPasswordWarning('Your password has repeating characters');
    } else {
      setPasswordWarning('');
    }
  }

  const isInvalid =
    state.password === '' ||
    state.password.length < PASSWORD_MIN_LENGTH ||
    state.email === '' ||
    state.password !== state.confirmPassword ||
    !state.email.includes('@') ||
    !state.email.includes('.') ||
    !isStrongPassword ||
    (passwordWarning !== '' && CONSTANTS.IS_ENTERPRISE) ||
    state.visibleName === '';
  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Box
        display="flex"
        flexGrow={'1'}
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
        overflow="auto"
        p={5}
      >
        <h1>Sign Up</h1>

        <Box
          display={'flex'}
          flexDir={'column'}
          borderRadius={6}
          boxShadow="base"
          padding={6}
        >
          <Input
            width={300}
            fontSize="sm"
            name="name"
            placeholder="Enter Name (this will be seen in VR)"
            value={state.visibleName}
            onChange={(event) => {
              setState({
                ...state,
                visibleName: removeEmojis(event.target.value),
              });
            }}
          />
          <Input
            width={300}
            fontSize="sm"
            name="email"
            placeholder="Enter Email Address"
            mt={3}
            value={state.email}
            onChange={(event) => {
              setState({
                ...state,
                email: event.target.value.trim(),
              });
            }}
          />
          <InputGroup>
            <Input
              width={'100%'}
              fontSize="sm"
              name="password"
              placeholder="Enter Password"
              mt={3}
              type={showPassword ? 'text' : 'password'}
              value={state.password}
              onChange={handleOnPasswordChange}
            />
            <InputRightElement
              style={{ marginTop: 11, marginRight: 11 }}
              onClick={() => {
                if (showPassword) {
                  setShowPassword(false);
                } else {
                  setShowPassword(true);
                }
              }}
              children={
                showPassword ? (
                  <FaEyeSlash color="gray" />
                ) : (
                  <FaEye color="gray" />
                )
              }
            />
          </InputGroup>
          <Box>
            <Box maxW="300px">
              <PasswordStrengthBar
                scoreWordStyle={{ textAlign: 'left' }}
                password={state.password}
                minLength={PASSWORD_MIN_LENGTH}
                onChangeScore={checkPasswordScore}
                shortScoreWord={`Your password is too short, you need at least ${PASSWORD_MIN_LENGTH} characters`}
                scoreWords={SCORE_WORDS}
              />
            </Box>
            <InputGroup>
              <Input
                width={'100%'}
                fontSize="sm"
                name="confirmPassword"
                placeholder="Confirm Password"
                mt={3}
                type={showConfirmPassword ? 'text' : 'password'}
                value={state.confirmPassword}
                onChange={(event) => {
                  setState({
                    ...state,
                    confirmPassword: event.target.value,
                  });
                }}
              />
              <InputRightElement
                style={{ marginTop: 11, marginRight: 11 }}
                onClick={() => {
                  setState({
                    ...state,
                    showConfirmPassword: setShowConfirmPassword(
                      !showConfirmPassword
                    ),
                  });
                }}
                children={
                  state.showConfirmPassword ? (
                    <FaEyeSlash color="gray" />
                  ) : (
                    <FaEye color="gray" />
                  )
                }
              />
            </InputGroup>
          </Box>
          {passwordWarning !== '' && (
            <Text
              textAlign="center"
              fontSize="sm"
              mb={3}
              mt={3}
              color="#E53E3E"
              maxW={300}
            >
              Your password has repeating characters or sequences, choose one
              without either.
            </Text>
          )}
          {state.confirmPassword !== '' &&
            state.password !== state.confirmPassword && (
              <Text
                textAlign="center"
                fontSize="sm"
                mb={3}
                mt={3}
                color="#E53E3E"
                maxW={300}
              >
                Passwords do not match
              </Text>
            )}
          {error && (
            <Text
              textAlign="center"
              fontSize="sm"
              mb={3}
              mt={3}
              color="#E53E3E"
              maxW={300}
            >
              {error.message}
            </Text>
          )}
          <Button
            fontSize="sm"
            id="start"
            onClick={() => signUp()}
            isDisabled={isInvalid}
            my={2}
          >
            Sign Up
          </Button>
        </Box>

        <Link
          color="blueDark"
          mt={6}
          onClick={() => {
            if (reroute) {
              history.push(`/${ROUTES.SIGNIN}?reroute=${reroute}`);
            } else {
              history.push(`/${ROUTES.SIGNIN}`);
            }
          }}
        >
          Already signed up? Sign In
        </Link>
      </Box>

      {/* Confirm Signup Modal */}
      {confirmation ? (
        <ConfirmCodeModal
          // code={state.code}
          onConfirmSignUp={onConfirmSignUp}
          onResendConfirmationCode={onResendConfirmationCode}
        />
      ) : null}
    </>
  );
};

export default Register;
