export const publicConfirmMsg = [
  `<p><b>WARNING: You are about to make your server public.</b></p>
            <p>Are you sure you want to make your server public? Everyone who enters your server
                through Remio will be able to see your data (presentations, 3D models, etc.).
                Your web dashboard will still only be accessible for your server members.
            </p>`,
  `<p><b>WARNING: You are about to make your server private.</b></p>
            <p>Are you sure you want to make your server private? Only your server members
                will have access to this server.</p>`,
  `<p><b>Sorry, this feature is not available as yet</b></p>
            <p>A public server will allow your community to reach all Remio users out there.
              We want to make sure you have everything you'll need to easily manage your community.
              We're working on a few more features to make this possible. Stay tuned!
            </p>`,
];

export const HostedModeMsg = [
  `<p>Are you sure you want to make it <b>Fully Unlocked</b>?</p>`,
  `<p>Are you sure you want to make it <b>Server Admin</b>?</p>`,
  `<p>Are you sure you want to make it <b>Fully Locked</b>?</p>`,
];

export const hostedModes = [
  {
    buttonTitle: 'Fully Unlocked',
    description:
      'Everyone has access to host controls and everyone can start games. This is a good option to use if your members will be using the server without any server admins present.',
  },
  {
    buttonTitle: 'Server Admin',
    description:
      'Under this setting, only server admins will be able to use host controls and start games. This is a good setting if all of your meetings or events will have at least one server admin present.',
  },
  {
    buttonTitle: 'Fully Locked',
    description:
      'This setting is for super admins and Remio hosts only. This should only be used for 100+ person events hosted by certified Remio hosts. Under this mode, only specialized pre-assigned personnel will be able to use host controls and start games.',
  },
];
