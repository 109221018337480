import React, { createContext, useEffect } from 'react';

import { appStateReducer } from '../reducers/appStateReducer';

export interface AppState {
  token: string;
}
export interface AppStateContainer {
  state?: AppState;
  dispatch?: React.Dispatch<any>;
}

const AppStateContext = createContext<AppStateContainer | null>(null);

const initialAppState = {
  isLoggedIn: false,
};

function getInitialState() {
  const remioState = localStorage.getItem('RemioState');

  if (remioState === null || remioState === 'undefined') {
    localStorage.setItem('RemioState', JSON.stringify(initialAppState));
  }

  return remioState ? JSON.parse(remioState) : initialAppState;
}

interface AppStateProviderProps {
  children: React.ReactElement | string | number;
}

function AppStateProvider({ children }: AppStateProviderProps) {
  const [state, dispatch] = React.useReducer(
    appStateReducer,
    getInitialState()
  );
  const value = { state, dispatch };

  useEffect(() => {
    localStorage.setItem('RemioState', JSON.stringify(state));
  }, [state]);

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
}

function useAppStateContext() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error(
      `${useAppStateContext.name} must be used within a ${AppStateProvider.name}`
    );
  }

  return context;
}

export { AppStateProvider, useAppStateContext, AppStateContext };
