import { createStandaloneToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import ColorPicker from './colorPicker';

import { ACTION } from '../../../../actions/actions';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { saveTeamColorsToDatabase } from '../../teamService';
import { isTeamAdminOrSuperUser } from '../../TeamUtils';

const TeamColorsComponent = () => {
  const { toast } = createStandaloneToast();
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [teamColors, setTeamColors] = useState(['#ffffff']);
  const [newTeamColors, setNewTeamColors] = useState('');

  useEffect(() => {
    if (teamState.teamName !== '') {
      setTeamColors(teamState.teamColors);
    }
  }, [teamState]);

  return (
    <>
      {teamState.teamName !== '' &&
      isTeamAdminOrSuperUser(state.role, teamState.teamRole) ? (
        <ColorPicker
          onChangeComplete={(color) => {
            setTeamColors([color.hex, ...teamColors.slice(1)]);
          }}
          color={teamColors[0]}
          onClose={() => {
            saveTeamColorsToDatabase(state.token, teamColors, teamState.teamId)
              .then((res) => {
                teamDispatch({
                  type: ACTION.UPDATE_TEAM_STATE,
                  payload: {
                    ...teamState,
                    teamColors,
                  },
                });
                toast({
                  title: 'Team Color',
                  description: res.message || 'Team color has updated!',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });
              })
              .catch((err) => {
                toast({
                  title: 'Team Color',
                  description:
                    'Not able to update color, please try again later.',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
              });
          }}
        />
      ) : null}
    </>
  );
};

export default TeamColorsComponent;
