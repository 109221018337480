import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Link,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';

import { PortalDto } from './PortalsService';


interface PortalItemProps {
  item: PortalDto;
  onEditClick?: (portal: PortalDto) => void;
  onDeleteClick?: (portal: PortalDto) => void;
}

export const PortalItem: React.FC<PortalItemProps> = ({
  item,
  onEditClick,
  onDeleteClick,
}) => {
  return (
    <Tr>
      <Td>{item.uuid}</Td>
      <Td>{item.doorTitle}</Td>
      <Td>{item.portalType}</Td>
      <Td>{item.max_players}</Td>
      <Td maxWidth="50px">
        <Link pr={10} onClick={() => onEditClick && onEditClick(item)}>
          <EditIcon />
        </Link>
        <Link pr={10} onClick={() => onDeleteClick && onDeleteClick(item)}>
          <DeleteIcon />
        </Link>
      </Td>
    </Tr>
  );
};

interface PortalsTableProps {
  items: PortalDto[];
  onItemEditClick?: (portal: PortalDto) => void;
  onItemDeleteClick?: (portal: PortalDto) => void;
}

export const PortalsTable: React.FC<PortalsTableProps> = ({
  items,
  onItemEditClick,
  onItemDeleteClick,
}) => {
  return (
    <Box>
      <TableContainer>
        <Table>
          <TableCaption>Portals</TableCaption>
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Door title</Th>
              <Th>Portal type</Th>
              <Th>Max players</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item) => (
              <PortalItem
                key={item.uuid}
                item={item}
                onEditClick={onItemEditClick}
                onDeleteClick={onItemDeleteClick}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
