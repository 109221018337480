import { API } from 'aws-amplify';

import {
  API_VERSION,
  DEFAULT_HEADERS,
  DEFAULT_HEADERS_V2,
} from '../../../../constants/Constants';

export const getMorphStoreItems = (
  token: string,
  limit = 100,
  lastEvaluatedKey: string | null = null
): Promise<RemioResponseV2<GetMorphStoreItemsResponse>> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/store_items`;

  const queryStringParameters: PaginationParams = { limit };
  if (lastEvaluatedKey) {
    queryStringParameters.lastEvaluatedKey = lastEvaluatedKey;
  }

  const myInit = {
    headers: {
      ...DEFAULT_HEADERS_V2,
      token: token,
    },
    queryStringParameters,
  };

  return API.get(apiName, path, myInit);
};

export const deleteMorphStoreItems = (
  token: string,
  storeItemId: string
): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/store_items/${storeItemId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
  };

  return API.del(apiName, path, myInit);
};

export const createMorphStoreItem = (
  token: string,
  storeItem: CreateMorphStoreItemDto
): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/store_items`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: storeItem,
  };

  return API.post(apiName, path, myInit);
};
