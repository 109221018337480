import {
  Box,
  Button,
  createStandaloneToast,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { ACTION } from '../../../actions/actions';
import TagSelect from '../../../components/TagSelect';
import { MEMBER_STATUS, SERVER_ROLES } from '../../../constants/Constants';
import { useAppStateContext } from '../../../context/appContext';
import { useTeamStateContext } from '../../../context/teamContext';
import {
  saveUserName,
  updateUserTags,
  getAvailableTags,
} from '../../../services/awsService';
import { isSuperUser } from '../../../services/commonService';
import { getTagOptions } from '../../../utils/getTagOptions';
import { isTeamAdminOrSuperUser } from '../TeamUtils';

import { setMemberRoleApi, updateMemberStatusApi } from './membersService';
import { getOptions } from './membersUtils';

const EditMemberModal = ({
  memberEdit,
  onCloseEditMemberModal,
  onEditMemberModalSuccess,
}) => {
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const { toast } = createStandaloneToast();
  const [isOpen, setIsOpen] = useState(true);
  const [memberId] = useState(memberEdit.uuid || '');
  const [memberVisibleName, setMemberVisibleName] = useState(
    memberEdit.visibleName || ''
  );
  const [memberRole, setMemberRole] = useState(memberEdit.role || '');
  const [memberStatus, setMemberStatus] = useState('GOOD');
  const [memberTags, setMemberTags] = useState(memberEdit.tags || []);
  const [availableTags, setAvailableTags] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const availableTagsResponse = await getAvailableTags(state.token);
      const availableTagList = JSON.parse(availableTagsResponse.body);
      setAvailableTags(availableTagList);
    }
    fetchData();
  }, [state.token]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        onCloseEditMemberModal();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter details for {memberEdit.visibleName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Text pl={2} mx={2} width={'25%'}>
              Name:{' '}
            </Text>
            <Input
              value={memberVisibleName}
              onChange={({ target: { value } }) => {
                setMemberVisibleName(value);
              }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mt={3}
          >
            <Text pl={2} mx={2} width={'25%'}>
              Role:{' '}
            </Text>
            <Select
              placeholder={memberRole}
              onChange={(e) => setMemberRole(e.target.value)}
            >
              {getOptions(SERVER_ROLES)}
            </Select>
          </Box>

          {isTeamAdminOrSuperUser(state.role, teamState.teamRole) ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mt={3}
            >
              <Text pl={2} mx={2} width={'25%'}>
                Status:{' '}
              </Text>
              <Select
                placeholder={'GOOD'}
                onChange={(e) => setMemberStatus(e.target.value)}
              >
                {getOptions(MEMBER_STATUS)}
              </Select>
            </Box>
          ) : null}

          {isSuperUser(state.role) ? (
            <>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                mt={3}
              >
                <TagSelect
                  tags={memberTags}
                  setTags={setMemberTags}
                  options={getTagOptions(availableTags)}
                />
              </Flex>
            </>
          ) : null}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              if (memberEdit.role !== memberRole && memberRole !== '') {
                setMemberRoleApi(
                  state.token,
                  memberEdit.uuid,
                  teamState.teamId,
                  parseInt(memberRole)
                )
                  .then((res) => {
                    onEditMemberModalSuccess();
                    toast({
                      title: 'Role updated successfully',
                      description: `${res.message}`,
                      status: 'success',
                      duration: 5000,
                      isClosable: true,
                    });
                    setIsOpen(false);
                  })
                  .catch((err) => {
                    const { message } = err?.response?.data || {};
                    toast({
                      title: 'Role update failed',
                      description: message || 'Please try again later',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                    });
                  });
              }

              if (memberEdit.visibleName !== memberVisibleName) {
                const userId = memberEdit.uuid;
                const teamId = teamState.teamId;
                const visibleName = memberVisibleName;

                saveUserName(state.token, {
                  userId,
                  visibleName,
                  teamId,
                }).then((res) => {
                  onEditMemberModalSuccess();
                  toast({
                    title: 'Name updated successfully',
                    description: `${res.message}`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });
                  setIsOpen(false);
                });
              }

              if (memberStatus !== 'GOOD') {
                if (memberStatus === '') return;
                updateMemberStatusApi(
                  state.token,
                  teamState.teamId,
                  memberEdit.uuid,
                  memberStatus
                ).then((res) => {
                  onEditMemberModalSuccess();
                  teamDispatch({
                    type: ACTION.UPDATE_TEAM_STATE,
                    payload: {
                      ...teamState,
                      hasBannedUserUpdated: true,
                    },
                  });
                  toast({
                    title: 'Member status updated successfully',
                    description: `${res.message}`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });
                  setIsOpen(false);
                });
              }

              if (memberTags.length > 0 && isSuperUser(state.role)) {
                if (memberTags[0] === '') return;
                updateUserTags(state.token, memberId, memberTags).then(
                  (res) => {
                    onEditMemberModalSuccess();
                    toast({
                      title: 'Member tags updated successfully',
                      description: `${res.message}`,
                      status: 'success',
                      duration: 5000,
                      isClosable: true,
                    });
                    setIsOpen(false);
                  }
                );
              }
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditMemberModal;
