import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

export const ModalComponent = ({
  header,
  body,
  confirmed,
  publicConfirmModalRef = {},
  okBtnColor = 'blue',
  OkBtnText,
  CancelBtnText = 'Cancel',
  canceled = () => {},
  children,
  isCentered = false,
  hideCloseButton = false,
  hideCancelButton = false,
  withTigger = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    publicConfirmModalRef.onOpen = onOpen;
  }, []);

  const confirmHandler = () => {
    confirmed();
    onClose();
  };

  const cancelHandler = () => {
    canceled();
    onClose();
  };

  return (
    <>
      {withTigger && <Button onClick={onOpen}>{withTigger}</Button>}
      <Modal
        isCentered={isCentered}
        onClose={onClose}
        isOpen={isOpen}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{header}</ModalHeader>
          {!hideCloseButton && <ModalCloseButton />}
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: body }} />
            {children}
          </ModalBody>
          <ModalFooter>
            {!hideCancelButton && (
              <Button variant={'cancel'} mr={3} onClick={cancelHandler}>
                {CancelBtnText}
              </Button>
            )}
            <Button onClick={confirmHandler} colorScheme={okBtnColor}>
              {OkBtnText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
