import {
  Avatar,
  Box,
  Button,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';

import { ACTION } from '../../../../actions/actions';
import FullScreenLoading from '../../../../components/FullScreenLoading';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { getTeamInfo } from '../../../../services/awsService';
import * as uploadService from '../../../../services/uploadService';
import { isTeamAdminOrSuperUser } from '../../TeamUtils';
import { uploadLogoAPI } from '../settingsService';

const ServerLogoComponent = () => {
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadImagePopoverOpen, setUploadImagePopoverOpen] = useState(false);
  const [canUploadLogo, setCanUploadLogo] = useState(false);
  const logoInput = useRef();
  const [teamColors, setTeamColors] = useState('#fff');
  const [logoDownloadLink, setLogoDownloadLink] = useState('#fff');

  useEffect(() => {
    if (teamState.teamName !== '') {
      setTeamColors(teamState.teamColors[0]);
      setLogoDownloadLink(teamState.logoDownloadLink);
    }
  }, [teamState]);

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Box width={'50%'} maxWidth={'120px'}>
        {teamState.teamName !== '' &&
        isTeamAdminOrSuperUser(state.role, teamState.teamRole) ? (
          <Popover
            isOpen={uploadImagePopoverOpen}
            onClose={() => setUploadImagePopoverOpen(false)}
          >
            <PopoverTrigger>
              <Avatar
                size="xl"
                name={teamState.teamVisibleName}
                src={logoDownloadLink}
                onMouseEnter={() => {
                  setUploadImagePopoverOpen(true);
                }}
                bg={teamColors}
                icon={<BsFillPersonFill />}
                // color={CommonFunctions.invertColor(teamColors)}
                borderWidth={1}
                borderColor={'grayLight'}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Input
                  onChange={(e) => {
                    e.target.value !== ''
                      ? setCanUploadLogo(true)
                      : setCanUploadLogo(false);
                  }}
                  type="file"
                  accept="image/x-png, image/gif, image/jpeg, image/jpg"
                  ref={logoInput}
                  m={1}
                  p={1}
                />
                <Button
                  m={1}
                  disabled={!canUploadLogo}
                  onClick={() => {
                    setIsLoading(true);
                    uploadLogoAPI(state.token, teamState.teamId)
                      .then(({ body }) => {
                        const { url, fields } = JSON.parse(body);
                        uploadService
                          .postForm(url, fields, logoInput.current.files[0])
                          .then(() => {
                            getTeamInfo(
                              state.token,
                              teamState.teamName,
                              'basics=1'
                            ).then((res) => {
                              const teamInfo = JSON.parse(res.body);
                              teamDispatch({
                                type: ACTION.UPDATE_TEAM_STATE,
                                payload: {
                                  ...teamState,
                                  ...teamInfo,
                                },
                              });
                              setIsLoading(false);
                            });
                          })
                          .catch((err) => {
                            setIsLoading(false);
                          });
                      })
                      .catch((err) => {
                        setIsLoading(false);
                      });
                  }}
                >
                  Upload File
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : null}

        {teamState.teamName !== '' &&
        !isTeamAdminOrSuperUser(state.role, teamState.teamRole) ? (
          <Avatar
            size="xl"
            name={teamState.teamVisibleName}
            src={logoDownloadLink}
            bg={teamColors}
            icon={<BsFillPersonFill />}
            // color={CommonFunctions.invertColor(teamColors)}
            borderWidth={1}
            borderColor={'grayLight'}
          />
        ) : null}
      </Box>
    </>
  );
};

export default ServerLogoComponent;
