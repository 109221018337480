import { Box, Button, Flex, createStandaloneToast } from '@chakra-ui/react';
import {
  DndContext,
  DragOverlay,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useEffect, useState } from 'react';

import AddNewPortalModal from './addNewPortalModal';
import AvailablePortalsComponent from './AvailablePortalsComponent';
import PortalCardComponent from './PortalCardComponent';
import SelectedPortalsComponent from './SelectedPortalsComponent';

import { ACTION } from '../../../actions/actions';
import FullScreenLoading from '../../../components/FullScreenLoading';
import { useAppStateContext } from '../../../context/appContext';
import { useTeamStateContext } from '../../../context/teamContext';
import { isTeamAdminOrSuperUser } from '../TeamUtils';
import { updateTeamPortalsV2 } from '../settings/settingsService';

const ServerPortalsComponent = () => {
  const { toast } = createStandaloneToast();
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [teamPortals, setTeamPortals] = useState(null);
  const [remainingPortals, setRemainingPortals] = useState(null);
  const [addPortalModalVisible, setAddPortalModalVisible] = useState(false);
  const [activePortal, setActivePortal] = useState(null);

  /**
   * If the team name is not empty (i.e. valid page), then we can set the team portals and remaining portals
   */
  useEffect(() => {
    if (teamState.teamName !== '') {
      setTeamPortals(teamState.teamPortals);
      setRemainingPortals(
        teamState.availablePortals.filter(
          (ap) => !teamState.teamPortals.some((tp) => tp.uuid === ap.uuid)
        )
      );
    }
  }, [teamState]);

  /**
   * Given a list of portals, we overwrite the team portals with the new list
   * and update the remaining portals to remove the newly selected portals
   * in the frontend and backend
   * @param {Array} portals
   */
  const updateSelectedPortals = async (portals) => {
    setIsLoading(true);
    const portalIds = [];
    portals.forEach((portal) => {
      portalIds.push(portal.uuid);
    });

    try {
      await updateTeamPortalsV2(portalIds, 0, teamState.teamId, state.token);
      setIsLoading(false);
      teamDispatch({
        type: ACTION.UPDATE_TEAM_STATE,
        payload: {
          ...teamState,
          teamPortals: portals,
        },
      });
      setRemainingPortals(
        remainingPortals.filter(
          (rp) => !portals.some((sp) => sp.uuid === rp.uuid)
        )
      );
      toast({
        title: 'Selected portals updated',
        description: 'Your selected portals have been updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Error updating portal',
        description:
          'There was an error updating your selected portals. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /**
   * Removes a portal from the team portals and updates the remaining portals
   * @param {object} portal
   */
  const deletePortalSelectHandler = (portal) => {
    return togglePortalSelect(portal, false);
  };

  /**
   * Adds a portal to the team portals and updates the remaining portals
   * @param {object} portal
   */
  const addPortalSelectHandler = (portal) => {
    return togglePortalSelect(portal, true);
  };

  const togglePortalSelect = async (portal, selected = false) => {
    setIsLoading(true);
    let updatedTeamPortals = [];

    if (selected) {
      updatedTeamPortals = [...teamPortals, portal];
    } else {
      updatedTeamPortals = teamPortals.filter((obj) => {
        return obj.uuid !== portal.uuid;
      });
    }

    const portalIds = [];
    updatedTeamPortals.forEach((portal) => {
      portalIds.push(portal.uuid);
    });

    try {
      updateTeamPortalsV2(portalIds, 0, teamState.teamId, state.token);
      setIsLoading(false);
      teamDispatch({
        type: ACTION.UPDATE_TEAM_STATE,
        payload: {
          ...teamState,
          teamPortals: updatedTeamPortals,
        },
      });
      setTeamPortals(updatedTeamPortals);
      setRemainingPortals(
        remainingPortals.filter(
          (rp) => !updatedTeamPortals.some((sp) => sp.uuid === rp.uuid)
        )
      );

      let toastTitle = 'Portal removed';
      let toastDescription = portal.doorTitle
        ? `${portal.doorTitle} was removed from you list of enabled portals`
        : 'You have removed a portal from your team';

      if (selected) {
        toastTitle = 'Portal added';
        toastDescription = portal.doorTitle
          ? `${portal.doorTitle} was added to you list of enabled portals`
          : 'You have added a portal to your team';
      }

      toast({
        title: toastTitle,
        description: toastDescription,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setIsLoading(false);

      let toastTitle = 'Error removing portal';
      let toastDescription = 'There was an error removing the portal.';
      if (selected) {
        toastTitle = 'Error adding portal';
        toastDescription = 'There was an error adding the portal.';
      }

      toast({
        title: toastTitle,
        description: toastDescription,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onPortalModalClose = (reload) => {
    setAddPortalModalVisible(false);
    if (reload) window.location.reload();
  };

  /**
   * Updates the active portal when dragging starts
   * This is so we can display an overlay, helping users understand where they are dragging
   * @param {DragStartEvent} event
   */
  const handleDragStart = (event) => {
    const { active } = event;
    setActivePortal(active.data.current.portal);
  };

  /**
   * Updates the portal arrays when dragging is finished
   * @param {DragEndEvent} event
   */
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active?.id && over?.id && active?.id !== over?.id) {
      const oldIndex = active.data.current.index;
      const newIndex = over.data.current.index;
      const newSelectedPortals = arrayMove(teamPortals, oldIndex, newIndex);
      setTeamPortals(newSelectedPortals);
      updateSelectedPortals(newSelectedPortals);
    }
  };

  // Configure sensors so drag events don't fire with a single click
  // Without sensors, clicking on the delete button will trigger a drag event
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}
      <Box
        display="flex"
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={4}
      >
        <h1>Experiences</h1>
      </Box>
      <Flex width={'100%'}>
        <Flex direction="column" width={'75%'}>
          {isTeamAdminOrSuperUser(state.role, teamState.teamRole) ? (
            <Button
              w="100%"
              maxW={256}
              size="lg"
              onClick={() => {
                setAddPortalModalVisible(true);
              }}
            >
              Add New Experience
            </Button>
          ) : null}
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            sensors={sensors}
          >
            <Box mt={2}>
              <SortableContext
                items={teamPortals ?? []}
                strategy={rectSortingStrategy}
              >
                <SelectedPortalsComponent
                  portals={teamPortals}
                  onDeleteHandler={
                    teamPortals?.length > 1 ? deletePortalSelectHandler : null
                  }
                />
              </SortableContext>
              <DragOverlay>
                {activePortal ? (
                  <PortalCardComponent
                    portal={activePortal}
                    isSelected={true}
                  />
                ) : null}
              </DragOverlay>
            </Box>
          </DndContext>
        </Flex>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          width="25%"
          overflowY="auto"
          maxHeight="80vh"
        >
          <AvailablePortalsComponent
            portals={remainingPortals}
            onAddHandler={addPortalSelectHandler}
          />
        </Box>
      </Flex>
      {addPortalModalVisible ? (
        <AddNewPortalModal onPortalModalClose={onPortalModalClose} />
      ) : null}
    </>
  );
};

export default ServerPortalsComponent;
