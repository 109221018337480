import {
  Text,
  Box,
  createStandaloneToast,
  Heading,
  Spinner,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as ROUTES from '../../constants/Routes';
import { checkEmailAndPairDevice } from '../../services/awsService';

const { toast } = createStandaloneToast();

const VerifyEmail = () => {
  const [userName, setUserName] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('uuid');
    const deviceId = params.get('deviceId');
    const oculusId = params.get('oculusId');
    const oculusUsername = params.get('oculusUsername');
    const oculusAppScopedId = params.get('oculusAppScopedId');
    const serverCode = params.get('serverCode');
    if (!userId || !deviceId) {
      window.location.replace('404');
    }

    pairDevice(
      userId,
      deviceId,
      oculusId,
      oculusUsername,
      oculusAppScopedId,
      serverCode
    );
  }, []);

  const pairDevice = async (
    userUuid,
    deviceId,
    oculusId,
    oculusUsername,
    oculusAppScopedId,
    serverCode
  ) => {
    try {
      const response = await checkEmailAndPairDevice(
        userUuid,
        deviceId,
        oculusId,
        oculusUsername,
        oculusAppScopedId,
        serverCode
      );
      // eslint-disable-next-line no-console
      console.log(response);

      const resultBody = JSON.parse(response.body);
      setUserName(resultBody['name']);
      setIsSuccess(true);
      setIsLoading(false);
      toast({
        title: 'Device paired',
        description: 'Device paired successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      toast({
        title: 'Device not paired',
        description:
          'We encountered an error trying to pair your device. Please contact support@remiovr.com',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" flexGrow={'1'} width="100%">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
        overflow="auto"
        flex={1}
        p={5}
      >
        {isLoading ? (
          <>
            <Heading color="gray" isTruncated>
              Please wait while we attempt to verify your email and pair your
              device.
            </Heading>

            <Box height="100%" alignItems="center" pt="7%">
              <Spinner
                thickness="5px"
                speed="1.5s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Box>
          </>
        ) : null}

        {!isLoading && isSuccess ? (
          <>
            <Heading color="gray" isTruncated>
              Welcome {userName}!
            </Heading>
            <Text m={5} fontSize="18px" color="gray" isTruncated>
              Your account has been successfully activated and your device is
              paired! You can now join others in Remio!
            </Text>
            <Button
              variant="solid"
              colorScheme="blue"
              alignSelf="center"
              onClick={() => history.push(`/${ROUTES.ACCOUNT}`)}
            >
              Manage Devices
            </Button>
          </>
        ) : null}

        {!isLoading && isError ? (
          <>
            <Heading color="gray" isTruncated>
              Oops! There was a problem.
            </Heading>
            <Text m={5} fontSize="18px" color="gray" isTruncated>
              Something went wrong with pairing your device.
            </Text>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default VerifyEmail;
