import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import React from 'react';

import ModelsComponent from './3dModels/3dModels';
import HologramsComponent from './holograms/holograms';
import PresentationsComponent from './presentations/Presentations';

const FilesComponent = () => {
  return (
    <>
      <h1>Manage Files</h1>
      <Tabs>
        <TabList>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            3D Models
          </Tab>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            Holograms
          </Tab>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            Presentations
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ModelsComponent />
          </TabPanel>
          <TabPanel>
            <HologramsComponent />
          </TabPanel>
          <TabPanel>
            <PresentationsComponent />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default FilesComponent;
