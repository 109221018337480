import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';

import GiftCurrency from './giftCurrency';
import GiftItems from './giftItems';

const Gifting = () => {
  return (
    <Flex
      direction="column"
      width="100%"
      height="100%"
      p={5}
      maxWidth={'1440px'}
    >
      <h1>Share Gifts With Players</h1>
      <Tabs orientation="horizontal" isLazy>
        <TabList>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            Currency
          </Tab>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            Items
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <GiftCurrency />
          </TabPanel>
          <TabPanel>
            <GiftItems />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default Gifting;
