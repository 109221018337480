import {
  Box,
  Divider,
  Grid,
  GridItem,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CSSProperties, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { UserReportDto, getUserReport } from './UserReportsService';
import { useAppStateContext } from '../../../context/appContext';
import { getUserDetailsApi } from '../../../services/awsService';
import FullScreenLoading from '../../../components/FullScreenLoading';

async function getUserEmail(
  accessToken: string,
  userId: string
): Promise<string | null> {
  try {
    const response = await getUserDetailsApi(accessToken, userId);
    return response.body
      ? (JSON.parse(response.body) as { email: string }).email
      : null;
  } catch {
    return null;
  }
}

export default function UserReport() {
  const state = useAppStateContext()?.state;
  const [report, setReport] = useState<UserReportDto | null>(null);
  const [senderEmail, setSenderEmail] = useState<string | null>(null);
  const [reportedEmails, setReportedEmails] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { reportId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        if (reportId) {
          const report = await getUserReport(state?.token ?? '', reportId);
          if (report) {
            const senderEmail = await getUserEmail(
              state?.token ?? '',
              report.senderId
            );
            setSenderEmail(senderEmail);

            const reportedEmails = await Promise.all(
              report.reportedUsers.map((id) =>
                id === report.senderId
                  ? Promise.resolve(senderEmail)
                  : getUserEmail(state?.token ?? '', id)
              )
            );
            setReportedEmails(
              reportedEmails.filter((x) => x != null) as string[]
            );
          }
          setReport(report);
        } else {
          setReport(null);
        }
      } finally {
        setIsLoading(false);
      }
    })().catch((err) => console.log(`Fetch report error ${err}`));
  }, [reportId]);

  function renderAttachment(attachment: string, index: number) {
    return attachment.startsWith('http:') || attachment.startsWith('https:') ? (
      <Link key={index} fontSize="md" href={attachment}>
        {attachment}
      </Link>
    ) : (
      <Text key={index} fontSize="md">
        {attachment}
      </Text>
    );
  }

  function renderReport(report: UserReportDto) {
    return (
      <Grid
        h="200px"
        templateRows={`repeat(${
          5 + ((report.attachments && report.attachments?.length + 1) ?? 0)
        }, 1.5em)`}
        templateColumns="repeat(8, 1fr)"
        gap={4}
      >
        <GridItem colSpan={8}>
          <Text fontSize="md" fontWeight="bold">
            Report #{report.reportId}
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Text fontSize="md" fontWeight="bold">
            Subject
          </Text>
        </GridItem>
        <GridItem colSpan={7}>
          <Text fontSize="md">{report.subject}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Text fontSize="md" fontWeight="bold">
            From
          </Text>
        </GridItem>
        <GridItem colSpan={7}>
          <Text fontSize="md">{senderEmail}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Text fontSize="md" fontWeight="bold">
            Reported users
          </Text>
        </GridItem>
        <GridItem colSpan={7}>
          <Text fontSize="md">{reportedEmails?.join('; ')}</Text>
        </GridItem>

        {report.attachments && (
          <>
            <GridItem colSpan={8}>
              <Text fontSize="md" fontWeight="bold">
                Attachments
              </Text>
            </GridItem>

            <GridItem colSpan={8} rowSpan={report.attachments.length}>
              <VStack alignItems="start" justifyContent="start">
                {report.attachments.map(renderAttachment)}
              </VStack>
            </GridItem>
          </>
        )}

        <GridItem colSpan={8}>
          <Divider orientation="horizontal" />
        </GridItem>
        <GridItem colSpan={8}>{report.message}</GridItem>
      </Grid>
    );
  }

  return (
    <Box
      display="flex"
      flexGrow={'1'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      paddingBottom={8}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        p={5}
        maxWidth={'1440px'}
      >
        {report ? (
          renderReport(report)
        ) : isLoading ? (
          <FullScreenLoading />
        ) : (
          <h3>No report found</h3>
        )}
      </Box>
    </Box>
  );
}
