import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import FullScreenLoading from '../../../../components/FullScreenLoading';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { setTeamCodeApi } from '../../../../services/awsService';

const SetServerCodeModal = ({
  expiryMinutes,
  onSetServerCodeComplete,
  onCancelSetServerCodeComplete,
}) => {
  const { teamState } = useTeamStateContext();
  const { state } = useAppStateContext();
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [expiryMins, setExpiryMins] = useState(expiryMinutes || 0);

  const setTeamCodeFn = () => {
    setIsLoading(true);

    setTeamCodeApi(state.token, teamState.teamId, { expiryMins })
      .then((res) => {
        const { teamCode, teamCodeExpiration } = JSON.parse(res.body);
        onSetServerCodeComplete(teamCode, teamCodeExpiration);
        setIsLoading(false);
        setIsOpen(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Server Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center">
                <Text pl={2} pr={1} width="50%">
                  Valid for :{' '}
                </Text>
                <Select
                  width="50%"
                  value={expiryMins}
                  onChange={(e) => {
                    const expiry = e.target.value;
                    setExpiryMins(expiry);
                  }}
                  m={1}
                  p={1}
                >
                  <option value="10">10 min</option>
                  <option value="60">60 min</option>
                  <option value="10080">1 week</option>
                  <option value="0">Forever</option>
                </Select>
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              variant={'cancel'}
              onClick={() => {
                setIsLoading(false);
                onCancelSetServerCodeComplete();
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button colorScheme="blue" mr={3} onClick={() => setTeamCodeFn()}>
              Set
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SetServerCodeModal;
