import {
  Button,
  Box,
  Tooltip,
  Text,
  createStandaloneToast,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

import FullScreenLoading from '../../components/FullScreenLoading';
import { uploadGalleryImage } from '../../services/awsService';
import { postForm } from '../../services/uploadService';

export const UploadGalleryPhoto = ({
  inputs: { accessToken, uuid, type = 'User' },
  outputs,
}) => {
  const { toast } = createStandaloneToast();
  const [file, setFile] = useState(null);
  const fileInput = useRef(null);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const FilesAmountLimit = 20; // maximum number of files per upload
  const FileSizeLimit = 1048576; // Max size per file
  const HumanFileSizeLimit = FileSizeLimit / 1024 / 1024;
  const [dragActive, setDragActive] = React.useState(false); // drag state

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileChangeHandlerFromDrop(e.dataTransfer.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    fileInput.current.click();
  };

  function checkFiles(files) {
    let acceptedFiles = [];

    if (files.length > FilesAmountLimit) {
      toast({
        title: 'You have reached your 20 file upload limit',
        description:
          "Please don't upload more than 20 files at a a time, no files were accepted please try again.",
        status: 'warning',
        duration: 9000,
        isClosable: false,
      });
      setIsLoading(false);
      acceptedFiles = [];
      setFiles([]);
      return;
    }

    files.forEach((file) => {
      if (file.size > FileSizeLimit) {
        toast({
          title: `${HumanFileSizeLimit}MB max file size limit`,
          description: `${file.name} exceeds the ${HumanFileSizeLimit}MB max file size limit, please try again.`,
          status: 'warning',
          duration: 5000,
          isClosable: false,
        });

        setFiles([]);
      } else {
        acceptedFiles.push(file);
      }
    });

    setFiles(acceptedFiles);
    return acceptedFiles;
  }

  const onFileChangeHandlerFromDrop = (files) => {
    const acceptedFiles = checkFiles(files);

    setFiles(acceptedFiles);
    setFile(files[0]);
  };

  const onFileChangeHandler = ({ target }) => {
    const acceptedFiles = checkFiles(target.files);

    setFiles(acceptedFiles);
    setFile(target.files[0]);
  };

  const uploadImageHandler = () => {
    if (files !== undefined) {
      setIsLoading(true);
      files.forEach((file) => {
        outputs({ data: true });
        uploadGalleryImage(accessToken, uuid, file.name, type)
          .then(({ url, fields }) => {
            postForm(url, fields, file)
              .then((res) => {
                toast.closeAll();
                setFile(null);
                fileInput.current.value = null;
                setIsLoading(false);
                outputs({
                  event: 'onSuccess',
                  data: {
                    message: 'Photo has been uploaded successfully',
                    file,
                  },
                });
              })
              .catch((err) => {
                // console.error(err);
                outputs({
                  event: 'onError',
                  data: 'Error on uploading photo, please try again later',
                });
              });
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            // console.error(err);
            outputs({
              event: 'onError',
              data: 'Error on uploading photo',
            });
          });
      });
    }

    setIsLoading(false);
    setFiles([]);
  };

  const formFileUploadStyle = {
    height: '100px',
    width: '400px',
    maxWidth: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginRight: '12px',
  };

  const InputFileUploadStyle = {
    display: 'none',
  };

  const labelFileUpload = {
    height: '100%',
    display: 'flex',
    width: '400px',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '2px',
    borderRadius: '1rem',
    borderStyle: 'dashed',
    borderColor: '#cbd5e1',
    backgroundColor: '#f8fafc',
    dragActive: {
      backgroundColor: '#ffffff',
    },
  };

  const dragFileElement = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    borderRadius: '1rem',
    borderStyle: 'dashed',
    borderColor: 'red',
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}
      <Text mt={6} mb={6}>
        Upload multiple files from your desktop. Accepted formats: png, gif,
        jpeg, jpg. Limited to 20 files per upload. Max file size per file is
        1MB.
      </Text>
      <Box display="flex" flexDir="column" mt={2} mb={2}>
        <Box display="flex" alignItems="center">
          <form
            style={formFileUploadStyle}
            id="form-file-upload"
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              ref={fileInput}
              type="file"
              id="input-file-upload"
              multiple={true}
              accept=".png, .gif, .jpeg, .jpg"
              onChange={onFileChangeHandler}
              style={InputFileUploadStyle}
            />
            <label
              id="label-file-upload"
              style={labelFileUpload}
              htmlFor="input-file-upload"
              className={dragActive ? 'drag-active' : ''}
            >
              <div>
                <p>Drag and drop your file here or</p>
                <button className="upload-button" onClick={onButtonClick}>
                  Upload a file
                </button>
              </div>
            </label>
            {dragActive && (
              <div
                style={dragFileElement}
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form>
          {file ? (
            <Button
              m={1}
              mr={4}
              w="150px"
              disabled={!file}
              onClick={uploadImageHandler}
            >
              Upload Files
            </Button>
          ) : null}

          <Box marginRight={6}>
            <Tooltip
              hasArrow
              placement="top-start"
              label="For best results use high quality images with at least 1920x1080 resolution and a 16:9 ratio. Accepted formats: png, gif,
        jpeg, jpg. Limited to 20 files per upload. Max file size per file is 1MB"
            >
              <span>
                <FaInfoCircle />
              </span>
            </Tooltip>
          </Box>
          <Box display={'flex'} flexDir={'column'}>
            {files &&
            typeof files != 'undefined' &&
            true &&
            files.length != null &&
            files.length >= 1 ? (
              <Text fontWeight={'semibold'}>Accepted Files</Text>
            ) : null}

            {files &&
            typeof files != 'undefined' &&
            true &&
            files.length != null &&
            files.length >= 1
              ? Object.keys(files).map((key, index) => {
                  return <div key={index}>{files[key].name}</div>;
                })
              : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};
