import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../../constants/Constants';

export function updateMetaAvatarLimit(accessToken, serverId, limit) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/meta_avatar_limit/${serverId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
    body: { limit: parseInt(limit) },
  };

  return API.put(apiName, path, myInit);
}

export function removeMetaAvatarLimit(accessToken, serverId) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/meta_avatar_limit/${serverId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.del(apiName, path, myInit);
}

export function uploadLogoAPI(accessToken, teamId) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/cloud/files/logo/upload/${teamId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.get(apiName, path, myInit);
}

export const updateTeamPortals = (portals, teamId, token) => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/portals/${teamId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: JSON.stringify(portals),
  };

  return API.put(apiName, path, myInit);
};

export const updateTeamPortalsV2 = (
  portals,
  defaultPortalIndex = 0,
  teamId,
  token
) => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/v2/portals/${teamId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: { portals, defaultPortalIndex },
  };

  return API.put(apiName, path, myInit);
};

export const updateDefaultTeamSpace = (space, teamId, token) => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/team_default_space/${teamId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: { spaceId: space.uuid },
  };

  return API.put(apiName, path, myInit);
};

export const updateServerVisibleName = (serverId, visibleName, token) => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/update_visible_name/${serverId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: { visibleName },
  };

  return API.put(apiName, path, myInit);
};
