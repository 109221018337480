import {
  Box,
  Button,
  createStandaloneToast,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Text,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AiFillCaretDown, AiOutlineLock } from 'react-icons/ai';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaPaperclip } from 'react-icons/fa';
import { MdPublic } from 'react-icons/md';

import styles from './SharePhotoModal.module.css';

import { ModalComponent } from '../../components/Modal';
import { CUSTOM_EVENTS } from '../../constants/Constants';
import {
  getServerListApi,
  getSharedServersByPhoto,
  postSharedTeamPhoto,
  updateObjectACL,
  revokeSharedAccessForServer,
} from '../../services/awsService';
import { CopyToClipboard } from '../../services/commonService';

const { toast } = createStandaloneToast();

export const SharePhotoModal = ({
  modalRef = {},
  accessToken,
  userId,
  photo,
  output = () => {},
}) => {
  const [teams, setTeams] = useState([]);
  const [loader, setLoader] = useState(false);
  const [shareLoader, setShareLoader] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isSharebale, setIsSharebale] = useState(false);
  const [publicModalRef, setPublicModalRef] = useState({});
  const [sharedServersUuid, setSharedServersUuid] = useState([]);
  const [selectLoader, setSelectLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState();

  useEffect(() => {
    getServerListApi(accessToken)
      .then(async (res) => {
        const { teams } = JSON.parse(res.body);
        setTeams(teams);
      })
      .catch((error) => {
        toast({
          title: 'Server List',
          description:
            error?.response?.data?.message ||
            `Error on fetching server, please try again later`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  }, []);

  useEffect(() => {
    photo && setIsSharebale(photo.isPublic);
    if (photo && photo.sharedServersUuid) {
      setSharedServersUuid(photo.sharedServersUuid);
    }
    if (photo && !photo.sharedServersUuid) {
      setSelectLoader(true);
      getSharedServersByPhoto(accessToken, {
        photoName: photo.name,
      }).then(({ body }) => {
        photo.shared_servers = JSON.parse(body);
        photo.sharedServersUuid = photo?.shared_servers?.map(
          (server) => server.team_uuid
        );
        setSharedServersUuid(photo.sharedServersUuid);
        setSelectLoader(false);
      });
    }
  }, [photo]);

  const sharePhotoWithTeam = () => {
    setShareLoader(true);
    postSharedTeamPhoto(accessToken, {
      teamId: selectedTeam.uuid,
      name: photo.name,
    })
      .then((res) => {
        setShareLoader(false);
        photo.sharedServersUuid.push(selectedTeam.uuid);
        setSharedServersUuid(photo.sharedServersUuid);
        setSelectedTeam(null);
        output({
          event: CUSTOM_EVENTS.sharedPhoto,
          data: { teamId: selectedTeam.uuid, photo },
        });
        toast({
          title: 'Shared photo successfully',
          description: `Photo shared with the server`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        setShareLoader(false);
        setSelectedTeam(null);
        toast({
          title: 'Share Photo Error',
          description:
            error?.response?.data?.message ||
            `Not able to share your photo, please try again later`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const getShareablePublicLink = (acl) => {
    setLoader(true);
    updateObjectACL(accessToken, { key: photo['key'], acl })
      .then((res) => {
        const { publicUrl } = JSON.parse(res.body);
        if (publicUrl && CopyToClipboard(publicUrl)) {
          setIsSharebale(publicUrl);
          toast({
            title: 'Photo is now public',
            description: 'Link copied to clipboard',
            status: 'success',
            duration: 8000,
            isClosable: true,
          });
        } else {
          setIsSharebale(false);
          CopyToClipboard('');
          toast({
            title: 'Photo is now private',
            description: 'You and selected servers can access the photo',
            status: 'success',
            duration: 8000,
            isClosable: true,
          });
        }
        setLoader(false);
        output({
          event: CUSTOM_EVENTS.shareableLink,
          data: { isPublic: !!publicUrl, publicUrl, photo },
        });
      })
      .catch((err) => {
        // console.error('err ', err);
        setLoader(false);
      });
  };

  const selectServerHandler = (target) => {
    const selected = teams.find((t) => t.teamName === target.value);
    setSelectedTeam(selected);
    output && output(selected);
  };

  const revokeSharedAccess = async (teamId, index) => {
    setDeleteLoader(index);
    const payload = { teamId, photoName: photo.name };
    try {
      const response = await revokeSharedAccessForServer(accessToken, payload);
      const _sharedServersUuid = sharedServersUuid.filter((t) => t !== teamId);
      setSharedServersUuid(_sharedServersUuid);
      photo.sharedServersUuid = _sharedServersUuid;
      photo.shared_servers = photo.shared_servers.filter(
        (t) => t.team_uuid !== teamId
      );
      toast({
        title: 'Access revoked',
        description: 'Access has been removed for the server',
        status: 'success',
        duration: 8000,
        isClosable: true,
      });
      output({
        event: CUSTOM_EVENTS.accessRevoked,
        data: { teamId, photo },
      });
    } catch (err) {
      // console.error(err);
    }
    setDeleteLoader(null);
  };

  return (
    <>
      <ModalComponent
        header={'Confirmation'}
        publicConfirmModalRef={publicModalRef}
        hideCloseButton={true}
        confirmed={() => {
          getShareablePublicLink('public-read');
        }}
        OkBtnText={'Confirm'}
      >
        Do you want to make it public?
      </ModalComponent>

      <ModalComponent
        header={'Share Photo'}
        body={``}
        publicConfirmModalRef={modalRef}
        confirmed={() => {
          setSharedServersUuid([]);
          setSelectedTeam(null);
        }}
        OkBtnText="Done"
        CancelBtnText="Cancel"
      >
        <>
          <Box mb={'30px'}>
            <Text mb={2} fontWeight={500}>
              Share photo with server
            </Text>
            <Box
              display="flex"
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              {selectLoader ? (
                <Box
                  width="100%"
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Spinner speed="0.65s" size="xs" />
                </Box>
              ) : (
                <Select
                  width={'75%'}
                  onChange={({ target }) => {
                    selectServerHandler(target);
                  }}
                  value={selectedTeam?.teamName || '#'}
                >
                  <option disabled value={'#'}>
                    {' '}
                    Select Server
                  </option>
                  {teams.map((t, i) => {
                    return (
                      !sharedServersUuid?.includes(t.uuid) && (
                        <option value={t.teamName} key={i}>
                          {' '}
                          {t.teamVisibleName}{' '}
                        </option>
                      )
                    );
                  })}
                </Select>
              )}
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                width={'30%'}
              >
                {shareLoader ? (
                  <Spinner speed="0.65s" size="xs" />
                ) : (
                  <Button
                    leftIcon={<AiOutlineLock />}
                    ml={1}
                    variant="solid"
                    disabled={!selectedTeam}
                    onClick={() => {
                      sharePhotoWithTeam();
                    }}
                  >
                    Share
                  </Button>
                )}
              </Box>
            </Box>
            {!!sharedServersUuid.length && (
              <Box my={2} mt={4}>
                <Text mb={2} fontWeight={500}>
                  Server with access
                </Text>
                <Box className={styles.accessServer_wrapper} px={'2px'}>
                  {sharedServersUuid?.map((server, index) => {
                    return teams.map((t) => {
                      return (
                        server === t.uuid && (
                          <Box
                            d={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            p={2}
                            borderRadius={10}
                            bgColor={'#F1F3F4'}
                            mb={'2px'}
                            key={index}
                          >
                            <Text>{t.teamVisibleName}</Text>
                            {deleteLoader === index ? (
                              <Spinner speed="0.65s" size="xs" />
                            ) : (
                              <Tooltip label="Revoke Access" fontSize="md">
                                <IconButton
                                  variant="solid"
                                  colorScheme="red"
                                  size="m"
                                  p={1}
                                  mx={2}
                                  icon={<AiOutlineDelete />}
                                  onClick={() => {
                                    revokeSharedAccess(t.uuid, index);
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Box>
                        )
                      );
                    });
                  })}
                </Box>
              </Box>
            )}
          </Box>
          <Box mt={4} mb={2} width={'100%'}>
            <Box mt={4}>
              <Text mb={2} fontWeight={500}>
                General access
              </Text>
              <Box
                border={'1px solid #e5e3e3'}
                borderRadius={20}
                display={'flex'}
                justifyItems="center"
                alignContent={'center'}
                height={'80px'}
                p={2}
              >
                {loader ? (
                  <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Spinner speed="0.65s" size="xs" />
                  </Box>
                ) : (
                  <>
                    <Box mt={'10px'}>
                      {isSharebale ? (
                        <MdPublic fontSize={22} color="green" />
                      ) : (
                        <AiOutlineLock fontSize={22} />
                      )}
                    </Box>
                    <Box ml={2}>
                      {isSharebale ? (
                        <>
                          /* eslint-disable */
                          <Menu isLazy offset={[1]}>
                            <MenuButton
                              as={Button}
                              size="sm"
                              rightIcon={<AiFillCaretDown />}
                            >
                              Public
                            </MenuButton>
                            <MenuList p={0}>
                              <MenuItem
                                onClick={() => {
                                  getShareablePublicLink('private');
                                }}
                              >
                                Restricted
                              </MenuItem>
                            </MenuList>
                          </Menu>
                          <Box
                            display={'flex'}
                            justifyContent={'center'}
                            mt={2}
                          >
                            <Text fontSize="sm">
                              Anyone with a link can view
                            </Text>
                            <Button
                              leftIcon={<FaPaperclip />}
                              size="xs"
                              ml={3}
                              variant="solid"
                              title={'Copy public url'}
                              onClick={() => {
                                if (CopyToClipboard(photo.publicUrl)) {
                                  toast({
                                    title: 'Link copied to clipboard',
                                    status: 'success',
                                    duration: 8000,
                                    isClosable: true,
                                  });
                                }
                              }}
                            >
                              Copy link
                            </Button>
                          </Box>
                        </>
                      ) : (
                        <>
                          /* eslint-disable */
                          <Menu isLazy offset={[1]}>
                            <MenuButton
                              as={Button}
                              size="sm"
                              rightIcon={<AiFillCaretDown />}
                            >
                              Restricted
                            </MenuButton>
                            <MenuList p={0}>
                              <MenuItem
                                onClick={() => {
                                  publicModalRef.onOpen();
                                }}
                              >
                                Anyone with a link
                              </MenuItem>
                            </MenuList>
                          </Menu>
                          <Text mt={2} fontSize="sm">
                            You and selected servers can access the photo
                          </Text>
                        </>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </>
      </ModalComponent>
    </>
  );
};
