import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';

import FullScreenLoading from '../../../components/FullScreenLoading';
import { useAppStateContext } from '../../../context/appContext';

import { addFundsToUsers } from './services/gifting';

const GiftCurrency = () => {
  const { state } = useAppStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [details, setDetails] = useState('');
  const [users, setUsers] = useState([]);
  const toast = useToast();

  const handleAmountInputChange = (e) => {
    setAmount(parseInt(e.target.value));
  };

  const handleDetailsInputChange = (e) => {
    setDetails(e.target.value);
  };

  const handleUserInputChange = (e) => {
    setUsers(e.target.value.split('\n'));
  };

  const submitGiftCurrencyRequest = async () => {
    setIsLoading(true);
    try {
      const response = await addFundsToUsers(
        state.token,
        users,
        amount,
        details
      );

      toast({
        title: 'Successfully added funds to users',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Reset state
      setAmount(0);
      setDetails('');
      setUsers([]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toast({
        title: 'Error adding funds to users',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}
      <VStack spacing={4}>
        <FormControl>
          <FormLabel htmlFor="amount">Amount</FormLabel>
          <Input
            name="amount"
            type="number"
            value={amount}
            onChange={handleAmountInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="name">Details</FormLabel>
          <Input
            name="details"
            type="text"
            value={details}
            onChange={handleDetailsInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="emails">Emails/Oculus Usernames/IDs</FormLabel>
          <Textarea
            name="emails"
            type="text"
            value={users.join('\n')}
            onChange={handleUserInputChange}
            placeholder="Enter emails separated by new lines."
          />
        </FormControl>
        <Button
          colorScheme="blue"
          onClick={submitGiftCurrencyRequest}
          isDisabled={amount === 0 || details === '' || users.length === 0}
        >
          Submit
        </Button>
      </VStack>
    </>
  );
};

export default GiftCurrency;
