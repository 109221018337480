import {
  Box,
  Text,
  IconButton,
  Image,
  WrapItem,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BiDownload, BiShareAlt, BiCheckCircle } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';

import { downloadGalleryImageFromKey } from '../services/awsService';

interface ThumbnailData {
  url?: string;
  name?: string;
  thumbnailKey?: string;
  type?: string;
}

interface ThumbnailEventData {
  image: ThumbnailData;
  index: number;
}

export type ThumbnailEventName =
  | 'onImageClick'
  | 'onCtrlClick'
  | 'onClick'
  | 'onShiftKey'
  | 'downloadImage'
  | 'sharePhoto'
  | 'deleteImage';

export interface ThumbnailEventArgs {
  event: ThumbnailEventName;
  data: ThumbnailEventData;
}

interface ThumbnailProps {
  data: ThumbnailData;
  canShare: boolean;
  canDelete: boolean;
  selected: boolean;
  index: number;
  output: (e: ThumbnailEventArgs) => unknown | void;
}

export const ThumbnailComponent: React.FC<ThumbnailProps> = ({
  data,
  canShare = true,
  canDelete = true,
  selected,
  output = () => {},
  index,
}) => {
  const [url, setUrl] = useState(data.url);

  useEffect(() => {
    if (data?.thumbnailKey) {
      downloadGalleryImageFromKey('accessToken', data.thumbnailKey).then(
        ({ body }) => {
          const { url } = JSON.parse(body);
          data.url = url;
          setUrl(url);
        }
      );
    }
  }, [data]);

  return (
    <WrapItem
      m={1}
      p={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      border={selected ? '2px solid #309795' : {}}
      borderWidth={!selected ? 1 : {}}
    >
      <Box
        display="flex"
        justifyContent="space-around"
        width="100%"
        flexDirection="column"
      >
        <Box
          className="thumbnail-container"
          maxWidth={300}
          maxHeight={300}
          display={'flex'}
          justifyContent="center"
          alignItems="center"
        >
          <Image
            objectFit="cover"
            width={selected ? '280px' : '300px'}
            height={selected ? '280px' : '300px'}
            padding="10px"
            src={url}
            alt={data.name}
            className="thumbnail-image"
            onClick={() => {
              output({
                event: 'onImageClick',
                data: { image: data, index },
              });
            }}
          />
          <Box
            className="thumbnail-middle thumbnail-image-middle"
            style={{
              opacity: selected ? 1 : undefined,
              top: selected ? '15%' : undefined,
              left: selected ? '15%' : undefined,
            }}
          >
            <Box
              color={'white'}
              onClick={(e) => {
                e.stopPropagation();
                const event =
                  e.ctrlKey || e.metaKey
                    ? 'onCtrlClick'
                    : e.shiftKey
                    ? 'onShiftKey'
                    : 'onClick';
                output({ event, data: { image: data, index } });
              }}
            >
              <IconButton
                icon={<BiCheckCircle />}
                _hover={{
                  background: selected ? 'blue' : '#afafaf',
                }}
                background={selected ? 'blue' : '#afafaf'}
                variant="solid"
                size="lg"
                p={1}
                aria-label="Select"
              />
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-around"
          width="100%"
          alignItems="center"
        >
          {/* <Tooltip label={data.name} fontSize="md"> */}
          <Text
            fontSize="xs"
            className="text-truncate"
            cursor={'pointer'}
            onClick={(e) => {
              // e.detail: 1 = single click, 2 = double click, 3 = triple click
              // var key = e.keyCode || e.charCode || 0;
              const event: ThumbnailEventName =
                e.ctrlKey || e.metaKey
                  ? 'onCtrlClick'
                  : e.shiftKey
                  ? 'onShiftKey'
                  : 'onClick';
              output({ event, data: { image: data, index } });
            }}
          >
            {data.name}
          </Text>
          {/* </Tooltip> */}
          <Tooltip label="Download" fontSize="md">
            <IconButton
              icon={<BiDownload />}
              color="white"
              variant="solid"
              size="s"
              p={1}
              aria-label="Download"
              onClick={() => {
                output({
                  event: 'downloadImage',
                  data: { image: data, index },
                });
              }}
            />
          </Tooltip>
          {canShare && (
            <Tooltip label="Share" fontSize="md">
              <IconButton
                icon={<BiShareAlt />}
                color="white"
                variant="solid"
                size="s"
                p={1}
                aria-label="Share"
                onClick={() => {
                  output({
                    event: 'sharePhoto',
                    data: { image: data, index },
                  });
                }}
              />
            </Tooltip>
          )}
          {canDelete && data.type !== 'Default' && (
            <Tooltip label="Delete" fontSize="md">
              <IconButton
                icon={<MdDelete />}
                color="white"
                variant="solid"
                size="s"
                p={1}
                aria-label="Delete"
                onClick={() => {
                  output({
                    event: 'deleteImage',
                    data: { image: data, index },
                  });
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    </WrapItem>
  );
};
