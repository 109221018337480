import { Image, Box, Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { MenuComponent } from './Menu';

import Logo from '../assets/images/remio-logo-large.png';
import * as ROUTES from '../constants/Routes';
import { useAppStateContext } from '../context/appContext';

export const HeaderComponent = () => {
  const { state } = useAppStateContext();
  const history = useHistory();

  return (
    <>
      {state.isLoggedIn ? (
        <Box width="100%" display="flex" backgroundColor="dark" p={5}>
          <Button
            variant={'clear'}
            onClick={() => history.push(`/${ROUTES.DASHBOARD}`)}
          >
            <Image src={Logo} width="155px" />
          </Button>
          <MenuComponent />
        </Box>
      ) : (
        <Box
          width="100%"
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          backgroundColor="dark"
          p={5}
          flexShrink="0"
          height={'60px'}
        >
          <Image src={Logo} width="155px" />
        </Box>
      )}
    </>
  );
};
