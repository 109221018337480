import { HamburgerIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  Drawer,
  Link,
  Tooltip,
} from '@chakra-ui/react';
import { css } from '@emotion/css';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { CgSoftwareDownload } from 'react-icons/cg';
import { useHistory, useLocation } from 'react-router-dom';

import FullScreenLoading from './FullScreenLoading';

import { ACTION } from '../actions/actions';
import * as ROUTES from '../constants/Routes';
import { useAppStateContext } from '../context/appContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { isSuperUser, detectOS } from '../services/commonService';
import { IS_ENTERPRISE } from '../constants/Constants';

export const MenuComponent = () => {
  const { state, dispatch } = useAppStateContext();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const currentPage = useLocation();
  const currentPath = currentPage.pathname.toString().substring(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [useMobile, setUseMobile] = useState(width);
  const isWindows = detectOS('Windows');

  useEffect(() => {
    if (width < 768) {
      setUseMobile(false);
    } else {
      setUseMobile(true);
    }
  }, [width]);

  function downloadStandaloneHandler() {
    window.open(window.location.origin + '/download', '_blank').focus();
  }

  function goToHelpCenter() {
    window.open('https://www.remiovr.com/help-center', '_blank');
  }

  async function signOut() {
    setIsLoading(true);
    try {
      await Auth.signOut();
      dispatch({
        type: ACTION.CLEAR_APP_STATE,
        payload: {
          isLoggedIn: false,
        },
      });
      history.push(`/${ROUTES.SIGNIN}`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // console.error('error signing out: ', error);
    }
  }

  const active = css`
    color: #e3aa3c !important;
  `;

  const btnRef = React.useRef();
  const superUser = isSuperUser(state.role || false);

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}
      {useMobile ? (
        // Desktop Menu
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <Link
              href={`/${ROUTES.DASHBOARD}`}
              _hover={{ textDecoration: 'none' }}
            >
              <Button
                variant="clear"
                color="white"
                className={currentPath === '' ? active : null}
              >
                Servers
              </Button>
            </Link>
            <Link
              href={`/${ROUTES.PEOPLE}`}
              _hover={{ textDecoration: 'none' }}
            >
              <Button
                variant="clear"
                color="white"
                className={currentPath === 'people' ? active : null}
              >
                People
              </Button>
            </Link>
            <Link
              href={`/${ROUTES.GALLERY}`}
              _hover={{ textDecoration: 'none' }}
            >
              <Button
                variant="clear"
                color="white"
                className={currentPath === 'gallery' ? active : null}
              >
                Gallery
              </Button>
            </Link>
            {IS_ENTERPRISE ? (
              <Link
                mr={'5px'}
                href={
                  currentPath.includes('team')
                    ? `/${ROUTES.CLOUD}${history.location.pathname.substring(
                        5
                      )}`
                    : `/${ROUTES.CLOUD}`
                }
                _hover={{ textDecoration: 'none' }}
              >
                <Button
                  variant="clear"
                  color="white"
                  className={currentPath === 'cloud' ? active : null}
                >
                  Web App
                  <Tooltip label="Join people who are online">
                    <InfoIcon color={'white'} />
                  </Tooltip>
                </Button>
              </Link>
            ) : null}
            <Link
              mr={'5px'}
              href={
                currentPath.includes('team')
                  ? `/${
                      ROUTES.SCREENSHARE
                    }${history.location.pathname.substring(5)}`
                  : `/${ROUTES.SCREENSHARE}`
              }
              _hover={{ textDecoration: 'none' }}
            >
              <Button
                variant="clear"
                color="white"
                className={currentPath === 'screenshare' ? active : null}
              >
                Share Screen
                <Tooltip label="Share your screen with people who are online">
                  <InfoIcon color={'white'} />
                </Tooltip>
              </Button>
            </Link>
            <Link
              href={`/${ROUTES.ADMIN}`}
              _hover={{ textDecoration: 'none' }}
              display={superUser ? 'inline' : 'none'}
            >
              <Button
                variant="clear"
                color="white"
                className={currentPath === 'people' ? active : null}
              >
                Admin
              </Button>
            </Link>
          </Box>
          <Box>
            {isWindows ? (
              <Button
                variant="ghost"
                size="sm"
                bg="white"
                color="dark"
                _hover={{ bg: 'blue', color: 'white' }}
                m={1}
                leftIcon={<CgSoftwareDownload />}
                onClick={downloadStandaloneHandler}
                display={currentPath === 'download' ? 'none' : 'inline'}
              >
                Remio for Windows
              </Button>
            ) : null}

            <Menu
              isOpen={isOpen}
              onOpen={() => setIsOpen(!isOpen)}
              onClose={() => setIsOpen(!isOpen)}
            >
              <MenuButton as={Button} variant={'clear'}>
                <HamburgerIcon boxSize={6} variant={'menuIcon'} />
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <Link
                    href={`/${ROUTES.ACCOUNT}`}
                    _hover={{ textDecoration: 'none' }}
                  >
                    Account Settings
                  </Link>
                </MenuItem>
                <MenuItem onClick={() => goToHelpCenter()}>
                  Help Center
                </MenuItem>
                <MenuItem onClick={() => signOut()}>Log Out</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Box>
      ) : (
        // Mobile Menu
        <Box display="flex" width="100%" justifyContent="End">
          <Button
            variant="clear"
            ref={btnRef}
            colorScheme="teal"
            onClick={() => setIsOpen(!isOpen)}
          >
            <HamburgerIcon boxSize={6} variant={'menuIcon'} />
          </Button>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={() => setIsOpen(!isOpen)}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent bg={'dark'}>
              <DrawerCloseButton color={'white'} />
              <DrawerHeader color={'white'}>Menu</DrawerHeader>
              <DrawerBody>
                <Button
                  variant="clear"
                  color={'white'}
                  width={'100%'}
                  mb={2}
                  onClick={() => {
                    history.push(`/${ROUTES.DASHBOARD}`);
                    setIsOpen(!isOpen);
                  }}
                >
                  Servers
                </Button>
                <Button
                  variant="clear"
                  color={'white'}
                  width={'100%'}
                  mb={2}
                  onClick={() => {
                    history.push(`/${ROUTES.GALLERY}`);
                    setIsOpen(!isOpen);
                  }}
                >
                  Gallery
                </Button>
                <Button
                  variant="clear"
                  color={'white'}
                  width={'100%'}
                  mb={2}
                  onClick={() => {
                    if (currentPath.includes('team')) {
                      history.push(
                        `/${ROUTES.CLOUD}${history.location.pathname.substring(
                          5
                        )}`
                      );
                      setIsOpen(!isOpen);
                    } else {
                      history.push(`/${ROUTES.CLOUD}`);
                      setIsOpen(!isOpen);
                    }
                  }}
                >
                  Web App
                </Button>
                <Button
                  variant="clear"
                  color={'white'}
                  width={'100%'}
                  mb={2}
                  onClick={() => {
                    if (currentPath.includes('team')) {
                      history.push(
                        `/${
                          ROUTES.SCREENSHARE
                        }${history.location.pathname.substring(5)}`
                      );
                      setIsOpen(!isOpen);
                    } else {
                      history.push(`/${ROUTES.SCREENSHARE}`);
                      setIsOpen(!isOpen);
                    }
                  }}
                >
                  Share Screen
                </Button>
                <Button
                  variant="clear"
                  color={'white'}
                  width={'100%'}
                  mb={2}
                  onClick={() => {
                    history.push(`/${ROUTES.ACCOUNT}`);
                    setIsOpen(!isOpen);
                  }}
                >
                  Account Settings
                </Button>
                <Button
                  variant="clear"
                  color={'white'}
                  width={'100%'}
                  mb={2}
                  onClick={() => {
                    goToHelpCenter();
                    setIsOpen(!isOpen);
                  }}
                >
                  Help Center
                </Button>
              </DrawerBody>

              <DrawerFooter>
                <Button
                  variant={'cancel'}
                  onClick={() => {
                    signOut();
                    setIsOpen(!isOpen);
                  }}
                  width={'100%'}
                  color={'white'}
                >
                  Log Out
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Box>
      )}
    </>
  );
};
