import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../constants/Constants';

export function deleteDeviceAPI(deviceId, userId, token) {
  const path = `/${API_VERSION}/devices/remove/${deviceId}/${userId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
  };
  return API.post(API_VERSION, path, myInit);
}

export function updateDeviceNameAPI(deviceName, deviceId, userId, token) {
  const path = `/${API_VERSION}/devices/update/${deviceId}/${userId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: { name: deviceName },
  };
  return API.put(API_VERSION, path, myInit);
}
