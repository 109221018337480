import React, { createContext, useEffect } from 'react';

import { teamStateReducer } from '../reducers/teamStateReducer';

const TeamStateContext = createContext(null);

const initialTeamState = {
  teamName: '',
};

const getInitialTeamState = () => {
  let RemioTeamState = localStorage.getItem('RemioTeamState');

  if (RemioTeamState === null || RemioTeamState === 'undefined') {
    localStorage.setItem('RemioTeamState', JSON.stringify(initialTeamState));
  }

  RemioTeamState = localStorage.getItem('RemioTeamState');

  return RemioTeamState ? JSON.parse(RemioTeamState) : initialTeamState;
};

function TeamStateProvider({ children }) {
  const [teamState, teamDispatch] = React.useReducer(
    teamStateReducer,
    getInitialTeamState()
  );

  const value = { teamState, teamDispatch };

  useEffect(() => {
    localStorage.setItem('RemioTeamState', JSON.stringify(teamState));
  }, [teamState]);

  return (
    <TeamStateContext.Provider value={value}>
      {children}
    </TeamStateContext.Provider>
  );
}

function useTeamStateContext() {
  const teamContext = React.useContext(TeamStateContext);
  if (teamContext === undefined) {
    throw new Error(
      'useTeamStateContext must be used within a TeamStateProvider component'
    );
  }

  return teamContext;
}

export { TeamStateProvider, useTeamStateContext, TeamStateContext };
