import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';

const DeleteServerModal = ({
  teamVisibleName,
  onDeleteServerSuccess,
  onDeleteServerCancel,
}) => {
  return (
    <Modal isOpen={true} isCentered closeOnOverlayClick={true}>
      <ModalOverlay />
      <ModalContent display="flex" flexDirection="column" alignItems="center">
        <ModalHeader>Warning!</ModalHeader>
        <ModalBody display="flex" flexDirection="column" alignItems="center">
          <Text textAlign="center" fontSize="sm" mb={5} mt={3}>
            Are you sure you want to permanently delete {teamVisibleName}? This
            can not be undone.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant={'cancel'}
            fontSize="sm"
            onClick={() => onDeleteServerCancel()}
            mx={2}
          >
            Cancel
          </Button>
          <Button
            fontSize="sm"
            variant="solid"
            colorScheme="red"
            _hover={{ bg: 'red' }}
            onClick={() => onDeleteServerSuccess()}
            mx={2}
          >
            Delete Server
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteServerModal;
