import { Box, Wrap } from '@chakra-ui/react';
import React, { useRef } from 'react';

export const ScrollComponent = ({
  output,
  pagination,
  children,
  height = '70vh',
}) => {
  const listInnerRef = useRef();

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const _height = scrollTop + clientHeight;
      if (
        _height === scrollHeight ||
        Math.round(_height) === Math.round(scrollHeight) ||
        _height === scrollHeight - 0.5
      ) {
        // console.log('bottom reached');
        if (pagination?.nextPage) {
          output({
            event: 'next',
            data: { nextPage: pagination.nextPage },
          });
        }
      }
    }
  };

  return (
    <Box display="flex" height={'calc(100vh - 424px)'}>
      <Wrap
        // height={height}
        width={'100%'}
        overflow="auto"
        spacing="4"
        onScroll={onScroll}
        ref={listInnerRef}
        mb={8}
      >
        {children}
      </Wrap>
    </Box>
  );
};
