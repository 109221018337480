import { API } from 'aws-amplify';
import * as qs from 'qs';

import { API_VERSION, DEFAULT_HEADERS } from '../../../constants/Constants';

export interface NewPortalDto {
  appBundleId?: string;
  oculusStoreId?: string;
  doorDescription?: string;
  portalPreviewImageName?: string;
  doorTitle?: string;
  gameName?: string;
  gameOnboardingName?: string;
  isRemioGame: boolean;
  max_players?: number;
  deepLinkMessage?: string;
}

export type PortalType = 'space' | 'game' | 'thirdP';

export interface PortalDto {
  uuid?: string;
  appBundleId?: string;
  oculusStoreId?: string;
  deepLinkMessage?: string;
  doorDescription?: string;
  doorPreview?: string;
  doorTitle?: string;
  gameName?: string;
  gameOnboardingName?: string;
  isRemioGame?: boolean;
  isPano?: boolean;
  max_players: number;
  webPreview?: string;
  webPreviewUrl?: string;
  portalType: PortalType;
}

export interface ListPortalsRequest {
  limit?: number;
  startKey?: string;
  search?: string;
  portalType?: PortalType;
}

export interface ListPortalsResponse {
  items: PortalDto[];
  lastKey?: string;
}

export async function listPortals(
  accessToken: string,
  request?: ListPortalsRequest
): Promise<ListPortalsResponse | undefined> {
  const apiName = API_VERSION!;
  const path = `/${API_VERSION}/portals?${qs.stringify(request ?? {})}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  const response: RemioResponseV1 = await API.get(apiName, path, myInit);
  return response.body && JSON.parse(response.body);
}

export async function updatePortal(
  accessToken: string,
  portalId: string,
  portal: Partial<PortalDto>
): Promise<PortalDto> {
  const apiName = API_VERSION!;
  const path = `/${API_VERSION}/portals/${portalId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
    body: portal,
  };

  const response: RemioResponseV1 = await API.put(apiName, path, myInit);
  return JSON.parse(response.body ?? '');
}

export async function createPortal(
  accessToken: string,
  portal: Partial<PortalDto>
): Promise<PortalDto> {
  const apiName = API_VERSION!;
  const path = `/${API_VERSION}/portals`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
    body: portal,
  };

  const response: RemioResponseV1 = await API.post(apiName, path, myInit);
  return JSON.parse(response.body ?? '');
}

export async function deletePortal(
  accessToken: string,
  portalId: string
): Promise<void> {
  const apiName = API_VERSION!;
  const path = `/${API_VERSION}/portals/${portalId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  await API.del(apiName, path, myInit);
}
