import { InfoIcon } from '@chakra-ui/icons';
import { Box, Button, createStandaloneToast, Tooltip } from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';

import { ACTION } from '../../../../actions/actions';
import FullScreenLoading from '../../../../components/FullScreenLoading';
import { ModalComponent } from '../../../../components/Modal';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { updateServerHostedMode } from '../../../../services/awsService';
import * as CommonFunctions from '../../../../services/commonService';
import { HostedModeMsg, hostedModes } from '../constants';

const HostedModesComponent = () => {
  const { toast } = createStandaloneToast();
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [hostedMode, setHostedMode] = useState(0);
  const [hostedModeToSet, setHostedModeToSet] = useState(0);
  const [hostedModeConfirmModalRef, setHostedModeConfirmModalRef] = useState(
    {}
  );

  useEffect(() => {
    if (teamState.teamName !== '') {
      setHostedMode(teamState.hostedMode);
    }
  }, [teamState]);

  const openModal = () => {
    hostedModeConfirmModalRef.onOpen();
  };

  const setHostedModeFn = async () => {
    setIsLoading(true);
    try {
      const res = await updateServerHostedMode(state.token, {
        hostedMode: hostedModeToSet,
        teamId: teamState.teamId,
      });
      teamDispatch({
        type: ACTION.UPDATE_TEAM_STATE,
        payload: {
          ...teamState,
          hostedMode: hostedModeToSet,
        },
      });
      setIsLoading(false);
      toast({
        title: 'Team Hosted Mode',
        description: res?.message || 'Team Hosted Mode has updated!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      setIsLoading(false);
      toast({
        title: 'Team Hosted Mode',
        description: err?.message || 'Team Hosted Mode not updated!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}

      <Box mb={4}>
        {CommonFunctions.isSuperUser(state.role) ? (
          <Box
            display={'flex'}
            flexDir={{ base: 'column', md: 'row' }}
            width={'100%'}
          >
            <Box width={'180px'}>
              Set Hosted Mode{' '}
              <Tooltip label="Controls what users in your server have access to the host controls such as muting everyone and starting games.">
                <InfoIcon color={'messageBlue'} />
              </Tooltip>
            </Box>
            <Box>
              {hostedModes.map((mode, index) => (
                <Button
                  key={index}
                  size="sm"
                  mr={2}
                  variant={'outline'}
                  title={mode.description}
                  disabled={hostedMode === index}
                  _disabled={{ opacity: '1' }}
                  bg={hostedMode === index ? 'blueDark' : null}
                  color={hostedMode === index ? 'white' : null}
                  _hover={{ bg: 'blue', color: 'white' }}
                  onClick={() => {
                    setHostedModeToSet(index);
                    openModal();
                  }}
                >
                  {mode.buttonTitle}
                </Button>
              ))}
            </Box>
          </Box>
        ) : null}
      </Box>

      <ModalComponent
        header={'Hosted Mode'}
        body={HostedModeMsg[hostedModeToSet]}
        publicConfirmModalRef={hostedModeConfirmModalRef}
        confirmed={setHostedModeFn}
        OkBtnText={'Confirm'}
      />
    </>
  );
};

export default HostedModesComponent;
