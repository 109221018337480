import { API } from 'aws-amplify';
import { API_VERSION, DEFAULT_HEADERS } from '../../../constants/Constants';

export interface UserReportDto {
  reportId: string;
  senderId: string;
  reportedUsers: string[];
  subject: string;
  message: string;
  created: string;
  updated: string;
  appName?: string;
  scope?: string;
  attachments?: string[];
}

export async function getUserReport(
  accessToken: string,
  reportId: string
): Promise<UserReportDto | null> {
  const apiName = API_VERSION!;
  const path = `/${API_VERSION}/user_reports/${reportId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  const response: RemioResponseV1 = await API.get(apiName, path, myInit);
  return response.body ? JSON.parse(response.body) : null;
}
