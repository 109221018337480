import {
  Box,
  Tab,
  Tabs,
  TabList,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import SidebarTabComponent from './SidebarTabComponent';

const SELECTED_TAB_COLOR = 'yellow';

const SidebarComponent = () => {
  const location = useLocation();
  const currentPath = location.pathname.replace('/admin/', '');
  const tabOrder = [
    'gifting',
    'shopping',
    'portals',
    'manageusers',
    'deleteusers',
    'newsboard',
  ];
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      as="nav"
      color="#989897"
      bg="dark"
      maxWidth="256px"
      display={'flex'}
      flexGrow={'1'}
      width={isMobile ? 'full' : '15%'}
    >
      <Tabs
        orientation="vertical"
        isLazy
        flexGrow={'1'}
        variant="unstyled"
        index={tabOrder.indexOf(currentPath) + 1} // We add one because the first tab is the "Admin" title
      >
        <TabList
          color="#989897"
          bg="dark"
          w="256px"
          display={'flex'}
          flexGrow={'1'}
          spacing={3}
        >
          <Tab
            backgroundColor={SELECTED_TAB_COLOR}
            p={2}
            minHeight={'45px'}
            w={'100%'}
          >
            <Text color={'dark'} fontWeight={'semibold'} textAlign={'center'}>
              Admin
            </Text>
          </Tab>
          <SidebarTabComponent path="gifting">Gifting</SidebarTabComponent>
          <SidebarTabComponent path="shopping">Shopping</SidebarTabComponent>
          <SidebarTabComponent path="portals">Portals</SidebarTabComponent>
          <SidebarTabComponent path="manageusers">
            Manage Users
          </SidebarTabComponent>
          <SidebarTabComponent path="deleteusers">
            Batch Delete Users
          </SidebarTabComponent>
          <SidebarTabComponent path="newsboard">News board</SidebarTabComponent>
        </TabList>
      </Tabs>
    </Box>
  );
};

export default SidebarComponent;
