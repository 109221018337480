import {
  Box,
  Button,
  createStandaloneToast,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { useAppStateContext } from '../../../context/appContext';
import { useTeamStateContext } from '../../../context/teamContext';
import {
  addNewPortal,
  getAppData,
  getPostPresignedUrlPortalImage,
} from '../../../services/awsService';
import * as uploadService from '../../../services/uploadService';

const AddNewPortalModal = ({ onPortalModalClose }) => {
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const { toast } = createStandaloneToast();
  const [isLoading, setIsLoading] = useState(false);
  const [appUrl, setAppUrl] = useState(null);
  const [oculusStoreId, setOculusStoreId] = useState('');
  const [appBundleId, setAppBundleId] = useState('');
  const [doorDescription, setDoorDescription] = useState('');
  const [doorTitle, setDoorTitle] = useState('');
  const [gameOnboardingName, setGameOnboardingName] = useState('');
  const [officialImageUrl, setOfficialImageUrl] = useState('');
  const [deepLinkMessage, setDeepLinkMessage] = useState('');
  const [newPortalIsLoaded, setNewPortalIsLoaded] = useState(false);
  const [addPortalManually, setAddPortalManually] = useState(false);
  const [localPortalImage, setLocalPortalImage] = useState(null);
  const [portalImageSwitch, setPortalImageSwitch] = useState(false);
  const [newAppPortalModalVisible, setNewAppPortalModalVisible] =
    useState(true);
  const [canAddNewPortalButton, setCanAddNewPortalButton] = useState(false);

  const validationForAddNewPortal = () => {
    return (
      !!appBundleId &&
      !!oculusStoreId &&
      (portalImageSwitch ? !!localPortalImage : !!officialImageUrl)
    );
  };

  const handleGetAppData = async (appUrl) => {
    if (appUrl) {
      setIsLoading(true);
      try {
        const response = await getAppData(appUrl);
        const appData = JSON.parse(response.body);

        setOculusStoreId(appData.oculusStoreId);
        setDoorDescription(appData.doorDescription);
        setDoorTitle(appData.doorTitle ? appData.doorTitle : 'None');
        setOfficialImageUrl(appData.officialImageUrl);
        setAppBundleId(appData.appBundleId);
        setNewPortalIsLoaded(true);
        setIsLoading(false);
        setPortalImageSwitch(false);
      } catch (err) {
        setNewPortalIsLoaded(true);
        setIsLoading(false);
      }
    }
  };

  const handleAddNewPortal = async () => {
    setIsLoading(true);
    if (localPortalImage) {
      try {
        const { body } = await getPostPresignedUrlPortalImage(
          state.token,
          localPortalImage.name
        );
        const { url, fields } = JSON.parse(body);
        const resp = await uploadService.postForm(
          url,
          fields,
          localPortalImage
        );
      } catch (err) {
        setIsLoading(false);
        toast({
          title: 'New Portal failed',
          description: `Error added new portal, try again later.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }
    try {
      const body = {
        localTeamUUID: teamState.teamId,
        oculusStoreId: oculusStoreId,
        doorDescription: doorDescription,
        doorTitle: doorTitle,
        appBundleId: appBundleId,
      };
      if (localPortalImage) {
        body['portalPreviewImageName'] = localPortalImage.name;
      } else {
        body['officialImageUrl'] = officialImageUrl;
      }

      if (deepLinkMessage !== '') body['deepLinkMessage'] = deepLinkMessage;

      await addNewPortal(state.token, body);

      toast({
        title: 'New Portal',
        description: 'New Portal has been added successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onPortalModalClose(true);
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'New Portal failed',
        description:
          error?.response?.data?.message ||
          'Error added new portal, request failed.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={true}
        isCentered
        size="6xl"
        closeOnOverlayClick={false}
        onClose={() => onPortalModalClose()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Remio Experience</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!oculusStoreId && !newPortalIsLoaded && (
              <>
                <Box display="flex" alignItems="center">
                  <Text>
                    Go to{' '}
                    <Link
                      color="blue"
                      href="https://www.oculus.com/experiences/quest"
                    >
                      Oculus
                    </Link>{' '}
                    and search for your app. Then copy-paste the URL address
                    below to auto-add the app info.
                  </Text>
                </Box>

                <Box display="flex" alignItems="center">
                  <Input
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setCanAddNewPortalButton(true)
                        : setCanAddNewPortalButton(false);
                      setAppUrl(e.target.value);
                    }}
                    m={1}
                    p={1}
                    placeholder="https://www.oculus.com/experiences/quest/5749594168399388"
                  />
                  <Button
                    disabled={!canAddNewPortalButton}
                    m={1}
                    w="15%"
                    onClick={() => {
                      handleGetAppData(appUrl);
                    }}
                  >
                    Add
                  </Button>
                </Box>
                <Button
                  m={1}
                  w="18%"
                  variant="outline"
                  onClick={() => {
                    setNewPortalIsLoaded(true);
                    setAddPortalManually(true);
                    setPortalImageSwitch(false);
                  }}
                >
                  Add Manually Instead
                </Button>
              </>
            )}
            {newPortalIsLoaded && (
              <>
                {!oculusStoreId && !addPortalManually && (
                  <Text>
                    <i>Add portal failed. Adding manually...</i>
                  </Text>
                )}
                <Box display="column" alignItems="center">
                  <label htmlFor={'OculusId'}>Oculus Store ID</label>
                  <Input
                    id={'OculusId'}
                    type="number"
                    value={oculusStoreId}
                    onChange={(e) => {
                      setOculusStoreId(e.target.value);
                    }}
                    m={1}
                    p={1}
                    placeholder="5749594168399388"
                  />
                  <label htmlFor={'appBundleId'} mt={1}>
                    App Bundle ID
                  </label>
                  <Input
                    id={'appBundleId'}
                    value={appBundleId}
                    onChange={(e) => {
                      setAppBundleId(e.target.value);
                    }}
                    m={1}
                    p={1}
                    placeholder="com.remio.remiovr"
                  />
                  <label htmlFor={'doorDescription'} mt={1}>
                    Door Description
                  </label>
                  <Input
                    id={'doorDescription'}
                    value={doorDescription}
                    onChange={(e) => {
                      setDoorDescription(e.target.value);
                    }}
                    m={1}
                    p={1}
                    placeholder="Perfect for team building and casual team meetings."
                  />
                  <label htmlFor={'doorTitle'} mt={1}>
                    Door Title
                  </label>
                  <Input
                    id={'doorTitle'}
                    value={doorTitle}
                    onChange={(e) => {
                      setDoorTitle(e.target.value);
                    }}
                    m={1}
                    p={1}
                    placeholder="Remio VR"
                  />
                  <label mt={1} mb={2} htmlFor="portal-image">
                    Thumbnail Image
                  </label>
                  <Box display={'flex'}>
                    <Box mr={2} fontSize="sm">
                      From URL
                    </Box>
                    <Switch
                      id="portal-image"
                      onChange={({ target }) => {
                        const _state = {};
                        if (target.checked) {
                          _state['localPortalImage'] = null;
                        }
                        setPortalImageSwitch(target.checked);
                      }}
                    />
                    <Box ml={2} fontSize="sm">
                      From Computer{' '}
                      <i>Dimensions: width: 150px, height: 200px</i>
                    </Box>
                  </Box>
                  {portalImageSwitch ? (
                    <Box display={'flex'} alignItems={'center'}>
                      <Input
                        type="file"
                        accept="image/x-png, image/gif, image/jpeg, image/jpg"
                        width={300}
                        m={1}
                        p={1}
                      />
                    </Box>
                  ) : (
                    <Input
                      value={officialImageUrl}
                      onChange={(e) => {
                        setOfficialImageUrl(e.target.value);
                      }}
                      m={1}
                      p={1}
                      placeholder="https://scontent.oculuscdn.com/v/t64.5771-25/38974864_104864000022222_2692301517852712121_n.jpg?{...}&oe=12CBCBEB"
                    />
                  )}
                  <label htmlFor={'deepLinkMessage'} mt={1}>
                    Door Deep Link Message
                  </label>
                  <Input
                    id="deepLinkMessage"
                    value={deepLinkMessage}
                    onChange={(e) => {
                      setDeepLinkMessage(e.target.value);
                    }}
                    m={1}
                    p={1}
                    placeholder="{'server':'PUBLIC','gameName':'BarVR'}"
                  />
                </Box>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {newPortalIsLoaded && (
              <>
                <Button
                  colorScheme="blue"
                  mr={3}
                  disabled={!validationForAddNewPortal()}
                  onClick={() => {
                    handleAddNewPortal();
                  }}
                >
                  Add New Experience
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddNewPortalModal;
