import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import React from 'react';

import MembersAll from './membersAll';
import MembersBanned from './membersBanned';
import MembersInvited from './membersInvited';

const MembersComponent = () => {
  return (
    <>
      <h1>Manage Members</h1>
      <Tabs>
        <TabList>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            Users
          </Tab>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            Invited
          </Tab>
          <Tab
            _selected={{ borderColor: 'blue' }}
            _focus={{ boxShadow: 'none' }}
          >
            Banned / Suspended
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <MembersAll />
          </TabPanel>
          <TabPanel>
            <MembersInvited />
          </TabPanel>
          <TabPanel>
            <MembersBanned />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default MembersComponent;
