import { Button } from '@chakra-ui/react';
import React, {
  CSSProperties,
  ChangeEventHandler,
  DragEventHandler,
  useRef,
  useState,
} from 'react';

interface FileUploadPanelProps {
  message?: string;
  allowMultiSelect: boolean;
  onFilesSelected?: (file: File[] | null) => void;
}

export const ImageUploader: React.FC<FileUploadPanelProps> = ({
  message,
  allowMultiSelect = false,
  onFilesSelected: onFileSelected,
}) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [_, setFiles] = useState<File[] | null>(null);
  const [dragActive, setDragActive] = useState<boolean>(false);

  const formFileUploadStyle: CSSProperties = {
    height: '100px',
    width: '100%',
    maxWidth: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginRight: '12px',
  };
  const fileUploadInputStyle: CSSProperties = {
    display: 'none',
  };
  const labelFileUpload: CSSProperties = {
    height: '100%',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '2px',
    borderRadius: '1rem',
    borderStyle: 'dashed',
    borderColor: '#cbd5e1',
    backgroundColor: '#f8fafc',
  };
  const lableFileUploadDragActive: CSSProperties = {
    ...labelFileUpload,
    backgroundColor: '#edf2f7',
  };
  const dragFileElement: CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    borderRadius: '1rem',
    borderStyle: 'dashed',
    borderColor: 'red',
  };

  const handleDrag: DragEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop: DragEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer?.files && e.dataTransfer.files[0]) {
      onFileChangeHandlerFromDrop(e.dataTransfer.files);
    }
  };

  const onFileChangeHandlerFromDrop = (files: FileList) => {
    const filesArray = Array.from(files);
    setFiles(filesArray);
    onFileSelected && onFileSelected(filesArray);
  };

  const onFileChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target?.files ? Array.from(e.target?.files) : null;
    setFiles(files);
    onFileSelected && onFileSelected(files);
  };

  const onUploadButtonClicked = () => {
    fileInput?.current?.click();
  };

  return (
    <form
      style={formFileUploadStyle}
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={fileInput}
        type="file"
        id="input-file-upload"
        multiple={false}
        accept=".png, .gif, .jpeg, .jpg"
        onChange={onFileChangeHandler}
        style={fileUploadInputStyle}
      />
      <label
        id="label-file-upload"
        style={dragActive ? lableFileUploadDragActive : labelFileUpload}
        htmlFor="input-file-upload"
      >
        <div>
          <p>{message ?? 'drag and drop your file here (max. 1MB) or'}</p>
          <Button className="upload-button" onClick={onUploadButtonClicked}>
            Upload a file
          </Button>
        </div>
      </label>
      {dragActive && (
        <div
          style={dragFileElement}
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
};
