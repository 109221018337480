import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../../../constants/Constants';

export const getAllStores = (token: string): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/stores`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
  };

  return API.get(apiName, path, myInit);
};

export const createStore = (
  token: string,
  newStore: CreateStoreDto
): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/stores`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: newStore,
  };

  return API.post(apiName, path, myInit);
};

export const updateStore = (
  token: string,
  storeId: string,
  newStoreData: UpdateStoreDto
): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/stores/${storeId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: newStoreData,
  };

  return API.put(apiName, path, myInit);
};

export const deleteStore = (
  token: string,
  storeId: string
): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/stores/${storeId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
  };

  return API.del(apiName, path, myInit);
};

export const getStoreItems = (
  token: string,
  storeId: string
): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/stores/${storeId}/items`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
  };

  return API.get(apiName, path, myInit);
};

export const addStoreItem = (
  token: string,
  storeId: string,
  itemId: string,
  slotId: string,
  overrides: Record<string, unknown>
): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/stores/${storeId}/items`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: { id: itemId, slotId, ...overrides },
  };

  return API.get(apiName, path, myInit);
};
