import { Badge, Spinner, Text } from '@chakra-ui/react';
import React from 'react';

import { SERVER_ROLES } from '../../../constants/Constants';
import { isTeamAdminOrSuperUser, msToTime } from '../TeamUtils';

export const getOptions = (OPTIONS) => {
  const optionsToReturn = [];
  const keys = Object.keys(OPTIONS);
  for (let i = 0; i < keys.length; i++) {
    optionsToReturn.push(
      <option value={OPTIONS[keys[i]]} key={i}>
        {keys[i]}
      </option>
    );
  }
  return optionsToReturn;
};

export const canAddParticipants = (newMembers) => {
  if (!Array.isArray(newMembers)) return false;
  if (newMembers.length === 0) return false;

  for (let i = 0; i < newMembers.length; i++) {
    const member = newMembers[i];
    if (member.visibleName === '') return false;
    if (member.email === '') return false;
    if (!member.email.includes('@')) return false;
    if (!member.email.includes('.')) return false;
  }

  return true;
};

export const getUploadStatus = (status) => {
  if (status === null) {
    return null;
  } else if (status === 'Busy') {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xs"
      />
    );
  } else {
    return (
      <Badge ml="1" colorScheme="green">
        Done
      </Badge>
    );
  }
};

export const getRoleName = (roleValue) => {
  const keys = Object.keys(SERVER_ROLES);
  for (let i = 0; i < keys.length; i++) {
    if (SERVER_ROLES[keys[i]] === roleValue) {
      return keys[i];
    }
  }
};

export const getNameWithBadge = (member, currentUserEmail) => {
  if (member.email?.props?.title === currentUserEmail) {
    return (
      <Text isTruncated>
        {member.visibleName}{' '}
        <Badge
          variant="subtle"
          bg={'blue'}
          color={'white'}
          borderRadius={2}
          size="xs"
        >
          That's you
        </Badge>
      </Text>
    );
  }

  return <Text isTruncated>{member.visibleName}</Text>;
};

export const getVerificationCode = (member, stateMember, teamRole) => {
  if (stateMember === null) return <Text isTruncated>-</Text>;

  if (member.email === stateMember.email)
    return (
      <Text isTruncated>
        {member.verificationCode === 0 ? '-' : member.verificationCode}
      </Text>
    );

  if (isTeamAdminOrSuperUser(stateMember.role, teamRole))
    return (
      <Text isTruncated>
        {member.verificationCode === 0 ? '-' : member.verificationCode}
      </Text>
    );

  return <Text isTruncated>-</Text>;
};

export const getMemberEmail = (email, stateMember, teamRole) => {
  if (stateMember === null) return '-';

  if (email === stateMember.email) {
    return (
      <Text isTruncated title={email}>
        {email}
      </Text>
    );
  }

  if (isTeamAdminOrSuperUser(stateMember.role, teamRole)) {
    return (
      <Text isTruncated title={email}>
        {email}
      </Text>
    );
  }
  return '-';
};

export const getLastSeen = (member) => {
  if (member.is_online) return 'Now';
  const now = new Date();
  const userLastSeen = new Date(member.lastSeen);
  const ms = Math.abs(now.getTime() - userLastSeen.getTime());
  if (msToTime(ms)) return msToTime(ms) + ' ago';
  return 'Never';
};

export const getMemberRoom = (member) => {
  if (!member.is_online) return <Text color="orange">Offline</Text>;

  return (
    <Text color="blue" isTruncated title={member.current_room}>
      {member.current_room}
    </Text>
  );
};

export const getIndexFromMember = (members, member) => {
  return members.findIndex((m) => m.uuid === member.uuid);
};
