import { ACTION } from '../actions/actions';

export const teamStateReducer = (state, action) => {
  switch (action.type) {
    case ACTION.UPDATE_TEAM_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ACTION.CLEAR_TEAM_STATE: {
      return {
        ...action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
