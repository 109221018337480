import {
  Box,
  Button,
  Center,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Textarea,
  UseDisclosureReturn,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React, { CSSProperties, useEffect, useState } from 'react';

import { ImageUploader } from '../../../components/ImageUploader';
import { PortalDto, PortalType } from './PortalsService';

import { getErrorMessage } from '../../../utils/errorUtils';

interface PortalModalProps extends UseDisclosureReturn {
  portal?: Partial<PortalDto>;
  doorPreviewImage?: File;
  onSave?: (
    isNew: boolean,
    portal: Partial<PortalDto>,
    previewImage: File | null
  ) => Promise<void> | void;
}

export const PortalModal: React.FC<PortalModalProps> = ({
  isOpen,
  onClose,
  onSave,
  portal,
  doorPreviewImage: initialPreviewImage,
}) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
  const [editPortal, setEditPortal] = useState<Partial<PortalDto>>({});
  const [previewImage, setPreviewImage] = useState<File | null>(null);
  const toast = useToast();

  const imagePreviewStyle: CSSProperties = {
    maxWidth: '120px',
    maxHeight: '120px',
  };

  useEffect(() => {
    setEditPortal({
      portalType: 'game',
      max_players: 20,
      ...(portal ?? {}),
    });
    setSelectedFileUrl(portal?.doorPreview ?? null);
  }, [portal]);

  useEffect(() => {
    onImageSelected(previewImage ?? initialPreviewImage ?? null);
  }, [initialPreviewImage]);

  useEffect(() => {
    setSelectedFileUrl(
      previewImage
        ? URL.createObjectURL(previewImage)
        : portal?.doorPreview ?? null
    );
  }, [previewImage]);

  function onSaveClicked() {
    (async () => {
      try {
        await (onSave &&
          onSave(typeof portal === 'undefined', editPortal, previewImage));
        onClose();
      } catch (err) {
        toast({
          title: 'Error',
          description: getErrorMessage(err),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    })();
  }

  function onImageSelected(file: File | null) {
    setPreviewImage(file);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter Portal Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="left">
            <Box>ID:</Box>
            <Input
              value={editPortal?.uuid}
              onChange={(e) =>
                setEditPortal({ ...editPortal, uuid: e.target.value })
              }
            />
            <Box>Title:</Box>
            <Input
              value={editPortal?.doorTitle}
              onChange={(e) =>
                setEditPortal({ ...editPortal, doorTitle: e.target.value })
              }
            />
            <Box>Description:</Box>
            <Textarea
              value={editPortal?.doorDescription ?? ''}
              onChange={(e) =>
                setEditPortal({
                  ...editPortal,
                  doorDescription: e.target.value,
                })
              }
            />
            <Box>Game name:</Box>
            <Input
              value={editPortal?.gameName}
              onChange={(e) =>
                setEditPortal({
                  ...editPortal,
                  gameName: e.target.value,
                })
              }
            />
            <Box>Door preview image:</Box>
            <ImageUploader
              allowMultiSelect={false}
              onFilesSelected={(files) =>
                onImageSelected(files ? files[0] : null)
              }
            />
            {selectedFileUrl && (
              <Center>
                <Image style={imagePreviewStyle} src={selectedFileUrl} />
              </Center>
            )}
            <Box>Type:</Box>
            <Select
              value={editPortal?.portalType}
              onChange={(e) =>
                setEditPortal({
                  ...editPortal,
                  portalType: e.target.value as PortalType,
                })
              }
            >
              <option value="space">Space</option>
              <option value="game">Game</option>
              <option value="thirdP">3rd Party</option>
            </Select>
            <Box>Max players:</Box>
            <NumberInput
              min={0}
              value={editPortal?.max_players}
              onChange={(valueAsString, valueAsNumber) =>
                setEditPortal({
                  ...editPortal,
                  max_players: valueAsNumber,
                })
              }
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Box>App bundle ID:</Box>
            <Input
              value={editPortal?.appBundleId}
              onChange={(e) =>
                setEditPortal({
                  ...editPortal,
                  appBundleId: e.target.value,
                })
              }
            />
            <Box>Oculus store ID:</Box>
            <Input
              value={editPortal?.oculusStoreId}
              onChange={(e) =>
                setEditPortal({
                  ...editPortal,
                  oculusStoreId: e.target.value,
                })
              }
            />
            <Box>Deep link message:</Box>
            <Textarea
              value={editPortal?.deepLinkMessage ?? ''}
              onChange={(e) =>
                setEditPortal({
                  ...editPortal,
                  deepLinkMessage: e.target.value,
                })
              }
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} variant={'ghost'} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" mr={3} onClick={onSaveClicked}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
