import {
  Box,
  Button,
  createStandaloneToast,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { CSVReader } from 'react-papaparse';

import { addTeamMemberToDatabase } from './membersService';
import {
  canAddParticipants,
  getOptions,
  getUploadStatus,
} from './membersUtils';

import { SERVER_ROLES } from '../../../constants/Constants';
import { useAppStateContext } from '../../../context/appContext';
import { useTeamStateContext } from '../../../context/teamContext';
import { MixPanelService } from '../../../services/mixpanelService';

const AddMembersModal = ({ onCloseAddMembersModal, onAddMembersSuccess }) => {
  const { state } = useAppStateContext();
  const { teamState } = useTeamStateContext();
  const { toast } = createStandaloneToast();
  const [isOpen, setIsOpen] = useState(true);
  const [newMembers, setNewMembers] = useState([
    {
      visibleName: '',
      email: '',
      role: SERVER_ROLES.PARTICIPANT,
      status: null,
    },
  ]);

  const handleOnDrop = (dataArray) => {
    setNewMembers([]);
    const newMembersToSet = [...newMembers];
    const nameColumn = 'first name';
    const emailColumn = 'email';
    let nameIndex = 0;
    let emailIndex = 0;

    dataArray.forEach((data, i) => {
      if (i === 0) {
        data.data.forEach((item, j) => {
          if (item.toLowerCase().trim() === nameColumn) {
            nameIndex = j;
          }
          if (item.toLowerCase().trim().includes(emailColumn)) {
            emailIndex = j;
          }
        });
      } else {
        newMembersToSet.push({
          visibleName: data.data[nameIndex],
          email: data.data[emailIndex],
          role: SERVER_ROLES.PARTICIPANT,
          status: null,
        });
      }
    });

    setNewMembers(newMembersToSet);
  };

  const handleOnError = (err, file, inputElem, reason) => {};

  const handleOnRemoveFile = (data) => {};

  const getUsers = () => {
    const userTagBox = [];
    const usersFiltered = newMembers;

    usersFiltered.forEach((user, i) => {
      userTagBox.push(
        <Tr key={i}>
          <Td>
            <Input
              value={user.visibleName}
              onChange={(e) => {
                const newMembersToSet = [...newMembers];
                newMembersToSet[i] = {
                  ...newMembersToSet[i],
                  visibleName: e.target.value,
                };
                setNewMembers(newMembersToSet);
              }}
              size="sm"
            />
          </Td>
          <Td>
            <Input
              value={user.email}
              onChange={(e) => {
                const newMembersToSet = [...newMembers];
                newMembersToSet[i] = {
                  ...newMembersToSet[i],
                  email: e.target.value,
                };
                setNewMembers(newMembersToSet);
              }}
              size="sm"
            />
          </Td>
          <Td>
            <Select
              value={user.role}
              onChange={(e) => {
                const newMembersToSet = [...newMembers];
                newMembersToSet[i] = {
                  ...newMembersToSet[i],
                  role: e.target.value,
                };
                setNewMembers(newMembersToSet);
                // this.setState({ newMembers: newMembersToSet });
              }}
              size="sm"
            >
              {getOptions(SERVER_ROLES)}
            </Select>
          </Td>
          <Td>
            <IconButton
              icon={<RiDeleteBin5Line />}
              size="xs"
              m={1}
              onClick={() => {
                const newMembersToSet = [...newMembers];
                newMembersToSet.splice(i, 1);
                setNewMembers(newMembersToSet);
              }}
              aria-label="delete row"
            />
            {getUploadStatus(user.status)}
          </Td>
        </Tr>
      );
    });

    return userTagBox;
  };

  const addTeamMemberToDatabaseSync = (index) => {
    let newMembersToSet = [...newMembers];
    newMembersToSet[index] = { ...newMembersToSet[index], status: 'Busy' };
    setNewMembers(newMembersToSet);
    const memberToAdd = newMembers[index];
    addTeamMemberToDatabase(state.token, memberToAdd, teamState.teamId)
      .then((res) => {
        newMembersToSet = [...newMembers];
        newMembersToSet[index] = {
          ...newMembersToSet[index],
          status: 'Done',
        };
        setNewMembers(newMembersToSet);
        MixPanelService.sendEvent('Invited Member', {
          userId: state.uuid,
          $email: memberToAdd?.email,
          teamname: teamState.teamName,
        });
        if (index < newMembers.length - 1) {
          addTeamMemberToDatabaseSync(index + 1);
        } else {
          onAddMembersSuccess();
        }
        toast({
          title: 'New members added successfully',
          description:
            'New team members have been added, they should receive an email shortly.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        newMembersToSet = [...newMembers];
        newMembersToSet[index] = {
          ...newMembersToSet[index],
          status: null,
        };
        setNewMembers(newMembersToSet);
        if (index < newMembers.length - 1) {
          addTeamMemberToDatabaseSync(index + 1);
        } else {
          onAddMembersSuccess();
        }
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        onCloseAddMembersModal();
      }}
      isCentered
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <ModalHeader>Add Server Members</ModalHeader>
          <Box
            display="flex"
            alignItems="center"
            overflow="auto"
            flexDirection="column"
          >
            <Box m={3} textAlign={'center'}>
              <a
                href="https://remio-public-development.s3.eu-west-1.amazonaws.com/Files/Member_Upload_Sample_CSV.csv"
                target="_blank"
              >
                Download Sample CSV
              </a>
              <CSVReader
                onDrop={handleOnDrop}
                onError={handleOnError}
                addRemoveButton
                onRemoveFile={handleOnRemoveFile}
              >
                <span>Drop CSV file here or click to upload.</span>
              </CSVReader>
            </Box>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Role</Th>
                </Tr>
              </Thead>
              <Tbody>{getUsers()}</Tbody>
            </Table>
            <Box>
              <Button
                variant="ghost"
                m={3}
                onClick={() => {
                  const newMembersToSet = [...newMembers];
                  newMembersToSet.push({
                    visibleName: '',
                    email: '',
                    role: SERVER_ROLES.PARTICIPANT,
                    status: null,
                  });
                  setNewMembers(newMembersToSet);
                }}
              >
                Add Row
              </Button>
              <Button
                variant={'ghost'}
                mr={3}
                onClick={() => {
                  setNewMembers([
                    {
                      visibleName: '',
                      email: '',
                      role: SERVER_ROLES.PARTICIPANT,
                      status: null,
                    },
                  ]);
                }}
              >
                Clear All
              </Button>
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            disabled={!canAddParticipants(newMembers)}
            mr={3}
            onClick={() => {
              addTeamMemberToDatabaseSync(0);
            }}
          >
            Add Members
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddMembersModal;
