import { API } from 'aws-amplify';

import {
  API_VERSION,
  DEFAULT_HEADERS,
  DEFAULT_HEADERS_V2,
} from '../../../../constants/Constants';

const regexUUID =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function isUUID(str: string): boolean {
  return regexUUID.test(str);
}

function isEmail(str: string): boolean {
  return regexEmail.test(str);
}
/**
 * From a list of users, return an object where the keys are userIds, emails and oculusUsernames.
 * It's a helper function the addFundsToUsers and addItemsToUsers functions.
 * userIds are UUIDs, emails are email addresses and oculusUsernames are any other string not in the previous categories.
 * @param users
 */
function getUserGroups(users: string[]): Record<string, string[]> {
  // Initialize the result object
  const userIds: string[] = [];
  const emails: string[] = [];
  const oculusUsernames: string[] = [];
  const result: Record<string, string[]> = {};

  // Iterate over the users and categorize each one
  users.forEach((user) => {
    if (isUUID(user)) {
      userIds.push(user);
    } else if (isEmail(user)) {
      emails.push(user);
    } else {
      oculusUsernames.push(user);
    }
  });

  // Add the categories to the result object
  if (userIds.length > 0) {
    result.userIds = userIds;
  }
  if (emails.length > 0) {
    result.emails = emails;
  }
  if (oculusUsernames.length > 0) {
    result.oculusUsernames = oculusUsernames;
  }

  return result;
}

export const addFundsToUsers = (
  token: string,
  users: string[],
  amount: number,
  details: string
): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/transactions/admin/add_funds`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: { ...getUserGroups(users), amount, details },
  };

  return API.post(apiName, path, myInit);
};

export const addItemsToUsers = (
  token: string,
  users: string[],
  storeItemIds: string[],
  details: string
): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/transactions/admin/purchase_items`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: { ...getUserGroups(users), storeItemIds, details },
  };

  return API.post(apiName, path, myInit);
};

interface GetStoreItemsMetadataResponse {
  categories: string[];
}

export const getStoreItemsMetadata = (
  token: string
): Promise<RemioResponseV2<GetStoreItemsMetadataResponse>> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/store_items/metadata`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS_V2,
      token,
    },
  };

  return API.get(apiName, path, myInit);
};

export const getStoreItemById = (
  token: string,
  storeItemId: string
): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/store_items/${storeItemId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
  };

  return API.get(apiName, path, myInit);
};

export const getStoreItemsByCategory = (
  token: string,
  category: string
): Promise<RemioResponseV1> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/store_items/categories/${category}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
  };

  return API.get(apiName, path, myInit);
};
