import { Box, Heading, Button, VStack, Text, Center } from '@chakra-ui/react';
import React, { useReducer } from 'react';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="column" w="full" flexGrow={'1'}>
      <VStack spacing={5} alignItems="center" w="full" h="full">
        <Center w="full" h="full">
          <VStack>
            <Heading color="gray" isTruncated>
              404
            </Heading>
            <Text m={5} fontSize="18px" color="gray" isTruncated>
              The page you are looking for does not exist.
            </Text>
            <Button
              colorScheme={'blue'}
              variant="solid"
              size="md"
              minW={300}
              onClick={() => {
                history.push('/');
              }}
            >
              Go Home
            </Button>
          </VStack>
        </Center>
      </VStack>
    </Box>
  );
};

export default NotFound;
