import {
  Box,
  Flex,
  Icon,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  Select,
} from '@chakra-ui/react';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import {
  BiSortDown,
  BiSortUp,
  BiChevronLeft,
  BiChevronRight,
  BiChevronsLeft,
  BiChevronsRight,
} from 'react-icons/bi';

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

function TableComponent({ columns, data, enableGlobalFilter = true }) {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const tableInstance = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      columnFilters,
      globalFilter,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  const gotoFirstPage = () => {
    tableInstance.setPageIndex(0);
  };

  const gotoPreviousPage = () => {
    tableInstance.previousPage();
  };

  const gotoNextPage = () => {
    tableInstance.nextPage();
  };

  const gotoLastPage = () => {
    tableInstance.setPageIndex(tableInstance.getPageCount() - 1);
  };

  const setPageSize = (e) => {
    tableInstance.setPageSize(Number(e.target.value));
  };

  return (
    <>
      {data && data.length ? (
        <>
          {enableGlobalFilter ? (
            <Flex>
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={(value) => setGlobalFilter(String(value))}
                className="p-2 font-lg shadow border border-block"
                placeholder="Search all columns..."
                variant="flushed"
              />
            </Flex>
          ) : null}
          <TableContainer>
            <Table variant={'striped'} colorScheme={'gray'} size="sm" mt={5}>
              <Thead>
                {tableInstance.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th key={header.id}>
                        {header.isPlaceholder ? null : (
                          <Box
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: (
                                <Icon
                                  as={BiSortDown}
                                  aria-label="sorted descending"
                                />
                              ),
                              desc: (
                                <Icon
                                  as={BiSortUp}
                                  aria-label="sorted ascending"
                                />
                              ),
                            }[header.column.getIsSorted()] ?? null}
                          </Box>
                        )}
                        <Box>
                          {header.column.getCanFilter() ? (
                            <Filter
                              column={header.column}
                              table={tableInstance}
                            />
                          ) : null}
                        </Box>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {tableInstance.getRowModel().rows.map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <Flex justifyContent="space-between" m={4} alignItems="center">
            <Flex>
              <Tooltip label="First Page">
                <IconButton
                  onClick={gotoFirstPage}
                  isDisabled={!tableInstance.getCanPreviousPage()}
                  icon={<BiChevronsLeft />}
                  mr={4}
                  aria-label={'Go to first page'}
                />
              </Tooltip>
              <Tooltip label="Previous Page">
                <IconButton
                  onClick={gotoPreviousPage}
                  isDisabled={!tableInstance.getCanPreviousPage()}
                  icon={<BiChevronLeft />}
                  aria-label={'Go to previous page'}
                />
              </Tooltip>
            </Flex>

            <Flex alignItems="center">
              <Text flexShrink="0" mr={8}>
                Page{' '}
                <Text fontWeight="bold" as="span">
                  {tableInstance.getState().pagination.pageIndex + 1}
                </Text>{' '}
                of{' '}
                <Text fontWeight="bold" as="span">
                  {tableInstance.getPageCount()}
                </Text>
              </Text>
              <Select
                w={32}
                value={tableInstance.getState().pagination.pageSize}
                onChange={setPageSize}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            </Flex>

            <Flex>
              <Tooltip label="Next Page">
                <IconButton
                  onClick={gotoNextPage}
                  isDisabled={!tableInstance.getCanNextPage()}
                  icon={<BiChevronRight />}
                  aria-label={'Go to next page'}
                />
              </Tooltip>
              <Tooltip label="Last Page">
                <IconButton
                  onClick={gotoLastPage}
                  isDisabled={!tableInstance.getCanNextPage()}
                  icon={<BiChevronsRight />}
                  ml={4}
                  aria-label={'Go to last page'}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </>
      ) : null}
    </>
  );
}

function Filter({ column, table }) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === 'number' || typeof firstValue === 'boolean'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys())
            .filter((x) => x !== undefined && x !== null)
            .sort(),
    [column.getFacetedUniqueValues()]
  );

  if (typeof firstValue === 'number') {
    return (
      <Flex>
        <DebouncedInput
          type="number"
          key={column.id + 'min'}
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={columnFilterValue?.[0] ?? ''}
          onChange={(value) =>
            column.setFilterValue((old) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ''
          }`}
        />
        <DebouncedInput
          type="number"
          key={column.id + 'max'}
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={columnFilterValue?.[1] ?? ''}
          onChange={(value) =>
            column.setFilterValue((old) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ''
          }`}
        />
      </Flex>
    );
  } else if (typeof firstValue === 'boolean') {
    return (
      <Select
        value={columnFilterValue}
        onChange={(e) => {
          column.setFilterValue(
            e.target.value === '' ? undefined : e.target.value === 'true'
          );
        }}
      >
        <option value="" key="all">
          All
        </option>
        <option value={true} key="true">
          {column.columnDef.filterText?.trueValue || 'True'}
        </option>
        <option value={false} key="false">
          {column.columnDef.filterText?.falseValue || 'False'}
        </option>
      </Select>
    );
  } else if (typeof firstValue === 'string') {
    return (
      <>
        <datalist id={column.id + 'list'}>
          {sortedUniqueValues.slice(0, 5000).map((value) => (
            <option value={value} key={value} />
          ))}
        </datalist>
        <DebouncedInput
          type="text"
          value={columnFilterValue ?? ''}
          onChange={(value) => column.setFilterValue(value)}
          placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
          list={column.id + 'list'}
        />
      </>
    );
  }
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export default TableComponent;
