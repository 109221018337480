import { API } from 'aws-amplify';
import { API_VERSION, DEFAULT_HEADERS_V2 } from '../../constants/Constants';

export function checkEmailAndPairDevice(
  payload: object
): Promise<RemioResponseV2<null>> {
  const path = `/${API_VERSION}/emails/v2/verify-email`;
  const myInit = {
    headers: DEFAULT_HEADERS_V2,
    body: payload,
  };
  return API.post(API_VERSION, path, myInit);
}
