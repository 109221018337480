import { Box } from '@chakra-ui/react';
import React from 'react';

export const FooterComponent = () => {
  return (
    <Box
      backgroundColor="dark"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={1}
      width="100%"
      color="#989897"
      // position={'absolute'}
      // bottom={'-34px'}
      flexShrink="0"
      height={'40px'}
    >
      © {new Date().getFullYear()} Remio All rights reserved
    </Box>
  );
};
