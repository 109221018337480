import mixpanel from 'mixpanel-browser';

import { MIXPANEL_TOKEN, CHANNEL_NAME } from '../constants/Constants';

class MIXPanelService {
  constructor(token, environment) {
    this.isProduction = environment === 'prod';
    this.token = token;
    this.once = true;
  }

  init(uuid, email) {
    if (this.once && this.isProduction) {
      mixpanel.identify(uuid);
      mixpanel.people.set({ email });
      this.once = false;
    }
  }

  sendEvent(eventName, data) {
    if (this.isProduction) {
      mixpanel.track(eventName, data);
    }
  }
}

export const MixPanelService = new MIXPanelService(
  MIXPANEL_TOKEN,
  CHANNEL_NAME
);
