import { API } from 'aws-amplify';

import {
  API_VERSION,
  DEFAULT_HEADERS,
  DEFAULT_HEADERS_V2,
} from '../../../constants/Constants';

export const adminGetUserByEmail = (
  token: string,
  email: string
): Promise<RemioResponseV2<UserDetails>> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/users/admin/get_user`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS_V2,
      token: token,
    },
    body: { email },
  };

  return API.post(apiName, path, myInit);
};

interface GetPurchasedItemsResponse {
  purchasedItems: PurchasedItem[];
}

export const adminGetPurchasedItems = (
  token: string,
  userId: string
): Promise<RemioResponseV2<GetPurchasedItemsResponse>> => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/purchased_items/admin/users/${userId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS_V2,
      token: token,
    },
  };

  return API.get(apiName, path, myInit);
};

export const adminDeletePurchasedItem = (
  token: string,
  userId: string,
  itemIds: string[]
) => {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/purchased_items/admin/users/${userId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: token,
    },
    body: { purchasedItems: itemIds },
  };

  return API.post(apiName, path, myInit);
};
