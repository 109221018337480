import { API } from 'aws-amplify';

import { API_VERSION, DEFAULT_HEADERS } from '../../../../constants/Constants';

export function updateVideoLinks(accessToken, teamId, videos) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/cloud/videos/${teamId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
    body: JSON.stringify(videos),
  };

  return API.post(apiName, path, myInit);
}

export function getVideoLinks(accessToken, teamId) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/cloud/videos/${teamId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };
  return API.get(apiName, path, myInit);
}
