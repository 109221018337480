import { Box, Text, Input, createStandaloneToast } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

import { ACTION } from '../../../../actions/actions';
import FullScreenLoading from '../../../../components/FullScreenLoading';
import { useAppStateContext } from '../../../../context/appContext';
import { useTeamStateContext } from '../../../../context/teamContext';
import { updateServerVisibleName } from '../settingsService';
import { isTeamAdminOrSuperUser } from '../../TeamUtils';

const VisibleNameComponent = () => {
  const { toast } = createStandaloneToast();
  const { state } = useAppStateContext();
  const { teamState, teamDispatch } = useTeamStateContext();
  const [visibleName, setVisibleName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = isTeamAdminOrSuperUser(state.role, teamState.teamRole);

  useEffect(() => {
    if (teamState.teamName !== '') {
      setVisibleName(teamState.teamVisibleName);
    }
  }, [teamState]);

  const handleVisibleNameChange = (e) => {
    setVisibleName(e.target.value);
  };

  const handleVisibleNameSubmit = async () => {
    if (visibleName === teamState.teamVisibleName) {
      return;
    }

    setIsLoading(true);
    try {
      await updateServerVisibleName(teamState.teamId, visibleName, state.token);
      setIsLoading(false);
      teamDispatch({
        type: ACTION.UPDATE_TEAM_STATE,
        payload: {
          ...teamState,
          teamVisibleName: visibleName,
        },
      });
      toast({
        title: 'Team Name',
        description: 'Team Name has updated!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      setIsLoading(false);
      toast({
        title: 'Team Name',
        description: err?.message || 'Team Name not updated!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleVisibleNameBlur = () => {
    handleVisibleNameSubmit();
  };

  const handleVisibleNameKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleVisibleNameSubmit();
    }
  };

  return (
    <>
      {isLoading ? <FullScreenLoading /> : null}
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems={'center'}
        overflow="auto"
        flex={1}
      >
        <Box width={'180px'}>
          <Text>Name</Text>
        </Box>
        <Box mb={4}>
          <Input
            id={'visibleName'}
            value={visibleName}
            onChange={handleVisibleNameChange}
            onBlur={handleVisibleNameBlur}
            onKeyDown={handleVisibleNameKeyDown}
            m={1}
            p={1}
            isDisabled={!isAdmin}
          />
        </Box>
      </Box>
    </>
  );
};

export default VisibleNameComponent;
