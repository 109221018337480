import axios from 'axios';

export function getErrorMessage(error: unknown): string {
  if (axios.isAxiosError(error)) {
    return (error.response?.data as RemioResponseV1)?.message ?? `${error}`;
  }

  return `${error}`;
}

export async function s3UploadThrowIfError(response: Response): Promise<void> {
  if (response.ok) return;

  const responseText = await response.text();
  const xmlParser = new DOMParser();
  const responseXml = xmlParser.parseFromString(responseText, 'text/xml');
  const queryResult = responseXml.evaluate(
    '/Error/Message',
    responseXml.documentElement
  );
  const messageText =
    queryResult.iterateNext()?.textContent ?? response.statusText;
  throw new Error(messageText);
}
